import React, { Component } from "react";
import { Table } from 'react-bootstrap';
import "../../assets/css/jquery.dataTables.min.css";
import authService from '../api-authorization/AuthorizeService';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline'
import ReactTable from './ReactTable'
import { Modal } from 'react-bootstrap';
import InventoryDetail from './InventoryDetail';
import { CSVLink } from "react-csv";
import { numberformat, warehouse} from '../../DateHelper';



class TruckDetail extends Component {
    constructor(props) {
        super(props);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            orderDetail: [], show: false, loading: true
        };

    }

    handleShow() {
        this.setState({ show: true });
    }
    handleClose() {
        this.setState({ show: false });
    }

    componentDidMount() {
        // this.populateOrderDetailData(this.props.sbNumber);
        this.populateSbByTruckId(this.props.sbNumber);

    }
    handleclick = (rowData) => {
        debugger;
        this.setState({
            sbNumber: rowData.original.sb_number, blNumber: rowData.original.bl_number, warehouse: rowData.original.warehouse, salvageCategory: rowData.original.salvage_category,
            containerQuantity: rowData.original.container_quantity, warehouseId: rowData.original.warehouse_id, show: true, loading: true
        });
        this.setState({ show: true });


    }
    getSBNumber = (e) => {
   
        return e.original.sb_number;
    }


    render() {
        const columns = [
            {
                Header: 'SB Number#',
                accessor: 'sb_number',
                Cell: ({ row }) => (
                    <div>
                        <a style={{ cursor: 'pointer', color: 'blue' }}
                            onClick={() => this.handleclick(row)}>
                            {this.getSBNumber(row)}
                        </a>
                    </div>
                )
            },
            {
                Header: 'BL Number#',
                accessor: 'bl_number',
            },
            {
                Header: 'Category #',
                accessor: 'salvage_category',
            },
            {
                Header: 'Wholesale',
                accessor: 'cost',
                Cell: ({ row }) => (

                    <React.Fragment>

                        {row.original.cost ? numberformat(row.original.cost) : row.original.cost}
                    </React.Fragment>
                )
            },
            {
                Header: 'Container Quantity',
                accessor: 'container_quantity',
            },

            {
                Header: 'Qty',
                accessor: 'item_quantity',
            },
            {
                Header: 'Type',
                accessor: 'hd_dot_com',
                //Cell: ({ row }) => (
                //    <React.Fragment>
                //        {row.original.wholesale ? numberformat(row.original.wholesale) : row.original.wholesale}
                //    </React.Fragment>
                //)
            },

        ]
        return (
            //<div className="DataTable_Area">
            //    <Table responsive hover id="example1" className="display" width="100%" ref={el => this.el = el}>
            //    </Table>
            //</div>
            <div>
                <CssBaseline />
                {
                    //<CSVLink
                    //    columns={columns}
                    //    data={this.state.orderDetail}
                    //    filename={"SbItem.csv"}
                    //    className="btn btn-primary"
                    //    target="_blank"
                    //>
                    //    Export To Excel
                    //        </CSVLink>
                }
                <ReactTable
                    columns={columns}
                    data={this.state.orderDetail}
                />
               

                    <Modal centered size="xl" show={this.state.show} onHide={this.handleClose} className="sb-details-modal">
                        <Modal.Header closeButton>
                            <Modal.Title>SB Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <p><strong> SB #:</strong> {this.state.sbNumber}, <strong>Bl #:</strong> {this.state.blNumber}, <strong>Warehouse:</strong> {this.state.warehouse ? warehouse(this.state.warehouse) : this.state.warehouse}, <strong>Category:</strong>{this.state.salvageCategory}, <strong>Pallet Qty:</strong> {this.state.containerQuantity}</p>
                            <InventoryDetail sbNumber={this.state.sbNumber} warehouse={this.state.warehouseId} />
                        </Modal.Body>
                    </Modal>
               





            </div>

        );

        
    
    }

    //async populateOrderDetailData(sbNumber, warehouse) {
    //    const token = await authService.getAccessToken();
    //    const response = await fetch('InventoryCode/Detail?wid=' + warehouse + '&sbn=' + sbNumber, {
    //        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    //    });
    //    const data = await response.json();
    //    for (var i = 0; i < data.manifestItemList.length; i++) {
    //        data.manifestItemList[i].wholesale = ((data.manifestItemList[i].quantity * data.manifestItemList[i].cost) * 1.15).toFixed(2);
    //        delete data.manifestItemList[i].cost;
    //    }
    //    this.setState({ orderDetail: data.manifestItemList, loading: false });

    //}

    async populateSbByTruckId(TruckNumber) {
        const token = await authService.getAccessToken();
        const response = await fetch('InventoryCode/GetSbfromTruck?orderId=' + TruckNumber, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        //const data = await response.json();
        //debugger;
        //var selectTruck = [];
        //var selectBl = [];
        //var containerquan = [];

        //if (data != null && data != "") {
        //    for (var i = 0; i < data.length; i++) {

        //        var obj = {};
        //        var obj1 = {};
        //        var obj2 = {};

        //        //obj["manifestId"] = data.[];
        //        obj["manifestId"] = data[i].sb_number;
        //        obj1["manifestId1"] = data[i].bl_number;
        //        obj2["manifestId3"] = data[i].container_quantity;
        //        selectTruck.push(Object.values(obj));
        //        selectBl.push(Object.values(obj1));
        //        containerquan.push(Object.values(obj2));
        //    }
        //}
        ////let cat = data.split(',')[0]
        //this.setState({ ShowSbs: selectTruck, showbl: selectBl, showconquan: containerquan, loading: false });
        // this.setState({ showCat: true });
        const data = await response.json();
        //for (var i = 0; i < data.manifestTruckList.length; i++) {
        //    var cost = 0.0;
        //    var cost = (data.manifestTruckList[i].cost);
        //    data.manifestTruckList[i].cost = cost.toFixed(2);
        //}
        this.setState({ orderDetail: data.manifestTruckList, loading: false });
    }

}

export default TruckDetail;
