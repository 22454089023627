import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Layout } from "../components/Layout";

class SendOrderEmail extends Component {
    render() {
        return (

            <div className="app flex-row align-items-center h-100">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="12">
                            <div className="clearfix text-center pt-5">
                                <h1 className="display-3 mr-4"></h1>
                                <button className="btn" style={{ background: '#fa7a22', color: '#fff', padding: '5px 16px' }}
                                    onClick={() => this.handleEmailclick()}
                                > Send Email
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        );
    }

    handleEmailclick = () => {

        this.SendMail();
    }

    async SendMail() {


        const response = await fetch('InventoryCode/SendOrderEmail', {

        });
        if (response.statusText == "OK")
            alert("Email Sent");
    }
}
export default SendOrderEmail;
