import React, { Component } from 'react';
import ContentLayout from "../components/ContentLayout";
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import { Col, Container, Row, Card, Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import { updateSearchList, returnUpdateSearch} from '../actions/search-actions';

class DeliveryInformation extends Component {
    constructor(props) {
        super(props);
        this.props.updateSearchList([]);
        this.props.returnUpdateSearch();
    }

  render() {
    return (
      <ContentLayout>
      <Breadcrumb
          breadcrumb={[
            { name: "Home", path: "/" },
            { name: "Delivery Information", path: "/DeliveryInformation" }
          ]}
        />
        <div className="content pt-5 pb-5">
          <Container>
            <Row className="justify-content-center">
              <Col md="12">
                <div className="animated fadeIn">
                  <Card className="rounded-0 bg-white shadow-sm">
                    <Card.Body>
                      <Card.Title>To Schedule Freight</Card.Title>
                      <Card.Text>
                        <ul style={{ listStyle: "disc", paddingLeft: "20px" }}>
                            <p>
                                All Customers are to arrange for their own pick ups.  To schedule trailers, emails are preferred to the facility location below.  Please forward a copy of your order release(s), with a note of what day you are trying to pickup.  You MUST include the order number(s) on your email to the shipping team.  </p>
                                                
                            <li className="mb-3"><strong>Title and Risk of Loss.</strong> Title and risk of loss shall pass to Purchaser at the applicable Home Depot Repair and Liquidation Center (RLC). Purchaser is solely responsible for arranging for shipping and transportation and any further sale of all Salvage Goods, including, without limitation, shipping document preparation, proper packaging and labeling, and compliance with all laws, rules and regulations related thereto, including, without limitation, those relating to export. Any claims for shortage of Salvage Goods shipped must be received by Home Depot in writing no more than (10) calendar days following the date of shipment. Home Depot shall have no responsibilities or liability for any such claims received after such ten (10) day period.</li>
                            <li><strong>Please note:</strong> We are not the shipper of record.</li>
                        </ul>
                       </Card.Text>
                        <div class="row m-3">
                            <div class="col-sm-4">
                                <div class="card card_file" id="card_file">
                                    <div class="card-body w-75">
                                        <Nav as="ul" className="category mt-4">
                                            <Nav.Item as="li">
                                                <strong><h3 class="card-title">Indy 8615- Pickup is from Facility 9815</h3></strong>
                                                                <p>1390 S. Perry Rd Plainfield, <br />   IN 46168 </p>
                                                               
                                                              
                                                                <strong>Email:</strong><a class="mailto text-primary" href="mailto: shipping8615@homedepot.com?cc=salvage@homedepot.com&subject=Order%20and%20pickup%20query%20(8615)"> shipping8615@homedepot.com</a>
                                                <p>Main Number: (XXX) XXX-XXX prompt 2 </p>
                                                <p>Mon-Thur	7:30AM &nbsp;-&nbsp; 5PM</p>
                                                <p>Friday 	6:00AM &nbsp;-&nbsp; 2:00PM</p>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="card card_file" id="card_file">
                                    <div class="card-body w-75">
                                        <Nav as="ul" className="category mt-4 pb-4">
                                            <Nav.Item as="li">
                                                <strong><h3 class="card-title">McDonough 8616</h3></strong>
                                                <p>180 Westridge Parkway, McDonough, GA 30253</p>
                                                                <strong>Email:</strong><a className="mailto text-primary" href="mailto: shipping8616@homedepot.com?cc=salvage@homedepot.com&subject=Order%20and%20pickup%20query%20(8616)"> shipping8616@homedepot.com</a>
                                                <p>Main Number: (770) 288-3590 prompt 2</p>
                                                <p>Mon-Thur	5:30AM &nbsp;-&nbsp; 1:00PM</p>
                                                <p>Friday	5:30AM &nbsp;-&nbsp; 2:00PM</p>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 card_file">
                                <div class="card "id="card_file">
                                    <div class="card-body w-75">
                                        <Nav as="ul" className="category mt-4 pb-4">
                                            <Nav.Item as="li">
                                                <strong><h3 class="card-title">Phoenix 8617</h3></strong>
                                                <p>7200 W. Buckeye Road, Phoenix, AZ 85043</p>
                                                                <strong>Email:</strong><a class="mailto text-primary" href="mailto: shipping8617@homedepot.com?cc=salvage@homedepot.com&subject=Order%20and%20pickup%20query%20(8617)"> shipping8617@homedepot.com</a>
                                                <p>Main Number: (623) 707-1850 prompt 2  </p>
                                                <p>Mon-Thur	6:00AM &nbsp;-&nbsp;12:00AM</p>
                                                <p>Friday	6:00AM &nbsp;-&nbsp;6:00PM</p>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </div>
                            </div>
                                                <div class="col-sm-4 card_file">
                                                    <div class="card " id="card_file">
                                                        <div class="card-body w-75">
                                                            <Nav as="ul" className="category mt-4 pb-4">
                                                                <Nav.Item as="li">
                                                                    <strong><h3 class="card-title">Pittston 8618</h3></strong>
                                                                    <p>200 Technology Dr, Pittston, PA 18640</p>
                                                                    <strong>Email:</strong><a class="mailto text-primary" href="mailto: shipping8618@homedepot.com?cc=salvage@homedepot.com&subject=Order%20and%20pickup%20query%20(8618)"> shipping8618@homedepot.com</a>
                                                                    <p>Main Number: (570) 300-6400 prompt 2 </p>
                                                                    <p>Mon-Fri	6:00AM &nbsp;-&nbsp;2:30AM</p>
                                                                </Nav.Item>
                                                            </Nav>
                                                        </div>
                                                    </div>
                                                </div>
                        </div>                              
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ContentLayout>
    );
  }
}

const mapActionsToProps = {
    updateSearchList: updateSearchList,
    returnUpdateSearch: returnUpdateSearch
}

export default connect(null, mapActionsToProps)(DeliveryInformation);
