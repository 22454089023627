import React, { Component } from 'react';
import ContentLayout from "../components/ContentLayout";
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import { Col, Container, Row, Card, Nav, Form, Button, InputGroup, Accordion } from 'react-bootstrap';
import authService from '../components/api-authorization/AuthorizeService';
//import { ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { customerPayment } from '../DateHelper';
import { CSVLink } from "react-csv";
import { updateSearchList, returnUpdateSearch } from '../actions/search-actions';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';



class MyProfile extends Component {
    constructor(props) {
        super(props);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            profileData: {}, loading: true, Status: {}, show: false, ConfirmPassword: "", OldPassword: "", NewPassword: "", UnConfirmedEmail:"",
            thd: {}, addressdata: {}, categoryRate:[]
        };
        this.OldPassword = this.OldPassword.bind(this);
        this.NewPassword = this.NewPassword.bind(this);
        this.ConfirmPassword = this.ConfirmPassword.bind(this);
        this.UnConfirmedEmail = this.UnConfirmedEmail.bind(this);
        this.props.updateSearchList([]);
        this.props.returnUpdateSearch();
        // this.handleClick = this.handleClick.bind(this);
    }
    componentWillMount() {
        this.populateProfileData();
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }
    OldPassword(event) {
        this.setState({ OldPassword: event.target.value })
    }
    NewPassword(event) {
        this.setState({ NewPassword: event.target.value })
    }
    ConfirmPassword(event) {
        this.setState({ ConfirmPassword: event.target.value })
    }
    UnConfirmedEmail(event) {
        this.setState({ UnConfirmedEmail: event.target.value })
    }
    changeEmail() {
        this.changeUserEmail();
    }

    changePassword() {
        this.changeUserPassword();
    }

    async changeUserPassword() {
        const formData = new FormData();
        formData.append("OldPassword", this.state.OldPassword);
        formData.append("NewPassword", this.state.NewPassword);
        formData.append("ConfirmPassword", this.state.ConfirmPassword);
        const token = await authService.getAccessToken();
        const response = await fetch('Account/ChangePassword', {
            method: 'post',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });
        const data = await response.json();
        this.setState({ Status: data });
    }

    async changeUserEmail() {
        const formData = new FormData();
        formData.append("UnConfirmedEmail", this.state.UnConfirmedEmail);
        formData.append("ConfirmedEmail", this.state.thd.confirmedEmail);
        const token = await authService.getAccessToken();
        const response = await fetch('Account/ChangeEmail', {
            method: 'post',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });
        const data = await response.json();
        this.setState({ Status: data });
    }
    navigateToInventory = () => {
        this.props.history.push({
            pathname: "/Inventory"
        })
    }
    navigateToRegistration = () => {
        this.props.history.push({
            pathname: '/RegistrationUsers'
        })
    }
    render() {
        //const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
        const { loading, profileData } = this.state;
        return (
            <ContentLayout>
                <Breadcrumb
                    breadcrumb={[
                        { name: "Home", path: "/" },
                        { name: "My Profile", path: "/MyProfile" }
                    ]}
                />


                <div className="content profile-content pt-5 pb-5">
                    <Button className="text-white" onClick={() => this.navigateToInventory()} variant="outline-secondary" style={{ backgroundColor: "#273546" }}><ArrowBackIcon/> <span>Back to Inventory</span></Button>
                    <Container>
                        <Row className="justify-content-center">

                            <Col lg="4">

                                <Card className="rounded-0 bg-white shadow-sm">
                                    <Card.Body className="p-0">
                                        <Card.Text>
                                            <Nav defaultActiveKey="" as="ul" className="d-block">
                                                {/*<Nav.Item as="li">*/}
                                                {/*    <Nav.Link href="/MyOrders"><i className="fas fa-book"></i> My Orders</Nav.Link>*/}
                                                {/*</Nav.Item>*/}
                                                <Nav.Item as="li">
                                                    <Nav.Link href=""><i className="fas fa-user"></i> Account Settings</Nav.Link>
                                                    <Nav as="ul" className="pr-inform-List d-block pl-4 pt-3 pb-3">
                                                        {this.state.thd.masterUser != null ?
                                                            <Nav.Item as="li">
                                                                <Nav.Link href="" onClick={() => this.navigateToRegistration()}><i className="fas fa-book"></i> Pending Registrations Sub Account</Nav.Link>
                                                            </Nav.Item> : null}
                                                    </Nav>
                                                    {/*<Nav as="ul" className="pr-inform-List d-block pl-4 pt-3 pb-3">*/}
                                                    {/*    <Nav.Item as="li">*/}
                                                    {/*        <Nav.Link href="/MyProfile">Profile Information</Nav.Link>*/}
                                                    {/*    </Nav.Item>*/}
                                                    {/*    <Nav.Item as="li">*/}
                                                    {/*        <Nav.Link href="/MyProfile">Change Password</Nav.Link>*/}
                                                    {/*    </Nav.Item>*/}
                                                    {/*</Nav>*/}
                                                </Nav.Item>
                                                {/*<Nav.Item as="li">*/}
                                                {/*    <Nav.Link href="/MyProfile"><i className="fas fa-power-off"></i> Logout</Nav.Link>*/}
                                                {/*</Nav.Item>*/}
                                            </Nav>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                <Card className="change-your-password rounded-0 bg-white shadow-sm mt-4" id="ChangePassword">
                                    <Card.Body>
                                        <Card.Text>
                                            <p>You’re logged in as</p><strong>{this.state.thd.confirmedEmail}</strong>
                                            <p><strong style={{ color: 'green' }}>{this.state.Status.status === "Success" ? this.state.Status.statusMessage : ""}</strong></p>
                                            {/* <p><strong style={{ color: 'red' }}>{this.state.Status.status === "Fail" ? this.state.Status.statusMessage : ""}</strong></p>*/}
                                            {this.state.Status.status === "Fail" ? this.state.Status.errorMessages.map((message) => {
                                                return( <p><strong style={{ color: 'red' }}>{message}</strong></p>)
                                            }) : null}
  
                                            <Form>
                                                <Form.Label>Current Password *</Form.Label>
                                                <Form.Control type="Password" onChange={this.OldPassword} placeholder="Current Password" />
                                                <Form.Label>New Password *</Form.Label>
                                                <Form.Control type="password" onChange={this.NewPassword} placeholder="New Password" />
                                                <Form.Label>Confirm Password *</Form.Label>
                                                <Form.Control type="password" onChange={this.ConfirmPassword} placeholder="Confirm Password" />
                                                <Button variant="primary" onClick={() => { this.changePassword() }}>
                                                    Change Password
                                                    </Button>
                                            </Form>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg="8">
                                <div className="animated fadeIn">

                                    <Card className="rounded-0 bg-white shadow-sm ProfileInformation_Col">
                                        <Card.Body>
                                            <Card.Text>

                                                <Form>
                                                    <Form.Label>Personal Information</Form.Label>
                                                    {this.state.thd.customerPaymentOptions == 1 || this.state.thd.customerPaymentOptions == 3 ?
                                                    <CSVLink
                                                        //columns={columns}
                                                        data={this.state.categoryRate}
                                                        filename={"CategoryRate.csv"}
                                                        className="btn text-white"
                                                        target="_blank"
                                                        style={{ float: 'right', backgroundColor:'#fd7e14'}}
                                                    >   Export Category Rates
                                                    </CSVLink>
                                                        : null
                                                    }
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="formGridFName">
                                                            <Form.Control type="text" value={this.state.profileData.firstName} readOnly />
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridLName">
                                                            <Form.Control type="text" value={this.state.profileData.lastName} readOnly />
                                                        </Form.Group>
                                                    </Form.Row>{
                                                        //<Form.Label>Your Gender</Form.Label>
                                                        //<Form.Row>
                                                        //    <Form.Group as={Col}>
                                                        //        <Form.Check
                                                        //            custom
                                                        //            inline
                                                        //            // checked
                                                        //            type="radio"
                                                        //            label="Male"
                                                        //            name="formHorizontalRadios"
                                                        //            id="formHorizontalRadios1"
                                                        //            className="mr-3"
                                                        //        />
                                                        //        <Form.Check
                                                        //            custom
                                                        //            inline
                                                        //            checked
                                                        //            type="radio"
                                                        //            label="Female"
                                                        //            name="formHorizontalRadios"
                                                        //            id="formHorizontalRadios2"
                                                        //        />
                                                        //    </Form.Group>
                                                        //</Form.Row>
                                                        }
                                                    <Form.Label>Email Address</Form.Label>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="formGridEmail">
                                                            <Form.Control type="email" value={this.state.thd.confirmedEmail} />
                                                        </Form.Group>
                                                        <InputGroup.Append>
                                                            <Button variant="outline-secondary" className="Edit_btn" onClick={this.handleShow}>Change Email</Button>
                                                        </InputGroup.Append>
                                                    </Form.Row>
                                                    <Form.Label>Mobile Number</Form.Label>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="formGridMno">
                                                            <Form.Control type="mobile" value={this.state.profileData.phoneNumber1} readOnly/>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridMno">
                                                            <Form.Control type="mobile" value={this.state.profileData.phoneNumber2} readOnly />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Label>Company Name</Form.Label>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="formGridCName">
                                                            <Form.Control type="c_name" value={this.state.profileData.company} readOnly />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Label>Account Type</Form.Label>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="formGridDomestic">
                                                            <Form.Control type="domestic" value={this.state.thd.customerType} readOnly />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Label>Payment Terms</Form.Label>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="formGridDomestic">
                                                            <Form.Control type="domestic" value={this.state.thd.customerPaymentOptions ? customerPayment(this.state.thd.customerPaymentOptions): this.state.thd.customerPaymentOptions} readOnly />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Label>Address</Form.Label>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="formGridAddress">
                                                            <Form.Control as="textarea" rows="1" value={this.state.profileData.address1} readOnly />
                                                            <Form.Control as="textarea" rows="1" value={this.state.addressdata} readOnly />
                                                            <Form.Control as="textarea" rows="1" value={this.state.profileData.country} readOnly />                                                            
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>

                                            </Card.Text>
                                        </Card.Body>
                                        <Modal centered size="l" show={this.state.show} onHide={this.handleClose} className="sb-details-modal">
                                            <Modal.Header closeButton>
                                                <Modal.Title>Change Email</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <p><strong style={{ color: 'green' }}>{this.state.Status.response === "Success" ? this.state.Status.responseMessage : ""}</strong></p>
                                                <p><strong style={{ color: 'red' }}>{this.state.Status.response === "Fail" ? this.state.Status.responseMessage : ""}</strong></p>
                                                <p>To change your account email address, please enter a valid new email address. We will send an email with a link to your new email address requesting confirmation immediately.</p>
                                                <Form>
                                                    <Form.Label>Current Email</Form.Label>
                                                    <Form.Control type="text" value={this.state.thd.confirmedEmail} readOnly />
                                                    <Form.Label>New Email *</Form.Label>
                                                    <Form.Control type="text" onChange={this.UnConfirmedEmail} placeholder="New Email" />
                                                </Form>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={this.handleClose}>
                                                    Close
                                         </Button>
                                                <Button variant="outline-secondary" className="Edit_btn" onClick={() => {this.changeEmail()}}>
                                                    Change Email
                                         </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </Card>

                                </div>
                            </Col>

                        </Row>
                        <Row className="justify-content-center mt-5">
                            <Col md="12">
                                <div className="animated fadeIn">
                                    <Card className="rounded-0 bg-white shadow-sm">
                                        <Card.Body>
                                            <Card.Title className="mb-3 pb-2 mb-4 border-bottom">Get answers to your queries</Card.Title>
                                            <Card.Text>
                                                <Row>
                                                    <Col md="3">
                                                        <h5>Payments</h5>
                                                    </Col>
                                                    <Col md="9">
                                                        <Accordion defaultActiveKey="0">
                                                            <Card>
                                                                <Card.Header>
                                                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                                                        How do I pay for an Order?
                                  </Accordion.Toggle>
                                                                </Card.Header>
                                                                <Accordion.Collapse eventKey="0">
                                                                    <Card.Body>To pay for an order you will need to wire a payment to the account listed at the bottom of your invoice.</Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                            <Card>
                                                                <Card.Header>
                                                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                                                        How long does it take for my payment to be received? 
                                  </Accordion.Toggle>
                                                                </Card.Header>
                                                                <Accordion.Collapse eventKey="1">
                                                                    <Card.Body>It can take 1-3 business days for your payment to be received and processed.</Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                            <Card>
                                                                <Card.Header>
                                                                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                                                        Can I pay for multiple invoices with one wire? 
                                  </Accordion.Toggle>
                                                                </Card.Header>
                                                                <Accordion.Collapse eventKey="2">
                                                                    <Card.Body>Yes, you can submit a payment for one or more invoices with the same wire. Please make sure to specify the invoice(s) that are being paid with the wire.
                                                                    Specify the invoice(s) being paid in the memo field if your bank allows. You can also email details of your payment to <a className="mailto text-primary  Email_text" href="mailto: shipping8616@homedepot.com"> salvage@homedepot.com</a></Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                            <Card>
                                                                <Card.Header>
                                                                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                                                        What do I do if my invoice is still unpaid after I have submitted a payment?
                                                                     </Accordion.Toggle>
                                                                </Card.Header>
                                                                <Accordion.Collapse eventKey="3">
                                                                    <Card.Body>On average your payment is applied within 3 business days. If after 3 business days your invoice(s) still shows as unpaid please call or email <a className="mailto text-primary  Email_text" href="mailto: shipping8616@homedepot.com"> salvage@homedepot.com</a> and provide a copy of your wire confirmation.</Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                            <Card>
                                                                <Card.Header>
                                                                    <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                                                        What happens if my order is cancelled by the time my payment is received? 
                                                                     </Accordion.Toggle>
                                                                </Card.Header>
                                                                <Accordion.Collapse eventKey="4">
                                                                    <Card.Body>If a payment is received for an order that has been cancelled we are able to apply funds to any existing or future orders.</Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                            <Card>
                                                                <Card.Header>
                                                                    <Accordion.Toggle as={Button} variant="link" eventKey="5">
                                                                        Can I receive a refund for an order that is cancelled? 
                                                                     </Accordion.Toggle>
                                                                </Card.Header>
                                                                <Accordion.Collapse eventKey="5">
                                                                    <Card.Body>A refund cannot be issued for a cancelled order but funds can be applied to existing or future orders.</Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                        </Accordion>
                                                    </Col>
                                                </Row>
                                            </Card.Text>{
                                  //              <Card.Text className="border-top pt-3">
                                  //                  <Row>
                                  //                      <Col md="3">
                                  //                          <h5>My Account</h5>
                                  //                      </Col>
                                  //                      <Col md="9">
                                  //                          <Accordion>
                                  //                              <Card>
                                  //                                  <Card.Header>
                                  //                                      <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                  //                                          How do I pay?
                                  //</Accordion.Toggle>
                                  //                                  </Card.Header>
                                  //                                  <Accordion.Collapse eventKey="4">
                                  //                                      <Card.Body>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</Card.Body>
                                  //                                  </Accordion.Collapse>
                                  //                              </Card>
                                  //                              <Card>
                                  //                                  <Card.Header>
                                  //                                      <Accordion.Toggle as={Button} variant="link" eventKey="5">
                                  //                                          How do I pay using a credit/debit card?
                                  //</Accordion.Toggle>
                                  //                                  </Card.Header>
                                  //                                  <Accordion.Collapse eventKey="5">
                                  //                                      <Card.Body>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</Card.Body>
                                  //                                  </Accordion.Collapse>
                                  //                              </Card>
                                  //                              <Card>
                                  //                                  <Card.Header>
                                  //                                      <Accordion.Toggle as={Button} variant="link" eventKey="6">
                                  //                                          Can I use my bank's Internet Banking feature to make a payment?
                                  //</Accordion.Toggle>
                                  //                                  </Card.Header>
                                  //                                  <Accordion.Collapse eventKey="6">
                                  //                                      <Card.Body>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took.</Card.Body>
                                  //                                  </Accordion.Collapse>
                                  //                              </Card>
                                  //                              <Card>
                                  //                                  <Card.Header>
                                  //                                      <Accordion.Toggle as={Button} variant="link" eventKey="7">
                                  //                                          Are there any hidden charges (Octroi or Tax)?
                                  //</Accordion.Toggle>
                                  //                                  </Card.Header>
                                  //                                  <Accordion.Collapse eventKey="7">
                                  //                                      <Card.Body>Lorem Ipsum has been the industry's standard dummy text ever since.</Card.Body>
                                  //                                  </Accordion.Collapse>
                                  //                              </Card>
                                  //                          </Accordion>
                                  //                      </Col>
                                  //                  </Row>
                                  //              </Card.Text>
                                            }
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>


                </div>

            </ContentLayout>
        );
    }
    async populateProfileData() {
        const token = await authService.getAccessToken();
        const response = await fetch('Account/Manage', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (data.updateCustomerRates.categoryList != null) {
            for (var i = 0; i < data.updateCustomerRates.categoryList.length; i++) {
                delete data.updateCustomerRates.categoryList[i].id;
                delete data.updateCustomerRates.categoryList[i].customerId;
            }
            this.setState({ categoryRate: data.updateCustomerRates.categoryList });
            const address = data.registration.city;
            const add = data.registration.stateProvinceRegion;
            const pin = data.registration.zipPostalCode;
            this.setState({ profileData: data.registration, categoryRate: data.updateCustomerRates.categoryList, loading: false });
            this.setState({ addressdata: [address, add, pin] })
            this.setState({ thd: data, loading: false })
        }
    }
}

const mapActionsToProps = {
    updateSearchList: updateSearchList,
    returnUpdateSearch: returnUpdateSearch
}

export default connect(null, mapActionsToProps)(MyProfile);
