import React, { Component } from "react";
import { Table } from 'react-bootstrap';
import "../../assets/css/jquery.dataTables.min.css";


const $ = require('jquery');
$.DataTable = require('datatables.net');

class AppliancesData extends Component {


  componentDidMount() {
    //console.log(this.el);
    this.$el=$(this.el)
    this.$el.DataTable(
      {
        columns: [
          { title: "<label class='control control--checkbox'><input name='select_all' value='1' id='select-all-cbox' type='checkbox' /><div class='control__indicator'></div></label>" },
          { title: "SB #" },
          { title: "BL #" },
          { title: "Warehouse" },
          { title: "Category" },
          { title: "Type" },
          { title: "Item" },
          { title: "Pallet Qty" },
          { title: "Wholesale" }
        ],
        data: [
          [ " ", "<a href='#'>345281</a>", "314250", "McDonough", "Mixed Load", "Stock", "187", "6", "$9453.16" ],
          [ " ", "<a href='#'>345860</a>", "313997", "McDonough", "Kitchen snd Bath", "Stock", "42", "6", "$4555.74" ],
          [ " ", "<a href='#'>345908</a>", "314076", "McDonough", "Appliances", "Stock", "239", "9", "$11108.39" ],
          [ " ", "<a href='#'>346004</a>", "314137", "McDonough", "Appliances", "Stock", "256", "9", "$9348.35" ],
          [ " ", "<a href='#'>346340</a>", "314607", "McDonough", "Floring and Rugs", "Stock", "260", "9", "$7499.92" ],
          [ " ", "<a href='#'>346396</a>", "314603", "McDonough", "Kitchen and Bath", "Stock", "68", "11", "$5320.68" ],
          [ " ", "<a href='#'>397605</a>", "362250", "Indianapolis", "Holiday", "Stock", "118", "7", "$5059.59" ],
          [ " ", "<a href='#'>397844</a>", "362072", "Indianapolis", "Hardware and Tools", "Stock", "10", "10", "$24163.17" ],
          [ " ", "<a href='#'>397913</a>", "362186", "Indianapolis", "Lighting", "Stock", "514", "9", "$16030.38" ],
          [ " ", "<a href='#'>398208</a>", "362452", "Indianapolis", "Plumbing", "Stock", "174", "4", "$6413.8" ],
          [ " ", "<a href='#'>398449</a>", "362725", "Indianapolis", "Plumbing", "Stock", "177", "5", "$9999.66" ],
          [ " ", "<a href='#'>398460</a>", "362796", "Indianapolis", "Blinds", "Stock", "711", "8", "$12458.55" ],
          [ " ", "<a href='#'>398463</a>", "362900", "Indianapolis", "Holiday", "Stock", "144", "6", "$3563.94" ],
          [ " ", "<a href='#'>398483</a>", "362743", "Indianapolis", "Kitchen and Bath", "Stock", "49", "9", "$4478.55" ],
          [ " ", "<a href='#'>398529</a>", "362759", "Indianapolis", "Kitchen and Bath", "Stock", "122", "7", "$4307.51" ],
          [ " ", "<a href='#'>398644</a>", "362979", "Indianapolis", "Blinds", "Stock", "755", "7", "$13121.8" ],
          [ " ", "<a href='#'>398681</a>", "362894", "Indianapolis", "Floring and Rugs", "Stock", "161", "7", "$4227.93" ],
          [ " ", "<a href='#'>398695</a>", "362915", "Indianapolis", "Lighting", "Stock", "664", "p", "$20130.16" ],
          [ " ", "<a href='#'>398735</a>", "362941", "Indianapolis", "Floring and Rugs", "Stock", "213", "11", "$5195.48" ],
          [ " ", "<a href='#'>398744</a>", "362932", "Indianapolis", "Kitchen and Bath", "Stock", "69", "10", "$3967.01" ],
          [ " ", "<a href='#'>398735</a>", "362186", "Indianapolis", "Holiday", "Stock", "215", "7", "$4227.93" ],
          [ " ", "<a href='#'>398529</a>", "362725", "Indianapolis", "Hardware and Tools", "Stock", "80", "5", "$5195.48" ],
          [ " ", "<a href='#'>398695</a>", "362186", "Indianapolis", "Plumbing", "Stock", "512", "8", "$4478.55" ],
          [ " ", "<a href='#'>398744</a>", "362915", "Indianapolis", "Blinds", "Stock", "30", "3", "$3967.01" ],
          [ " ", "<a href='#'>398744</a>", "362894", "Indianapolis", "Kitchen and Bath", "Stock", "66", "6", "$4478.55" ],
        ],
        columnDefs: [{
           'targets': 0,
           'className':'dt-body-center',
           'render': function (data, type, full, meta){
               return '<label class="control control--checkbox"><input name="select_all" value="1" type="checkbox" /><div class="control__indicator"></div></label>';
           }
        },
        {"orderable":false, "aTargets": [0]}
        ],
        order: [],
        aLengthMenu: [[5, 10, 25, -1], [5, 10, 25, "All"]],
        iDisplayLength: 5
      }
    )

    $("#select-all-cbox").click(function() {
      if($(this).prop("checked")) {
        $('.dt-body-center input:checkbox').each(function(){
          $(this).prop('checked',true);
        })      
      } else {
       $('.dt-body-center input:checkbox').each(function(){
        $(this).prop('checked',false);
       })      
      }         
    });

  }  
  render() {

    return (
      <div>
        <Table responsive hover id="example" className="display" width="100%" ref={el=>this.el=el}>
        </Table>
        <p className="bg-info p-3 mt-4 text-white"><strong>Note:</strong> If you need to cancel your Order, please forward your Order notification to our email address at Salvage@homedepot.com requesting to cancel your Order. Please allow for 3 business days before your Order is cancelled.</p>
      </div>
    );
  }
}

export default AppliancesData;
