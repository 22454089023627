import React, { Component } from 'react';
import ContentLayout from "../components/ContentLayout";
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import { Col, Container, Row, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { updateSearchList, returnUpdateSearch } from '../actions/search-actions';

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.props.updateSearchList([]);
        this.props.returnUpdateSearch();
    }
  render() {
    return (
      <ContentLayout>
      <Breadcrumb
          breadcrumb={[
            { name: "Home", path: "/" },
            { name: "About Us", path: "/AboutUs" }
          ]}
        />
        <div className="content pt-5 pb-5">
          <Container>
            <Row className="justify-content-center">
              <Col md="12">
                <div className="animated fadeIn">
                  <Card className="rounded-0 bg-white shadow-sm">
                    <Card.Body>
                      <Card.Title>About Us</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                        <Card.Text> 
                            <ul style={{ listStyle: "disc", paddingLeft: "20px" }}>
                                <p style={{textAlign: "justify"}}>
                                    The Home Depot is the world's largest home improvement specialty retailer, with 2,293 retail stores in all 50 states, the District of Columbia, Puerto Rico, U.S. Virgin Islands, Guam, 10 Canadian provinces and Mexico. The company employs more than 400,000 associates, 2,000 of which work within one of our 4 RLCs across 4 states. They are responsible for processing over $1B/year worth of returns from stores, ecommerce channels, and overstock within the Supply Chain network, returning what we can to receive credit from vendors, and building pallets of high quality merchandise to sell to our Salvage network. The RLC teams work 7 days a week to ensure inbound trailers are unloaded, products are sorted, pallets are accurately built and loaded out on one of the tens of thousands of trailers shipped out yearly. Our goal is to be the best in class reverse logistics organization in retail by continuously innovating our systems and processes to move product through the buildings quicker, more accurately, and better targeted to the needs of our customers.
                                </p>
                            </ul>
                        </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ContentLayout>
    );
  }
}

const mapActionsToProps = {
    updateSearchList: updateSearchList,
    returnUpdateSearch: returnUpdateSearch
}

export default connect(null, mapActionsToProps)(AboutUs);

