import React, { Component } from 'react';
import { Col, Container, Row, Button,Form} from 'react-bootstrap';
import ContentLayout from "../components/ContentLayout";
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import authService from '../components/api-authorization/AuthorizeService';
import InventoryDT from '../components/DataTable/InventoryDatatable';
import InventoryBidDT from '../components/DataTable/InventoryBidDatatable';
import { connect } from 'react-redux';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import IconButton from '@material-ui/core/IconButton';
//import Tooltip from '@material-ui/core/Tooltip';
import Select from 'react-select';
import { updateCartData } from '../actions/cart-actions';
import { updateSearchList, updateSearchListt } from '../actions/search-actions'
import { warehouses, Categories } from '../DateHelper';
import zIndex from '@material-ui/core/styles/zIndex';
import '../header.css'
import Tooltip from '@material-ui/core/Tooltip';
import { commonErrorHandler } from '../Utils'
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles
} from "@material-ui/core/styles";
const defaultTheme = createMuiTheme();
const theme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "1em",
                maxWidth: 5000,
                color: "white",
                backgroundColor: "#fa7a22"
            }
        }
    }
});



class Inventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Categories: '', warehouses: '', checkisapply: 0

        };


        this.props.updateSearchListt([]);
        this.props.updateSearchList([
            {
                id: '1',
                caption: 'SB Number',
                name: 'sbNumber'
            },
            {
                id: '2',
                caption: 'BL Number',
                name: 'blNumber'
            },
            //{
            //    id: '3',
            //    caption: 'Warehouse',
            //    name: 'warehouse'
            //},
            {
                id: '4',
                caption: 'Category',
                name: 'salvageCategory'
            },
            //{
            //    id: '5',
            //    caption: 'Type',
            //    name: 'hdDotCom'
            //},
            //{
            //    id: '6',
            //    caption: 'Item',
            //    name: 'itemQuantity'
            //},
            {
                id: '7',
                caption: 'Pallet Quantity',
                name: 'containerQuantity'
            }
            //},
            //{
            //    id: '8',
            //    caption: 'Wholesale',
            //    name: 'cost'
            //},
            //{
            //    id: '9',
            //    caption: 'Rate',
            //    name: 'rate'
            //},
            //{
            //    id: '10',
            //    caption: 'Cost',
            //    name: 'rate_Cost'
            //}

        ]);
    }
    componentDidMount() {

        this.PopulateApplytopallet();

    }

    async PopulateApplytopallet() {
        const token = await authService.getAccessToken();
        const response = await fetch('InventoryCode/Applytopalletcheck', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        /*const data = await response.json();*/
        const data = await commonErrorHandler(response)
        if (data!=null) {
            this.setState({ checkisapply: data, loading: false });
        }
        else {
            this.setState({ checkisapply: 1, loading: false });
        }

    }


    componentDidUpdate() {

    }
    render() {
        return (
            <MuiThemeProvider theme={defaultTheme}>
                <div className="topspace">
                    <ContentLayout>
                        <Breadcrumb
                            breadcrumb={[
                                { name: "Home", path: "/" },
                                { name: "Inventory", path: "/Inventory" }
                            ]}
                        />{
                        }
                        <Container fluid className="pl-5 pr-5">
                            <Row className="justify-content-center">
                                <Col md="12">
                                    <div className="animated fadeIn d-flex">
                                        <h2 className="title position-relative mt-2">Inventory</h2>

                                        <div> <MuiThemeProvider theme={theme}>
                                            <Tooltip title="SB # is the identifying Slot By Number used to identify manifests" placement="left">
                                                <div style={{ marginBottom: "20px" }}>
                                                    <NotListedLocationIcon /></div>
                                            </Tooltip>
                                        </MuiThemeProvider></div>
                                    </div>

                                    <div className="animated fadeIn">
                                        {this.state.checkisapply == 1 ?
                                            <InventoryBidDT /*updateCart={() => this.props.getCartSummary()}*/ /*{...this.state}*/ data={this.props.location.state} CompleteOrder={this.props.location.act} warehouseid={this.props.location.wid} allLocation={this.props.location.allLocation} />
                                            :
                                            <InventoryDT /*updateCart={() => this.props.getCartSummary()}*/ /*{...this.state}*/ data={this.props.location.state} CompleteOrder={this.props.location.act} warehouseid={this.props.location.wid} allLocation={this.props.location.allLocation} />
                                        }</div>

                                </Col>
                            </Row>
                        </Container>
                    </ContentLayout>
                </div>
            </MuiThemeProvider>
        );
    }
}


const mapStateToProps = state => ({
    cart: state.cart
});

const mapActionsToProps = {
    getCartSummary: updateCartData,
    updateSearchList: updateSearchList,
    updateSearchListt: updateSearchListt
}

export default connect(mapStateToProps, mapActionsToProps)(Inventory);