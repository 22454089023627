import React, { Component } from "react";
import IconButton from '@material-ui/core/IconButton';
import "../../assets/css/jquery.dataTables.min.css";
import authService from '../api-authorization/AuthorizeService'
import ReactDOM from 'react-dom';
import { css } from "@emotion/core";
import CssBaseline from '@material-ui/core/CssBaseline'
import ReactTable from './ReactTable'
import SyncLoader from "react-spinners/SyncLoader";
import { connect } from 'react-redux';
import { createNewOrder, completeOpenOrder, addToOrder } from '../../actions/cart-actions';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import Tooltip from '@material-ui/core/Tooltip';
import { dateMDY, numberformat } from '../../DateHelper';
import OrderDetail from './OrderDetail';
import { Modal } from 'react-bootstrap';

const override = css`
  display: block;
  margin: 2 auto;
  border-color: red;
`;
const style = { position: "fixed", top: "75%", left: "50%", transform: "translate(-50%, -50%)" };
//const $ = require('jquery');
//$.DataTable = require('datatables.net');

class Orderdata extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderdata: [], orderDataByStatus: [], searchText: "", oldStatus: "", show: false, showCat: false, loading: true
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.setState({ show: false, showCat: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    componentDidMount() {
        if (this.props.data)
            this.populateOrderData(this.props.data);
        else
            this.populateOrderData("All");
    }
    componentWillReceiveProps(props) {
        if (props.data != this.state.oldStatus) {
            this.setState({
                oldStatus: props.data
            })
            this.populateOrderData(props.data);
        }
        else if (props.ordersearch.searchText != this.state.searchText) {
            let tempData = [];
            var filter = [];
            let inventoryOriginalData = this.state.orderDataByStatus;
            if (props.ordersearch.searchText != "") {
                for (var i = 0; i < inventoryOriginalData.length; i++) {
                    if (String(inventoryOriginalData[i].id).includes(props.ordersearch.searchText))
                        tempData.push(inventoryOriginalData[i])
                }
                filter = tempData;
            }
            else {
                filter = inventoryOriginalData;
            }

            this.setState({
                searchText: props.ordersearch.searchText,
                orderdata: filter
            });
        }
        else {
            this.setState({
                searchText: "",
            })
            this.populateOrderData(props.data);
        }
    }

    getOrderId = (e) => {
        return e.original.id;
    }

    getOrderDate = (e) => {
        return e.original.orderDateTime
    }
    getOrderStatus = (e) => {
        return e.original.orderStatus
    }
    getWarehouse = (e) => {
        return e.original.warehouse
    }
    getsbcount = (e) => {
        return e.original.sbCount
    }
    handleclick = (rowData, sbcount) => {
        this.setState({
            orderNumber: rowData.original.id, status: rowData.original.orderStatus
            //orderNumber: rowData.original.id, blNumber: rowData.original.blNumber, warehouse: rowData.original.warehouse, salvageCategory: rowData.original.salvageCategory,
            //containerQuantity: rowData.original.containerQuantity, warehouseId: rowData.original.warehouseId, show: true, loading: true
        });

        // if (sbcount > 0) {
        this.setState({ show: true });
        // }
    }

    handleCategoryclick = (rowData, sbcount) => {
        this.setState({
            orderNumber: rowData.original.id, status: rowData.original.orderStatus
        });
        this.populateCategoryByOrderId(rowData.original.id);
        this.setState({ showCat: true });
    }
    async populateCategoryByOrderId(orderNumber) {
        const token = await authService.getAccessToken();
        const response = await fetch('InventoryCode/GetOrderCategoriess?orderId=' + orderNumber, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        var selectCategory = [];
        if (data != null && data != "") {
            for (var i = 0; i < data.split(',').length; i++) {

                var obj = {};
                obj["manifestId"] = data.split(',')[i].split(',')[0];
                selectCategory.push(Object.values(obj));
            }
        }
        //let cat = data.split(',')[0]
        this.setState({ Showcategory: selectCategory, loading: false });
        // this.setState({ showCat: true });
    }
    render() {
        const { orderdata, loading } = this.state;
        const columns = [
            {
                Header: 'Order #',
                accessor: 'id',
                //Cell: ({ row }) => {
                //    if (row.original.sbCount > 0) {
                //        return (
                //           <div>
                //             <a style={{ cursor: 'pointer', color: 'blue' }}
                //             onClick={() => this.handleclick(row, this.getsbcount(row))}>
                //                 {this.getOrderId(row)}
                //             </a>
                //           </div>
                //        )
                //    }
                //    else {
                //        return (
                //            row.original.id
                //        )
                //    }
                //}
                Cell: ({ row }) => (
                    <div>
                        <a style={{ cursor: 'pointer', color: 'blue' }}
                            onClick={() => this.handleclick(row, this.getsbcount(row))}>
                            {this.getOrderId(row)}
                        </a>
                    </div>
                )
                //Cell: ({ row }) => (
                //    <div>
                //        <a style={{ cursor: 'pointer', color: 'blue' }}
                //            onClick={() => this.props.goto(this.getOrderId(row), row)}>
                //            {this.getOrderId(row)}
                //        </a>
                //    </div>
                //)
            },
            {
                Header: 'Warehouse',
                accessor: 'warehouse',
            },
            {
                Header: 'Categories',
                accessor: 'category',
                Cell: ({ row }) => {
                    if (row.original.categoryCount > 1) {
                        return (<div><a style={{ cursor: 'pointer', color: 'blue' }}
                            onClick={() => this.handleCategoryclick(row, this.getsbcount(row))}>
                            Show Categories
                        </a></div>)
                    }
                    else {
                        return (
                            row.original.category
                        )
                    }
                }
            },
            {
                Header: 'SB Count',
                accessor: 'sbCount',
            },
            {
                Header: 'Pallet Count',
                accessor: 'containerCount',
            },
            {
                Header: 'Wholesale',
                accessor: 'cost',
                Cell: ({ row }) => (
                    <React.Fragment>
                        {row.original.cost ? numberformat(row.original.cost) : row.original.cost}
                    </React.Fragment>
                )
            },
            {
                Header: 'Order Total',
                accessor: 'totalOrderAmount',
                Cell: ({ row }) => (
                    <React.Fragment>
                        {row.original.totalOrderAmount ? numberformat(row.original.totalOrderAmount) : row.original.totalOrderAmount}
                    </React.Fragment>
                )
            },
            {
                Header: 'Status',
                accessor: 'orderStatus',
            },
            {
                Header: 'Action',
                accessor: '',

                Cell: ({ row }) => {
                    if (row.original.orderStatus == "Open") {
                        return (<div>
                            <Tooltip title="Add cart contents to order">
                                <IconButton onClick={() => this.props.addToOrder(this.getOrderId(row))} style={{ border: "1px solid", padding: "5px", marginRight: "5px" }} variant="outline-secondary" value={row.original.orderStatus}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>

                            {row.original.sbCount > 0 &&
                                <Tooltip title="Mark Order as Complete">
                                    <IconButton onClick={() => this.props.completeOpenOrder(this.getOrderId(row))} style={{ border: "1px solid", padding: "5px" }} variant="outline-secondary" value={row.original.orderStatus}>
                                        <DoneIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>)
                    }
                    else {
                        return (
                            ""//row.original.orderStatus
                        )
                    }
                }
            },
            {
                Header: 'Order Date',
                accessor: 'orderDateTime',

                //Cell: ({ row }) => {
                //    var date = new Date(this.getOrderDate(row));
                //    var month = date.getMonth() + 1;
                //    return (month.toString().length > 1 ? month : "0" + month) + "/" + date.getDate() + "/" + date.getFullYear();
                //}
                Cell: ({ row }) => {
                    if (row.original.orderDateTime != null) {
                        return (<div>
                            <React.Fragment>
                                {row.original.orderDateTime ? dateMDY(row.original.orderDateTime.substring(0, 10)) : ""}
                            </React.Fragment>
                        </div>)
                    }
                    else {
                        return (<div>
                            <React.Fragment>
                                {row.original.insertDate ? dateMDY(row.original.insertDate.substring(0, 10)) : ""}
                            </React.Fragment>
                        </div>)
                    }
                }
            },

        ]
        if (loading && orderdata.length === 0) {
            return (
                <div><em>Please wait while Loading MY Order Data......</em>
                    <div style={style}>
                        <SyncLoader
                            css={override}
                            size={15}
                            color={"#fd7e14"}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            );
        }
        if (orderdata == null || orderdata == "")
            return (
                <div>
                    <div>
                        <CssBaseline />
                        <div style={style}>
                            <SyncLoader
                                css={override}
                                size={15}
                                color={"#fd7e14"}
                                loading={this.state.loading}
                            /></div>
                        <ReactTable
                            columns={columns}
                            data={this.state.orderdata}
                        />
                    </div>
                    <div>
                        <h2>No Record Found</h2>
                    </div>
                </div>)
        return (
            <div>
                <div>{
                    //<p className="bg-info p-3 mb-4 text-white shadow-sm"><strong>NOTE:</strong> SB # is the identifying Slot By Number used to identify manifests</p>

                    //<Table responsive hover id="inventory-datable" className="display" width="100%" ref={el => this.el = el} >

                    //</Table>
                }
                    <CssBaseline />
                    <div style={style}>
                        <SyncLoader
                            css={override}
                            size={15}
                            color={"#fd7e14"}
                            loading={this.state.loading}
                        /></div>
                    <ReactTable
                        columns={columns}
                        data={this.state.orderdata}
                    />

                    <Modal centered size="xl" show={this.state.show} onHide={this.handleClose} className="sb-details-modal">
                        <Modal.Header closeButton>
                            <Modal.Title>Order Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{
                            //   <p><strong> SB #:</strong> {this.state.sbNumber}, <strong>Bl #:</strong> {this.state.blNumber}, <strong>Warehouse:</strong> {this.state.warehouse}, <strong>Category:</strong>{this.state.salvageCategory}, <strong>Pallet Qty:</strong> {this.state.containerQuantity}</p>
                        } <OrderDetail orderNumber={this.state.orderNumber} orderStatus={this.state.status} />
                        </Modal.Body>
                    </Modal>
                    <Modal centered size="l" show={this.state.showCat} onHide={this.handleClose} className="sb-details-modal">
                        <Modal.Header closeButton>
                            <Modal.Title>Assigned Categories</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.Showcategory != null ? this.state.Showcategory.map(catList => {
                                return (
                                    <ul>
                                        <li>{catList[0]}</li>
                                    </ul>
                                )
                            }) : null}
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        );
    }



    async populateOrderData(status) {
        const token = await authService.getAccessToken();
        const response = await fetch('InventoryCode/Order?status=' + status + '&archivedOrders=2', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        for (var i = 0; i < data.orderViewModel.orderList.length; i++) {
            var cost = 0.0;
            /* var cost = (data.orderViewModel.orderList[i].cost) * 1.15;*/
            var cost = (data.orderViewModel.orderList[i].cost);
            data.orderViewModel.orderList[i].cost = cost.toFixed(2);
            var totalOrderAmount = 0.0;
            var totalOrderAmount = (data.orderViewModel.orderList[i].totalOrderAmount);
            data.orderViewModel.orderList[i].totalOrderAmount = totalOrderAmount.toFixed(2);
            if (data.orderViewModel.orderList[i].orderStatus == 0)
                data.orderViewModel.orderList[i].orderStatus = "NotSpecified";
            else if (data.orderViewModel.orderList[i].orderStatus == 1)
                data.orderViewModel.orderList[i].orderStatus = "Open";
            else if (data.orderViewModel.orderList[i].orderStatus == 2)
                data.orderViewModel.orderList[i].orderStatus = "Assigned";
            else if (data.orderViewModel.orderList[i].orderStatus == 3)
                data.orderViewModel.orderList[i].orderStatus = "Approved";
            else if (data.orderViewModel.orderList[i].orderStatus == 4)
                data.orderViewModel.orderList[i].orderStatus = "Shipped";
            else if (data.orderViewModel.orderList[i].orderStatus == 5)
                data.orderViewModel.orderList[i].orderStatus = "PartiallyShipped";
            else if (data.orderViewModel.orderList[i].orderStatus == 6)
                data.orderViewModel.orderList[i].orderStatus = "Cancelled";
            else if (data.orderViewModel.orderList[i].orderStatus == 7)
                data.orderViewModel.orderList[i].orderStatus = "PendingApproval";
            else if (data.orderViewModel.orderList[i].orderStatus == 9)
                data.orderViewModel.orderList[i].orderStatus = "Scheduled";
            else if (data.orderViewModel.orderList[i].orderStatus == 10)
                data.orderViewModel.orderList[i].orderStatus = "PartiallyScheduled";
            else
                data.orderViewModel.orderList[i].orderStatus = data.orderViewModel.orderList[i].orderStatus;
        }
        let tempData = [], orderData;
        if (this.state.searchText != "") {
            for (var i = 0; i < data.orderViewModel.orderList.length; i++) {
                if (String(data.orderViewModel.orderList[i].id).includes(this.state.searchText))
                    tempData.push(data.orderViewModel.orderList[i])
            }
            orderData = tempData;
        }
        else
            orderData = data.orderViewModel.orderList
        this.setState({ orderdata: orderData, orderDataByStatus: data.orderViewModel.orderList, loading: false });

    }
}

const mapStateToProps = state => ({
    userOrders: state.orders,
    ordersearch: state.searchOrderText
});

const mapActionsToProps = {
    createNewOrder: createNewOrder,
    completeOpenOrder: completeOpenOrder,
    addToOrder: addToOrder
}

export default connect(mapStateToProps, mapActionsToProps)(Orderdata);