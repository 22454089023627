import React, { Component } from "react";
//import "../css/jquery.dataTables.min.css";

import { Col, Row, Form, Button, Card, ButtonToolbar, Accordion, Container, Table } from 'react-bootstrap';
import authService from '../api-authorization/AuthorizeService';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import InventoryDetail from './InventoryDetail';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline'
import EnhancedTable from './EnhancedTable'
import ViewCart from "../../views/View-Cart";
import { css } from "@emotion/core";
import InvoiceData from "../../views/invoiceData";
import SyncLoader from "react-spinners/SyncLoader";
import { CSVLink } from "react-csv";
import FilterResults from 'react-filter-search';
import matchSorter from 'match-sorter'
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import NumberFormat from 'react-number-format';
import { numberformat } from "../../DateHelper";
import { openDialog } from '../../actions/dialog-actions';
import { fetchOrders } from '../../actions/cart-actions';
import { updateCartData, updateViewCart } from '../../actions/cart-actions';
import { FetchInventoryFilters } from "../../actions/filter-actions";
import { DIALOG_ACTION_CLOSE } from '../../constants';
import Snackbar from '@material-ui/core/Snackbar';
import Checkbox from '@material-ui/core/Checkbox';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'
import styled from 'styled-components'
import { updateClearSearch } from "../../actions/search-actions";
import { red } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import { commonErrorHandler } from '../../Utils'
const StyledLoader = styled(LoadingOverlay)`
  width: auto;
  height: auto;
  .MyLoader_overlay {
  }
`
const styles = {
    snackbarStyleViaContentProps: {
        backgroundColor: "#f44336"
    },
    snackbarStyleViaNestedContent: {
        backgroundColor: "#d4edda",
        color: "black"
    },
    snackbarStyleViaNestedContente: {
        backgroundColor: "#ff9800",
        color: "white"
    }
};
const override = css`
  display: block;
  margin: 2 auto;
  border-color: red;
`;
const style = { position: "fixed", top: "45%", left: "50%", transform: "translate(-50%, -50%)" };
class InventoryDT extends Component {
    constructor(props) {
        super(props);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
       // this.intervalID;
        const { pathname } = window.location;
        this.state = {
            currentPathName: pathname,
            inventorydata: [], selected: false, checked: [], snackbarOpen: false, snackbaruncheck: false, snackbarUnavailableSB: false, show: false, checked: false, IsClosed: false,
            Category: this.props.data, Warehouse: this.props.warehouseid, AllLocation: this.props.allLocation,selectSB:false, selectAllCheck:false,
            TotalsBySb: "", loading: false, CheckedData: {}, value: '', sb: '', bl: '', warehouse: '', category: '', type: '', MaximumPallet: '',
            searchText: "", fieldName: "", inventoryOriginalData: [], /*AllInventryClear: [],*/ AllCategoryClear: [], AllCategory: [], categoryList: [], filterList: [
                {
                    id: 8615,
                    name: "INDIANAPOLIS",
                    value: "Indianapolis"
                },
                {
                    id: 8616,
                    name: "MCDONOUGH",
                    value: "McDonough"
                },
                {
                    id: 8617,
                    name: "PHOENIX",
                    value: "Phoenix"
                },
                {
                    id: 8618,
                    name: "PITTSTON",
                    value: "Pittston"
                }
            ],
            inventoryType: [
                {
                    name: "HD.COM",
                    value: "HD.COM"
                },
                {
                    name: "STOCK",
                    value: "STOCK"
                }],
            DemoCategories: [
                {
                    caption: "BLINDS",
                    name: "BLINDS"
                },
                {
                    caption: "HOLIDAY",
                    name: "HOLIDAY"
                },
                {
                    caption: "PLUMBING",
                    name: "PLUMBING"
                },
                {
                    caption: "001",
                    name: "001"
                },
                {
                    caption: "MIXED LOAD",
                    name: "MIXED LOAD"
                },
                {
                    caption: "APPLIANCES",
                    name: "APPLIANCES"
                },
                {
                    caption: "DOORS AND WINDOWS",
                    name: "DOORS AND WINDOWS"
                },
                {
                    caption: "FLOORING AND RUGS",
                    name: "FLOORING AND RUGS"
                },
                {
                    caption: "HARDWARE AND TOOLS",
                    name: "HARDWARE AND TOOLS"
                },
                {
                    caption: "OUTDOOR SEASONAL",
                    name: "OUTDOOR SEASONAL"
                },
                {
                    caption: "KITCHEN AND BATH",
                    name: "KITCHEN AND BATH"
                },
                {
                    caption: "LIGHTING",
                    name: "LIGHTING"
                },
                {
                    caption: "HOME DECORS",
                    name: "HOME DECOR"
                }
            ]
        };
    }
    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    handleClose() {
        this.setState({ show: false });
    }
    snackBarClose = (event) => {
        this.setState({ snackbarOpen: false, snackbaruncheck: false, snackbarUnavailableSB: false });
    }

    handleShow() {
        this.setState({ show: true });
    }
    clearFilter = () => {
        this.props.setClearSearch();
        let categoryList = this.state.AllCategoryClear;
        for (var i = 0; i < categoryList.length; i++) {
            categoryList[i].checked = false;
        }
        let filterList = this.state.filterList;
        for (var i = 0; i < filterList.length; i++) {
            if (filterList[i].checked)
                filterList[i].checked = false;
        }
        let TypeList = this.state.inventoryType;
        for (var i = 0; i < TypeList.length; i++) {
            if (TypeList[i].checked)
                TypeList[i].checked = false;
        }
        let hdChecked = this.state.hdChecked
        let inventoryOriginalData = this.state.inventoryOriginalData;/*this.state.AllInventryClear;*/
        this.setState({ categoryList: categoryList, AllCategory: categoryList, inventoryType: TypeList, filterList: filterList, hdChecked: hdChecked, inventorydata: inventoryOriginalData, Category: "", Warehouse: "", selectAllCheck: false });
        this.setState({ searchText: "", fieldName:"" });
        this.props.fetchFilters(TypeList, categoryList, filterList);
    }

    componentDidUpdate(prevProps) {
        if (this.props.search.hdChecked != prevProps.search.hdChecked) {
            this.populateInventoryData();
        }
        if (this.props.CompleteOrder != prevProps.CompleteOrder) {
            this.setState({
                TotalsBySb: ""
            });
        }
        if (this.props.search.searchText != prevProps.search.searchText || this.props.search.fieldName != prevProps.search.fieldName) {
            var filter = [];

            // get Inventory data according to filter by selected type,location and category 
            let inventoryOriginalData = this.getFilterData();

            if (this.props.search.fieldName == "salvageCategory" && (this.props.search.index != undefined || this.props.search.searchText!="")) {
                this.getDataBySearch(this.props.search.searchText.toUpperCase(), this.props.search.fieldName);
            }
            else if (this.props.search.fieldName != "salvageCategory") {
                //let inventoryOriginalData = this.state.inventorydata;
                if (this.props.search.searchText != "" && this.props.search.fieldName === "Select") {
                    filter = this.search(inventoryOriginalData, this.props.search.searchText)
                } else if (this.props.search.searchText != "" && this.props.search.fieldName != "Select") {
                    if (this.props.search.fieldName === "containerQuantity") {
                        filter = inventoryOriginalData.filter(a => a[this.props.search.fieldName] == this.props.search.searchText);
                    }
                    else
                        filter = inventoryOriginalData.filter(a => a[this.props.search.fieldName].toLowerCase().includes(this.props.search.searchText.toLowerCase()));
                } else {
                    filter = inventoryOriginalData;
                }
                this.setState({
                    inventorydata: filter,
                    searchText: this.props.search.searchText,
                    fieldName: this.props.search.fieldName,
                    Category: ""
                });
            }
        }
        
    }

    componentDidMount() {
        const pageLoad = true;
        this.populateInventoryData(pageLoad);
    }
    componentWillUnmount() {
       
        clearTimeout(this.intervalID);
    }
    async handleChecked(id, warehouseId, pallets) {

        //check first that current user is allowed to buy/bid
        const token = await authService.getAccessToken();
        const response = await fetch('InventoryCode/IsBuyAndBidAllowed', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        /*const data = await response.json();*/
        const data = await commonErrorHandler(response);

        if (data != null && data.success == true && data.accessDenied == true) {
            //access denied
            setTimeout(
                function () {
                    this.props.openDialog(1,
                        "Restriction",
                        "Sorry, Your account has been restricted from purchasing. Please contact your account manager.", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                }
                    .bind(this),
                50
            );
        }

        else if (pallets > 26) {
            this.props.openDialog(1,
                "Alert",
                "Manifest cannot be selected since maximum pallet per order is 26", "Close", "", DIALOG_ACTION_CLOSE, null, null);
        }
        else {
            this.setState({
                selectSB: true,
            });
            const newSelected = Object.assign({}, this.state.selected);
            let SelectedTotalsBySb = this.state.TotalsBySb;
            var sameWarehouse = true;
            var totalPalletQty = 0;
            if (SelectedTotalsBySb != "") {
                for (let i = 0; i < SelectedTotalsBySb.split(',').length; i++) {
                    if (SelectedTotalsBySb.split(',')[i].split('|')[9] != warehouseId) {
                        this.props.openDialog(1,
                            "Alert",
                            "Manifest selections must be located in the same warehouse", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                        sameWarehouse = false;
                        this.setState({
                            selectSB: false,
                        });
                        break;
                    }
                    else {
                        if (SelectedTotalsBySb.split(',')[i].split('|')[0] == id) {
                            newSelected[id] = false;
                            break;
                        }
                        else
                            newSelected[id] = true;
                    }
                    totalPalletQty += parseInt(SelectedTotalsBySb.split(',')[i].split('|')[4]);
                }
                totalPalletQty += parseInt(pallets);
                if (totalPalletQty > 26) {
                    this.props.openDialog(1,
                        "Alert",
                        "Manifest cannot be selected since maximum pallet per order is 26", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                    sameWarehouse = false;
                    this.setState({
                        selectSB: false,
                    });
                }
            }
            else
                newSelected[id] = !this.state.selected[id];
            if (sameWarehouse) {
                if (newSelected[id]) {
                    this.callCheckedApi("add", id)

                }
                else {
                    this.callCheckedApi("remove", id)
                }
                this.setState({
                    selected: newSelected,
                });
            }
        }
    }
    handleclick = (rowData) => {
        this.setState({
            sbNumber: rowData.original.sbNumber, blNumber: rowData.original.blNumber, warehouse: rowData.original.warehouse, salvageCategory: rowData.original.salvageCategory,
            containerQuantity: rowData.original.containerQuantity, warehouseId: rowData.original.warehouseId, show: true, loading: true
        });
        this.setState({ show: true });


    }
    getSBNumber = (e) => {
        return e.original.sbNumber;
    }
    getFilterData() {
        let inventoryOriginalData = this.state.inventoryOriginalData;
        let select, selectCat, selectType;
        let filterList, categoryList, inventoryType;
        filterList = this.state.filterList;
        categoryList = this.state.categoryList;
        inventoryType = this.state.inventoryType;
        select = this.state.filterList.filter(item => item.checked);
        selectCat = this.state.categoryList.filter(item => item.checked);
        selectType = this.state.inventoryType.filter(item => item.checked);
        if ((selectType.length === 0 || selectType.length === inventoryType.length) && (select.length === 0 || select.length === filterList.length) && (selectCat.length === 0 || selectCat === categoryList.length)) {
            inventoryOriginalData = this.state.inventoryOriginalData;
        }
        else {
            if (selectType.length != 0) {
                inventoryOriginalData = inventoryOriginalData.filter(o1 => selectType.some(o2 => o1.hdDotCom.toUpperCase() === o2.value.toUpperCase()));
            }
            if (select.length != 0) {
                inventoryOriginalData = inventoryOriginalData.filter(o1 => select.some(o2 => o1.warehouseId === o2.id));
            }
            if (selectCat.length != 0) {
                inventoryOriginalData = inventoryOriginalData.filter(o1 => selectCat.some(o2 => o1.salvageCategory === o2.caption));
            }
        }
        return inventoryOriginalData;
    }
    getDataBySearch(categoryName,searchField) {
        let inventoryOriginalData = this.state.inventoryOriginalData;
        let AllInventoryData = inventoryOriginalData;
        let selectCat;
        let filterList, categoryList, inventoryType, newCategory, stateCtegory;
        filterList = this.state.filterList;
        stateCtegory = this.state.categoryList;
        categoryList = this.state.AllCategoryClear;
        inventoryType = this.state.inventoryType;

        // bind and check category according to select
        newCategory = categoryList;
        let cnt = 0;
        for (let i = 0; i < newCategory.length; i++) {
            let cnt = 0;
            for (let j = 0; j < stateCtegory.length; j++) {
                if (newCategory[i].caption.toUpperCase() == categoryName || (newCategory[i].caption.toUpperCase() == stateCtegory[j].caption.toUpperCase() && stateCtegory[j].checked)) {
                    newCategory[i].checked = true;
                    cnt++;
                }
            }
            if (cnt == 0)
                newCategory[i].checked = false;
        }
        this.setState({
            categoryList: newCategory,
        })
        selectCat = newCategory.filter(item => item.checked);
        if (selectCat.length != 0) {
            inventoryOriginalData = inventoryOriginalData.filter(o1 => selectCat.some(o2 => o1.salvageCategory === o2.caption));
        }
        if (selectCat.length != 0) {
            for (var i = 0; i < filterList.length; i++) {
                filterList[i].checked = false;
                for (var j = 0; j < inventoryOriginalData.length; j++) {
                    if (filterList[i].name.toUpperCase() == inventoryOriginalData[j].warehouse.toUpperCase())
                        filterList[i].checked = true;
                }
            }
            for (var i = 0; i < inventoryType.length; i++) {
                inventoryType[i].checked = false;
                for (var j = 0; j < inventoryOriginalData.length; j++) {
                    if (inventoryType[i].name.toUpperCase() == inventoryOriginalData[j].hdDotCom.toUpperCase())
                        inventoryType[i].checked = true;
                }
            }
            // function for select categoryList on inventoryType and Warehouse for selected category
            categoryList = getCategoryListByCategory(AllInventoryData, filterList, inventoryType, "", selectCat)
        }

        this.setState({
            inventorydata: inventoryOriginalData,
            categoryList: categoryList,
            filterList: filterList,
            inventoryType: inventoryType,
            searchText: categoryName,
            fieldName: searchField,
            Category:""
        });
    }
    search = (data, search) => {
        var obj = [], index = 0;
        for (var i = 0; i < data.length; i++) {
            for (var key in data[i]) {
                if (data[i][key] && data[i][key].toString().toLowerCase().includes(search.toLowerCase())) {
                    obj[index] = data[i];
                    index++;
                    break;
                }
            }
        }
        return obj;
    }
    onToggle(index, e) {
        this.setState({ Warehouse: '', Category: '' });
        let inventoryOriginalData = this.state.inventoryOriginalData;
        let AllInventoryData = inventoryOriginalData;
        let select, selectCat, selectType;
        let filterList, categoryList, inventoryType, newItems, newCategory, newType;
        filterList = this.state.filterList;
        categoryList = this.state.categoryList;
        inventoryType = this.state.inventoryType;
        if (e == "warehouse") {
            newItems = this.state.filterList.slice();
            newItems[index].checked = !newItems[index].checked;
            this.setState({
                filterList: newItems,
            })
            select = this.state.filterList.filter(item => item.checked);
            selectCat = this.state.categoryList.filter(item => item.checked);
            selectType = this.state.inventoryType.filter(item => item.checked);
            if ((selectType.length === 0 || selectType.length === inventoryType.length) && (select.length === 0 || select.length === filterList.length) && (selectCat.length === 0 || selectCat === categoryList.length)) {
                inventoryOriginalData = this.state.inventoryOriginalData;
                categoryList = getCategoryData(inventoryOriginalData, selectCat);
            }
            else {
                if (selectType.length != 0) {
                    inventoryOriginalData = inventoryOriginalData.filter(o1 => selectType.some(o2 => o1.hdDotCom.toUpperCase() === o2.value.toUpperCase()));
                    categoryList = getCategoryData(inventoryOriginalData, selectCat);
                }
                if (select.length != 0) {
                    inventoryOriginalData = inventoryOriginalData.filter(o1 => select.some(o2 => o1.warehouseId === o2.id));
                    for (var i = 0; i < inventoryOriginalData.length; i++) {
                        for (var k = 0; k < inventoryType.length; k++) {
                            if (inventoryType[k].name.toUpperCase() == inventoryOriginalData[i].hdDotCom.toUpperCase())
                                inventoryType[k].checked = true;
                        }
                    }
                    categoryList = getCategoryData(inventoryOriginalData, selectCat);
                }
                if (select.length == 0) {
                    for (var i = 0; i < inventoryOriginalData.length; i++) {
                        for (var j = 0; j < categoryList.length; j++) {
                            if (categoryList[j].caption.toUpperCase() == inventoryOriginalData[i].salvageCategory.toUpperCase())
                                categoryList[j].checked = false;
                        }
                    }
                    selectCat = categoryList.filter(item => item.checked);
                }
                if (selectCat.length != 0 && categoryList.filter(x1 => selectCat.some(x2 => x1.caption === x2.caption)).length > 0) {
                    inventoryOriginalData = inventoryOriginalData.filter(o1 => selectCat.some(o2 => o1.salvageCategory === o2.caption));
                }
                //for (var i = 0; i < inventoryOriginalData.length; i++) {
                //    for (var j = 0; j < categoryList.length; j++) {
                //        if (categoryList[j].caption.toUpperCase() == inventoryOriginalData[i].salvageCategory.toUpperCase())
                //            categoryList[j].checked = true;
                //    }
                //}
            }

        }
        else if (e == "category") {
            newCategory = this.state.categoryList.slice();
            newCategory[index].checked = !newCategory[index].checked;
            this.setState({
                categoryList: newCategory,
            })
            select = this.state.filterList.filter(item => item.checked);
            selectCat = this.state.categoryList.filter(item => item.checked);
            selectType = this.state.inventoryType.filter(item => item.checked);
            if ((selectType.length === 0 || selectType.length === inventoryType.length) && (select.length === 0 || select.length === filterList.length) && (selectCat.length === 0 || selectCat === categoryList.length)) {
                inventoryOriginalData = this.state.inventoryOriginalData;
                categoryList = getCategoryData(inventoryOriginalData, selectCat);
            }
            else {
                if (selectType.length != 0) {
                    inventoryOriginalData = inventoryOriginalData.filter(o1 => selectType.some(o2 => o1.hdDotCom.toUpperCase() === o2.value.toUpperCase()));
                    categoryList = getCategoryData(inventoryOriginalData, selectCat);
                }
                if (select.length != 0) {
                    inventoryOriginalData = inventoryOriginalData.filter(o1 => select.some(o2 => o1.warehouseId === o2.id));
                    categoryList = getCategoryData(inventoryOriginalData, selectCat);
                }
                if (selectCat.length != 0) {
                    inventoryOriginalData = inventoryOriginalData.filter(o1 => selectCat.some(o2 => o1.salvageCategory === o2.caption));
                }
            }
            if (selectCat.length != 0) {
                for (var i = 0; i < inventoryOriginalData.length; i++) {
                    for (var j = 0; j < filterList.length; j++) {
                        if (filterList[j].name.toUpperCase() == inventoryOriginalData[i].warehouse.toUpperCase())
                            filterList[j].checked = true;
                    }
                    for (var k = 0; k < inventoryType.length; k++) {
                        if (inventoryType[k].name.toUpperCase() == inventoryOriginalData[i].hdDotCom.toUpperCase())
                            inventoryType[k].checked = true;
                    }
                }
                // function for select categoryList on inventoryType and Warehouse for selected category
                categoryList = getCategoryListByCategory(AllInventoryData, filterList, inventoryType, "", selectCat)
            }
        }
        else if (e == "type") {
            newType = this.state.inventoryType.slice();
            newType[index].checked = !inventoryType[index].checked;
            this.setState({
                inventoryType: newType,
            })
            select = this.state.filterList.filter(item => item.checked);
            selectCat = this.state.categoryList.filter(item => item.checked);
            selectType = this.state.inventoryType.filter(item => item.checked);
            if ((selectType.length === 0 || selectType.length === inventoryType.length) && (select.length === 0 || select.length === filterList.length) && (selectCat.length === 0 || selectCat === categoryList.length)) {
                inventoryOriginalData = this.state.inventoryOriginalData;
                categoryList = getCategoryData(inventoryOriginalData, selectCat);
            }
            else {
                if (selectType.length != 0) {
                    inventoryOriginalData = inventoryOriginalData.filter(o1 => selectType.some(o2 => o1.hdDotCom.toUpperCase() === o2.value.toUpperCase()));
                    for (var i = 0; i < inventoryOriginalData.length; i++) {
                        for (var j = 0; j < filterList.length; j++) {
                            if (filterList[j].name.toUpperCase() == inventoryOriginalData[i].warehouse.toUpperCase())
                                filterList[j].checked = true;
                        }
                    }
                    categoryList = getCategoryData(inventoryOriginalData, selectCat);
                }
                if (selectType.length == 0) {
                    for (var i = 0; i < inventoryOriginalData.length; i++) {
                        for (var j = 0; j < categoryList.length; j++) {
                            if (categoryList[j].caption.toUpperCase() == inventoryOriginalData[i].salvageCategory.toUpperCase())
                                categoryList[j].checked = false;
                        }
                    }
                    selectCat = categoryList.filter(item => item.checked);
                }
                if (select.length != 0) {
                    inventoryOriginalData = inventoryOriginalData.filter(o1 => select.some(o2 => o1.warehouseId === o2.id));
                    categoryList = getCategoryData(inventoryOriginalData, selectCat);
                }
                if (selectCat.length != 0 && categoryList.filter(x1 => selectCat.some(x2 => x1.caption === x2.caption)).length>0) {
                    inventoryOriginalData = inventoryOriginalData.filter(o1 => selectCat.some(o2 => o1.salvageCategory === o2.caption));
                }
            }
        }
        else if (e == "All") {
            if (this.state.selectAllCheck) {
                this.setState({
                    selectAllCheck: false
                })
                for (var i = 0; i < categoryList.length; i++) {
                    categoryList[i].checked = false;
                }
                for (var j = 0; j < filterList.length; j++) {
                    filterList[j].checked = false;
                }
                for (var k = 0; k < inventoryType.length; k++) {
                    inventoryType[k].checked = false;
                }
            }
            else {
                this.setState({
                    selectAllCheck: true
                })
                for (var i = 0; i < categoryList.length; i++) {
                    categoryList[i].checked = true;
                }
                for (var j = 0; j < filterList.length; j++) {
                    filterList[j].checked = true;
                }
                for (var k = 0; k < inventoryType.length; k++) {
                    inventoryType[k].checked = true;
                }
            }
            select = this.state.filterList.filter(item => item.checked);
            selectCat = this.state.categoryList.filter(item => item.checked);
            selectType = this.state.inventoryType.filter(item => item.checked);
            if ((selectType.length === 0 || selectType.length === inventoryType.length) && (select.length === 0 || select.length === filterList.length) && (selectCat.length === 0 || selectCat === categoryList.length)) {
                inventoryOriginalData = this.state.inventoryOriginalData;
                categoryList = getCategoryData(inventoryOriginalData, selectCat);
            }
            else {
                if (selectType.length != 0) {
                    inventoryOriginalData = inventoryOriginalData.filter(o1 => selectType.some(o2 => o1.hdDotCom.toUpperCase() === o2.value.toUpperCase()));
                    for (var i = 0; i < inventoryOriginalData.length; i++) {
                        for (var j = 0; j < filterList.length; j++) {
                            if (filterList[j].name.toUpperCase() == inventoryOriginalData[i].warehouse.toUpperCase())
                                filterList[j].checked = true;
                        }
                    }
                    categoryList = getCategoryData(inventoryOriginalData, selectCat);
                }
                if (select.length != 0) {
                    inventoryOriginalData = inventoryOriginalData.filter(o1 => select.some(o2 => o1.warehouseId === o2.id));
                    categoryList = getCategoryData(inventoryOriginalData, selectCat);
                }
                for (var i = 0; i < inventoryOriginalData.length; i++) {
                    for (var j = 0; j < categoryList.length; j++) {
                        if (categoryList[j].caption.toUpperCase() == inventoryOriginalData[i].salvageCategory.toUpperCase())
                            categoryList[j].checked = true;
                    }
                }
            }
        }
        if (AllInventoryData.length == 0) {
            categoryList = this.state.DemoCategories;
        }
        this.setState({
            inventorydata: inventoryOriginalData,
            categoryList: categoryList,
            filterList: filterList,
            inventoryType: inventoryType,
            Category:""
        });
        this.props.fetchFilters(inventoryType, categoryList, filterList);
    }
    render() {
        const { inventorydata, loading, selectSB } = this.state;
        const { classes } = this.props;
        const columns = [
            {
                id: "checkbox",
                accessor: "",
                Cell: ({ row }) => {
                    return (selectSB

                        ? <div>
                            <div style={style}>
                                <SyncLoader
                                    css={override}
                                    size={15}
                                    color={"#fd7e14"}
                                    loading={this.state.selectSB}
                                />
                            </div>
                            <input
                                type="checkbox"
                                className="checkbox"
                                checked={this.state.selected[row.original.id] == true || row.original.isSelected == true}
                                onClick={() => this.handleChecked(row.original.id, row.original.warehouseId, row.original.containerQuantity)}/>
                        </div>
                        :
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.state.selected[row.original.id] == true || row.original.isSelected == true}
                            onClick={() => this.handleChecked(row.original.id, row.original.warehouseId, row.original.containerQuantity)}
                        />
                    );
                },
                sortable: false,
                filterable: false,
                width: 45
            },
            {
                Header: 'SB #',
                accessor: 'sbNumber',


                Cell: ({ row }) => (
                    <div>
                        <a style={{ cursor: 'pointer', color: 'blue' }}
                            onClick={() => this.handleclick(row)}>
                            {this.getSBNumber(row)}
                        </a>
                    </div>
                )
            },
            {
                Header: 'BL #',
                accessor: 'blNumber',
            },
            {
                Header: 'Warehouse',
                accessor: 'warehouse',

            },
            {
                Header: 'Category',
                accessor: 'salvageCategory',
                filter: "fuzzyText"
            },
            {
                Header: 'Type',
                accessor: 'hdDotCom',
            },
            {
                Header: 'Item',
                accessor: 'itemQuantity',


            },
            {
                Header: 'Pallet Qty',
                accessor: 'containerQuantity',

            },
            {
                Header: 'Wholesale',
                accessor: 'cost',
                Cell: ({ row }) => (
                    <React.Fragment>
                        {row.original.cost ? numberformat(row.original.cost) : row.original.cost}
                    </React.Fragment>
                )

            },
            {
                Header: 'Rate',
                accessor: 'rate',

            },
            {
                Header: 'Cost',
                accessor: 'rate_Cost',
                Cell: ({ row }) => (
                    <React.Fragment>
                        {row.original.rate_Cost ? numberformat(row.original.rate_Cost) : row.original.rate_Cost}
                    </React.Fragment>
                )

            }
        ]

        if (loading && inventorydata.length === 0) {
            return (
                <div><em>Please wait while Loading Inventory Data......</em>
                    <div style={style}>
                        <SyncLoader
                            css={override}
                            size={15}
                            color={"#fd7e14"}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            );
        }
        if (inventorydata == null || inventorydata == "")
            return (<div>
                {//<p className="bg-dark p-3 mb-4 text-white shadow-sm"><strong>NOTE:</strong> SB # is the identifying Slot By Number used to identify manifests</p>
                }{
                    <div>
                        <form>
                            <div >
                                <Accordion defaultActiveKey="0">
                                    <Card>
                                        <Card.Header className="p-1 icon-down" style={{ backgroundColor: "#fa7a22" }} >
                                            <Accordion.Toggle as={Button} className="positive-relative text-decoration-none" variant="link" eventKey="0">
                                                <div className="d-flex text-light ">
                                                    <h2 className="text-light">Filter By:</h2>

                                                    <span className="float-right down_btn position-absolute" style={{ left: "95%" }} >
                                                        <i class="fa-2x fa fa-angle-down" aria-hidden="true"></i></span>
                                                </div>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <div className="searchContainer">
                                                    <form>
                                                        <div className="mycheckbox">
                                                            <input type="checkbox" className="mr-1" checked={this.state.selectAllCheck} onChange={this.onToggle.bind(this, 0, "All")} />
                                                            <label style={{ fontSize: "20" }} className="mr-3">Select All</label>
                                                            <span className="mycheck"></span>
                                                        </div>
                                                    </form>
                                                    <div className="clearfix"></div>
                                                </div>
                                                <div className="searchContainer">
                                                    <h3 className="mt-2 mb-3"><strong>Inventory Type</strong></h3>
                                                    <form>
                                                        {this.state.inventoryType.map((filter, i) => (
                                                            <React.Fragment key={i}>
                                                                <div className="mycheckbox">
                                                                    <input type="checkbox" className="mr-1" checked={filter.checked} onChange={this.onToggle.bind(this, i, "type")} />
                                                                    <label style={{ fontSize: "20" }} className="mr-3" htmlFor={filter.id}>{filter.name}</label>
                                                                    <span className="mycheck"></span>
                                                                </div>
                                                            </React.Fragment>
                                                        ))}
                                                    </form>
                                                    <div className="clearfix"></div>
                                                </div>

                                                <div className="clearfix"></div>
                                                <div className="searchContainer">
                                                    <h3 className="mt-2 mb-3"><strong>Warehouses</strong></h3>
                                                    <form>
                                                        {this.state.filterList.map((filter, i) => (
                                                            <React.Fragment key={i}>
                                                                <div className="mycheckbox">
                                                                    <input type="checkbox" className="mr-1" checked={filter.checked} onChange={this.onToggle.bind(this, i, "warehouse")} />
                                                                    <label style={{ fontSize: "20" }} className="mr-3" htmlFor={filter.id}>{filter.name}</label>
                                                                    <span className="mycheck"></span>
                                                                </div>
                                                            </React.Fragment>
                                                        ))}
                                                    </form>
                                                    <div className="clearfix"></div>
                                                </div>
                                                <div className="searchContainer mb-3">

                                                    {this.state.categoryList.length > 0 ?
                                                        <h3 className="mt-2 mb-3"><strong>Category</strong></h3>
                                                        : <h3 className="mt-2 mb-3"><strong></strong></h3>
                                                    }
                                                    <form>
                                                        {this.state.categoryList.map((filter, i) => (
                                                            <React.Fragment key={i}>
                                                                <div className="mycheckbox">
                                                                    <input type="checkbox" className="mr-1" checked={filter.checked} onChange={this.onToggle.bind(this, i, "category")} />
                                                                    <label style={{ fontSize: "20" }} className="mr-3" htmlFor={filter.id}>{filter.caption}</label>
                                                                    <span className="mycheck"></span>
                                                                </div>
                                                            </React.Fragment>
                                                        ))}
                                                    </form>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                        </form>
                    </div>
                }
                <div>
                    <div class="clearfix"></div>
                    <CssBaseline />
                    <div class="clearfix mt-3 mb-3">
                        <div style={{ float: 'right' }}>
                            <Button className="text-right" onClick={() => this.clearFilter()} variant="outline-secondary">Clear Filter</Button>
                        </div>
                    </div>
                    <EnhancedTable
                        columns={columns}
                        data={this.state.inventorydata} minRows={0} />
                </div>
                <div>
                    <h2>No SB Available.Please Refresh Page</h2>
                </div>
            </div>)


        return (
            <div>
                <div>
                    <StyledLoader
                    active={this.state.selectSB}
                    classNamePrefix='MyLoader_'>
                    <div>
                        <form>
                            <div >
                                <Accordion defaultActiveKey="0">
                                    <Card>
                                        <Card.Header className="p-1 icon-down" style={{ backgroundColor: "#fa7a22" }} >
                                            <Accordion.Toggle as={Button} className="positive-relative text-decoration-none" variant="link" eventKey="0">
                                                <div className="d-flex text-light ">
                                                    <h2 className="text-light">Filter By:</h2>

                                                    <span className="float-right down_btn position-absolute" style={{ left: "95%" }} >
                                                        <i class="fa-2x fa fa-angle-down" aria-hidden="true"></i></span>
                                                </div>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                <div className="searchContainer">
                                                    <form>
                                                        <div className="mycheckbox">
                                                                <input type="checkbox" className="mr-1" checked={this.state.selectAllCheck} onChange={this.onToggle.bind(this, 0, "All")} />
                                                            <label style={{ fontSize: "20" }} className="mr-3">Select All</label>
                                                            <span className="mycheck"></span>
                                                        </div>
                                                    </form>
                                                    <div className="clearfix"></div>
                                                </div>
                                                <div className="searchContainer">
                                                    <h3 className="mt-2 mb-3"><strong>Inventory Type</strong></h3>
                                                    <form>
                                                        {this.state.inventoryType.map((filter, i) => (
                                                            <React.Fragment key={i}>
                                                                <div className="mycheckbox">
                                                                    <input type="checkbox" className="mr-1" checked={filter.checked} onChange={this.onToggle.bind(this, i, "type")} />
                                                                    <label style={{ fontSize: "20" }} className="mr-3" htmlFor={filter.id}>{filter.name}</label>
                                                                    <span className="mycheck"></span>
                                                                </div>
                                                            </React.Fragment>
                                                        ))}
                                                    </form>
                                                    <div className="clearfix"></div>
                                                </div>
                                                <div className="searchContainer">
                                                    <h3 className="mt-2 mb-3"><strong>Warehouses</strong></h3>
                                                    <form>
                                                        {this.state.filterList.map((filter, i) => (
                                                            <React.Fragment key={i}>
                                                                <div className="mycheckbox">
                                                                    <input type="checkbox" className="mr-1" checked={filter.checked} onChange={this.onToggle.bind(this, i, "warehouse")} />
                                                                    <label style={{ fontSize: "20" }} className="mr-3" htmlFor={filter.id}>{filter.name}</label>
                                                                    <span className="mycheck"></span>
                                                                </div>
                                                            </React.Fragment>
                                                        ))}
                                                    </form>
                                                    <div className="clearfix"></div>
                                                </div>
                                                <div className="searchContainer mb-3">
                                                        {this.state.categoryList.length > 0 ?
                                                            <h3 className="mt-2 mb-3"><strong>Category</strong></h3>
                                                            : <h3 className="mt-2 mb-3"><strong></strong></h3>
                                                        }
                                                    <form>
                                                        {this.state.categoryList.map((filter, i) => (
                                                            <React.Fragment key={i}>
                                                                <div className="mycheckbox">
                                                                    <input type="checkbox" className="mr-1" checked={filter.checked} onChange={this.onToggle.bind(this, i, "category")} />
                                                                    <label style={{ fontSize: "20" }} className="mr-3" htmlFor={filter.id}>{filter.caption}</label>
                                                                    <span className="mycheck"></span>
                                                                </div>
                                                            </React.Fragment>
                                                        ))}
                                                    </form>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                        </form>
                        <Snackbar
                            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                            open={this.state.snackbarOpen}
                            autoHideDuration={2000}
                            onClose={this.snackBarClose}
                            ContentProps={{
                                "aria-describedby": "message-id",
                                className: classes.snackbarStyleViaNestedContent
                            }}
                            message={
                                <span id="message-id">
                                    <div>SB added to cart successfully!!!</div>
                                </span>
                            }

                        />
                            <Snackbar
                            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                            open={this.state.snackbaruncheck}
                            autoHideDuration={2000}
                            onClose={this.snackBarClose}
                            ContentProps={{
                                "aria-describedby": "message-id",
                                className: classes.snackbarStyleViaNestedContent
                            }}
                            message={
                                <span id="message-id">
                                    <div>Sb removed from Cart Successfully!!!</div>
                                </span>
                            }
                        />
                        <Snackbar
                            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                            open={this.state.snackbarUnavailableSB}
                            autoHideDuration={2000}
                            onClose={this.snackBarClose}
                            ContentProps={{
                                "aria-describedby": "message-id",
                                className: classes.snackbarStyleViaContentProps
                            }}
                            message={
                                <span id="message-id">
                                    <div>This sb has been hold by another user</div>
                                </span>
                            }
                        />
                    </div>
                        <div>
                            <div class="clearfix"></div>
                            <CssBaseline />
                            <div class="clearfix mt-3 mb-3">
                                
                                <div style={{ float: 'right' }}>
                                    <Button className="text-right" onClick={() => this.clearFilter()} variant="outline-secondary">Clear Filter</Button>
                                </div>
                            </div> 
                            <EnhancedTable
                                columns={columns}
                                data={this.state.inventorydata} minRows={0}
                                    pageSize={(this.state.inventorydata.length > 10) ? this.state.inventorydata.length : this.state.inventorydata.length} />
                        </div>
                    </StyledLoader>
                </div>
                <Modal centered size="xl" show={this.state.show} onHide={this.handleClose} className="sb-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>SB Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p><strong> SB #:</strong> {this.state.sbNumber}, <strong>Bl #:</strong> {this.state.blNumber}, <strong>Warehouse:</strong> {this.state.warehouse}, <strong>Category:</strong>{this.state.salvageCategory}, <strong>Pallet Qty:</strong> {this.state.containerQuantity}</p>
                        <InventoryDetail sbNumber={this.state.sbNumber} warehouse={this.state.warehouseId} />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }

    async callCheckedApi(act, manifestId) {
        console.time("Act");
        let inventorydata = this.state.inventorydata;
        const token = await authService.getAccessToken();
        const newSelected = Object.assign({}, this.state.selected);
        console.timeEnd("Act");
        console.time("check SB");
        const response = await fetch('InventoryCode/SetSelectedRowsSession?manifestId=' + manifestId + '&abc=' + act, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        /*const data = await response.json();*/
        const data = await commonErrorHandler(response);
        if (data.accessDenied !== undefined && data.accessDenied == true) {
            //buy/bid is disabled
            this.props.openDialog(1,
                "Restriction",
                "Sorry, Your account has been restricted from purchasing. Please contact your account manager.", "Close", "", DIALOG_ACTION_CLOSE, null, null);
            newSelected[manifestId] = false;
            this.setState({
                selectSB: false, selected: newSelected
            });
        }
        else if (data.addSBId == -2) {
            this.props.openDialog(1,
                "Alert",
                "Manifest cannot be selected since maximum pallet per order is 26", "Close", "", DIALOG_ACTION_CLOSE, null, null);
            this.setState({
                selectSB: false,
            });
        }
        else { 
            this.setState({
                inventorydata: []
            })
            console.timeEnd("check SB");
            this.setState({ CheckedData: data, loading: false });
            console.time("PopulateInventory");
            this.populateInventoryData(false, true, token);
            this.setState({
                selectSB: false,
            });
            console.timeEnd("PopulateInventory");
            if (act == "add" && data.addSBId > 0)
                this.setState({ snackbarOpen: true, inventorydata: inventorydata });
            else if (act == "add" && data.addSBId == 0) {
                newSelected[manifestId] = false;
                this.setState({ snackbarUnavailableSB: true, inventorydata: inventorydata, selected: newSelected });
            }
            else
                this.setState({ snackbaruncheck: true, inventorydata: inventorydata });
        }
    }

    async populateInventoryData(pageLoad, sbCheck, Authtoken) {
        this.setState({ loading: true });
        let formData = new FormData();
        let token, action;
        action = this.props.CompleteOrder;
        if (Authtoken != undefined && Authtoken != "" && Authtoken != null) {
            token = Authtoken;
        }
        else {
            token = await authService.getAccessToken();
        }
        const response = await fetch('InventoryCode/SearchInventory', {
            method: 'post',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,

            },
            body: formData
        });
        /* const data = await response.json();*/
        const data = await commonErrorHandler(response);
        //var chekpallet = data.value.checkpallet[0].isApplyPallet;
        //if (chekpallet > 1) {
        //    setTimeout(
        //        function () {
        //            this.props.openDialog(1,
        //                "Alert",
        //                "Apply To Pallet Rate Has Changed.Please Refresh Page.Please Refresh Pagee", "Close", "", DIALOG_ACTION_CLOSE, null, null);
        //        }
        //            .bind(this),
        //        5000
        //    );
        //   // window.location.reload();
        //    return false;
        //}
        if (data.value.manifestList == null) {
            data.value.manifestList = [];
        }
        for (var i = 0; i < data.value.manifestList.length; i++) {
            var rate = 0.0;
            var rate = (data.value.manifestList[i].rate) * 100;
            data.value.manifestList[i].rate = rate.toFixed(2) + " %";
            var cost = 0.0;
            /*var cost = (data.value.manifestList[i].cost) * 1.15;*/
            var cost = (data.value.manifestList[i].cost);
            data.value.manifestList[i].cost = cost.toFixed(2);
            var rate_Cost = 0.0;
            data.value.manifestList[i].rate_Cost = (parseFloat(cost) * parseFloat(rate) / 100).toFixed(2);
            if (data.value.manifestList[i].warehouse == 0)
                data.value.manifestList[i].warehouse = "NotSpecified";
            else if (data.value.manifestList[i].warehouse == 8615)
                data.value.manifestList[i].warehouse = "Indianapolis";
            else if (data.value.manifestList[i].warehouse == 8616)
                data.value.manifestList[i].warehouse = "McDonough";
            else if (data.value.manifestList[i].warehouse == 8617)
                data.value.manifestList[i].warehouse = "Phoenix";
            else if (data.value.manifestList[i].warehouse == 8618)
                data.value.manifestList[i].warehouse = "Pittston";
            else if (data.value.manifestList[i].warehouse == 9999)
                data.value.manifestList[i].warehouse = "Other";
            else
                data.value.manifestList[i].warehouse = data.value.manifestList[i].warehouse;
            for (var j = 0; j < data.value.selectedTotalsBySb.split(',').length; j++) {
                if (data.value.selectedTotalsBySb.split(',')[j].split('|')[0] == data.value.manifestList[i].id) {
                    data.value.manifestList[i].isSelected = true;
                }
            }
        }
        var gridData = data.value.manifestList;
        //let allInventryClear = data.value.manifestList;
        var completeData = gridData;
        var dynamicCategoryList = [];
        for (var i = 0; i < gridData.length; i++) {
            let catCounnt = 0;
            for (var j = 0; j < dynamicCategoryList.length; j++) {
                if (dynamicCategoryList[j].name == gridData[i].salvageCategory) {
                    catCounnt++;
                }
            }
            if (catCounnt == 0) {
                dynamicCategoryList.push({ caption: gridData[i].salvageCategory, name: gridData[i].salvageCategory })
            }
        }
        var InventoryData = data.value.manifestList;
        let categoryName, warehouseName, allLocation;
        if (sbCheck) {
            categoryName = '';
            warehouseName = '';
            allLocation = '';
        }
        else {
            allLocation = this.state.AllLocation;
            categoryName = this.state.Category;
            warehouseName = this.state.Warehouse;
        }
        let filterList, categoryList, inventoryType, tempCategoryList = [], checkAll = false;
        inventoryType = this.state.inventoryType;
        filterList = this.state.filterList;
        categoryList = dynamicCategoryList;
        let allCategoryClear = categoryList.sort((a, b) => a.caption > b.caption ? 1 : -1);
        let selectType = this.state.inventoryType.filter(item => item.checked);
        let selectCat = this.state.categoryList.filter(item => item.checked);
        let select = this.state.filterList.filter(item => item.checked);
        if (categoryName != "" && categoryName != undefined && categoryName != null) {
            for (var i = 0; i < categoryList.length; i++) {
                if (categoryList[i].name.toUpperCase() == categoryName.toUpperCase() || categoryList[i].checked) {
                    categoryList[i].checked = true;
                }
                else {
                    categoryList[i].checked = false;
                }
                if (categoryName.toUpperCase() == "MIXED LOAD") {
                    if (categoryList[i].name.toUpperCase() == "001" || categoryList[i].checked)
                        categoryList[i].checked = true;
                }
            }
            if (selectCat.length > 0) {
                gridData = gridData.filter(o1 => selectCat.some(o2 => o1.salvageCategory.toUpperCase() === o2.name.toUpperCase()))
            }
            else {
                if (categoryName.toUpperCase() == "MIXED LOAD") {
                    gridData = gridData.filter(x => x.salvageCategory.toUpperCase() == categoryName.toUpperCase() || x.salvageCategory.toUpperCase() == "001")
                }
                else
                    gridData = gridData.filter(x => x.salvageCategory.toUpperCase() == categoryName.toUpperCase())
                if (gridData.length != 0) {
                    for (var i = 0; i < gridData.length; i++) {
                        for (var j = 0; j < filterList.length; j++) {
                            if (filterList[j].name.toUpperCase() == gridData[i].warehouse.toUpperCase())
                                filterList[j].checked = true;
                        }
                        for (var k = 0; k < inventoryType.length; k++) {
                            if (inventoryType[k].name.toUpperCase() == gridData[i].hdDotCom.toUpperCase())
                                inventoryType[k].checked = true;
                        }
                    }

                    // function for select categoryList on inventoryType and Warehouse for selected category
                    tempCategoryList = getCategoryListByCategory(InventoryData, filterList, inventoryType, categoryName, "")

                    dynamicCategoryList = tempCategoryList;
                }
                else {
                    for (var j = 0; j < categoryList.length; j++) {
                        if (categoryList[j].caption.toUpperCase() == categoryName.toUpperCase()) {
                            tempCategoryList.push({ caption: categoryName.toUpperCase(), name: categoryName.toUpperCase(), checked: true });
                        }
                        else {
                            tempCategoryList.push({ caption: categoryList[j].caption, name: categoryList[j].caption, checked: false });
                        }
                    }
                    dynamicCategoryList = tempCategoryList;
                }

            }
        }
        else if (warehouseName != "" && warehouseName != undefined && warehouseName != null) {
            for (var i = 0; i < filterList.length; i++) {
                if (filterList[i].name.toUpperCase() == warehouseName.toUpperCase() || filterList[i].checked)
                    filterList[i].checked = true;
            }
            if (select.length > 0) {
                gridData = gridData.filter(o1 => select.some(o2 => o1.warehouseId === o2.id))
            }
            else {
                gridData = gridData.filter(x => x.warehouse == warehouseName)
                dynamicCategoryList = getCategoryList(gridData);
                for (var i = 0; i < gridData.length; i++) {
                    for (var k = 0; k < inventoryType.length; k++) {
                        if (inventoryType[k].name.toUpperCase() == gridData[i].hdDotCom.toUpperCase())
                            inventoryType[k].checked = true;
                    }
                    for (var l = 0; l < dynamicCategoryList.length; l++) {
                        if (dynamicCategoryList[l].caption.toUpperCase() == gridData[i].salvageCategory.toUpperCase())
                            dynamicCategoryList[l].checked = true;
                    }
                }
                categoryList = dynamicCategoryList; 
            }
        }
        else if ((allLocation != "" && allLocation != undefined && allLocation != null) || sbCheck == undefined) {
            if (allLocation != "AllLocation" && this.props.Filters.inventoryType.length > 0) {
                inventoryType = this.props.Filters.inventoryType;
                filterList = this.props.Filters.filterList;
                dynamicCategoryList = this.props.Filters.categoryList;

                select = filterList.filter(item => item.checked);
                selectCat = dynamicCategoryList.filter(item => item.checked);
                selectType = inventoryType.filter(item => item.checked);
                if (selectType.length > 0) {
                    gridData = gridData.filter(o1 => selectType.some(o2 => o1.hdDotCom.toUpperCase() === o2.value.toUpperCase()));
                }
                if (select.length > 0) {
                    gridData = gridData.filter(o1 => select.some(o2 => o1.warehouseId === o2.id));
                }
                if (selectCat.length > 0) {
                    gridData = gridData.filter(o1 => selectCat.some(o2 => o1.salvageCategory === o2.caption));
                }
            }
            else if (gridData.length != 0) {
                for (var i = 0; i < gridData.length; i++) {
                    for (var j = 0; j < filterList.length; j++) {
                        if (filterList[j].name.toUpperCase() == gridData[i].warehouse.toUpperCase())
                            filterList[j].checked = true;
                    }
                    for (var k = 0; k < inventoryType.length; k++) {
                        if (inventoryType[k].name.toUpperCase() == gridData[i].hdDotCom.toUpperCase())
                            inventoryType[k].checked = true;
                    }
                    for (var l = 0; l < dynamicCategoryList.length; l++) {
                        if (dynamicCategoryList[l].caption.toUpperCase() == gridData[i].salvageCategory.toUpperCase())
                            dynamicCategoryList[l].checked = true;
                    }
                }
                categoryList = dynamicCategoryList;
                checkAll = true;
            }
        }
        if (sbCheck) {
            if ((selectType.length === 0 || selectType.length === filterList.length) && (select.length === 0 || select.length === filterList.length) && (selectCat.length === 0 || selectCat === categoryList.length)) {
                dynamicCategoryList = getCategoryData(completeData, selectCat);
            }
            else {
                if (selectType.length != 0) {
                    completeData = completeData.filter(o1 => selectType.some(o2 => o1.hdDotCom.toUpperCase() === o2.value.toUpperCase()));
                    dynamicCategoryList = getCategoryData(completeData, selectCat);
                }
                if (select.length != 0) {
                    completeData = completeData.filter(o1 => select.some(o2 => o1.warehouseId === o2.id));
                    dynamicCategoryList = getCategoryData(completeData, selectCat);
                }
                if (selectCat.length != 0) {
                    completeData = completeData.filter(o1 => selectCat.some(o2 => o1.salvageCategory === o2.caption));
                    if (select.length == 0 && selectType.length == 0) {
                        let AllCategory = this.state.AllCategory;
                        dynamicCategoryList = getAllCategory(selectCat, AllCategory);
                    }
                }
            }
            gridData = completeData;
            this.updateViewCart(data.value);
        }
        if (InventoryData.length > 0) {
            dynamicCategoryList = dynamicCategoryList.sort((a, b) => a.caption > b.caption ? 1 : -1)
            categoryList = categoryList.sort((a, b) => a.caption > b.caption ? 1 : -1)
        }
        else {
            dynamicCategoryList = this.state.DemoCategories.sort((a, b) => a.caption > b.caption ? 1 : -1)
            categoryList = this.state.DemoCategories.sort((a, b) => a.caption > b.caption ? 1 : -1)
            allCategoryClear = this.state.DemoCategories.sort((a, b) => a.caption > b.caption ? 1 : -1)
        }
        this.props.fetchFilters(inventoryType, dynamicCategoryList, filterList);
        this.setState({ TotalsBySb: data.value.selectedTotalsBySb, MaximumPallet: data.value.maximumPalletPerOrder, selectAllCheck: checkAll, AllCategoryClear: allCategoryClear })
        this.setState({ inventorydata: gridData, inventoryOriginalData: InventoryData, categoryList: dynamicCategoryList, AllCategory: categoryList, filterList: filterList, inventoryType: inventoryType, loading: false })
        const { pathname } = window.location;
        if (pageLoad && pathname === this.state.currentPathName) {
            this.intervalID = setTimeout(this.populateInventory.bind(this, token), 6000);
        }
    }

    async populateInventory(Authtoken) {
        let formData = new FormData();
        let token;
        if (Authtoken != undefined && Authtoken != "" && Authtoken != null) {
            token = Authtoken;
        }
        else {
            token = await authService.getAccessToken();
        }
        const response = await fetch('InventoryCode/SearchInventory', {
            method: 'post',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,

            },
            body: formData
        });
        /* const data = await response.json();*/
        const data = await commonErrorHandler(response);
        //  var chekpallet = data.value.checkpallet[0].isApplyPallet;
        //if (chekpallet > 1) {
        //    setTimeout(
        //        function () {
        //            this.props.openDialog(1,
        //                "Alert",
        //                "Apply To Pallet Rate Has Changed vipin .Please Refresh Page.Please Refresh Pagee", "Close", "", DIALOG_ACTION_CLOSE, null, null);
        //        }
        //            .bind(this),
        //        5000
        //    );
        //  //  window.location.reload();
        //    return false;
        //}
        if (data.value.manifestList == null) {
            data.value.manifestList = [];
        }
        if (data.value.manifestList == null && data.value.warehouseId == -1) {
            this.setState({
                inventorydata: this.state.inventorydata
            });
        }
        else {
            var InventoryData = data.value.manifestList;
            for (var i = 0; i < data.value.manifestList.length; i++) {
                var rate = 0.0;
                var rate = (data.value.manifestList[i].rate) * 100;
                data.value.manifestList[i].rate = rate.toFixed(2) + " %";
                var cost = 0.0;
                /*var cost = (data.value.manifestList[i].cost) * 1.15;*/
                var cost = (data.value.manifestList[i].cost);
                data.value.manifestList[i].cost = cost.toFixed(2);
                var rate_Cost = 0.0;
                data.value.manifestList[i].rate_Cost = (parseFloat(cost) * parseFloat(rate) / 100).toFixed(2);
                if (data.value.manifestList[i].warehouse == 0)
                    data.value.manifestList[i].warehouse = "NotSpecified";
                else if (data.value.manifestList[i].warehouse == 8615)
                    data.value.manifestList[i].warehouse = "Indianapolis";
                else if (data.value.manifestList[i].warehouse == 8616)
                    data.value.manifestList[i].warehouse = "McDonough";
                else if (data.value.manifestList[i].warehouse == 8617)
                    data.value.manifestList[i].warehouse = "Phoenix";
                else if (data.value.manifestList[i].warehouse == 8618)
                    data.value.manifestList[i].warehouse = "Pittston";
                else if (data.value.manifestList[i].warehouse == 9999)
                    data.value.manifestList[i].warehouse = "Other";
                else
                    data.value.manifestList[i].warehouse = data.value.manifestList[i].warehouse;
                for (var j = 0; j < data.value.selectedTotalsBySb.split(',').length; j++) {
                    if (data.value.selectedTotalsBySb.split(',')[j].split('|')[0] == data.value.manifestList[i].id) {
                        data.value.manifestList[i].isSelected = true;
                    }
                }
            }
            var gridData = data.value.manifestList;
            let select, selectCat, selectType;
            let filterList, categoryList, inventoryType, categoryName;
            let prevData = this.state.inventoryOriginalData;
            filterList = this.state.filterList;
            categoryList = this.state.categoryList;
            inventoryType = this.state.inventoryType;
            categoryName = this.state.Category;
            if (gridData.length == prevData.length) {
                select = this.state.filterList.filter(item => item.checked);
                selectCat = this.state.categoryList.filter(item => item.checked);
                selectType = this.state.inventoryType.filter(item => item.checked);

                if ((selectType.length === 0 || selectType.length === inventoryType.length) && (select.length === 0 || select.length === filterList.length) && (selectCat.length === 0 || selectCat === categoryList.length) && categoryName == "") {
                    gridData = gridData;
                }
                else {
                    if (selectType.length != 0) {
                        gridData = gridData.filter(o1 => selectType.some(o2 => o1.hdDotCom.toUpperCase() === o2.value.toUpperCase()));
                    }
                    if (select.length != 0) {
                        gridData = gridData.filter(o1 => select.some(o2 => o1.warehouseId === o2.id));
                    }
                    if (selectCat.length != 0 && categoryList.filter(x1 => selectCat.some(x2 => x1.caption === x2.caption)).length > 0) {
                        gridData = gridData.filter(o1 => selectCat.some(o2 => o1.salvageCategory === o2.caption));
                    }
                    if (categoryName != "" && categoryName != undefined) {
                        if (categoryName.toUpperCase() == "MIXED LOAD") {
                            gridData = gridData.filter(x => x.salvageCategory.toUpperCase() == categoryName.toUpperCase() || x.salvageCategory.toUpperCase() == "001")
                        }
                        else
                            gridData = gridData.filter(x => x.salvageCategory.toUpperCase() == categoryName.toUpperCase())
                    }
                }
                if (this.state.fieldName == "salvageCategory") {
                    this.getDataBySearch(this.state.searchText.toUpperCase(), this.state.fieldName);
                }
                else if (this.state.searchText != "" && this.state.fieldName != "salvageCategory") {
                    if (this.state.fieldName === "Select") {
                        gridData = this.search(gridData, this.state.searchText)
                    } else if (this.state.fieldName != "Select") {
                        if (this.state.fieldName === "containerQuantity") {
                            gridData = gridData.filter(a => a[this.state.fieldName] == this.state.searchText);
                        }
                        else
                            gridData = gridData.filter(a => a[this.state.fieldName].toLowerCase().includes(this.state.searchText.toLowerCase()));
                    }
                }
                this.setState({
                    fieldName: this.state.fieldName, searchText: this.state.searchText,
                    inventoryType: inventoryType, filterList: filterList, categoryList: categoryList,
                    inventorydata: gridData, inventoryOriginalData: InventoryData
                });
            }
            else {
                InventoryData = gridData;
                this.inventoryUpload(InventoryData)
            }
        }
        this.props.getCartSummary();
        const { pathname } = window.location;
        if (pathname === this.state.currentPathName) {
            this.intervalID = setTimeout(this.populateInventory.bind(this, token), 6000);
        }
    }

    async inventoryUpload(InventoryData) {
        var AllInventory = InventoryData;
        var dynamicCategoryList = [];
        for (let i = 0; i < InventoryData.length; i++) {
            let catCounnt = 0;
            for (let j = 0; j < dynamicCategoryList.length; j++) {
                if (dynamicCategoryList[j].name == InventoryData[i].salvageCategory) {
                    catCounnt++;
                }
            }
            if (catCounnt == 0) {
                dynamicCategoryList.push({ caption: InventoryData[i].salvageCategory, name: InventoryData[i].salvageCategory })
            }
        }
        var filterList, categoryList, inventoryType, allCategoryList, data;
        var select, selectCat, selectType;
        allCategoryList = dynamicCategoryList;

        inventoryType = this.props.Filters.inventoryType;
        filterList = this.props.Filters.filterList;
        categoryList = this.props.Filters.categoryList;

        select = filterList.filter(item => item.checked);
        selectCat = categoryList.filter(item => item.checked);
        selectType = inventoryType.filter(item => item.checked);
        for (var i = 0; i < dynamicCategoryList.length; i++) {
            for (var j = 0; j < selectCat.length; j++) {
                if (dynamicCategoryList[i].caption.toUpperCase() == selectCat[j].caption.toUpperCase()) {
                    dynamicCategoryList[i].checked = true;
                }
            }
        }
        categoryList = dynamicCategoryList;
        if (InventoryData.length > 0 && (selectType.length != 0 || select.length != 0 || selectCat.length != 0)) {
            if (selectType.length > 0) {
                InventoryData = InventoryData.filter(o1 => selectType.some(o2 => o1.hdDotCom.toUpperCase() === o2.value.toUpperCase()));
                categoryList = getCategoryData(InventoryData, selectCat);
            }
            if (select.length > 0) {
                InventoryData = InventoryData.filter(o1 => select.some(o2 => o1.warehouseId === o2.id));
                categoryList = getCategoryData(InventoryData, selectCat);
            }
            if (selectCat.length > 0) {
                InventoryData = InventoryData.filter(o1 => selectCat.some(o2 => o1.salvageCategory === o2.caption));
            }
        }
        else {
            for (var i = 0; i < inventoryType.length; i++) {
                inventoryType[i].checked = false;
            }
            for (var i = 0; i < filterList.length; i++) {
                filterList[i].checked = false;
            }
            categoryList = dynamicCategoryList;
        }
        data = InventoryData;
        if (this.state.fieldName == "salvageCategory") {
            this.getDataBySearch(this.state.searchText.toUpperCase(), this.state.fieldName);
        }
        else if (this.state.searchText != "" && this.state.fieldName != "salvageCategory") {
            if (this.state.fieldName === "Select") {
                data = this.search(data, this.state.searchText)
            } else if (this.state.fieldName != "Select") {
                if (this.state.fieldName === "containerQuantity") {
                    data = data.filter(a => a[this.state.fieldName] == this.state.searchText);
                }
                else
                    data = data.filter(a => a[this.state.fieldName].toLowerCase().includes(this.state.searchText.toLowerCase()));
            }
        }
        if (selectCat.length == 0) {
            for (var i = 0; i < dynamicCategoryList.length; i++) {
                dynamicCategoryList[i].checked = false;
            }
        }
        dynamicCategoryList = dynamicCategoryList.sort((a, b) => a.caption > b.caption ? 1 : -1);
        categoryList = categoryList.sort((a, b) => a.caption > b.caption ? 1 : -1);
        this.setState({
            fieldName: this.state.fieldName, searchText: this.state.searchText,
            inventoryType: inventoryType, filterList: filterList, categoryList: categoryList, AllCategory: dynamicCategoryList, AllCategoryClear: dynamicCategoryList,
            inventorydata: data, inventoryOriginalData: AllInventory
        })
    }
    async updateViewCart(data) {
        var checkedInventory = [];
        var Item = 0;
        var WholeSale = 0.0;
        var Amount = 0.0;
        var PalletQty = 0;
        if (data.selectedTotalsBySb != null && data.selectedTotalsBySb != "") {
            for (var i = 0; i < data.selectedTotalsBySb.split(',').length; i++) {

                var obj = {};
                obj["manifestId"] = data.selectedTotalsBySb.split(',')[i].split('|')[0];
                obj["sbNumber"] = data.selectedTotalsBySb.split(',')[i].split('|')[1];
                obj["category"] = data.selectedTotalsBySb.split(',')[i].split('|')[2];
                obj["type"] = data.selectedTotalsBySb.split(',')[i].split('|')[3];
                obj["palletQuantity"] = data.selectedTotalsBySb.split(',')[i].split('|')[4];
                obj["totalWeight"] = data.selectedTotalsBySb.split(',')[i].split('|')[5];
                obj["wholesale"] = parseFloat(data.selectedTotalsBySb.split(',')[i].split('|')[6]).toFixed(2);
                obj["rate%"] = data.selectedTotalsBySb.split(',')[i].split('|')[7];
                obj["itemQuantity"] = data.selectedTotalsBySb.split(',')[i].split('|')[8];
                obj["warehouseId"] = data.selectedTotalsBySb.split(',')[i].split('|')[9];
                checkedInventory.push(Object.values(obj));
            }
            for (var j = 0; j < data.selectedTotalsBySb.split(',').length; j++) {
                WholeSale += parseFloat(data.selectedTotalsBySb.split(',')[j].split('|')[6]);
                PalletQty += parseInt(data.selectedTotalsBySb.split(',')[j].split('|')[4])
                Amount += parseFloat(data.selectedTotalsBySb.split(',')[j].split('|')[6]) * parseFloat(data.selectedTotalsBySb.split(',')[j].split('|')[7]) / 100
            }
            WholeSale = WholeSale.toFixed(2);
            Amount = Amount.toFixed(2);
            Item = data.selectedTotalsBySb.split(',').length;

        }
        if (data.manifestList != "" && data.manifestList != null) {
            var gridData = data.manifestList;
            var dynamicCategoryList = [];
            for (var i = 0; i < gridData.length; i++) {
                let catCounnt = 0;
                for (var j = 0; j < dynamicCategoryList.length; j++) {
                    if (dynamicCategoryList[j] == gridData[i].salvageCategory) {
                        catCounnt++;
                    }
                }
                if (catCounnt == 0) {
                    dynamicCategoryList.push(gridData[i].salvageCategory)
                }
            }
        }
        this.props.viewCartUpdate(Item, Amount, checkedInventory, PalletQty, dynamicCategoryList);
    }
}

function getCategoryList(gridData) {
    let dynamicCategoryList = [];
    for (var i = 0; i < gridData.length; i++) {
        let count = 0;
        for (var j = 0; j < dynamicCategoryList.length; j++) {
            if (dynamicCategoryList[j].name == gridData[i].salvageCategory) {
                count++;
            }
        }
        if (count == 0) {
            dynamicCategoryList.push({ caption: gridData[i].salvageCategory, name: gridData[i].salvageCategory, checked: false })
        }
    }
    return dynamicCategoryList;
}
function getCategoryData(gridData, selectCat) {
    let dynamicCategoryList = [];
    let updatedCatList = [];
    for (var i = 0; i < gridData.length; i++) {
        let count = 0;
        for (var j = 0; j < dynamicCategoryList.length; j++) {
            if (dynamicCategoryList[j].name == gridData[i].salvageCategory) {
                count++;
            }
        }
        if (count == 0) {
            dynamicCategoryList.push({ caption: gridData[i].salvageCategory, name: gridData[i].salvageCategory })
        }
    }
    if (selectCat.length > 0) {
        for (var j = 0; j < dynamicCategoryList.length; j++) {
            let cnt = 0;
            for (var i = 0; i < selectCat.length; i++) {
                if (selectCat[i].caption == dynamicCategoryList[j].caption && selectCat[i].checked)
                    cnt++;
            }
            if (cnt > 0)
                updatedCatList.push({ caption: dynamicCategoryList[j].caption, name: dynamicCategoryList[j].caption, checked: true })
            else
                updatedCatList.push({ caption: dynamicCategoryList[j].caption, name: dynamicCategoryList[j].caption, checked: false })
        }
    }
    else {
        for (var i = 0; i < dynamicCategoryList.length; i++) {
            updatedCatList.push({ caption: dynamicCategoryList[i].caption, name: dynamicCategoryList[i].caption, checked: false })
        }
    }

    return updatedCatList.sort((a, b) => a.caption > b.caption ? 1 : -1);
}

function getAllCategory(selectCat, AllCategory) {
    let updatedCatList = [];
    for (var j = 0; j < AllCategory.length; j++) {
        let cnt = 0;
        for (var i = 0; i < selectCat.length; i++) {
            if (selectCat[i].caption == AllCategory[j].caption && selectCat[i].checked)
                cnt++;
        }
        if (cnt > 0) {
            updatedCatList.push({ caption: AllCategory[j].caption, name: AllCategory[j].caption, checked: true })
        }
        else
            updatedCatList.push({ caption: AllCategory[j].caption, name: AllCategory[j].caption, checked: false })
    }
    return updatedCatList.sort((a, b) => a.caption > b.caption ? 1 : -1);
}
function getCategoryListByCategory(InventoryData, filterList, inventoryType, categoryName, selectCat) {
    let selectType, select, tempCategoryList = [];
    selectType = inventoryType.filter(item => item.checked);
    select = filterList.filter(item => item.checked);
    if (selectType.length > 0) {
        InventoryData = InventoryData.filter(x => selectType.some(y => x.hdDotCom.toUpperCase() === y.name.toUpperCase()));
    }
    if (select.length > 0) {
        InventoryData = InventoryData.filter(o1 => select.some(o2 => o1.warehouseId === o2.id));
    }
    for (var i = 0; i < InventoryData.length; i++) {
        let count = 0;
        for (var j = 0; j < tempCategoryList.length; j++) {
            if (tempCategoryList[j].name == InventoryData[i].salvageCategory) {
                count++;
            }
        }
        if (count == 0) {
            if (categoryName != "") {
                if (InventoryData[i].salvageCategory == categoryName)
                    tempCategoryList.push({ caption: InventoryData[i].salvageCategory, name: InventoryData[i].salvageCategory, checked: true })
                else
                    tempCategoryList.push({ caption: InventoryData[i].salvageCategory, name: InventoryData[i].salvageCategory, checked: false })
                if (categoryName.toUpperCase() == "MIXED LOAD") {
                    if (InventoryData[i].salvageCategory == "001")
                        //tempCategoryList.push({ caption: InventoryData[i].salvageCategory, name: InventoryData[i].salvageCategory, checked: true })
                        for (var k = 0; k < tempCategoryList.length; k++) {
                            if (tempCategoryList[k].caption == "001")
                                tempCategoryList[k].checked = true;
                        }
                }
            }
            else if (selectCat.length > 0) {
                for (var k = 0; k < selectCat.length; k++) {
                    if (InventoryData[i].salvageCategory == selectCat[k].caption)
                        tempCategoryList.push({ caption: InventoryData[i].salvageCategory, name: InventoryData[i].salvageCategory, checked: true })
                }
                if (tempCategoryList.filter(x => x.caption == InventoryData[i].salvageCategory).length == 0) {
                    tempCategoryList.push({ caption: InventoryData[i].salvageCategory, name: InventoryData[i].salvageCategory, checked: false })
                }

            }
        }
    }
    return tempCategoryList.sort((a, b) => a.caption > b.caption ? 1 : -1);
}


const mapStateToProps = state => ({
    search: state.searchText,
    cart: state.cart,
    clearSearch: state.clearSearch,
    Filters: state.fetchFilters
});

const mapActionsToProps = {
    getCartSummary: updateCartData,
    openDialog: openDialog,
    fetchOrders: fetchOrders,
    setClearSearch: updateClearSearch,
    viewCartUpdate: updateViewCart,
    fetchFilters : FetchInventoryFilters
}
export default compose(connect(mapStateToProps, mapActionsToProps), withStyles(styles))(InventoryDT);
