import React, { Component } from 'react';
import ContentLayout from "../components/ContentLayout";
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import { Col, Container, Row, Card } from 'react-bootstrap';
import { connect } from 'react-redux';

import { updateSearchList, returnUpdateSearch } from '../actions/search-actions';

class SupportHelp extends Component {
    constructor(props) {
        super(props);
        this.props.updateSearchList([]);
        this.props.returnUpdateSearch();
    }
  render() {
    return (
      <ContentLayout>
        <Breadcrumb
          breadcrumb={[
            { name: "Home", path: "/" },
            { name: "Support 24/7", path: "/SupportHelp" }
          ]}
        />
        <div className="content pt-5 pb-5">
          <Container>
            <Row className="justify-content-center">
              <Col md="12">
                <div className="animated fadeIn">
                  <Card className="rounded-0 bg-white shadow-sm">
                    <Card.Body>
                      <Card.Title></Card.Title>
                      <Card.Text> 
                         <div class="middle"> 
                           <h1>COMING SOON</h1>
                         </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ContentLayout>
    );
  }
}

const mapActionsToProps = {
    updateSearchList: updateSearchList,
    returnUpdateSearch: returnUpdateSearch
}

export default connect(null, mapActionsToProps)(SupportHelp);
