import React, { Component } from 'react';
import ContentLayout from "../components/ContentLayout";
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import { Col, Container, Row, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { updateSearchList, returnUpdateSearch } from '../actions/search-actions';

class TermsConditions extends Component {
    constructor(props) {
        super(props);
        this.props.updateSearchList([]);
        this.props.returnUpdateSearch();
    }
  render() {
    return (
      <ContentLayout>
      <Breadcrumb
          breadcrumb={[
            { name: "Home", path: "/" },
            { name: "Terms & Conditions", path: "/TermsConditions" }
          ]}
        />
        <div className="content pt-5 pb-5">
          <Container>
            <Row className="justify-content-center">
              <Col md="12">
                <div className="animated fadeIn">
                  <Card className="rounded-0 bg-white shadow-sm">
                    <Card.Body>
                      <Card.Title>Terms &amp; Conditions</Card.Title>
                        <Card.Text>
                            <ul style={{ listStyle: "disc", paddingLeft:"20px" }}>
                                <li className="mb-3">  
                                    Payment Terms. All pricing and payments are and shall be in U.S. dollars. Purchaser shall make all payments for the Salvage Goods immediately to Home Depot by electronic funds transfer or with Home Depot's preapproval, by bank certified check. No cash transactions are accepted. NOTICE: Once you have submitted the wire payment please email Salvage@homedepot.com so we can ensure your payment has been applied to the account accordingly.
                                    </li>
                                <li className="mb-3">Disclaimer of Warranties. PURCHASER ACKNOWLEDGES AND AGREES THAT ALL SALVAGE GOODS ARE PURCHASED "AS IS," WITH NO WARRANTY OF ANY NATURE OR TYPE WHATSOEVER AND HEREBY DISCLAIMS ALL EXPRESS, IMPLIED, AND STATUTORY WARRANTIES, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT, EXCEPT WARRANTY OF TITLE.</li>
                                <li className="mb-3">Taxes. Purchaser is responsible for all taxes, tariffs, duties and applicable expenses based on any sales transactions occurring under this Agreement. Sales taxes will be added to the purchase price unless a copy of Purchaser's sales tax exemption agreement is received by Home Depot prior to purchase.</li>
                                <li className="mb-3">Title and Risk of Loss. Title and risk of loss shall pass to Purchaser at the applicable Home Depot Repair and Liquidation Center. Purchaser is solely responsible for arranging for shipping and transportation and any further sale of all Salvage Goods, including, without limitation, shipping document preparation, proper packaging and labeling, and compliance with all laws, rules and regulations related thereto, including, without limitation, those relating to exporting  Any claims for shortage of Salvage Goods shipped must be received by Home Depot no more than (10) calendar days following the date of shipment. Home Depot shall have no responsibilities or liability for any such claims received after such ten (10) day period. Any claim for shortage of Salvage Goods must be received by Home Depot in writing within ten (10) calendar days of the date of shipment.</li>
                            </ul>
                            {
                                //<label>
                                //    <input type="checkbox" />
                                //    <span className="ml-2"><b>I accept all terms and conditions</b></span>
                                //</label>
                            }
                        </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ContentLayout>
    );
  }
}
const mapActionsToProps = {
    updateSearchList: updateSearchList,
    returnUpdateSearch: returnUpdateSearch
}

export default connect(null, mapActionsToProps)(TermsConditions);
