/// <reference path="../app.js" />
import React, { Component } from 'react';
import { Col, Container, Row, Button, Form } from 'react-bootstrap';
import ContentLayout from "../components/ContentLayout";
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import InventoryDT from '../components/DataTable/InventoryDatatable';
import TruckInventoryDT from '../components/DataTable/TruckLoadInventoryDatatable';
import { connect } from 'react-redux';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import IconButton from '@material-ui/core/IconButton';
//import Tooltip from '@material-ui/core/Tooltip';
import Select from 'react-select';
import { updateCartData } from '../actions/cart-actions';
import { updateSearchListt, updateSearchList } from '../actions/search-actions'
import { warehouses, Categories } from '../DateHelper';
import zIndex from '@material-ui/core/styles/zIndex';
import '../header.css'
import Tooltip from '@material-ui/core/Tooltip';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles
} from "@material-ui/core/styles";
const defaultTheme = createMuiTheme();
const theme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "1em",
                maxWidth: 5000,
                color: "white",
                backgroundColor: "#fa7a22"
            }
        }
    }
});


class TruckInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Categories: '', warehouses: ''

        };
        this.props.updateSearchList([]);
        this.props.updateSearchListt([
            {
                id: '1',
                caption: 'SB Number',
                name: 'sbNumber'
            },
            {
                id: '2',
                caption: 'BL Number',
                name: 'blNumber'
            },
            //{
            //    id: '3',
            //    caption: 'Warehouse',
            //    name: 'warehouse'
            //},
            {
                id: '4',
                caption: 'Category',
                name: 'salvageCategory'
            },
            //{
            //    id: '5',
            //    caption: 'Type',
            //    name: 'hdDotCom'
            //},
            //{
            //    id: '6',
            //    caption: 'Item',
            //    name: 'itemQuantity'
            //},
            {
                id: '7',
                caption: 'Pallet Quantity',
                name: 'containerQuantity'
            }
            //},
            //{
            //    id: '8',
            //    caption: 'Wholesale',
            //    name: 'cost'
            //},
            //{
            //    id: '9',
            //    caption: 'Rate',
            //    name: 'rate'
            //},
            //{
            //    id: '10',
            //    caption: 'Cost',
            //    name: 'rate_Cost'
            //}

        ]);
    }

    componentDidUpdate() {

    }
    render() {
        return (
            <MuiThemeProvider theme={defaultTheme}>
                <div className="topspace">
                    <ContentLayout>
                        <Breadcrumb
                            breadcrumb={[
                                { name: "Home", path: "/" },
                                { name: "TruckloadInventory", path: "/TruckInventory" }
                            ]}
                        />{
                        }
                        <Container fluid className="pl-5 pr-5">
                            <Row className="justify-content-center">
                                <Col md="12">
                                    <div className="animated fadeIn d-flex">
                                        <h2 className="title position-relative mt-2">Truckload Inventory</h2>

                                        <div> <MuiThemeProvider theme={theme}>
                                            <Tooltip title="Use this screen to bid on truckloads.  If you are the highest bidder when the time expires you win.  There is also the option to buy now and purchase without waiting." placement="left">
                                                <div style={{ marginBottom: "20px" }}>
                                                    <NotListedLocationIcon /></div>
                                            </Tooltip>
                                        </MuiThemeProvider></div>
                                    </div>
                                    <div className="animated fadeIn">
                                        <TruckInventoryDT /*updateCart={() => this.props.getCartSummary()}*/ /*{...this.state}*/ data={this.props.location.state} CompleteOrder={this.props.location.act} warehouseid={this.props.location.wid} allTruckLoadLocation={this.props.location.allTruckLoadLocation} />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </ContentLayout>
                </div>
            </MuiThemeProvider>
        );
    }
}


const mapStateToProps = state => ({
    cart: state.cart
});

const mapActionsToProps = {
    getCartSummary: updateCartData,
    updateSearchListt: updateSearchListt,
    updateSearchList: updateSearchList,
}

export default connect(mapStateToProps, mapActionsToProps)(TruckInventory);


