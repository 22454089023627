import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import {Layout} from "../components/Layout";

const techCompanies = [
  { label: "United states of america", value: 1 },
  { label: "India", value: 2 },
  { label: "South Africa", value: 3 },
  { label: "United Kingdom", value: 4 },
];


class RegisterPage extends Component {
  render() {
    return (
      <Layout>
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
             <Col md="12">
                <div className="login-dialog">
                  <div className="login-content signup-content">
                    <div className="rbm_form_header">
                      <h3>sign up</h3>
                      </div>
                      <div className="rbm_form_body">
                        <Form>
                        <div className="rbm_input_txt">
                          <div className="rbm_input_inline">
                            <Form.Group controlId="exampleForm.ControlInput1">
                              <Form.Label>first name *</Form.Label>
                              <Form.Control type="text" placeholder="first name" />
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlInput2">
                              <Form.Label>last name *</Form.Label>
                              <Form.Control type="text" placeholder="last name" />
                            </Form.Group>
                          </div>
                          <Form.Group controlId="exampleForm.ControlInput3">
                            <Form.Label>Your Email *</Form.Label>
                            <Form.Control type="email" placeholder="Your email" />
                          </Form.Group>
                          <div className="rbm_input_inline">
                            <Form.Group controlId="exampleForm.ControlInput4">
                              <Form.Label>phone #1 *</Form.Label>
                              <Form.Control type="phone" placeholder="Phone" />
                              </Form.Group>
                              <Form.Group controlId="exampleForm.ControlInput5">
                                <Form.Label>Ext:</Form.Label>
                                <Form.Control type="password" placeholder="EXT" />
                            </Form.Group>
                          </div>
                          <div className="rbm_input_inline">
                            <Form.Group controlId="exampleForm.ControlInput6">
                              <Form.Label>phone #2 *</Form.Label>
                              <Form.Control type="phone" placeholder="Phone" />
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlInput7">
                              <Form.Label>Ext:</Form.Label>
                              <Form.Control type="password" placeholder="EXT" />
                            </Form.Group>
                          </div>
                          <div className="rbm_input_inline">
                            <Form.Group controlId="exampleForm.ControlInput8">
                              <Form.Label>Company *</Form.Label>
                              <Form.Control type="text" placeholder="Company" />
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlInput9">
                              <Form.Label>City *</Form.Label>
                              <Form.Control type="text" placeholder="City" />
                            </Form.Group>
                          </div>
                          <Form.Group controlId="exampleForm.ControlInput10">
                            <Form.Label>Address line 1 *</Form.Label>
                            <Form.Control type="address1" placeholder="Your address" />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput11">
                            <Form.Label>address line 2 *</Form.Label>
                            <Form.Control type="address2" placeholder="Your address" />
                          </Form.Group>
                          <div className="rbm_input_inline">
                            <Form.Group controlId="exampleForm.ControlInput12">
                              <Form.Label>State/Province/Region *</Form.Label>
                              <Form.Control type="text" placeholder="State/Province/Region" />
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlInput13">
                              <Form.Label>Zip/postal code *</Form.Label>
                              <Form.Control type="text" placeholder="Zip/postal code" />
                            </Form.Group>
                          </div>
                          <div className="rbm_input_inline">
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Label>Country *</Form.Label>
                              <Select options={ techCompanies } />
                            </Form.Group>
                          </div>
                          <Form.Group controlId="exampleForm.ControlInput14">
                            <Form.Label><strong>if other country, please specify</strong></Form.Label>
                            <Form.Control type="other-country" placeholder="Other Country" />
                          </Form.Group>
                          <div className="rbm_form_submit">
                            <Button type="submit" className="rbm_btn_x_out_shtr">sign up</Button>
                          </div>
                        </div>
                      </Form>
                    </div>
                    <div className="text-center create-new-fau">
                      <Link to="/">
                        Already have an account? <span>Sign In</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    );
  }
}

export default RegisterPage;
