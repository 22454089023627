import { SEARCH_OPTIONS_UPDATE, SEARCH_OPTIONS_UPDATET, SEARCH_TEXT_UPDATE, RETURN_SEARCH_TEXT_UPDATE, SEARCH_TEXT_UPDATET, SEARCH_TEXT_CLEAR, SEARCH_OPTIONS_UPDATE_ORDER ,SEARCH_TEXT_UPDATE_ORDER ,RETURN_SEARCH_TEXT_UPDATE_ORDER } from '../constants';

export const updateSearchList = function (list) {
    return {
        type: SEARCH_OPTIONS_UPDATE,
        payload: {
            list : list
        }
    }
}
export const updateSearchListt = function (listt) {
    return {
        type: SEARCH_OPTIONS_UPDATET,
        payload: {
            listt: listt
        }
    }
}

export const updateSearch = function (fieldName, searchText, hdChecked,index) {
    return {
        type: SEARCH_TEXT_UPDATE,
        payload: {
            fieldName,
            searchText,
            hdChecked,
            index
        }
    }
}
export const updateSearchh = function (fieldName, searchText, hdChecked, index) {
    return {
        type: SEARCH_TEXT_UPDATET,
        payload: {
            fieldName,
            searchText,
            hdChecked,
            index
        }
    }
}


export const returnUpdateSearch = function (fieldName, searchText, hdChecked,index) {
    return {
        type: RETURN_SEARCH_TEXT_UPDATE,
        payload: {
            fieldName,
            searchText,
            hdChecked,
            index
        }
    }
}

export const clearSearch = function (searchText) {
    return {
        type: SEARCH_TEXT_CLEAR,
        payload: {
            searchText,
        }
    }
}
export const reload = () => window.location.reload();

export const updateOrderSearchList = function (list) {
    return {
        type: SEARCH_OPTIONS_UPDATE_ORDER,
        payload: {
            list: list
        }
    }
}

export const updateOrderSearch = function (searchText) {
    return {
        type: SEARCH_TEXT_UPDATE_ORDER,
        payload: {
            searchText
        }
    }
}

export const returnUpdateOrderSearch = function (searchText) {
    return {
        type: RETURN_SEARCH_TEXT_UPDATE_ORDER,
        payload: {
            searchText
        }
    }
}

export function updateClearSearch() {
    return dispatch => {
        dispatch(clearSearch(""));
    }
}