import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import HomePage from "./views/Home";
import AboutUs from "./views/AboutUs";
import faq from "./views/faq";
import RegistrationUsers from "./views/RegistrationUser";
import DeliveryInformation from "./views/DeliveryInformation";
import SupportHelp from "./views/SupportHelp";
import PrivacyPolicy from "./views/PrivacyPolicy";
import TermsConditions from "./views/TermsConditions";
import ContactUs from "./views/ContactUs";
import MyProfile from "./views/MyProfile";
import MyCredits from "./views/MyCredits";
import LoginPage from "./views/Login";
import ForgotPage from "./views/Forgot";
import RegisterPage from "./views/Register";
import Managecreditrequests from "./views/Managecreditrequests";
import Dashboard from "./views/Dashboard";
import Invoices from "./views/Invoices";
import ArchivedInvoices from "./views/ArchivedInvoices";
import MyOrders from "./views/MyOrders";
import ArchiveOrders from "./views/ArchiveOrders";
import Page404 from "./views/Page404";
import SendOrderEmail from "./views/SendOrderEmail";
import BrowseListingsPallets from "./views/Browse-Listings-Pallets";
import BrowseListingsTruckload from "./views/Browse-Listings-Truckloads";
import Appliances from "./views/Appliances";
import ViewCart from "./views/View-Cart";
import CartCheckout from "./views/Cart-Checkout";
import Inventory from "./views/Inventory";
import TruckInventory from "./views/TruckInventory";
import Timestamp from "./views/Timestamp";
import TruckLoadOrders from "./views/TruckLoadOrders";
import ManageRequest from './components/DataTable/ManageRequest';
import "./assets/css/animate.min.css";
import NavMenu from './components/NavMenu';
import AppDialog from './components/AppDialog';
import './custom.css'

export default class App extends Component {
    static displayName = App.name;
    constructor() {
        super();
        this.state = {
            updatedOn: new Date()
        };
    }

    render() {
        return (
            <div>
                <AppDialog />
                <div >
                    <NavMenu />
                </div>
                <Switch>
                    <Route path="/Login" component={LoginPage} />
                    <Route path="/Forgot" component={ForgotPage} />
                    <Route path="/Register" component={RegisterPage} />
                    <Route path='/counter' component={Counter} />
                    <AuthorizeRoute exact path="/" component={HomePage} />
                    <Route path="/AboutUs" component={AboutUs} />
                    <Route path="/faq" component={faq} />
                    <Route path="/SupportHelp" component={SupportHelp} />
                    <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
                    <Route path="/TermsConditions" component={TermsConditions} />
                    <AuthorizeRoute path="/ContactUs" component={ContactUs} />
                    <AuthorizeRoute path="/MyProfile" component={MyProfile} />
                    <AuthorizeRoute path="/MyCredits" component={MyCredits} />
                    <AuthorizeRoute path="/RegistrationUsers" component={RegistrationUsers} />
                    <AuthorizeRoute path="/Managecreditrequests" component={Managecreditrequests} />
                    <AuthorizeRoute path="/Dashboard" component={Dashboard} />
                    <AuthorizeRoute path="/Invoices" component={Invoices} />
                    <AuthorizeRoute path="/ArchivedInvoices" component={ArchivedInvoices} />
                    <AuthorizeRoute path="/MyOrders" component={MyOrders} />
                    <AuthorizeRoute path="/ArchiveOrders" component={ArchiveOrders} />
                    <AuthorizeRoute path="/Browse-Listings-Pallets" component={BrowseListingsPallets} />
                    <AuthorizeRoute path="/Browse-Listings-Truckloads" component={BrowseListingsTruckload} />
                    <AuthorizeRoute path="/Appliances" component={Appliances} />
                    <AuthorizeRoute path="/View-Cart" component={ViewCart} />
                    <AuthorizeRoute path="/Cart-Checkout" component={CartCheckout} />
                    <AuthorizeRoute path="/Inventory" component={Inventory} />
                    <AuthorizeRoute path="/TruckInventory" component={TruckInventory} />
                    <AuthorizeRoute path="/Timestamp" component={Timestamp} />
                    <AuthorizeRoute path="/TruckLoadOrders" component={TruckLoadOrders} />                
                    <AuthorizeRoute path="/ManageRequest" component={ManageRequest} />
                    <AuthorizeRoute path="/DeliveryInformation" component={DeliveryInformation} />
                    <Route path="/Page404" component={Page404} />
                    <Route path="/SendOrderEmail" component={SendOrderEmail} />
                    <AuthorizeRoute path='/fetch-data' component={FetchData} />
                    <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                </Switch>
            </div>
        );
    }
}