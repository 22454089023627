import React, { Component } from "react";
import IconButton from '@material-ui/core/IconButton';
import "../../assets/css/jquery.dataTables.min.css";
import authService from '../api-authorization/AuthorizeService'
import ReactDOM from 'react-dom';
import { css } from "@emotion/core";
import CssBaseline from '@material-ui/core/CssBaseline'
import ReactTable from './ReactTable'
import SyncLoader from "react-spinners/SyncLoader";
import TruckDetail from './TruckDetail';
import { connect } from 'react-redux';
import { createNewOrder, completeOpenOrder, addToOrder } from '../../actions/cart-actions';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import Tooltip from '@material-ui/core/Tooltip';
import { dateMDY, numberformat, warehouse, Rateformat } from '../../DateHelper';
import OrderDetail from './OrderDetail';
import { Modal } from 'react-bootstrap';

const override = css`
  display: block;
  margin: 2 auto;
  border-color: red;
`;
const style = { position: "fixed", top: "75%", left: "50%", transform: "translate(-50%, -50%)" };
//const $ = require('jquery');
//$.DataTable = require('datatables.net');

class TruckLoadOrderData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderdata: [], orderDataByStatus: [], searchText: "", oldStatus: "", show: false, showCat: false, loading: true
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handlecatclose = this.handlecatclose.bind(this);
    }

    handleClose() {
        this.setState({ show: false});
    }


    handlecatclose() {

        this.setState({ showCat: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    componentDidMount() {
      
            this.populateOrderData();
     
    }
    //componentWillReceiveProps(props) {
    //    if (props.data != this.state.oldStatus) {
    //        this.setState({
    //            oldStatus: props.data
    //        })
    //        this.populateOrderData(props.data);
    //    }
    //    else if (props.ordersearch.searchText != this.state.searchText) {
    //        let tempData = [];
    //        var filter = [];
    //        let inventoryOriginalData = this.state.orderDataByStatus;
    //        if (props.ordersearch.searchText != "") {
    //            for (var i = 0; i < inventoryOriginalData.length; i++) {
    //                if (String(inventoryOriginalData[i].id).includes(props.ordersearch.searchText))
    //                    tempData.push(inventoryOriginalData[i])
    //            }
    //            filter = tempData;
    //        }
    //        else {
    //            filter = inventoryOriginalData;
    //        }

    //        this.setState({
    //            searchText: props.ordersearch.searchText,
    //            orderdata: filter
    //        });
    //    }
    //    else {
    //        this.setState({
    //            searchText: "",
    //        })
    //        this.populateOrderData(props.data);
    //    }
    //}

    getOrderId = (e) => {
        return e.original.id;
    }

    getOrderDate = (e) => {
        return e.original.orderDateTime
    }
    getOrderStatus = (e) => {
        return e.original.orderStatus
    }
    getWarehouse = (e) => {
        return e.original.warehouse
    }
    getsbcount = (e) => {
        return e.original.sbCount
    }
    handleclick = (rowData, sbcount) => {
        this.setState({
            orderNumber: rowData.original.id, status: rowData.original.orderStatus
            //orderNumber: rowData.original.id, blNumber: rowData.original.blNumber, warehouse: rowData.original.warehouse, salvageCategory: rowData.original.salvageCategory,
            //containerQuantity: rowData.original.containerQuantity, warehouseId: rowData.original.warehouseId, show: true, loading: true
        });

        // if (sbcount > 0) {
        this.setState({ show: true });
        // }
    }
    handleTruckclick = (rowData) => {
        this.setState({
            truckid: rowData.original.truckload_id
        });
        // this.populateSbByTruckId(rowData.original.id);
        this.setState({ show: true, truckid: rowData.original.truckload_id, TruckBarcode: rowData.original.truckLoad_Barcode });
    }

    handleCategoryclick = (rowData) => {
        this.setState({
            orderNumber: rowData.original.truckload_id
        });
        this.populateCategoryByTRUCKID(rowData.original.truckload_id);
        this.setState({ showCat: true });
    }

    async populateCategoryByTRUCKID(orderNumber) {
        const token = await authService.getAccessToken();
        const response = await fetch('InventoryCode/GetTruckCategoriess?orderId=' + orderNumber, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        var selectCategory = [];
        if (data != null && data != "") {
            for (var i = 0; i < data.split(',').length; i++) {

                var obj = {};
                obj["manifestId"] = data.split(',')[i].split(',')[0];
                selectCategory.push(Object.values(obj));
            }
        }
        //let cat = data.split(',')[0]
        this.setState({ Showcategory: selectCategory, loading: false });
        // this.setState({ showCat: true });
    }
    async populateCategoryByOrderId(orderNumber) {
        const token = await authService.getAccessToken();
        const response = await fetch('InventoryCode/GetOrderCategoriess?orderId=' + orderNumber, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        var selectCategory = [];
        if (data != null && data != "") {
            for (var i = 0; i < data.split(',').length; i++) {

                var obj = {};
                obj["manifestId"] = data.split(',')[i].split(',')[0];
                selectCategory.push(Object.values(obj));
            }
        }
        //let cat = data.split(',')[0]
        this.setState({ Showcategory: selectCategory, loading: false });
        // this.setState({ showCat: true });
    }
    render() {
    

        const { orderdata, loading } = this.state;
        const columns = [
            {
                Header: 'Order #',
                accessor: 'id',

                Cell: ({ row }) => {


                    return (<div><a style={{ cursor: 'pointer', color: 'blue' }}
                        onClick={() => this.handleTruckclick(row)}>
                        {row.original.id}
                    </a></div>)


                }
                //Cell: ({ row }) => (
                //    <div>
                //        <a style={{ cursor: 'pointer', color: 'blue' }}
                //            onClick={() => this.handleclick(row, this.getsbcount(row))}>
                //            {this.getOrderId(row)}
                //        </a>
                //    </div>
                //)
                //Cell: ({ row }) => (
                //    <div>
                //        <a style={{ cursor: 'pointer', color: 'blue' }}
                //            onClick={() => this.props.goto(this.getOrderId(row), row)}>
                //            {this.getOrderId(row)}
                //        </a>
                //    </div>
                //)
            },
            {
                Header: 'Warehouse',
                accessor: 'warehouse',
                Cell: ({ row }) => (
                    <React.Fragment>
                        { row.original.warehouse ? warehouse(row.original.warehouse) : row.original.warehouse}
                    </React.Fragment>
                )

                
            },
            {
                Header: 'Categories',
                accessor: 'salvage_category',
                Cell: ({ row }) => {
                    if (row.original.catogarycount > 1) {
                        return (<div><a style={{ cursor: 'pointer', color: 'blue' }}
                            onClick={() => this.handleCategoryclick(row)}>
                            Show Categories
                        </a></div>)
                    }
                    else {
                        return (
                            row.original.salvage_category
                        )
                    }
                }
            },
            {
                Header: 'SB Count',
                accessor: 'sb_count',
            },
            {
                Header: 'Pallet Count',
                accessor: 'item_quantity',
            },
            {
                Header: 'Wholesale',
                accessor: 'wholesaleAmtTotalvalue',
                Cell: ({ row }) => (
                    <React.Fragment>
                        {row.original.wholesaleAmtTotalvalue ? numberformat(row.original.wholesaleAmtTotalvalue) : row.original.wholesaleAmtTotalvalue}
                    </React.Fragment>
                )
            },
            {
                Header: 'Rate %',
                accessor: 'highestbidrate',
                Cell: ({ row }) => (
                    <React.Fragment>
                        {row.original.highestbidrate == 0 ? Rateformat(row.original.buynowrate) : Rateformat(row.original.highestbidrate)}
                    </React.Fragment>
                )

            },
            {
                Header: 'TruckLoad Order Amount',
                accessor: 'highestbidamount',
                Cell: ({ row }) => (
                    <React.Fragment>
                        {row.original.highestbidamount == 0 ? numberformat(row.original.wholesaleAmtTotalvalue * row.original.buynowrate/100) : numberformat(row.original.highestbidamount)}
                    </React.Fragment>
                )

            },
            {
                Header: 'Status',
                accessor: 'orderStatus',
            },

            {
                Header: 'Order Date',
                accessor: 'insertDateTime',

                //Cell: ({ row }) => {
                //    var date = new Date(this.getOrderDate(row));
                //    var month = date.getMonth() + 1;
                //    return (month.toString().length > 1 ? month : "0" + month) + "/" + date.getDate() + "/" + date.getFullYear();
                //}
                Cell: ({ row }) => {

                        return (<div>
                            <React.Fragment>
                                {row.original.insertDateTime ? dateMDY(row.original.insertDateTime.substring(0, 10)) : ""}
                            </React.Fragment>
                        </div>)
                    

                }
            },

        ]
        if (loading && orderdata.length === 0) {
            return (
                <div><em>Please wait while Loading MY Order Data......</em>
                    <div style={style}>
                        <SyncLoader
                            css={override}
                            size={15}
                            color={"#fd7e14"}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            );
        }
        if (orderdata == null || orderdata == "")
            return (
                <div>
                    <div>
                        <CssBaseline />
                        <div style={style}>
                            <SyncLoader
                                css={override}
                                size={15}
                                color={"#fd7e14"}
                                loading={this.state.loading}
                            /></div>
                        <ReactTable
                            columns={columns}
                            data={this.state.orderdata}
                        />
                    </div>
                    <div>
                        <h2>No Record Found</h2>
                    </div>
                </div>)
        return (
     
            <div>
           
                <div>{
                    //<p className="bg-info p-3 mb-4 text-white shadow-sm"><strong>NOTE:</strong> SB # is the identifying Slot By Number used to identify manifests</p>

                    //<Table responsive hover id="inventory-datable" className="display" width="100%" ref={el => this.el = el} >

                    //</Table>
                }
                    <CssBaseline />
                    <div style={style}>
                        <SyncLoader
                            css={override}
                            size={15}
                            color={"#fd7e14"}
                            loading={this.state.loading}
                        /></div>
                    <ReactTable
                        columns={columns}
                        data={this.state.orderdata}
                    />

                    <Modal centered size="xl" show={this.state.show} onHide={this.handleClose} className="sb-details-modal">
                        <Modal.Header closeButton>
                            <Modal.Title>Truck Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p><strong> TruckLoad Barcode :</strong> {this.state.TruckBarcode}</p>
                            {/*<InventoryDetail sbNumber={this.state.sbNumber} warehouse={this.state.warehouseId} />*/}
                            <TruckDetail sbNumber={this.state.truckid} />

                        </Modal.Body>
                    </Modal>
                    <Modal centered size="l" show={this.state.showCat} onHide={this.handlecatclose} className="sb-details-modal">
                        <Modal.Header closeButton>
                            <Modal.Title>Assigned Categories</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.Showcategory != null ? this.state.Showcategory.map(catList => {
                                return (
                                    <ul>
                                        <li>{catList[0]}</li>
                                    </ul>
                                )
                            }) : null}
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        );
    }



    async populateOrderData(status) {
        const token = await authService.getAccessToken();
        const response = await fetch('InventoryCode/TruckOrderList', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (data.manifestTruckList == null) {
            data.manifestTruckList = [];
        }
        for (var i = 0; i < data.manifestTruckList.length; i++) {
            //var cost = 0.0;
            //var cost = (data.manifestTruckList[i].cost) * 1.15;
            //data.orderViewModel.orderList[i].cost = cost.toFixed(2);

            if (data.manifestTruckList[i].orderStatus == 0)
                data.manifestTruckList[i].orderStatus = "NotSpecified";
            else if (data.manifestTruckList[i].orderStatus == 1)
                data.manifestTruckList[i].orderStatus = "Open";
            else if (data.manifestTruckList[i].orderStatus == 2)
                data.manifestTruckList[i].orderStatus = "Assigned";
            else if (data.manifestTruckList[i].orderStatus == 3)
                data.manifestTruckList[i].orderStatus = "Approved";
            else if (data.manifestTruckList[i].orderStatus == 4)
                data.manifestTruckList[i].orderStatus = "Shipped";
            else if (data.manifestTruckList[i].orderStatus == 5)
                data.manifestTruckList[i].orderStatus = "PartiallyShipped";
            else if (data.manifestTruckList[i].orderStatus == 6)
                data.manifestTruckList[i].orderStatus = "Cancelled";
            else if (data.manifestTruckList[i].orderStatus == 7)
                data.manifestTruckList[i].orderStatus = "PendingApproval";
            else
                data.orderViewModel.orderList[i].orderStatus = data.orderViewModel.orderList[i].orderStatus;
        }

        this.setState({ orderdata: data.manifestTruckList, loading: false });

    }
}


export default TruckLoadOrderData;