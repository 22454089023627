import { FETCH_FILTERS } from '../constants';


export const FetchFilters = function (inventoryType, categoryList, filterList) {
    return {
        type: FETCH_FILTERS,
        payload: {
            inventoryType,
            categoryList,
            filterList
        }
    }
}

export const reload = () => window.location.reload();

export function FetchInventoryFilters(inventoryType, categoryList, filterList) {
    return dispatch => {
        dispatch(FetchFilters(inventoryType, categoryList, filterList));
    }
}