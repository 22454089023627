import React, { Component } from 'react';
import { Button, Col, Container, Row,  Nav,Modal, Form, FormControl, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ContentLayout from "../components/ContentLayout";
//import CarouselIMG1 from '../assets/img/slider/s1.png';
//import CarouselIMG2 from '../assets/img/slider/s2.png';
//import CarouselIMG3 from '../assets/img/slider/s3.png';
import CarouselIMG4 from '../assets/img/slider/s4.png';
import CarouselIMG5 from '../assets/img/slider/s5.png';
import CarouselIMG6 from '../assets/img/slider/s6.png';
import CarouselIMG7 from '../assets/img/slider/s7.png';
import CarouselIMG8 from '../assets/img/slider/s8.png';
import CarouselIMG9 from '../assets/img/slider/s9.png';
import CarouselIMG10 from '../assets/img/slider/s10.png';
import CarouselIMG11 from '../assets/img/slider/s11.png';
import CarouselIMG12 from '../assets/img/slider/s12.png';
import CarouselIMG13 from '../assets/img/slider/s13.png';
import CarouselIMG14 from '../assets/img/slider/s14.png';
import CarouselIMG15 from '../assets/img/slider/s15.png';
import CarouselIMG16 from '../assets/img/slider/s16.png';
import CarouselIMG17 from '../assets/img/slider/s17.png';
import CarouselIMG18 from '../assets/img/slider/s18.png';
//import CarouselIMG4 from '../assets/img/slider/browse-listung-pallet.png';
//import CarouselIMG5 from '../assets/img/slider/browse-listung-tool.png';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import Newsletter_Notification from '../assets/img/icon/news-message.png';
import { connect } from 'react-redux';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import IconButton from '@material-ui/core/IconButton';
import { updateSearchList, updateSearchListt, returnUpdateSearch} from '../actions/search-actions';



class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0, modalState: false
        };
        this.props.updateSearchList([]);
        this.props.updateSearchListt([]);
        this.props.returnUpdateSearch();
        this.handleClose = this.handleClose.bind(this);
    }
    onChange = value => this.setState({ value });
    handleOrderClick(category,warehouse) {
        this.props.history.push({
            pathname: '/Inventory',
            state: category,
            wid: warehouse
        })

    }
    handleInventoryLocation(warehouse) {
        this.props.history.push({
            pathname: '/Inventory',
            wid: warehouse
        })

    }
    navigateToInventory = (location) => {
        this.props.history.push({
            pathname: "/Inventory",
            allLocation: location
        })
    }
    navigateToTruckLoadInventory = (location) => {
        this.props.history.push({
            pathname: "/TruckInventory",
            allTruckLoadLocation: location
        })
    }
    
    componentDidMount() {
        const action = this.props.location.state;
        if (action =="Again")
        this.setState({
            modalState: false
        })
        else
            this.setState({
            modalState: true
            })
        window.onpopstate = this.onBackButtonEvent
    }
    handleClose() {
        this.setState({ modalState: false });
    }
    onBackButtonEvent = (e) => {
        e.preventDefault()
        console.log('in backbutton')
        this.props.history.push('/') //homepage route.
        this.setState({
            modalState: false
        })
        window.onpopstate = () => { } // reset the onpopstate
    }
  render() {
    return (
      <ContentLayout>
        <div className="app flex-row align-items-center">
          <div className="homepage-slider mt-5">
            <Container>
                        {/*<div>{this.state.modalState ? <Modal centered size="xl" show={this.state.modalState} onHide={this.handleClose} className="sb-details-modal">*/}
                        {/*    <Modal.Header closeButton>*/}
                        {/*        <Modal.Title>Message</Modal.Title>*/}
                        {/*    </Modal.Header>*/}
                        {/*    <Modal.Body className=" text-center " style={{ backgroundColor: "antiquewhite" }}>*/}
                        {/*        <p className="PopUp_font" style={{ fontSize: 15 }}> We have an exciting update. Product is being manifested to the pallet out of Indy. Please check your email for more details.</p>*/}
                        {/*    </Modal.Body>*/}
                        {/*</Modal> : null}</div>*/}
              <Row className="justify-content-center align-items-center">
                            <Col lg="12">{
                                <div>
                                <Carousel infinite slidesPerPage={3} autoPlay={4000}
                                    animationSpeed={1000}
                                    value={this.state.value}
                                    onChange={this.onChange}>
                                    {/*<img width="90%" src={CarouselIMG1} />*/}
                                    {/*<img width="90%" src={CarouselIMG2} />*/}
                                    {/*<img width="90%" src={CarouselIMG3} />*/}
                                    <img width="90%" src={CarouselIMG4} />
                                    <img width="90%" src={CarouselIMG5} />
                                    <img width="90%" src={CarouselIMG6} />
                                    <img width="90%" src={CarouselIMG7} />
                                    <img width="90%" src={CarouselIMG8} />
                                    <img width="90%" src={CarouselIMG9} />
                                    <img width="90%" src={CarouselIMG10} />
                                    <img width="90%" src={CarouselIMG11} />
                                    <img width="90%" src={CarouselIMG12} />
                                    <img width="90%" src={CarouselIMG13} />
                                    <img width="90%" src={CarouselIMG14} />
                                    <img width="90%" src={CarouselIMG15} />
                                    <img width="90%" src={CarouselIMG16} />
                                    <img width="90%" src={CarouselIMG17} />
                                    <img width="90%" src={CarouselIMG18} />
                                    </Carousel>
                                    <Dots value={this.state.value} onChange={this.onChange} number={3} />
                                    </div>
                                //<Carousel>
                                //    <Carousel.Item>
                                //        <Row>
                                //            <Col lg="4">
                                //                <img
                                //                    className="d-block w-100"
                                //                    src={CarouselIMG1}
                                //                    alt="First slide"
                                //                />
                                //            </Col>
                                //            <Col lg="4">
                                //                <img
                                //                    className="d-block w-100"
                                //                    src={CarouselIMG2}
                                //                    alt="First slide"
                                //                />
                                //            </Col>
                                //            <Col lg="4">
                                //                <img
                                //                    className="d-block w-100"
                                //                    src={CarouselIMG3}
                                //                    alt="First slide"
                                //                />
                                //            </Col>
                                //        </Row>
                                //    </Carousel.Item>
                                //    <Carousel.Item>
                                //        <Row>
                                //            <Col lg="4">
                                //                <img
                                //                    className="d-block w-100"
                                //                    src={CarouselIMG1}
                                //                    alt="First slide"
                                //                />
                                //            </Col>
                                //            <Col lg="4">
                                //                <img
                                //                    className="d-block w-100"
                                //                    src={CarouselIMG2}
                                //                    alt="First slide"
                                //                />
                                //            </Col>
                                //            <Col lg="4">
                                //                <img
                                //                    className="d-block w-100"
                                //                    src={CarouselIMG3}
                                //                    alt="First slide"
                                //                />
                                //            </Col>
                                //        </Row>
                                //    </Carousel.Item>
                                //</Carousel>
                            }
                            </Col>{
                                //<Col lg="4">
                                //    <div className="browse-listing">
                                //        <img
                                //            className="d-block w-100"
                                //            src={CarouselIMG4}
                                //            alt="First slide"
                                //        />
                                //        <div className="pallet-description">
                                //            <Link to="/Browse-Listings-Pallets">
                                //                Browse Listings in <br /> <span>Pallets</span>
                                //            </Link>
                                //        </div>
                                //    </div>
                                //    <div className="browse-listing mt-4">
                                //        <img
                                //            className="d-block w-100"
                                //            src={CarouselIMG4}
                                //            alt="First slide"
                                //        />
                                //        <div className="pallet-description">
                                //            <Link to="/Browse-Listings-Truckloads">
                                //                Browse Listings in <br /> <span>Truckloads</span>
                                //            </Link>
                                //        </div>
                                //    </div>
                                //</Col>
                            }
                           </Row>
            </Container>
          </div>
            </div>

            <div className="shopby-category mt-5 mb-5 bg-white text-center">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="header">
                                <h2 className="title mb-3"> **New Payment Instructions**</h2>
                            </div>
                        </Col>

                        <Col lg="12">

                            <span style={{ fontSize: 18 }}>
                                Account Name: HOME DEPOTS USA INC. BRANDS DIRECT
                            </span>
                            <br />
                            <span style={{ fontSize: 18 }}>Routing (ABA) #: 031100209 / Account #: 38741154</span>
                        </Col>

                        {/*<Col lg="12">*/}
                        {/*    <div>*/}
                        {/*        <p><blink>Routing (ABA) #: 031100209 / Account #: 38741154</blink></p>*/}
                        {/*    </div>*/}
                        {/*</Col>*/}
                    </Row>
                </Container>
            </div>
            <div className="shopby-category mt-5 mb-5 bg-white text-center">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="header">
                                <h2 className="title mb-3">Shop by Locations</h2>
                            </div>
                        </Col>
                        <Col lg="12">
                            <Nav as="ul" className="category mt-4" style={{ paddingLeft: '85px', paddingRight: '85px' }}>{
                                //<Nav.Item as="li">
                                //    <Button variant="outline-secondary" className="buton-location" onClick={() => this.navigateToInventory()}>{/*<img src={'img/button/USA.PNG'} />*/}<p className="text-white mt-2">Search All Locations</p></Button>
                                //</Nav.Item>
                            }
                                <Nav.Item as="li">
                                    <Button variant="outline-secondary" className="buton-location third" onClick={() => this.handleInventoryLocation("Indianapolis")}><img src={'img/button/indiana.PNG'} /><p className="text-white mt-2">Indianapolis,IN<div>(8615)</div></p></Button>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Button variant="outline-secondary" className="buton-location third" onClick={() => this.handleInventoryLocation("McDonough")}><img src={'img/button/georgia.PNG'} /><p className="text-white mt-2">McDonough,GA<div>(8616)</div></p></Button>
                                    
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Button variant="outline-secondary" className="buton-location third" onClick={() => this.handleInventoryLocation("Phoenix")}><img src={'img/button/arizona.PNG'} /><p className="text-white mt-2">Phoenix,AZ<div>(8617)</div></p></Button>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Button variant="outline-secondary" className="buton-location third" onClick={() => this.handleInventoryLocation("Pittston")}><img src={'img/button/pittston.PNG'} /><p className="text-white mt-2">Pittston,PA<div>(8618)</div></p></Button>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <div className="d-flex">
                        <Col lg="6">
                                <div className="text-center mt-5" style={{ marginLeft: '212px' }}>
                                    <button className="buton-location btn btn-outline-secondary third" onClick={() => this.navigateToInventory("AllLocation")}><span style={{  padding: "3px", marginRight: "3px" }}><LocationOnIcon /></span><span className="text-white">Search By All Locations</span></button>
                            </div>
                        </Col>
                            <Col lg="6">
                                <div className="text-center mt-5" style={{ marginRight: '200px' }}>
                                    <button className="buton-location btn btn-outline-secondary third" onClick={() => this.navigateToTruckLoadInventory("AllTruckLoadLocation")}><span style={{ padding: "3px", marginRight: "3px" }}><img style={{ height: "30px" }} src={'img/button/TruckloadAnimation.gif'} /><LocalShippingIcon style={{ marginLeft: "5px" }}/></span><span className="text-white">Search By Truckloads</span></button>
                            </div>
                            </Col>
                        </div>
                    </Row>
                </Container>
            </div>
        <div className="shopby-category mt-5 mb-5 bg-white text-center">
          <Container>
            <Row>
              <Col lg="12">
                <div className="header">
                  <h2 className="title mb-3">Shop By Category</h2>
                </div>
              </Col>
              <Col lg="12">
                <Nav as="ul" className="category mt-4">
                  <Nav.Item as="li">
                      <Nav.Link  onClick={() => this.handleOrderClick("APPLIANCES")}>
                      <span className="cate-img">
                        <img src={'img/shopby-category/1.png'} alt="Category 1" />
                      </span> Appliances
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                  <Nav.Link  onClick={() => this.handleOrderClick("BLINDS")}>
                      <span className="cate-img">
                        <img src={'img/shopby-category/2.png'} alt="Category 2" />
                      </span> Blinds
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                  <Nav.Link  onClick={() => this.handleOrderClick("DOORS AND WINDOWS")}>
                      <span className="cate-img">
                        <img src={'img/shopby-category/3.png'} alt="Category 3" />
                      </span> Doors and Windows
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                  <Nav.Link  onClick={() => this.handleOrderClick("FLOORING AND RUGS")}>
                      <span className="cate-img">
                        <img src={'img/shopby-category/4.png'} alt="Category 4" />
                      </span> Flooring and Rugs
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                  <Nav.Link  onClick={() => this.handleOrderClick("HARDWARE AND TOOLS")}>
                      <span className="cate-img">
                        <img src={'img/shopby-category/5.png'} alt="Category 5" />
                      </span> Hardware and Tools
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                  <Nav.Link  onClick={() => this.handleOrderClick("HOLIDAY")}>
                      <span className="cate-img">
                        <img src={'img/shopby-category/6.png'} alt="Category 6" />
                      </span> Holiday
                    </Nav.Link>
                                </Nav.Item>
                  <Nav.Item as="li">
                  <Nav.Link onClick={() => this.handleOrderClick("KITCHEN AND BATH")}>
                      <span className="cate-img">
                        <img src={'img/shopby-category/10.png'} alt="Category 7" />
                      </span> Kitchen and Bath
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item as="li">
                  <Nav.Link  onClick={() => this.handleOrderClick("LIGHTING")}>
                      <span className="cate-img">
                        <img src={'img/shopby-category/11.png'} alt="Category 8" />
                      </span> Lighting
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                  <Nav.Link onClick={() => this.handleOrderClick("MIXED LOAD")}>
                      <span className="cate-img">
                        <img src={'img/shopby-category/12.png'} alt="Category 2" />
                      </span> Mixed Load / 001
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                  <Nav.Link onClick={() => this.handleOrderClick("OUTDOOR SEASONAL")}>
                      <span className="cate-img">
                        <img src={'img/shopby-category/13.png'} alt="Category 3" />
                      </span> Outdoor Seasonal
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                  <Nav.Link  onClick={() => this.handleOrderClick("PLUMBING")}>
                      <span className="cate-img">
                        <img src={'img/shopby-category/14.png'} alt="Category 4" />
                      </span> Plumbing
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                  <Nav.Link onClick={() => this.handleOrderClick("HOME DECORE")}>
                      <span className="cate-img">
                        <img src={'img/shopby-category/01.png'} alt="Category 5" />
                      </span> Home Decor
                    </Nav.Link>
                  </Nav.Item>{
                  //<Nav.Item as="li">
                  //<Nav.Link onClick={() => this.handleOrderClick("Blinds")}>
                  //    <span className="cate-img">
                  //      <img src={'img/shopby-category/4.png'} alt="Category 6" />
                  //    </span> Blinds
                  //  </Nav.Link>
                  //</Nav.Item>
                  //<Nav.Item as="li">
                  //<Nav.Link onClick={() => this.handleOrderClick("DOORS AND WINDOWS")}>
                  //    <span className="cate-img">
                  //      <img src={'img/shopby-category/9.png'} alt="Category 7" />
                  //    </span> Doors Windows
                  //  </Nav.Link>
                  //</Nav.Item>
                                }
                            </Nav>
                            {
                                //<Link to="/ViewAllCategory" className="view-all-category">
                                //    View All <i className="fas fa-long-arrow-alt-right"></i>
                                //</Link>
                            }
              </Col>
            </Row>
          </Container>
        </div>{
                //<div className="subscribe-newsletter pt-4 pb-4">
                //    <Container>
                //        <Row>
                //            <Col lg="4">
                //                <div className="subdcribe-content h-100 d-flex align-items-center">
                //                    <img src={Newsletter_Notification} alt="Subscribe Notification" className="mr-5" />
                //                    <h3 className="title mb-0">Subscribe Notification</h3>
                //                </div>
                //            </Col>
                //            <Col lg="8">
                //                <div className="subscribe-home">
                //                    <Form className="form subscribe">
                //                        <InputGroup className="">
                //                            <FormControl
                //                                placeholder="Your email address"
                //                                aria-label="Your email address"
                //                                aria-describedby="basic-addon5"
                //                            />
                //                            <InputGroup.Append>
                //                                <Button variant="outline-secondary"><span>Subscribe</span></Button>
                //                            </InputGroup.Append>
                //                        </InputGroup>
                //                    </Form>
                //                </div>
                //            </Col>
                //        </Row>
                //    </Container>
                //</div>
            }
      </ContentLayout>
    );
  }
}


const mapActionsToProps = {
    updateSearchList: updateSearchList,
    updateSearchListt: updateSearchListt,
    returnUpdateSearch: returnUpdateSearch
}

export default connect(null, mapActionsToProps)(HomePage);
