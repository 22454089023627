import React, { Component } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import {Layout} from "../components/Layout";

class ForgotPage extends Component {
  render() {
    return (
      <Layout>
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
             <Col md="12">
                <div className="login-dialog">
                  <div className="login-content">
                    <div className="rbm_form_header">
                      <h3>Reset Your Password</h3>
                      </div>
                      <Form className="rbm_form_body">
                        <div className="rbm_input_txt">
                        <p>To restore your account password, please enter the email address you used when registering with us. We will forward instructions on how to restore your password immediately.</p>
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label>Email *</Form.Label>
                          <Form.Control type="email" className="form-control" placeholder="enter your email" />
                        </Form.Group>
                        <div className="rbm_form_submit">
                          <Button type="submit" className="rbm_btn_x_out_shtr">Submit</Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    );
  }
}

export default ForgotPage;
