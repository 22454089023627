import React, { Component } from 'react';
import { Col, Row, Container} from 'react-bootstrap';
import ContentLayout from "../components/ContentLayout";
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import { connect } from 'react-redux';
import { updateSearchList, returnUpdateSearch } from '../actions/search-actions';


class BrowseListingsPallets extends Component {

  constructor(props) {
    super(props);
  
    this.state = {
       header: "Browse Listings in Pallets"
      }
      this.props.updateSearchList([]);
      this.props.returnUpdateSearch();
  }

  render() {
    return (
      <ContentLayout>
        <Breadcrumb
          breadcrumb={[
            { name: "Home", path: "/" },
            { name: "Browse Listings Pallets", path: "/Browse-Listings-Pallets" }
          ]}
        />
        <div className="bg-grey pt-5 pb-5">
          <Container fluid className="pl-5 pr-5">
            <Row>
              <Col lg="12">
                <h2 className="title">{this.state.header}</h2>
              </Col>
            </Row>
          </Container>
        </div>
      </ContentLayout>
    );
  }
}

const mapActionsToProps = {
    updateSearchList: updateSearchList,
    returnUpdateSearch: returnUpdateSearch
}

export default connect(null, mapActionsToProps)( BrowseListingsPallets);

