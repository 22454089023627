import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { closeDialog, exectueDialogCommand } from '../actions/dialog-actions'
import { connect } from 'react-redux';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import HelpIcon from '@material-ui/icons/Help';

class AppDialog extends Component {
    constructor(props) {
        super(props);
    }

    handleClose = () => {
        if (this.props.dialog.onCloseAction) {
            this.props.executeCommand(this.props.dialog.onCloseAction, this.props.dialog.data);
        }
        this.props.closeDialog();
    }

    handleContinue = () => {
        if (this.props.dialog.onContinueAction) {
            this.props.executeCommand(this.props.dialog.onContinueAction, this.props.dialog.data);
        }
        this.props.closeDialog();
    }

    getTitleColor = () => {
        let returnValue = "#7CBB00";

        if (this.props.dialog.message.includes("?")) {
            returnValue = "#FFBB00";
        } else if (this.props.dialog.errorCode != 0) {
            returnValue = "#F65314"
        } else {
            returnValue = "#7CBB00";
        }

        return returnValue;
    }

    getIcon = () => {
        let returnValue = <CheckCircleIcon />

        if (this.props.dialog.message.includes("?")) {
            returnValue = <HelpIcon />
        } else if (this.props.dialog.errorCode != 0) {
            returnValue = <ErrorIcon />
        } else {
            returnValue = <CheckCircleIcon />;
        }

        return returnValue;
    }

    render() {
        return (this.props.dialog.open &&
            <Dialog
                open={this.props.dialog.open}
                onClose={() => this.handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogTitle id="alert-dialog-title" style={{ backgroundColor: this.getTitleColor() }} > {this.getIcon()}  {this.props.dialog.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.props.dialog.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {this.props.dialog.continueText &&
                        <Button onClick={() => this.handleContinue()} color="primary">
                            {this.props.dialog.continueText}
                        </Button>
                    }
                    {this.props.dialog.closeText && 
                    <Button onClick={() => this.handleClose() } color="primary" >
                        {this.props.dialog.closeText}
                    </Button>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}


const mapStateToProps = state => ({
    dialog: state.dialog
});

const mapActionsToProps = {
    closeDialog: closeDialog,
    executeCommand: exectueDialogCommand
}

export default connect(mapStateToProps, mapActionsToProps)(AppDialog);