import { OPEN_DIALOG, CLOSE_DIALOG, DIALOG_ACTION_CLEARCART, DIALOG_ACTION_CLOSE, DIALOG_ACTION_REMOVE_CART_ITEM, DIALOG_ACTION_REMOVE_SB_FROM_ORDER } from "../constants";
import { removeCartItem, removeSbFromOrder } from '../actions/cart-actions';

export const openDialog = function (errorCode, title, message, closeText, continueText , onCloseAction, onContinueAction, data ) {
    return {
        type: OPEN_DIALOG,
        payload: {
            errorCode,
            open : true,
            title,
            message,
            closeText,
            continueText,
            onCloseAction,
            onContinueAction,
            data
        }
    }
}

export const closeDialog = function () {
    return {
        type: CLOSE_DIALOG,
        payload: {
            errorCode: 0,
            open: false,
            title: "",
            message: "",
            closeText: "",
            continueText: "",
            onCloseAction: null,
            onContinueAction: null,
            data: {}
        }
    }
}


export function exectueDialogCommand(command, data) {
    return (dispatch => {
        switch (command) {
            case DIALOG_ACTION_CLOSE:
                break;

            case DIALOG_ACTION_REMOVE_CART_ITEM:
                dispatch(removeCartItem(data.action, data.id));
                break;

            case DIALOG_ACTION_CLEARCART:
                dispatch(removeCartItem(data.action, data.id));
                break;
            case DIALOG_ACTION_REMOVE_SB_FROM_ORDER:
                dispatch(removeSbFromOrder(data.id, data.manifestId, data.SBNumber));
                break;
        }
    })
}