import React, { Component } from 'react';
import { Col, Container, Row, Nav } from 'react-bootstrap';
import Footerlogo from '../../assets/img/footer-logo.png';
import PaymentO from '../../assets/img/icon/payment-option.png';
import FB from '../../assets/img/icon/fb.png';
import TW from '../../assets/img/icon/tw.png';
import INSTA from '../../assets/img/icon/insta.png';
import WF from '../../assets/img/icon/wf.png';
import GP from '../../assets/img/icon/google-play.png';
import AS from '../../assets/img/icon/app-store.png';
import AddressIocn from '../../assets/img/icon/address.png';
import PhoneIcon from '../../assets/img/icon/phone.png';
import EmailIcon from '../../assets/img/icon/email.png';



class Footer extends Component {
  render() {
    const mystyle = {
      width: "55px",
    };
    return (
      <footer className="footer border-top">
        <div className="mt-5 mb-5">
          <Container>
            <Row>
              <Col lg="4" sm="6">
                <h4><img src={Footerlogo} alt="Company Name" /></h4>
                <Nav as="ul" className="home-address">{
                  //<Nav.Item as="li">
                  //  <span className="float-left" style={mystyle}>
                  //    <img src={AddressIocn} alt="Company Name" />
                  //  </span> 
                  //  Mailing Address: 5325 FAA Blvd. Suite 160, Irving, Texas 75061
                  //</Nav.Item>
                  //<Nav.Item as="li">
                  //  <span className="float-left">
                  //    <img src={PhoneIcon} alt="Company Name" />
                  //  </span> 
                  //  (+1) 02 315 215 - (+1) 03 315 215
                  //</Nav.Item>
                            }
                  <Nav.Item as="li">
                    <span className="float-left">
                      <img src={EmailIcon} alt="Company Name" />
                    </span> 
                    <a class ="mailto" href="mailto: salvage@homedepot.com">salvage@homedepot.com</a>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg="4" sm="6">
                <h4 className="border-bottom">Information</h4>
                <Nav as="ul" className="footer-links">
                  <Nav.Item as="li">
                    <Nav.Link href="/AboutUs"><i className="far fa-dot-circle"></i> About Us</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link href="/DeliveryInformation"><i className="far fa-dot-circle"></i> To Schedule Freight </Nav.Link>
                  </Nav.Item>{
                  //<Nav.Item as="li">
                  //  <Nav.Link href="/SupportHelp"><i className="far fa-dot-circle"></i> Support 24/7</Nav.Link>
                  //</Nav.Item>
                                    
                  //<Nav.Item as="li">
                  //  <Nav.Link href="/faq"><i className="far fa-dot-circle"></i> FAQ</Nav.Link>
                  //</Nav.Item>
                    }
                  <Nav.Item as="li">
                    <Nav.Link href="/PrivacyPolicy"><i className="far fa-dot-circle"></i> Privacy Policy</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link href="/TermsConditions"><i className="far fa-dot-circle"></i> Terms &amp; Conditions</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg="4" sm="6">
                <h4 className="border-bottom">My Account</h4>
                <Nav as="ul" className="footer-links">
                  <Nav.Item as="li">
                    <Nav.Link href="/MyProfile"><i className="far fa-dot-circle"></i> My Profile</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link href="/MyCredits"><i className="far fa-dot-circle"></i> My Credits</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link href="/Invoices"><i className="far fa-dot-circle"></i> Invoices</Nav.Link>
                  </Nav.Item>{
                  //<Nav.Item as="li">
                  //  <Nav.Link href="/SupportHelp"><i className="far fa-dot-circle"></i> Support 24/7</Nav.Link>
                  //</Nav.Item>
                   
                  //<Nav.Item as="li">
                  //  <Nav.Link href="/faq"><i className="far fa-dot-circle"></i> FAQs</Nav.Link>
                  //</Nav.Item>
                                }
                  <Nav.Item as="li">
                    <Nav.Link href="/ContactUs"><i className="far fa-dot-circle"></i> Contact Us</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg="3" sm="6">
                            {
                               // <h4 className="border-bottom">Follow Us On</h4>
                                //<Nav as="ul" className="social-links">
                                //    <Nav.Item as="li">
                                //        <Nav.Link href="https://www.facebook.com/" target="_blank"><img src={FB} alt="Facebook" /></Nav.Link>
                                //    </Nav.Item>
                                 //   <Nav.Item as="li">
                                //        <Nav.Link href="http://twitter.com/" target="_blank"><img src={TW} alt="Twitter" /></Nav.Link>
                               //     </Nav.Item>
                                //    <Nav.Item as="li">
                                //        <Nav.Link href="https://www.instagram.com/" target="_blank"><img src={INSTA} alt="instagram" /></Nav.Link>
                                //    </Nav.Item>
                                //    <Nav.Item as="li">
                                 //       <Nav.Link href="https://www.wifi.com/" target="_blank"><img src={WF} alt="wifi" /></Nav.Link>
                              //      </Nav.Item>
                              //  </Nav>
                              //  <h4 className="mt-5 border-bottom">Download Our Apps</h4>
                                
                //<h4 className="border-bottom">Download Our Apps</h4>
                //<Nav as="ul" className="download-app">
                //  <Nav.Item as="li">
                //    <Nav.Link href="https://play.google.com/" target="_blank"><img src={GP} alt="google play store" /></Nav.Link>
                //  </Nav.Item>
                //  <Nav.Item as="li">
                //    <Nav.Link href="https://www.apple.com/ios/app-store/" target="_blank"><img src={AS} alt="App store" /></Nav.Link>
                //  </Nav.Item>
                //                </Nav>
                            }
              </Col>
            </Row>
          </Container>
        </div>
        <div className="copyright bg-light pt-2 pb-2">
          <Container>
            <Row>
              <Col lg="12">
                <div className="copyrught-content text-center">
                                <p className="copyright m-auto">
                  &copy; {new Date().getFullYear()}{" "}
                  The Simple Sell, LLC    
                  </p>
                                {
                  //<p className="text-center mb-1">
                  //Designed by&nbsp;
                  //<a
                  //  href="https://www.grsoftsolution.com?ref=thd-footer"
                  //  target={"_blank"}
                  //  className=""
                  //>
                  //  GRSoft
                  //</a>
                  //</p>
                  //<p>
                  //  <img
                  //    src={PaymentO}
                  //    alt="Payment Option"
                  //  />
                  //</p>
}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    );
  }
}

export default Footer;
