import { UPDATE_CART, FETCH_DATA_CART, FETCH_ORDERS_CART } from '../constants';

export const cartReducer = function (state = {}, action) {
    let returnValue = state;

    switch (action.type) {
        case UPDATE_CART:
            returnValue = action.payload ; 
            break;     
    }

    return returnValue;
}

export const cartDataReducer = function (state = { itemQty :0 , palletQty : 0, wholeSale : 0, amount : 0, checkedData:[]}, action) {
    let returnValue = state;

    switch (action.type) {
        case FETCH_DATA_CART:
            returnValue = action.payload;
            break;
    }

    return returnValue;
}

export const orderReducer = function (state = { timeStamp: 0, data: [] }, action) {
    let returnValue = state;

    switch (action.type) {
        case FETCH_ORDERS_CART:
            returnValue = action.payload;
            break;
    }

    return returnValue;
}