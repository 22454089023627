import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import authService from './api-authorization/AuthorizeService';
import  LoginMenu  from './api-authorization/LoginMenu';
import Categories from './Categories';
import { Col, Row, Dropdown, InputGroup, DropdownButton, FormControl, Button } from "react-bootstrap";
import { withRouter, Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';
import 'react-flags-select/scss/react-flags-select.scss';
import logo from '../assets/img/logo.png';
import user from '../assets/img/avatars/user.png';
import invoice from '../assets/img/icon/invoice.png';
import dashh from '../assets/img/icon/dashh.png';
import dash from '../assets/img/icon/dash.png';
import MyOrders from '../assets/img/icon/order-icon.png';
import usflag from '../assets/img/icon/usflag.svg';
import MyCartIcon from '../assets/img/icon/mycart-icon.png';
import { updateCartData, fetchCartData, completeOrder} from '../actions/cart-actions'
import { updateSearch, updateOrderSearch, returnUpdateSearch, updateClearSearch, returnUpdateOrderSearch } from '../actions/search-actions';
import { connect } from 'react-redux';
import { openDialog } from '../actions/dialog-actions';
import { DIALOG_ACTION_CLOSE, DIALOG_ACTION_REMOVE_CART_ITEM, DIALOG_ACTION_CLEARCART } from '../constants';
import Checkbox from '@material-ui/core/Checkbox';
import { dateMDY, numberformat, warehouse } from '../DateHelper';
import '../header.css'
import { commonErrorHandler } from '../Utils'

class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true, item: 0, wholeSale: 0.0, checked: false,
            selectedField: 'Select', searchValue: "", selectedFieldCaption: 'Search All', suggestions: [], dynamicCategory: [], buttonDisabled: false,
            text: '',
            hdChecked: false
        };
        this.props.returnUpdateSearch();
        this.props.returnUpdateOrderSearch();
    }
    handleOrderClick(status) {
        this.props.history.push({
            pathname: '/MyOrders',
            state: status
        })
    }
    handleInvoiceClick(status) {
        this.props.history.push({
            pathname: '/Invoices',
            state: status
        })
    }
    handleDashboardClick=()=> {
        this.props.history.push({
            pathname: '/Dashboard'
        })
    }
    navigateToViewCart = async () => {
        //check first that current user is allowed to buy/bid
        const token = await authService.getAccessToken();
        try {
            const response = await fetch('InventoryCode/IsBuyAndBidAllowed', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            /*const data = await response.json();*/
            const data = await commonErrorHandler(response);

            if (data != null && data.success && data.accessDenied) {
                //access denied
                setTimeout(
                    function () {
                        this.props.openDialog(1,
                            "Restriction",
                            "Sorry, Your account has been restricted from purchasing. Please contact your account manager.", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                    }
                        .bind(this),
                    500
                );
            }
            else {
                this.props.history.push({
                    pathname: '/View-Cart'
                })
            }

        } catch (e) {
        }
    }

    ClearSelect() {
        this.props.openDialog(0, "Clear Cart", "Are you sure you want to clear the cart?", "Cancel", "Continue", DIALOG_ACTION_CLOSE, DIALOG_ACTION_CLEARCART, { action: "reset", id: 0 });
        //window.location.reload();
    }

    navigateToCheckOut = () => {
        this.props.history.push({
            pathname: '/Cart-Checkout'
        })
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    onChange(e) {
        const { checked } = e.target;
        this.setState({ checked });

    }
    handleClick(category) {
        this.props.history.push({
            pathname: '/Inventory',
            state: category
        })

    }
    handlelogoclick(category) {
        this.props.history.push({
            pathname: '/',
            state: category
        })

    }
    componentDidMount() {
        this.props.getCartSummary();
        this.props.setClearSearch();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.updatedOn != this.props.updatedOn) {
            console.log("Update Cart");
            this.populateInventoryData();
        }
        if (prevProps.clearSearch != this.props.clearSearch) {
            this.setState({ searchValue: "", categorySelect: "", selectedFieldCaption: 'Search All'});
        }
        if (this.props.cartData.data != prevProps.cartData.data) {
            if (this.props.cartData != null && this.props.cartData.data != undefined) {
                this.setState({ buttonDisabled: false });
                if (this.props.cartData.data.responseType == "restriction" || (this.props.cartData.data.value !== undefined && this.props.cartData.data.valueresponseType == "restriction")) {
                    //    this.props.openDialog(1,
                    //        "Restriction",
                    //        this.props.cartData.data.responseMessage, "Close", "", DIALOG_ACTION_CLOSE, null, null);
                }
                else {
                    this.props.openDialog(0,
                        "Add to Order",
                        this.props.cartData.data.responseMessage, "Close", "", DIALOG_ACTION_CLOSE, null, null);
                }
                setTimeout(() => {
                    //this.props.history.push('/Inventory');
                    this.props.history.push({
                        pathname: '/Inventory',
                        act: this.props.cartData.data

                    })
                }, 3000);
            }
        }
    }

    setSelectedField(fieldName, fieldCaption) {
        debugger;
        this.triggerSearch(fieldName, fieldCaption, this.state.searchValue, this.state.hdChecked);
        this.setState({ categorySelect: fieldName});
    }

    setSearchValue(value) {
        this.setState({ searchHome: value });
        this.triggerSearch(this.state.selectedField, this.state.selectedFieldCaption, value, this.state.hdChecked);
    }
    setCatSearchValue(value,i) {
        this.setState({ searchHome: value });
        let suggestions = [];
        // this.setState({ dynamicCategory: this.props.cartData.categoryList });
        let allList = this.props.cart.categoryList != undefined ? this.props.cart.categoryList : this.props.cartData.categoryList
        if (value.length > 0) {
            const regex = new RegExp(`^${value}`, 'i');
            if (allList != undefined) {
                suggestions = allList.sort().filter(v => regex.test(v))
            }
        }

        this.setState(() => ({
            suggestions,
            searchValue: value
        }))
        this.triggerSearch(this.state.selectedField, this.state.selectedFieldCaption, value, this.state.hdChecked,i);
    }

    setHdChecked() {
        this.triggerSearch(this.state.selectedField, this.state.selectedFieldCaption, this.state.searchValue, !this.state.hdChecked);
    }

    triggerSearch(fieldName, setSelectedField, searchValue, hdChecked ,i) {
        this.setState({
            selectedField: fieldName,
            selectedFieldCaption: setSelectedField,
            searchValue: searchValue,
            hdChecked: hdChecked,
            i:i
        });
        this.props.updateSearch(fieldName, searchValue, hdChecked, i);
        this.props.updateOrderSearch(searchValue);
        //  this.setState({ searchValue:''});
    }
    //handleClick = () => {
    //    this.setState({ searchValue: '' })

    //}
    selectedText(value,i) {
        this.setState({ searchHome: value });
        this.setCatSearchValue(value,i)
        this.setState(() => ({
            searchValue: value,
            suggestions: [],
        }))
        // this.triggerSearch(this.state.selectedField, this.state.selectedFieldCaption, value, this.state.hdChecked);
    }

    CompleteOrder() {
        this.setState({buttonDisabled: true });
        this.props.completeOrder()

    }
    renderSuggestions = () => {
        let { suggestions } = this.state;
        if (suggestions.length === 0) {
            return null;
        }
        return (
            <ul >
                {
                    suggestions.map((item, index) => (<li key={index} onClick={() => this.selectedText(item,index)}>{item}</li>))
                }
            </ul>
        );
    }

    render() {
        const { suggestions, buttonDisabled } = this.state;
        if(this.props.searchListt.listt && this.props.searchListt.listt.length > 0)

            return (


                <div className="headtop">
                    <header className="header-top">
                        <div className="container-fluid pl-5 pr-5">
                            <div className="h-100">
                                <Row className="justify-content-center align-items-center">
                                    <Col lg="8">
                                        <div className=" header-top-settings">
                                            <Nav as="ul">
                                                <Nav.Item as="li" className="language">{
                                                    //<ReactFlagsSelect
                                                    //    defaultCountry="US"
                                                    //    countries={["US"]}
                                                    //        customLabels={{ "US": "EN-US" }} />
                                                    //<img src={usflag} alt="Invoice" style={{ height: "14px" }} />
                                                }
                                                </Nav.Item>

                                            </Nav>
                                        </div>
                                    </Col>
                                    <Col lg="4">
                                        <div className="header-top-settings">
                                            <Nav as="ul" className="align-items-center ml-Auto">
                                                <Nav.Item as="li">
                                                    <Link to="/THDMarketplace.docx" target="_blank" download>FAQs</Link>
                                                </Nav.Item>
                                                <LoginMenu>
                                                </LoginMenu>
                                            </Nav>

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="bottom_header bdr-bottom">
                                <Row className="justify-content-center align-items-center">
                                    <Col lg="2">
                                        <Nav.Link onClick={() => this.handlelogoclick("Again")}>
                                            <img src={logo} alt="Company Name" />
                                        </Nav.Link>
                                    </Col>
                                    {
                                        //this.props.searchList.list && this.props.searchList.list.length > 0 ? (
                                        //<Col lg="2">

                                        //    <Checkbox
                                        //        checked={this.state.hdChecked}
                                        //        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        //        style={{
                                        //            color: '#fa7a22'
                                        //        }}
                                        //        onClick={() => { this.setHdChecked() }}
                                        //    />
                                        //    <label style={{ color: 'white' }}>
                                        //        HD.COM
                                        //</label>
                                        //</Col>) : ( <Col lg="2"> </Col>)
                                    }
                                    <Col lg="1"> </Col>
                                    <Col lg="4">

                                        <div className="search-panel">


                                            <InputGroup className="">
                                                {this.props.searchList.list && this.props.searchList.list.length > 0 &&
                                                    <DropdownButton
                                                        as={InputGroup.Prepend}
                                                        variant="outline-secondary"
                                                        title={this.state.selectedFieldCaption}
                                                        id="input-group-dropdown-1"
                                                    >
                                                        {
                                                            //<Dropdown.Item onClick={() => this.setSelectedField('Select', 'Select')}>Select</Dropdown.Item>
                                                        }
                                                        {this.props.searchList.list.map(fieldName => {
                                                            return (
                                                                <Dropdown.Item key={fieldName.id} onClick={() => this.setSelectedField(fieldName.name, fieldName.caption)}>{fieldName.caption}</Dropdown.Item>
                                                            )
                                                        }
                                                        )}
                                                    </DropdownButton>
                                                }

                                                {this.state.categorySelect == "salvageCategory" ? <FormControl
                                                    input type="search"
                                                    placeholder="Search Category ...."
                                                    aria-label="Search Product"
                                                    aria-describedby="basic-addon1"
                                                    value={this.state.searchValue}
                                                    onChange={(event) => this.setCatSearchValue(event.target.value)}
                                                /> :
                                                    <FormControl
                                                        input type="search"
                                                        placeholder="Search ...."
                                                        aria-label="Search Product"
                                                        aria-describedby="basic-addon1"
                                                        //value={this.props.clearSearch.searchText}
                                                        value={this.state.searchValue}
                                                        onChange={(event) => this.setSearchValue(event.target.value)}
                                                    />
                                                }
                                                <InputGroup.Append>
                                                    <Button variant="outline-secondary" /*onClick={()=>this.handleClick(this.state.searchHome)}*/ className="btn btn-default search_btn border-0 rounded-0"><span className="fas fa-search"></span></Button>
                                                </InputGroup.Append>
                                            </InputGroup>



                                        </div>
                                        {suggestions.length === 0 ? null :
                                            <div fluid="md" id="notebooks">
                                                {this.renderSuggestions()}
                                            </div>
                                        }

                                    </Col>
                                    <Col lg="5">
                                        <div className="cart-address header-top-settings">
                                            <Nav as="ul" className="align-items-center ml-Auto">
                                                <Nav.Item as="li" className="pr-4">
                                                    <Nav.Link onClick={() => this.handleDashboardClick()}><img src={dash} alt="Invoice" style={{ height: "28px" }} /> My DashBoard</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item as="li" className="pr-4">
                                                    <Nav.Link onClick={() => this.handleInvoiceClick("All")}><img src={invoice} alt="Invoice" style={{ height: "28px" }} /> My Invoices</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item as="li" className="pr-4">
                                                    <Nav.Link onClick={() => this.handleOrderClick("All")}><img src={MyOrders} alt="My Orders" /> My Orders</Nav.Link>
                                                </Nav.Item>
                                                {this.props.searchListt.listt && this.props.searchListt.listt.length > 0 ? "" :
                                                    <Nav.Item as="li" className="pl-0 position-relative">
                                                        <Nav.Link href="#" className="clearfix" onClick={() => this.navigateToViewCart()}><img src={MyCartIcon} alt="My Orders" className="float-left" /> <span className="badge badge-orange">{this.props.cart.itemsSelected}</span>
                                                            <span className="float-left line-height-0 ml-2">My Cart<span className="d-block cart-price">{this.props.cart.valuePurchased ? numberformat(this.props.cart.valuePurchased) : this.props.cart.valuePurchased}</span></span> </Nav.Link>
                                                        <span className="s-color-text float-right">Total Pallets : <span className=" cart_pallet float-right">{this.props.cart.palletQty}</span></span>
                                                        <div className="shopping-cart dropdown-listCart shadow-sm rounded p-4">
                                                            <div className="shopping-cart-header border-bottom pb-2">
                                                                <i className="fa fa-shopping-cart cart-icon"></i><span className="badge badge-orange">{this.props.cart.itemsSelected}</span>
                                                                <div className="shopping-cart-total">
                                                                    <span className="s-color-text">Order Total:</span>
                                                                    <span className="main-color-text">{this.props.cart.valuePurchased ? numberformat(this.props.cart.valuePurchased) : this.props.cart.valuePurchased}</span>

                                                                </div>
                                                            </div>
                                                            <div className="data-text">
                                                                <span className="text-dark"> SUMMARY</span>
                                                                <div className="shopping-cart-header border-bottom pb-2"></div>
                                                                {
                                                                    this.props.cart.checkedData != null ? this.props.cart.checkedData.map(item => {
                                                                        return (
                                                                            <span key={item[0]}>

                                                                                <div>
                                                                                    <span className="text-dark">SBNumber : </span>
                                                                                    <span className="main-color-text mr-1">{item[1]}</span>
                                                                                    <span className="text-dark">Category : </span>
                                                                                    <span className="main-color-text mr-1">{item[2]}</span>
                                                                                    <span className="text-dark">Type : </span>
                                                                                    <span className="main-color-text mr-1">{item[3]}</span>
                                                                                    <span className="text-dark">Item Qty : </span>
                                                                                    <span className="main-color-text mr-1">{item[8]}</span>
                                                                                    <span className="text-dark">Pallet QTY : </span>
                                                                                    <span className="main-color-text mr-1">{item[4]}</span>
                                                                                    <span className="text-dark">Wholesale : </span>
                                                                                    <span className="main-color-text">{item[6] ? numberformat(item[6]) : item[6]}</span>
                                                                                    <span className="text-dark">Rate% : </span>
                                                                                    <span className="main-color-text mr-1">{item[7]}</span>
                                                                                    <span className="text-dark">InvoiceAmount : </span>
                                                                                    <span className="main-color-text mr-1">{(parseFloat(item[6]) * parseFloat(item[7]) / 100).toFixed(2) ? numberformat((parseFloat(item[6]) * parseFloat(item[7]) / 100).toFixed(2)) : (parseFloat(item[6]) * parseFloat(item[7]) / 100).toFixed(2)}</span>
                                                                                    <span className="text-dark">Warehouse : </span>
                                                                                    <span className="main-color-text mr-1">{item[9] ? warehouse(item[9]) : item[9]}</span>
                                                                                    <div className="shopping-cart-header border-bottom pb-2"></div>
                                                                                </div></span>

                                                                        )
                                                                    }) : null
                                                                }
                                                            </div>
                                                            <div className="viewcart_checkout_btn mt-4 align-items-center text-center">
                                                                <a onClick={() => this.navigateToViewCart()} className="button vcb_white">View Cart</a>
                                                                {this.props.cart.checkedData && this.props.cart.checkedData.length > 0 ?
                                                                    <button onClick={() => { this.ClearSelect() }} className="button vcb_white onbg-checkout ml-3">Clear Cart</button>
                                                                    : null}
                                                                <div className="mt-3">
                                                                    {this.props.cart.checkedData && this.props.cart.checkedData.length > 0 ?
                                                                        <button onClick={() => this.CompleteOrder()} disabled={buttonDisabled} className="button  vcb_white">Complete Order</button> : null}</div>
                                                            </div>
                                                        </div>
                                                    </Nav.Item>}


                                            </Nav>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </header>
                </div>
            );

        return (


            <div className="headtop">
                <header className="header-top">
                    <div className="container-fluid pl-5 pr-5">
                        <div className="h-100">
                            <Row className="justify-content-center align-items-center">
                                <Col lg="8">
                                    <div className=" header-top-settings">
                                        <Nav as="ul">
                                            <Nav.Item as="li" className="language">{
                                                //<ReactFlagsSelect
                                                //    defaultCountry="US"
                                                //    countries={["US"]}
                                                //        customLabels={{ "US": "EN-US" }} />
                                                //<img src={usflag} alt="Invoice" style={{ height: "14px" }} />
                                            }
                                            </Nav.Item>

                                        </Nav>
                                    </div>
                                </Col>
                                <Col lg="4">
                                    <div className="header-top-settings">
                                        <Nav as="ul" className="align-items-center ml-Auto">
                                            <Nav.Item as="li">
                                                <Link to="/THDMarketplace.docx" target="_blank" download>FAQs</Link>
                                            </Nav.Item>
                                            <LoginMenu>
                                            </LoginMenu>
                                        </Nav>

                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="bottom_header bdr-bottom">
                            <Row className="justify-content-center align-items-center">
                                <Col lg="2">
                                    <Nav.Link onClick={() => this.handlelogoclick("Again")}>
                                        <img src={logo} alt="Company Name" />
                                    </Nav.Link>
                                </Col>
                                {
                                    //this.props.searchList.list && this.props.searchList.list.length > 0 ? (
                                    //<Col lg="2">

                                    //    <Checkbox
                                    //        checked={this.state.hdChecked}
                                    //        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    //        style={{
                                    //            color: '#fa7a22'
                                    //        }}
                                    //        onClick={() => { this.setHdChecked() }}
                                    //    />
                                    //    <label style={{ color: 'white' }}>
                                    //        HD.COM
                                    //</label>
                                    //</Col>) : ( <Col lg="2"> </Col>)
                                }
                                <Col lg="1"> </Col>
                                <Col lg="4">

                                    <div className="search-panel">


                                        <InputGroup className="">
                                            {this.props.searchList.list && this.props.searchList.list.length > 0 &&
                                                <DropdownButton
                                                    as={InputGroup.Prepend}
                                                    variant="outline-secondary"
                                                    title={this.state.selectedFieldCaption}
                                                    id="input-group-dropdown-1"
                                                >
                                                    {
                                                        //<Dropdown.Item onClick={() => this.setSelectedField('Select', 'Select')}>Select</Dropdown.Item>
                                                    }
                                                    {this.props.searchList.list.map(fieldName => {
                                                        return (
                                                            <Dropdown.Item key={fieldName.id} onClick={() => this.setSelectedField(fieldName.name, fieldName.caption)}>{fieldName.caption}</Dropdown.Item>
                                                        )
                                                    }
                                                    )}
                                                </DropdownButton>
                                            }

                                            {this.state.categorySelect == "salvageCategory" ? <FormControl
                                                input type="search"
                                                placeholder="Search Category ...."
                                                aria-label="Search Product"
                                                aria-describedby="basic-addon1"
                                                value={this.state.searchValue}
                                                onChange={(event) => this.setCatSearchValue(event.target.value)}
                                            /> :
                                                <FormControl
                                                    input type="search"
                                                    placeholder="Search ...."
                                                    aria-label="Search Product"
                                                    aria-describedby="basic-addon1"
                                                    //value={this.props.clearSearch.searchText}
                                                    value={this.state.searchValue}
                                                    onChange={(event) => this.setSearchValue(event.target.value)}
                                                />
                                            }
                                            <InputGroup.Append>
                                                <Button variant="outline-secondary" /*onClick={()=>this.handleClick(this.state.searchHome)}*/ className="btn btn-default search_btn border-0 rounded-0"><span className="fas fa-search"></span></Button>
                                            </InputGroup.Append>
                                        </InputGroup>



                                    </div>
                                    {suggestions.length === 0 ? null :
                                        <div fluid="md" id="notebooks">
                                            {this.renderSuggestions()}
                                        </div>
                                    }

                                </Col>
                                <Col lg="5">
                                    <div className="cart-address header-top-settings">
                                        <Nav as="ul" className="align-items-center ml-Auto">
                                            <Nav.Item as="li" className="pr-4">
                                                <Nav.Link onClick={() => this.handleDashboardClick()}><img src={dash} alt="Invoice" style={{ height: "28px" }} /> My DashBoard</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li" className="pr-4">
                                                <Nav.Link onClick={() => this.handleInvoiceClick("All")}><img src={invoice} alt="Invoice" style={{ height: "28px" }} /> My Invoices</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li" className="pr-4">
                                                <Nav.Link onClick={() => this.handleOrderClick("All")}><img src={MyOrders} alt="My Orders" /> My Orders</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li" className="pl-0 position-relative">
                                                <Nav.Link href="#" className="clearfix" onClick={() => this.navigateToViewCart()}><img src={MyCartIcon} alt="My Orders" className="float-left" /> <span className="badge badge-orange">{this.props.cart.itemsSelected}</span>
                                                    <span className="float-left line-height-0 ml-2">My Cart<span className="d-block cart-price">{this.props.cart.valuePurchased ? numberformat(this.props.cart.valuePurchased) : this.props.cart.valuePurchased}</span></span> </Nav.Link>
                                                <span className="s-color-text float-right">Total Pallets : <span className=" cart_pallet float-right">{this.props.cart.palletQty}</span></span>
                                                <div className="shopping-cart dropdown-listCart shadow-sm rounded p-4">
                                                    <div className="shopping-cart-header border-bottom pb-2">
                                                        <i className="fa fa-shopping-cart cart-icon"></i><span className="badge badge-orange">{this.props.cart.itemsSelected}</span>
                                                        <div className="shopping-cart-total">
                                                            <span className="s-color-text">Order Total:</span>
                                                            <span className="main-color-text">{this.props.cart.valuePurchased ? numberformat(this.props.cart.valuePurchased) : this.props.cart.valuePurchased}</span>

                                                        </div>
                                                    </div>
                                                    <div className="data-text">
                                                        <span className="text-dark"> SUMMARY</span>
                                                        <div className="shopping-cart-header border-bottom pb-2"></div>
                                                        {
                                                            this.props.cart.checkedData != null ? this.props.cart.checkedData.map(item => {
                                                                return (
                                                                    <span key={item[0]}>

                                                                        <div>
                                                                            <span className="text-dark">SBNumber : </span>
                                                                            <span className="main-color-text mr-1">{item[1]}</span>
                                                                            <span className="text-dark">Category : </span>
                                                                            <span className="main-color-text mr-1">{item[2]}</span>
                                                                            <span className="text-dark">Type : </span>
                                                                            <span className="main-color-text mr-1">{item[3]}</span>
                                                                            <span className="text-dark">Item Qty : </span>
                                                                            <span className="main-color-text mr-1">{item[8]}</span>
                                                                            <span className="text-dark">Pallet QTY : </span>
                                                                            <span className="main-color-text mr-1">{item[4]}</span>
                                                                            <span className="text-dark">Wholesale : </span>
                                                                            <span className="main-color-text">{item[6] ? numberformat(item[6]) : item[6]}</span>
                                                                            <span className="text-dark">Rate% : </span>
                                                                            <span className="main-color-text mr-1">{item[7]}</span>
                                                                            <span className="text-dark">InvoiceAmount : </span>
                                                                            <span className="main-color-text mr-1">{(parseFloat(item[6]) * parseFloat(item[7]) / 100).toFixed(2) ? numberformat((parseFloat(item[6]) * parseFloat(item[7]) / 100).toFixed(2)) : (parseFloat(item[6]) * parseFloat(item[7]) / 100).toFixed(2)}</span>
                                                                            <span className="text-dark">Warehouse : </span>
                                                                            <span className="main-color-text mr-1">{item[9] ? warehouse(item[9]) : item[9]}</span>
                                                                            <div className="shopping-cart-header border-bottom pb-2"></div>
                                                                        </div></span>

                                                                )
                                                            }) : null
                                                        }
                                                    </div>
                                                    <div className="viewcart_checkout_btn mt-4 align-items-center text-center">
                                                        <a onClick={() => this.navigateToViewCart()} className="button vcb_white">View Cart</a>
                                                        {this.props.cart.checkedData && this.props.cart.checkedData.length > 0 ?
                                                            <button onClick={() => { this.ClearSelect() }} className="button vcb_white onbg-checkout ml-3">Clear Cart</button>
                                                            : null}
                                                        <div className="mt-3">
                                                            {this.props.cart.checkedData && this.props.cart.checkedData.length > 0 ?
                                                                <button onClick={() => this.CompleteOrder()} disabled={buttonDisabled} className="button  vcb_white">Complete Order</button> : null}</div>
                                                    </div>
                                                </div>
                                            </Nav.Item>
                                            {this.props.cart.checkedData && this.props.cart.checkedData.length > 0 ?
                                                <div className="btn-group dropend position-relative side_bar " data-toggle="">
                                                    <span type="button" className="btn fa fa-ellipsis-v icon_display position-fixed" style={{ color: 'red', fontSize: "38px" }}
                                                        data-bs-toggle="dropdown" aria-expanded="false">
                                                    </span>
                                                    <ul class="dropdown-menu dropdownmenu_sidebar " >

                                                        <div className="data-text p-2">
                                                            <span ><h2 className="text-center pb-2"> CART SUMMARY </h2></span>
                                                            <div className="shopping-cart-header d-flex card_header border-bottom pb-2">
                                                                <i className="fa-2x fa fa-shopping-cart cart-icon"></i><span className="badge badge-orange"> {this.props.cart.truckvalue}</span>
                                                                <div className="shopping-cart-total order_total text-dark font-weight-bold" style={{ marginLeft: "30%", fontSize: "14px" }}>
                                                                    <span className="s-color-text">Order Total:</span>
                                                                    <span className="main-color-text">{this.props.cart.valuePurchased ? numberformat(this.props.cart.valuePurchased) : this.props.cart.valuePurchased}</span>

                                                                </div>
                                                            </div>
                                                            <div className="shopping-cart-header border-bottom "></div>
                                                            {
                                                                this.props.cart.checkedData != null ? this.props.cart.checkedData.map(item => {
                                                                    return (
                                                                        <span key={item[0]}>

                                                                            <div className="side_Content pt-1" style={{ fontSize: "13px" }}>
                                                                                <span className="text-dark font-weight-bold">SBNumber : </span>
                                                                                <span className="main-first font-weight-bold">{item[1]}</span>
                                                                                <span className="text-dark font-weight-bold"> Category : </span>
                                                                                <span className="main-secound font-weight-bold">{item[2]}</span>
                                                                                <span className="text-dark font-weight-bold"> Type : </span>
                                                                                <span className="main-third font-weight-bold">{item[3]}</span>
                                                                                <span className="text-dark font-weight-bold"> Item Qty : </span>
                                                                                <span className="main-four font-weight-bold">{item[8]}</span>
                                                                                <span className="text-dark font-weight-bold"> Pallet QTY : </span>
                                                                                <span className="main-five font-weight-bold">{item[4]}</span>
                                                                                <span className="text-dark font-weight-bold"> Wholesale : </span>
                                                                                <span className="main-six font-weight-bold">{item[6] ? numberformat(item[6]) : item[6]}</span>
                                                                                <span className="text-dark font-weight-bold"> Rate% : </span>
                                                                                <span className="main-seven font-weight-bold">{item[7]}</span>
                                                                                <span className="text-dark font-weight-bold"> InvoiceAmount : </span>
                                                                                <span className="main-eight font-weight-bold">{(parseFloat(item[6]) * parseFloat(item[7]) / 100).toFixed(2) ? numberformat((parseFloat(item[6]) * parseFloat(item[7]) / 100).toFixed(2)) : (parseFloat(item[6]) * parseFloat(item[7]) / 100).toFixed(2)}</span>
                                                                                <span className="text-dark font-weight-bold"> Warehouse : </span>
                                                                                <span className="main-ninet font-weight-bold">{item[9] ? warehouse(item[9]) : item[9]}</span>
                                                                                <div className=" pb-2"></div>
                                                                                <hr className=" text-secondary m-0 p-0 w-0" />
                                                                            </div>
                                                                        </span>

                                                                    )
                                                                }) : null
                                                            }
                                                        </div>
                                                    </ul>
                                                </div>
                                                : null
                                            }

                                        </Nav>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </header>
            </div>
        );
    }

    async populateInventoryData() {
        const token = await authService.getAccessToken();
        const response = await fetch('InventoryCode/Manage', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        /*const data = await response.json();*/
        const data = await commonErrorHandler(response);
        var Item = 0;
        var WholeSale = 0.0;
        if (data.selectedTotalsBySb != null && data.selectedTotalsBySb != "") {
            for (var j = 0; j < data.selectedTotalsBySb.split(',').length; j++) {
                WholeSale += parseFloat(data.selectedTotalsBySb.split(',')[j].split('|')[6])
            }
            WholeSale = WholeSale.toFixed(2);
            Item = data.selectedTotalsBySb.split(',').length;
        }
        this.setState({ item: Item, loading: false });
        this.setState({ wholeSale: WholeSale, loading: false });
    }
}

const mapStateToProps = state => ({
    cart: state.cart,
    searchList: state.searchList,
    searchListt: state.searchListt,
    cartData: state.cartData,
    clearSearch: state.clearSearch,
    searchOrderList: state.searchOrderList
});
const mapActionsToProps = {
    getCartSummary: updateCartData,
    getCartData: fetchCartData,
    openDialog: openDialog,
    completeOrder: completeOrder,
    updateSearch: updateSearch,
    returnUpdateSearch: returnUpdateSearch,
    setClearSearch: updateClearSearch,
    updateOrderSearch: updateOrderSearch,
    returnUpdateOrderSearch: returnUpdateOrderSearch
}

export default withRouter(connect(mapStateToProps, mapActionsToProps)(NavMenu));
