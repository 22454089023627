import React from "react";
import Footer from "../components/Footer/Footer";

const ContentLayout = props => (
  <div>
    <div className="page">{props.children}</div>
    <Footer />
  </div>
);

export default ContentLayout;
