
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ContentLayout from "../components/ContentLayout";
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import authService from '../components/api-authorization/AuthorizeService'
import { Col, Container, Row, Card, Form, Button, ListGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { updateSearchList, returnUpdateSearch } from '../actions/search-actions';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SyncLoader from "react-spinners/SyncLoader";
import { css } from "@emotion/core";
import { DIALOG_ACTION_CLOSE } from '../constants';
import { openDialog } from '../actions/dialog-actions';

const override = css`
  display: block;
  margin: 2 auto;
  border-color: red;
`;
const style = { position: "fixed", top: "45%", left: "50%", transform: "translate(-50%, -50%)" };


class MyCredits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OrderNumber: "", CreditAmountTotal: "", CreditReason: "", Comments: "", credit: {}, file: '', fileName: '', fields: {}, isLoading: false,
            errors: {}
        };
        this.handleChange = this.handleChange.bind(this);
        this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
        this.checkOrderRequest = this.checkOrderRequest.bind(this);
        //this.OrderNumber = this.OrderNumber.bind(this);
        //this.CreditAmountTotal = this.CreditAmountTotal.bind(this);
        //this.CreditReason = this.CreditReason.bind(this);
        //this.Comments = this.Comments.bind(this);
        this.props.updateSearchList([]);
        this.props.returnUpdateSearch();
    }
    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });

    }
    submituserRegistrationForm(e) {
        this.setState({ isLoading: true });
        e.preventDefault();
        if (this.validateForm()) {
            this.creditRequest()
            if (this.state.credit.responsetype === "Fail") {
                let fields = {};
                fields["OrderNumber"] = "";
                fields["CreditAmountTotal"] = "";
                fields["CreditReason"] = "";
                fields["Comments"] = "";
                this.setState({ fields: fields });
                this.setState({ isLoading: false });
            }
        }
        else if (!this.validateForm()) {
            this.setState({ isLoading: false });
        }
    }
    validateForm() {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["OrderNumber"]) {
            formIsValid = false;
            errors["OrderNumber"] = "*Please enter your OrderNumber.";
        }

        if (typeof fields["OrderNumber"] !== "undefined") {
            if (!fields["OrderNumber"].match(/^[0-9]*$/)) {
                formIsValid = false;
                errors["OrderNumber"] = "*Please enter numeric value only.";
            }
        }

        if (!fields["CreditAmountTotal"]) {
            formIsValid = false;
            errors["CreditAmountTotal"] = "*Please enter CreditAmountTotal.";
        }

        if (typeof fields["CreditAmountTotal"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp("^[0-9]+(\.[0-9]{0,2})?$");
            if (!pattern.test(fields["CreditAmountTotal"])) {
                formIsValid = false;
                errors["CreditAmountTotal"] = "*Invalid Credit Amount Total; Maximum Two Decimal Points.";
            }
        }

        if (fields["CreditReason"] === undefined || fields["CreditReason"].trim() == "" || fields["CreditReason"].toLowerCase().trim() == "select one") {
            formIsValid = false;
            errors["CreditReason"] = "*Please select a Credit Reason.";
        }
        //if (fields["CreditReason"]) {
        //    formIsValid = false;
        //    errors["CreditReason"] = "*Please select CreditReason.";
        //}

        //if (typeof fields["CreditReason"] !== "undefined") {
        //    errors["CreditReason"] = "*Please select CreditReason.";
        //    if (!fields["CreditReason"].match("Shortage /Missing")) {
        //        formIsValid = tru;
        //        errors["CreditReason"] = "*Please select CreditReason.";
        //    }
        //    else if (!fields["CreditReason"].match("SevereShippingDamage")) {
        //        formIsValid = false;
        //        errors["CreditReason"] = "*Please select CreditReason.";
        //    }
        //    else if (!fields["CreditReason"].match("WholesaleAmountDiscrepancy")) {
        //        formIsValid = false;
        //        errors["CreditReason"] = "*Please select CreditReason.";
        //    }
        //}

        if (!fields["Comments"]) {
            formIsValid = false;
            errors["Comments"] = "*Please enter your Comments.";
        }
        if (typeof fields["Comments"] !== "undefined") {
            if (fields["Comments"].length > 200) {
                formIsValid = false;
                errors["Comments"] = "*Comments must be a maximum length of 200";
            }
        }
       

        //if (typeof fields["Comments"] !== "undefined") {
        //    if (!fields["Comments"].match(/^[a-zA-Z0-9_]*$/)) {
        //        formIsValid = false;
        //        errors["Comments"] = "*Please enter alphabet characters only.";
        //    }
        //}

        this.setState({
            errors: errors
        });
        return formIsValid;


    }
    //OrderNumber(event) {
    //    this.setState({ OrderNumber: event.target.value })
    //}
    //CreditAmountTotal(event) {
    //    this.setState({ CreditAmountTotal: event.target.value })
    //}
    //CreditReason(event) {
    //    this.setState({ CreditReason: event.target.value })
    //}
    //Comments(event) {
    //    this.setState({ Comments: event.target.value })
    //}
    checkOrderRequest(e) {
        e.preventDefault();
        if (this.validateOrderForm()) {
            this.CheckOrderValidCredit()
        }

    }
    validateOrderForm() {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["OrderNumber"]) {
            formIsValid = false;
            errors["OrderNumber"] = "*Please enter your OrderNumber.";
        }

        if (typeof fields["OrderNumber"] !== "undefined") {
            if (!fields["OrderNumber"].match(/^[0-9]*$/)) {
                formIsValid = false;
                errors["OrderNumber"] = "*Please enter numeric value only.";
            }
        }
        this.setState({
            errors: errors
        });
        return formIsValid;
    }
    onFileChange = event => {
        //const files = event.target.files;

        //for (let i = 0; i < files.length; i++) {
        //    formData.append(`images[${i}]`, files[i])
        //}
        this.setState({ file: event.target.files });
        //this.setState({ fileName: event.target.files[0].name });

    };

    async CheckOrderValidCredit() {
        let fields = this.state.fields;
        const formData = new FormData();
        formData.append("OrderNumber", fields["OrderNumber"]);
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/CheckOrderValidCredit', {
            method: 'post',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });
        const data = await response.json();
        this.setState({ credit: data });
    }

    async creditRequest() {
        let fields = this.state.fields;
        const formData = new FormData();

        formData.append("OrderNumber", fields["OrderNumber"]);
        formData.append("CreditAmountTotal", fields["CreditAmountTotal"]);
        formData.append("CreditReason", fields["CreditReason"]);
        formData.append("Comments", fields["Comments"]);
        const files = this.state.file;

        for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i])
        }

        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/RequestCredit', {
            method: 'post',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });
        const data = await response.json();
        if (data.responseType == "Success") {
            this.props.openDialog(0,
                "Credit Request Successfully Created",
                data.responseMessage, "Close", "", DIALOG_ACTION_CLOSE, null, null);
        }
        else {
            this.props.openDialog(1,
                "Failed To Create Credit Request",
                data.responseMessage, "Close", "", DIALOG_ACTION_CLOSE, null, null);
        }

        fields = {};
        fields["OrderNumber"] = "";
        fields["CreditAmountTotal"] = "";
        fields["CreditReason"] = "";
        fields["Comments"] = "";
        this.setState({ credit: "", file: "", fields: fields, isLoading: false });
    }
    navigateToInventory = () => {
        this.props.history.push({
            pathname: "/Inventory"
        })
    }
    render() {
        return (
            <ContentLayout>
                <Breadcrumb
                    breadcrumb={[
                        { name: "Home", path: "/" },
                        { name: "My Credits", path: "/MyCredits" }
                    ]}
                />
                <div className="content profile-content pt-5 pb-5">
                    <Button className="text-white" onClick={() => this.navigateToInventory()} variant="outline-secondary" style={{ backgroundColor: "#273546" }}><ArrowBackIcon /> <span>Back to Inventory</span></Button>
                    <Container>
                        <div className="bg-grey pt-2 pb-0">
                            <Row>
                                <Col lg="8">
                                    {//<Button className="text-white" onClick={() => this.navigateToInventory()} variant="outline-secondary" style={{ backgroundColor: "#273546" }}>Back to Inventory</Button>
                                    } </Col>
                            </Row>
                        </div>
                        <Card className="change-your-Comments rounded-0 bg-white shadow-sm">
                            <p><strong style={{ color: 'green' }}>{this.state.credit.responsetype === "Success" ? this.state.credit.response : ""}</strong></p>
                            <p><strong style={{ color: 'red' }}>{this.state.credit.responsetype === "Fail" ? this.state.credit.response : ""}</strong></p>
                        </Card>
                        <Row className="justify-content-center">
                            <Col lg="4">
                                <Card className="change-your-Comments rounded-0 bg-white shadow-sm">
                                    <Card.Body>
                                        <Card.Title>Request Credit</Card.Title>
                                        <Card.Text>
                                            <Form.Label>Order Number *</Form.Label>
                                            <Form.Control type="text" name="OrderNumber" value={this.state.fields.OrderNumber} onChange={this.handleChange} placeholder="Order Number" />
                                            <div className="errorMsg">{this.state.errors.OrderNumber}</div>
                                            {/*<Button variant="primary" style={{ backgroundColor: '#fd7e14' }} onClick={this.checkOrderRequest} type="submit">*/}
                                            {/*    Validate*/}
                                            {/*</Button>*/}
                                            <div></div>
                                            <Form.Label>Credit Amount Total *</Form.Label>
                                            <Form.Control type="text" name="CreditAmountTotal" value={this.state.fields.CreditAmountTotal} onChange={this.handleChange} placeholder="Credit Amount Total" />
                                            <div className="errorMsg">{this.state.errors.CreditAmountTotal}</div>
                                            <Form.Label>Credit Reason *</Form.Label>
                                            {
                                                //   <Form.Control type="text" onChange={this.CreditReason} placeholder="Credit Reason" />
                                            }
                                            <div>
                                                <Form.Control as="select" class="country" name="CreditReason" value={this.state.fields.CreditReason} onChange={this.handleChange}>
                                                    <option >Select One</option>
                                                    <option value="Shortage /Missing">Shortage/Missing</option>
                                                    <option value="SevereShippingDamage">Severe Shipping Damage</option>
                                                    <option value="WholesaleAmountDiscrepancy">Wholesale Amount Discrepancy</option>
                                                </Form.Control></div>
                                            <div className="errorMsg">{this.state.errors.CreditReason}</div>
                                            <Form.Label>Comments (Max. 200 char) *</Form.Label>
                                            <Form.Control as="textarea" rows="3" name="Comments" value={this.state.fields.Comments} onChange={this.handleChange} placeholder="Comments" />
                                            <div className="errorMsg">{this.state.errors.Comments}</div>
                                            <Form.Label>File Upload</Form.Label>
                                            <div className="sucessMsg">
                                                <Form.Control type="file" onChange={this.onFileChange} placeholder="Upload" enctype="multipart/form-data" multiple />
                                            </div>

                                            <Button variant="primary" style={{ backgroundColor: '#fd7e14' }} onClick={this.submituserRegistrationForm} disabled={ this.state.isLoading} type="submit">
                                                Submit
                                            </Button>
                                            <div style={style}>
                                                <SyncLoader
                                                    css={override}
                                                    size={15}
                                                    color={"#fd7e14"}
                                                    loading={this.state.isLoading}
                                                />
                                            </div>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg="8">
                                <div className="animated fadeIn">
                                    <Card className="rounded-0 bg-white shadow-sm ProfileInformation_Col">
                                        <Card.Body>
                                            <Card.Title>Credit Request - Business Procedures/Rules</Card.Title>
                                            <Card.Text>
                                                Below please find The Home Depot Salvage returns business procedures/rules when submitting credit requests. Allow 10 business days for us to complete the research for each credit submission.
                                            </Card.Text>
                                            <ListGroup>
                                                <ListGroup.Item className="border-0">Auto Deny - Credits need to be requested within 5 days of Order date, expected pick up time of Orders are 2 days no more than 5 days. If your credit request is after 5 days from Order date, your credit will automatically be denied regardless of reason. We cannot hold product longer than the 5 days to reduce risk of damage, missing items.</ListGroup.Item>
                                                <ListGroup.Item className="border-0">Damaged items, this is the nature of the Salvage business. Some things are going to be damaged, and some will always be damaged. This damage can be due to how it came in to the RLC’s, the transportation to our Customers, or negligence in the RLC’s, we are selling returned goods, which came back for a reason.</ListGroup.Item>
                                                <ListGroup.Item className="border-0">Missing items will always be a small %, most if not all loads have extra product that is sellable.</ListGroup.Item>
                                                <ListGroup.Item className="border-0">Manifest discrepancies will need to be researched for keying errors, please provide the exact details in your credit request.</ListGroup.Item>
                                            </ListGroup>
                                            <Card.Title className="mt-4">Credit Request - Business Procedures/Rules</Card.Title>
                                            <Card.Text>
                                                When building your Orders, please consider the type of product you are including on the same truck to avoid any damage upon arrival:
                                            </Card.Text>
                                            <ListGroup>
                                                <ListGroup.Item className="border-0">Will the category mix ship well ie; Doors and Windows with Flooring.</ListGroup.Item>
                                                <ListGroup.Item className="border-0">Allow for enough pallet space so not to overfill truck to not have missing items.</ListGroup.Item>
                                                <ListGroup.Item className="border-0">Trucks should not be maxed more than 26 pallets.</ListGroup.Item>
                                                <ListGroup.Item className="border-0">Categories such as Blinds, Flooring, Doors and Windows can take up 2 pallet spaces.</ListGroup.Item>
                                            </ListGroup>
                                            <Card.Title className="mt-4">Request Credit</Card.Title>{
                                                <Link to="/Managecreditrequests" className="Mcrequests_btn p-2">
                                                    Manage credit requests
                                                </Link>
                                            }

                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ContentLayout>
        );
    }
}


const mapActionsToProps = {
    updateSearchList: updateSearchList,
    openDialog: openDialog,
    returnUpdateSearch: returnUpdateSearch
}

export default connect(null, mapActionsToProps)(MyCredits);
