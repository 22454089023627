import React from 'react'
import GlobalFilter from './GlobalFilter'
import PropTypes from 'prop-types'

const TableToolbar = props => {
    const {
        preGlobalFilteredRows,
        setGlobalFilter,
        globalFilter,
    } = props
    return (
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
            />
    )
}

TableToolbar.propTypes = {
    setGlobalFilter: PropTypes.func.isRequired,
    preGlobalFilteredRows: PropTypes.array.isRequired,
    globalFilter: PropTypes.string.isRequired,
}

export default TableToolbar
