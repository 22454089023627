import React, { Component } from 'react';
import { Col, Row, Container} from 'react-bootstrap';
import ContentLayout from "../components/ContentLayout";
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import AppliancesData from '../components/DataTable/AppliancesData';
import { connect } from 'react-redux';

import { updateSearchList, returnUpdateSearch } from '../actions/search-actions';


class Appliances extends Component {

  constructor(props) {
    super(props);
  
    this.state = {
       header: "Appliances Data"
      }
      this.props.updateSearchList([]);
      this.props.returnUpdateSearch();
  }

  render() {
    return (
      <ContentLayout>
        <Breadcrumb
          breadcrumb={[
            { name: "Home", path: "/" },
            { name: "Appliances", path: "/Appliances" }
          ]}
        />
        <div className="content invoices-contant border-top bg-grey pt-0 pb-5">
          <div className="DataTable_Area bg-grey mt-5">
            <Container fluid className="pl-5 pr-5">
              <Row>
                <Col lg="12">
                  <h2 className="title mb-4">{this.state.header}</h2>
                  <AppliancesData />
                </Col>  
              </Row>
            </Container>
          </div>
        </div>
      </ContentLayout>
    );
  }
}

const mapActionsToProps = {
    updateSearchList: updateSearchList,
    returnUpdateSearch: returnUpdateSearch
}

export default connect(null, mapActionsToProps)(Appliances);
