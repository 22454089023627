
import React, { Component } from 'react';
import { Badge, Button, ButtonToolbar, Card, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { numberformat } from '../../DateHelper';
import Moment from 'react-moment';
class InvoiceDetailOther extends Component {
    print() {
        var content = document.getElementById('printarea');
        var pri = document.getElementById('ifmcontentstoprint').contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }
    render() {
        var txt;
        if (this.props.InvoiceCust.paymentOption == 0) {
            txt = 'NotSpecified'
        }
        else if (this.props.InvoiceCust.paymentOption == 1) {
            txt = 'Prepaid'
        }
        else if (this.props.InvoiceCust.paymentOption == 2) {
            txt = 'Consignment'
        }
        else if (this.props.InvoiceCust.paymentOption == 3) {
            txt = 'Net Terms'
        }
        var Balance = parseFloat(this.props.stotal) - parseFloat(this.props.scredit) - parseFloat(this.props.spayment)
        // else (this.props.InvoiceCust.paymentOption)
        return (

            <div className="thd-content-body thd-content-body-invoice bg-white">
                <table width="100%">
                    <tr>
                        <td align="center" className="text-center">
                            <Button variant="outline-secondary" onClick={() => { this.print() }}>
                                Print
                            </Button>
                        </td>
                    </tr>
                </table>
                <Card className="border-0 rounded-0 card-invoice" id="printarea">

                    <Card.Body>
                        <table cellPadding="5" cellSpacing="0" border="0" width="100%">
                            <tr>
                                <td>
                                    <div className="border-right thd-logo">
                                        <img src={'img/thd-logo.png'} alt="THD Logo" />
                                    </div>
                                </td>
                                <td>
                                    <div className="billed-from">
                                    <div className="invoice-content">
                                        <h3 className="mb-2">{this.props.InvoiceCust.firstName}{this.props.InvoiceCust.lastName}</h3>
                                        <p>
                                            {this.props.InvoiceCust.address1},<br />
                                            {this.props.InvoiceCust.address2},
                                            {this.props.InvoiceCust.address3}<br />
                                            {this.props.InvoiceCust.city} ,
                                            {this.props.InvoiceCust.stateProvince},
                                            {this.props.InvoiceCust.country},{this.props.InvoiceCust.zipPostalCode} <br />
                                            Tel No: {this.props.InvoiceCust.phoneNumber1} <br />
                                            Email: {this.props.InvoiceCust.email}
                                        </p>
                                    </div>
                                </div>
                                </td>
                                <td>
                                    <div className="invoice-header mb-5">
                                        <h1 className="invoice-title">Invoice</h1>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <table cellPadding="5" cellSpacing="0" border="0" width="100%">
                            <tr>
                                <td width="33%">
                                    <table width="100%">
                                        <tr>
                                            <td colSpan="">
                                                <span className="">Client</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="">{this.props.InvoiceCust.company}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="">C/o {this.props.InvoiceCust.firstName}{this.props.InvoiceCust.lastName}</span>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td width="25%"></td>
                                <td>
                                    <table width="100%">
                                        <tr>
                                            <td colSpan="2">
                                                <label className="tx-gray-600"><b>Invoice Information</b></label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><span>Invoice No</span></td>
                                            <td align="right"><span className="text-right">{this.props.invoiceId}</span></td>
                                        </tr>
                                        <tr>
                                            <td style={{ borderTop: "1px dotted #9fa5ad" }}><span>Amount Due</span></td>
                                            <td style={{ borderTop: "1px dotted #9fa5ad" }} align="right"><span className="text-right">{Balance ? numberformat(Balance) : Balance}</span></td>
                                        </tr>
                                        <tr>
                                            <td style={{ borderTop: "1px dotted #9fa5ad" }}><span>Issue Date:</span></td>
                                            <td style={{ borderTop: "1px dotted #9fa5ad" }} align="right"><span className="text-right"><Moment format="MM/DD/YYYY">{this.props.Data.invoiceDate}</Moment></span></td>
                                        </tr>
                                        <tr>
                                            <td style={{ borderTop: "1px dotted #9fa5ad" }}><span>Terms:</span></td>
                                            <td style={{ borderTop: "1px dotted #9fa5ad" }} align="right"><span className="text-right">{txt}</span></td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                        <div className="mt-5">

                            <Table cellPadding="5" border="1" cellSpacing="0" width="100%" align="center" responsive size="xl" className="table-invoice">
                                <thead>
                                    <tr>
                                        <th colSpan="11" className="text-center datacol">Invoiced Items</th>
                                    </tr>
                                    <tr>
                                        <th>Facility</th>
                                        <th className="text-right">Order #</th>
                                        <th className="text-right">Description</th>
                                        <th className="text-right">ShipmentDate</th>
                                        <th className="text-right">Load Type</th>
                                        <th className="text-right">Sub Load Type</th>
                                        <th className="text-right">Unit Cost</th>
                                        <th className="text-right">Wholesale Cost</th>
                                        <th className="text-right">Rate</th>
                                        <th className="text-right">Qty</th>
                                        <th className="text-right">Price</th>
                                    </tr>
                                </thead>
                                <tbody className="pheonixes">

                                    {
                                        this.props.InvoiceList.map(otherManualInvoiceItemList => {
                                            if (!otherManualInvoiceItemList.isCredit && !otherManualInvoiceItemList.isPayment) {
                                                return (
                                                    <tr key={otherManualInvoiceItemList.id}>
                                                        <td>{otherManualInvoiceItemList.warehouse}</td>
                                                        <td className="text-right">{otherManualInvoiceItemList.orderNumber}</td>
                                                        <td className="text-right">{otherManualInvoiceItemList.description}</td>
                                                        <td className="text-right" >{otherManualInvoiceItemList.shipmentDate}</td>
                                                        <td className="text-right">{otherManualInvoiceItemList.loadType}</td>
                                                        <td className="text-right">{otherManualInvoiceItemList.subLoadType}</td>
                                                        <td className="text-right">{otherManualInvoiceItemList.unitCost ? numberformat(otherManualInvoiceItemList.unitCost) : otherManualInvoiceItemList.unitCost}</td>
                                                        <td className="text-right">{otherManualInvoiceItemList.wholesaleCost}</td>
                                                        <td className="text-right">{otherManualInvoiceItemList.rate}</td>
                                                        <td className="text-right">{otherManualInvoiceItemList.quantity}</td>
                                                        <td className="text-left">{otherManualInvoiceItemList.priceTotal ? numberformat(otherManualInvoiceItemList.priceTotal) : otherManualInvoiceItemList.priceTotal}</td>
                                                    </tr>
                                                )
                                            }
                                        })
                                    }
                                </tbody>
                                <tbody>
                                    <tr>
                                        <th colSpan="11" className="text-center datacol">Credits</th>
                                    </tr>
                                    <tr>
                                        <th colSpan="2" className="text-left">Order #</th>
                                        <th colSpan="2" className="text-left">Description</th>
                                        <th colSpan="2" className="text-left">Credit Reason</th>
                                        <th colSpan="2" className="text-left">Credit Issue Date</th>
                                        <th colSpan="3" className="text-left">Credit Total</th>
                                    </tr>
                                </tbody>
                                <tbody className="pheonixes">
                                    {this.props.InvoiceList.map(otherManualInvoiceItemList => {
                                        if (otherManualInvoiceItemList.isCredit) {
                                            return (

                                                <tr key={otherManualInvoiceItemList.id}>
                                                    <td colSpan="2">{otherManualInvoiceItemList.orderNumber}</td>
                                                    <td colSpan="2" className="text-left">{otherManualInvoiceItemList.description}</td>
                                                    <td colSpan="2" className="text-left">{otherManualInvoiceItemList.creditReason}</td>
                                                    <td colSpan="2" className="text-left">{otherManualInvoiceItemList.shipmentDate}</td>
                                                    <td colSpan="2" className="text-left">{otherManualInvoiceItemList.priceTotal ? numberformat(otherManualInvoiceItemList.priceTotal) : otherManualInvoiceItemList.priceTotal}</td>
                                                </tr>

                                            )
                                        }
                                    })}
                                </tbody>
                                <tbody>

                                    <tr>
                                        <th colSpan="11" className="text-center datacol">Payments Applied</th>
                                    </tr>
                                    <tr>
                                        <th colSpan="5" className="text-center">Payment Date</th>

                                        <th className="text-center" colSpan="6" >Payment Amount</th>
                                    </tr>
                                </tbody>

                                <tbody className="pheonixes">
                                    {this.props.InvoiceList.map(otherManualInvoiceItemList => {
                                        if (otherManualInvoiceItemList.isPayment) {
                                            return (

                                                <tr key={otherManualInvoiceItemList.id}>
                                                    <td colSpan="5" className="text-center">{otherManualInvoiceItemList.shipmentDate}</td>

                                                    <td className="text-center" colSpan="6">{otherManualInvoiceItemList.priceTotal ? numberformat(otherManualInvoiceItemList.priceTotal) : otherManualInvoiceItemList.priceTotal}</td>
                                                </tr>

                                            )
                                        }
                                    })}
                                </tbody>
                                <tbody>

                                    <tr>
                                        <th colSpan="11" className="text-center datacol" >Summary</th>
                                    </tr>
                                    <tr className="data">
                                        <td colSpan="8" className="blank">
                                            PLEASE ACH OR WIRE PAYMENT TO THE HOME DEPOT:
                                            </td>
                                        <td colSpan="2" className="total-line">Total Invoiced</td>
                                        {//<td colSpan="1"><div id="totalqty"></div></td-->
                                        }
                                        <td className="total-value"><div id="subtotal">{this.props.stotal ? numberformat(this.props.stotal) : this.props.stotal}</div></td>
                                    </tr>
                                    <tr className="data">
                                        <td colSpan="8" className="blank">
                                            Bank: Citibank, 111 Wall Street, New York, NY 10005
                    </td>
                                        <td colSpan="2" className="credit-line">Credits</td>

                                        <td className="total-value"><div id="totalcredits">{this.props.scredit ? numberformat(this.props.scredit) : this.props.scredit }</div></td>
                                    </tr>
                                    <tr className="data">
                                        <td colSpan="8" className="blank">
                                            Account Name: HOME DEPOTS USA INC. BRANDS DIRECT<br />
                                            Account Holder Address: 2455 Paces Ferry Rd SE, Atlanta, GA 30339
                                             </td>
                                        <td colSpan="2" className="total-line">Payments Applied</td>

                                        <td className="total-value"><div id="totalpayments">{this.props.spayment ? numberformat(this.props.spayment) : this.props.spayment}</div></td>
                                    </tr>
                                    <tr className="data">
                                        <td colSpan="8" className="blank fill" >
                                            Routing (ABA) #: 031100209  / Account #: 38741154<br /><br />
                                            Instructions: Please include Invoice/s in comments or reference field upon payment
                                              </td>
                                        <td colSpan="2" className="total-line balance">Balance Due</td>

                                        <td className="total-value balance"><div className="due">{Balance ? numberformat(Balance) : Balance}</div></td>
                                    </tr>
                                </tbody>


                            </Table>

                        </div>
                            <iframe id="ifmcontentstoprint" style={{
                                height: '0px',
                                width: '0px',
                                position: 'absolute'
                            }}></iframe>
                           
                        <hr className="mg-b-40" />
                        <div className="Thanku_Message mt-5">
                            <h6 className="mb-4">Please pay within {Balance > 0 ? this.props.InvoiceCust.netTermDays:this.props.Data.netTermDays} days. Thank you for your business.</h6>
                            <h5 className="mb-2">TERMS AND CONDITIONS</h5>
                            <div className="footer-invoice">
                                <img src={'img/thd-logo.png'} alt="THD Logo" className="float-left mr-3" />
                                <p className="float-left"> Payment Terms.  All pricing and payments are and shall be in U.S. dollars. Purchaser shall make all payments for the Salvage Goods immediately to Home Depot by electronic funds transfer or with Home Depot’s preapproval, by bank certified check. No cash transactions are accepted. NOTICE: Once you have submitted the wire payment please email salvage@homedepot.com so we can ensure your payment has been applied to the account accordingly.
                                     <br /><br />
                                        Disclaimer of Warranties. PURCHASER ACKNOWLEDGES AND AGREES THAT ALL SALVAGE GOODS ARE PURCHASED "AS IS," WITH NO WARRANTY OF ANY NATURE OR TYPE WHATSOEVER AND HEREBY DISCLAIMS ALL EXPRESS, IMPLIED, AND STATUTORY WARRANTIES, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT, EXCEPT WARRANTY OF TITLE.
                                        <br /><br />
                                        Taxes. Purchaser is responsible for all taxes, tariffs, duties and applicable expenses based on any sales transactions occurring under this Agreement. Sales taxes will be added to the purchase price unless a copy of Purchaser's sales tax exemption agreement is received by Home Depot prior to purchase.
                                        <br /><br />
                                        Title and Risk of Loss. Title and risk of loss shall pass to Purchaser at the applicable Home Depot Repair and Liquidation Center. Purchaser is solely responsible for arranging for shipping and transportation and any further sale of all Salvage Goods, including, without limitation, shipping document preparation, proper packaging and labeling, and compliance with all laws, rules and regulations related thereto, including, without limitation, those relating to exporting  Any claims for shortage of Salvage Goods shipped must be received by Home Depot no more than (10) calendar days following the date of shipment. Home Depot shall have no responsibilities or liability for any such claims received after such ten (10) day period. Any claim for shortage of Salvage Goods must be received by Home Depot in writing within ten (10) calendar days of the date of shipment.
                                        <br /><br />
                                </p>

                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <table width="100%">
                    <tr>
                        <td align="center" className="text-center">
                            <Button variant="outline-secondary" onClick={() => { this.print() }}>
                                Print
                            </Button>
                        </td>
                    </tr>
                </table>
            </div>


        )
    }
}
export default InvoiceDetailOther;