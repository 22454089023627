import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService';
import { Badge, Button, ButtonToolbar, Card, Col, Container, Modal, Form, Row, Table } from 'react-bootstrap';
import SyncLoader from "react-spinners/SyncLoader";
import CssBaseline from '@material-ui/core/CssBaseline'
import ReactTable from './ReactTable'
import { CSVLink } from "react-csv";
import { numberformat } from '../../DateHelper';
import InvoiceDetailSalvageSales from '../Invoice/InvoiceDetailSalvageSales';
import InvoiceDetailSalvageSalesTruck from '../Invoice/InvoiceDetailSalvageSalesTruck';
import InvoiceDetailBerOrTurbo from '../Invoice/InvoiceDetailBerOrTurbo';
import InvoiceDetailOther from '../Invoice/InvoiceDetailOther';
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 2 auto;
  border-color: red;
`;
const style = { position: "fixed", top: "45%", left: "50%", transform: "translate(-50%, -50%)" };

class AddInvoice extends Component {
    constructor(props) {
        super(props) //since we are extending class Table so we have to use super in order to override Component class constructor
        this.state = {
            invoiceDetail: [], data: '', loading: true, orderdata: [], orderDetail: [], orderDetailDate: [], show: false, showdocument: false, visible: false, showInvoice: false, showInvoiceRequest: false, customerDetail: {}, thd: {
                invoiceCustomer: {}
            }, loading: true, total: "", credit: "", payment: "", selectedradioOption: "",
            OtherInvoiceDetailsById: [], SalvageinvoiceItemList: [], Salvageinvoicecredit: [], BerTurboInvoiceDetailsById: [], InvoiceList: [], InvoiceDetailsById: []

        }
        this.onValueChange = this.onValueChange.bind(this);
    }
    handleClosee = () => {
        this.setState({ visible: false });
    }
    componentDidMount() {
        this.populateAddInvoice(this.props.cId, this.props.CreditRemainingAmount);
    }
    async populateAddInvoice(customerID, CreditRemainingAmount) {
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/GetInvoicesByCustomerId?customerId=' + customerID + '&creditRequestRemainingAmount=' + CreditRemainingAmount, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ invoiceDetail: data, loading: false });

    }
    getInvoiceId = (e) => {
        return e.original.invoiceNumber;
    }

    invoiceCredit(Invoice, invoiceType,isTruck) {
        this.setState({
            Invoice: Invoice, invoiceType: invoiceType, isTruck: isTruck
        });
        if (invoiceType == "ARMInvoice" && isTruck == false) {
            this.SalvageInvoiceDetailsById(Invoice);
        }
        else if (invoiceType == "ARMInvoice" && isTruck == true) {
            this.SalvageInvoiceDetailsByIdTruck(Invoice);
        }
        else if (invoiceType == "BER" || invoiceType == "Turbo") {
            this.BERInvoiceDetailsById(Invoice);
        }
        else
            this.OtherInvoiceDetailsById(Invoice);

        this.setState({ visible: true });
    }

    onValueChange(row) {
        this.setState({
            selectedradioOption: row.original.id
        });
        this.props.goto(row.original.id, row.original.invoiceType)

    }

    render() {
        const columns = [
            {
                id: "checkbox",
                accessor: "",
                Cell: ({ row }) => {
                    return (
                        <input
                            type="radio"
                            value={row.original.id}
                            checked={this.state.selectedradioOption == row.original.id}
                            onChange={() => this.onValueChange(row)}
                        />
                    );
                }
            },
            {
                Header: 'InvoiceNumber',
                accessor: 'invoiceNumber',
                Cell: ({ row }) => (
                    <div>
                        <a style={{ cursor: 'pointer', color: 'blue' }}
                            onClick={() => this.invoiceCredit(this.getInvoiceId(row), row.original.invoiceType,row.original.isTruck)}>
                            {this.getInvoiceId(row)}
                        </a>
                    </div>
                )
            },
            {
                Header: 'Invoice Type',
                accessor: 'invoiceType',
            },
            {
                Header: 'Invoice Amount',
                accessor: 'invoiceAmount',
            },
            {
                Header: 'Credits Applied',
                accessor: 'creditsApplied',
            },
            {
                Header: 'Payments Applied',
                accessor: 'paymentsApplied',
            },
            {
                Header: 'Invoice Status',
                accessor: 'invoiceStatus',

            },
            {
                Header: 'Credits Applied',
                accessor: 'invoiceBalance',

            },

        ]
        return (
            <div>
                <div style={style}>
                    <SyncLoader
                        css={override}
                        size={15}
                        color={"#fd7e14"}
                        loading={this.state.loading}
                    />
                </div>
                <ReactTable
                    columns={columns}
                    data={this.state.invoiceDetail}
                />

                <Modal centered size="xl" show={this.state.visible} onHide={this.handleClosee} className="sb-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                        {this.state.invoiceType == 'ARMInvoice' && this.state.isTruck!=true?
                            <InvoiceDetailSalvageSales invoiceId={this.state.Invoice} invoiceStatus={this.state.invoiceStatus} InvoiceCust={this.state.thd.invoiceCustomer} InvoiceList={this.state.SalvageinvoiceItemList} Data={this.state.thd} stotal={this.state.total} scredit={this.state.credit} spayment={this.state.payment} />
                            : this.state.invoiceType == 'ARMInvoice' && this.state.isTruck == true ?
                             <InvoiceDetailSalvageSalesTruck invoiceId={this.state.invoiceId} invoiceStatus={this.state.invoiceStatus} InvoiceCust={this.state.thd.invoiceCustomer} InvoiceList={this.state.SalvageinvoiceItemList} InvoiceListTruck={this.state.Salvageinvoicecredit} Data={this.state.thd} stotal={this.state.total} scredit={this.state.credit} spayment={this.state.payment} />
                            : this.state.invoiceType == "BER" || this.state.invoiceType == "Turbo" ?
                                <InvoiceDetailBerOrTurbo invoiceId={this.state.Invoice} invoiceType={this.state.invoiceType} invoiceStatus={this.state.invoiceStatus} InvoiceCust={this.state.thd.invoiceCustomer} InvoiceList={this.state.BerTurboInvoiceDetailsById} Data={this.state.thd} stotal={this.state.total} scredit={this.state.credit} spayment={this.state.payment} />
                                : this.state.invoiceType == 'Other' ?
                                    < InvoiceDetailOther invoiceId={this.state.Invoice} invoiceStatus={this.state.invoiceStatus} InvoiceCust={this.state.thd.invoiceCustomer} InvoiceList={this.state.OtherInvoiceDetailsById} Data={this.state.thd} stotal={this.state.total} scredit={this.state.credit} spayment={this.state.payment} />
                                    : null}
                    </Modal.Header>
                    <Modal.Body>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }

    async BERInvoiceDetailsById(invoiceId) {
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/BEROrTurboManualInvoice?id=' + invoiceId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        var subtotal = 0.0;
        var totalcredits = 0.0;
        var totalpayments = 0.0;
        if (data.berOrTurboManualInvoiceItemList != null && data.berOrTurboManualInvoiceItemList != "") {
            for (var j = 0; j < data.berOrTurboManualInvoiceItemList.length; j++) {
                if (!data.berOrTurboManualInvoiceItemList[j].isCredit && !data.berOrTurboManualInvoiceItemList[j].isPayment) {
                    subtotal += parseInt(data.berOrTurboManualInvoiceItemList[j].priceTotal)
                }
                if (data.berOrTurboManualInvoiceItemList[j].isCredit) {
                    totalcredits += parseInt(data.berOrTurboManualInvoiceItemList[j].priceTotal)
                }
                if (data.berOrTurboManualInvoiceItemList[j].isPayment) {
                    totalpayments += parseInt(data.berOrTurboManualInvoiceItemList[j].priceTotal)
                }
            }
            subtotal = subtotal.toFixed(2);
            totalcredits = Math.abs(totalcredits.toFixed(2));
            totalpayments = Math.abs(totalpayments.toFixed(2));
        }
        this.setState({ thd: data, loading: false });
        this.setState({ customerDetail: data.invoiceCustomer, loading: false });
        this.setState({ BerTurboInvoiceDetailsById: data.berOrTurboManualInvoiceItemList, total: subtotal, credit: totalcredits, payment: totalpayments, loading: false });
    }
    async SalvageInvoiceDetailsById(invoiceId) {
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/Invoice?id=' + invoiceId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        var subtotal = 0.0;
        var totalcredits = 0.0;
        var totalpayments = 0.0;
        if (data.invoiceItemList != null && data.invoiceItemList != "") {
            for (var j = 0; j < data.invoiceItemList.length; j++) {
                if (!data.invoiceItemList[j].isCredit && !data.invoiceItemList[j].isPayment) {
                    subtotal += parseFloat(data.invoiceItemList[j].unitCost) * parseFloat(data.invoiceItemList[j].quantity)
                }
                if (data.invoiceItemList[j].isCredit) {
                    totalcredits += parseFloat(data.invoiceItemList[j].unitCost) * parseFloat(data.invoiceItemList[j].quantity)
                }
                if (data.invoiceItemList[j].isPayment) {
                    totalpayments += parseFloat(data.invoiceItemList[j].paymentAmount)
                }
            }
            subtotal = subtotal.toFixed(2);
            totalcredits = Math.abs(totalcredits.toFixed(2));
            totalpayments = Math.abs(totalpayments.toFixed(2));
        }
        this.setState({ thd: data, loading: false });
        this.setState({ customerDetail: data.invoiceCustomer, loading: false });
        this.setState({ SalvageinvoiceItemList: data.invoiceItemList, total: subtotal, credit: totalcredits, payment: totalpayments, loading: false });
    }

    async SalvageInvoiceDetailsByIdTruck(invoiceId) {
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/InvoiceTruck?id=' + invoiceId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        var subtotal = 0.0;
        var totalcredits = 0.0;
        var totalpayments = 0.0;
        if (data.invoiceItemList != null && data.invoiceItemList != "") {
            for (var j = 0; j < data.invoiceItemList.length; j++) {
                if (!data.invoiceItemList[j].isCredit && !data.invoiceItemList[j].isPayment) {
                    if (data.invoiceItemList[j].truckLoadOrderamount == 0) {
                        var quant = (data.invoiceItemList[j].quantity / 100);
                        subtotal += parseFloat(data.invoiceItemList[j].unitCost) * parseFloat(quant)
                    }
                    else {
                        var unitcosttotal = data.invoiceItemList[j].unitCost;
                        var quant = (data.invoiceItemList[j].new_BuyitNow_Rate / 100);
                        subtotal += parseFloat(unitcosttotal) * parseFloat(quant)
                    }
                }
                //if (data.invoiceItemList[j].isCredit) {
                //    totalcredits += parseFloat(data.invoiceItemList[j].unitCost) * parseFloat(data.invoiceItemList[j].quantity)
                //}
                //if (data.invoiceItemList[j].isPayment) {
                //    totalpayments += parseFloat(data.invoiceItemList[j].paymentAmount)
                //}
            }
            subtotal = subtotal.toFixed(2);
            //totalcredits = Math.abs(totalcredits.toFixed(2));
            //totalpayments = Math.abs(totalpayments.toFixed(2));
        }

        if (data.invoiceItemListTruck != null && data.invoiceItemListTruck != "") {
            for (var j = 0; j < data.invoiceItemListTruck.length; j++) {

                if (data.invoiceItemListTruck[j].isCredit) {
                    totalcredits += parseFloat(data.invoiceItemListTruck[j].unitCost) * parseFloat(data.invoiceItemListTruck[j].quantity)
                }
                if (data.invoiceItemListTruck[j].isPayment) {
                    totalpayments += parseFloat(data.invoiceItemListTruck[j].paymentAmount)
                }
            }

            totalcredits = Math.abs(totalcredits.toFixed(2));
            totalpayments = Math.abs(totalpayments.toFixed(2));
        }

        this.setState({ thd: data, loading: false });
        this.setState({ customerDetail: data.invoiceCustomer, loading: false });
        this.setState({ SalvageinvoiceItemList: data.invoiceItemList, Salvageinvoicecredit: data.invoiceItemListTruck, total: subtotal, credit: totalcredits, payment: totalpayments, loading: false });
    }

    async OtherInvoiceDetailsById(invoiceId) {
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/OtherManualInvoice?id=' + invoiceId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        var subtotal = 0.0;
        var totalcredits = 0.0;
        var totalpayments = 0.0;
        if (data.otherManualInvoiceItemList != null && data.otherManualInvoiceItemList != "") {
            for (var j = 0; j < data.otherManualInvoiceItemList.length; j++) {
                if (!data.otherManualInvoiceItemList[j].isCredit && !data.otherManualInvoiceItemList[j].isPayment) {
                    subtotal += parseInt(data.otherManualInvoiceItemList[j].priceTotal)
                }
                if (data.otherManualInvoiceItemList[j].isCredit) {
                    totalcredits += parseInt(data.otherManualInvoiceItemList[j].priceTotal)
                }
                if (data.otherManualInvoiceItemList[j].isPayment) {
                    totalpayments += parseInt(data.otherManualInvoiceItemList[j].priceTotal)
                }
            }
            subtotal = subtotal.toFixed(2);
            totalcredits = Math.abs(totalcredits.toFixed(2));
            totalpayments = Math.abs(totalpayments.toFixed(2));
        }
        this.setState({ thd: data, loading: false });
        this.setState({ customerDetail: data.invoiceCustomer, loading: false });
        this.setState({ OtherInvoiceDetailsById: data.otherManualInvoiceItemList, total: subtotal, credit: totalcredits, payment: totalpayments, loading: false });
    }
}

export default AddInvoice