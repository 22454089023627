import 'bootstrap/dist/css/bootstrap.css';
import createHistory from 'history/createBrowserHistory';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { cartReducer, cartDataReducer, orderReducer } from './reducers/cart-reducers';
import { searchListReducer, searchListReducert, serachTextReducer, returnSerachTextReducer, clearTextReducer, searchOrderListReducer, serachOrderTextReducer, returnSerachOrderTextReducer } from './reducers/search-reducer'
import { dialogReducer } from './reducers/dialog-reducer';
import { fetchFilterReducer } from './reducers/filter-reducers';
import { closeDialog, openDialog } from './actions/dialog-actions';
import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import App from './App';
import { DIALOG_ACTION_CLEARCART } from './constants';
//import registerServiceWorker from './registerServiceWorker';

const history = createHistory();
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const allReducers = combineReducers({
    cart: cartReducer,
    cartData: cartDataReducer,
    dialog: dialogReducer,
    orders: orderReducer,
    searchList: searchListReducer,
    searchListt: searchListReducert,
    searchText: serachTextReducer,
    returnSearchText: returnSerachTextReducer,
    clearSearch: clearTextReducer,
    searchOrderList: searchOrderListReducer,
    searchOrderText: serachOrderTextReducer,
    returnSearchOrderText: returnSerachOrderTextReducer,
    fetchFilters : fetchFilterReducer

});

const allStoreEnhancers = compose(
    applyMiddleware(thunk)
);

let store = createStore(allReducers, allStoreEnhancers);

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <Provider store={store}>
            <App history={history} />
        </Provider>
  </BrowserRouter>,
  rootElement);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();

