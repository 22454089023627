import React, { useEffect } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import MaUTable from "@material-ui/core/Table";
import PropTypes from "prop-types";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "./TablePaginationActions";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import matchSorter from "match-sorter";
import TableToolbar from "./TableToolbar";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useFilters,
  useTable,
} from "react-table";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const inputStyle = {
  padding: 0,
  margin: 0,
  border: 0,
  background: "transparent",
};

// Create an editable cell renderer
//const EditableCell = ({
//    cell: { value: initialValue },
//    row: { index },
//    column: { id },
//    //updateMyData, // This is a custom function that we supplied to our table instance
//}) => {
//    // We need to keep and update the state of the cell normally
//    const [value, setValue] = React.useState(initialValue)

//    // We'll only update the external data when the input is blurred
//    //const onBlur = () => {
//    //    updateMyData(index, id, value)
//    //}

//    // If the initialValue is changed externall, sync it up with our state
//    React.useEffect(() => {
//        setValue(initialValue)
//    }, [initialValue])

//    return (
//        <input
//            style={inputStyle}
//            value={value}
//        />
//    )
//}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      //placeholder= {` ${count} records...`}
      style={{
        width: "75%",
      }}
    />
  );
}

//EditableCell.propTypes = {
//    cell: PropTypes.shape({
//        value: PropTypes.any.isRequired,
//    }),
//    row: PropTypes.shape({
//        index: PropTypes.number.isRequired,
//    }),
//    column: PropTypes.shape({
//        id: PropTypes.number.isRequired,
//    }),
//    //updateMyData: PropTypes.func.isRequired,
//}

// Set our editable cell renderer as the default Cell renderer
//const defaultColumn = {
//    //Cell: EditableCell,
//}
function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

const EnhancedTable = ({
  columns,
  data,
  setData,
  handleclick,
  //updateMyData,
  skipPageReset,
}) => {
  const [sorting, setSorting] = React.useState([]);
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter, sortBy },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: 10000,
        sortBy: sorting,
      },
      filterTypes,
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      //updateMyData,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.allColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox.  Pagination is a problem since this will select all
          // rows even though not all rows are on the current page.  The solution should
          // be server side pagination.  For one, the clients should not download all
          // rows in most cases.  The client should only download data for the current page.
          // In that case, getToggleAllRowsSelectedProps works fine.
          //Header: ({ getToggleAllRowsSelectedProps }) => (
          //    <div>
          //        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          //    </div>
          //),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          //Cell: ({ row }) => (
          //    <div>
          //        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()}
          //            onChange={() => this.handleclick(row)}
          //            {...Checkbox.row}
          //        />
          //    </div>
          //),
        },
        ...columns,
      ]);
      handleclick = (rowData) => {
        this.setState({
          sbNumber: rowData.original.sbNumber,
          blNumber: rowData.original.blNumber,
          warehouse: rowData.original.warehouse,
          salvageCategory: rowData.original.salvageCategory,
          containerQuantity: rowData.original.containerQuantity,
          show: true,
          loading: true,
        });
      };
    }
  );

  useEffect(() => {
    setSorting(sortBy);
  }, [sortBy]);

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };

  //const removeByIndexs = (array, indexs) =>
  //    array.filter((_, i) => !indexs.includes(i))

  //const deleteUserHandler = event => {
  //    const newData = removeByIndexs(
  //        data,
  //        Object.keys(selectedRowIds).map(x => parseInt(x, 10))
  //    )
  //    setData(newData)
  //}

  //const addUserHandler = user => {
  //    const newData = data.concat([user])
  //    setData(newData)
  //}

  // Render the UI for your table
  return (
    <TableContainer>
      {
        //<TableToolbar
        //    //numSelected={Object.keys(selectedRowIds).length}
        //    preGlobalFilteredRows={preGlobalFilteredRows}
        //    setGlobalFilter={setGlobalFilter}
        //    globalFilter={globalFilter}
        ///>
      }
      {
        //   <input type="checkbox" style={{ marginLeft: "45px", colSpan: "2" }} />
        // <label>HD.COM</label>
      }
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...(column.id === "selection"
                    ? column.getHeaderProps()
                    : column.getHeaderProps(column.getSortByToggleProps()))}
                >
                  {column.render("Header")}

                  {column.id !== "selection" ? (
                    <TableSortLabel
                      active={column.isSorted}
                      // react-table has a unsorted state which is not treated here
                      direction={column.isSortedDesc ? "desc" : "asc"}
                    />
                  ) : null}
                  {
                    //<div>{column.canFilter ? column.render("Filter") : null}</div>
                  }
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: 10000 }]}
              colSpan={0}
              count={data.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </MaUTable>
    </TableContainer>
  );
};

EnhancedTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  //updateMyData: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  skipPageReset: PropTypes.bool.isRequired,
};

export default EnhancedTable;
