import React, { Component } from "react";
//import "../css/jquery.dataTables.min.css";
import moment from "moment";
import { Col, Row, Form, Button, Card, ButtonToolbar, Accordion, Container, Table } from 'react-bootstrap';
import authService from '../api-authorization/AuthorizeService';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import InventoryDetail from './InventoryDetail';
import Timmer from './Timmerstandard';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline'
import Tooltip, { tooltipClasses } from '@material-ui/core/Tooltip'
import EnhancedTable from './EnhancedTable'
import ViewCart from "../../views/View-Cart";
import { css } from "@emotion/core";
import InvoiceData from "../../views/invoiceData";
import SyncLoader from "react-spinners/SyncLoader";
import { CSVLink } from "react-csv";
import FilterResults from 'react-filter-search';
import matchSorter from 'match-sorter'
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import NumberFormat from 'react-number-format';
import { numberformat, Rateformat, calculatedynamic } from "../../DateHelper";
import { openDialog } from '../../actions/dialog-actions';
import { fetchOrders } from '../../actions/cart-actions';
import { updateCartData, updateViewCart } from '../../actions/cart-actions';
import { FetchInventoryFilters } from "../../actions/filter-actions";
import { DIALOG_ACTION_CLOSE } from '../../constants';
import Snackbar from '@material-ui/core/Snackbar';
import Checkbox from '@material-ui/core/Checkbox';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'
import styled from 'styled-components'
import { updateClearSearch } from "../../actions/search-actions";
import { red } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import { commonErrorHandler } from '../../Utils'
const StyledLoader = styled(LoadingOverlay)`
  width: auto;
  height: auto;
  .MyLoader_overlay {
  }
`
const styles = {
    snackbarStyleViaContentProps: {
        backgroundColor: "#f44336"
    },
    snackbarStyleViaNestedContent: {
        backgroundColor: "#d4edda",
        color: "black"
    },
    snackbarStyleViaNestedContente: {
        backgroundColor: "#ff9800",
        color: "white"
    }
};
const override = css`
  display: block;
  margin: 2 auto;
  border-color: red;
`;
const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        fontSize: '1.15em'
    },
}))(Tooltip);
const style = { position: "fixed", top: "45%", left: "50%", transform: "translate(-50%, -50%)" };
class InventoryBidDT extends Component {
    constructor(props) {
        super(props);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handlecloseTimervalue = this.handlecloseTimervalue.bind(this);
        this.handleBuynowclose = this.handleBuynowclose.bind(this);
        this.handleHighestBuynowclose = this.handleHighestBuynowclose.bind(this);

        this.handleBuynowConfirmationclose = this.handleBuynowConfirmationclose.bind(this);
       
        this.handleBuynowpopup = this.handleBuynowpopup.bind(this);

        this.handleHightestBuynowpopup = this.handleHightestBuynowpopup.bind(this);


        this.handleHighestBuynowConfirmationclose = this.handleHighestBuynowConfirmationclose.bind(this);

        this.handleCloseaddproxybid = this.handleCloseaddproxybid.bind(this);
        this.handleConfirmAddProxyBidBoxClose = this.handleConfirmAddProxyBidBoxClose.bind(this);
        this.handleCloseproxybidinformation = this.handleCloseproxybidinformation.bind(this);
        this.handleCloseMakeoffer = this.handleCloseMakeoffer.bind(this);
        this.handleCloselatestoffer = this.handleCloselatestoffer.bind(this);
        this.handleConfirmBoxclose = this.handleConfirmBoxclose.bind(this);
        this.handlecloseBidHistory = this.handlecloseBidHistory.bind(this);
        // this.intervalID;
        this.state = {name:'',
            inventorydata: [], gettimedataa: [], gettimedata: [],selected: false, checked: [], snackbarOpen: false, snackbaruncheck: false, snackbarUnavailableSB: false, show: false, checked: false, IsClosed: false,
            Category: this.props.data, Warehouse: this.props.warehouseid, AllLocation: this.props.allLocation, selectSB: false, selectAllCheck: false,
            TotalsBySb: "", loading: false, CheckedData: {}, value: '', sb: '', bl: '', warehouse: '', category: '', type: '', MaximumPallet: '',
            searchText: "", fieldName: "", inventoryOriginalData: [], /*AllInventryClear: [],*/ AllCategoryClear: [], AllCategory: [], categoryList: [], filterList: [
                {
                    id: 8615,
                    name: "INDIANAPOLIS",
                    value: "Indianapolis"
                },
                {
                    id: 8616,
                    name: "MCDONOUGH",
                    value: "McDonough"
                },
                {
                    id: 8617,
                    name: "PHOENIX",
                    value: "Phoenix"
                },
                {
                    id: 8618,
                    name: "PITTSTON",
                    value: "Pittston"
                }
            ],
            inventoryType: [
                {
                    name: "HD.COM",
                    value: "HD.COM"
                },
                {
                    name: "STOCK",
                    value: "STOCK"
                }],
            DemoCategories: [
                {
                    caption: "BLINDS",
                    name: "BLINDS"
                },
                {
                    caption: "HOLIDAY",
                    name: "HOLIDAY"
                },
                {
                    caption: "PLUMBING",
                    name: "PLUMBING"
                },
                {
                    caption: "001",
                    name: "001"
                },
                {
                    caption: "MIXED LOAD",
                    name: "MIXED LOAD"
                },
                {
                    caption: "APPLIANCES",
                    name: "APPLIANCES"
                },
                {
                    caption: "DOORS AND WINDOWS",
                    name: "DOORS AND WINDOWS"
                },
                {
                    caption: "FLOORING AND RUGS",
                    name: "FLOORING AND RUGS"
                },
                {
                    caption: "HARDWARE AND TOOLS",
                    name: "HARDWARE AND TOOLS"
                },
                {
                    caption: "OUTDOOR SEASONAL",
                    name: "OUTDOOR SEASONAL"
                },
                {
                    caption: "KITCHEN AND BATH",
                    name: "KITCHEN AND BATH"
                },
                {
                    caption: "LIGHTING",
                    name: "LIGHTING"
                },
                {
                    caption: "HOME DECORS",
                    name: "HOME DECOR"
                }
            ]
        };
    }
    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    handleClose() {
        this.setState({ show: false });
    }
    snackBarClose = (event) => {
        this.setState({ snackbarOpen: false, snackbaruncheck: false, snackbarUnavailableSB: false });
    }

    handleShow() {
        this.setState({ show: true });
    }
    clearFilter = () => {
        this.props.setClearSearch();
        let categoryList = this.state.AllCategoryClear;
        for (var i = 0; i < categoryList.length; i++) {
            categoryList[i].checked = false;
        }
        let filterList = this.state.filterList;
        for (var i = 0; i < filterList.length; i++) {
            if (filterList[i].checked)
                filterList[i].checked = false;
        }
        let TypeList = this.state.inventoryType;
        for (var i = 0; i < TypeList.length; i++) {
            if (TypeList[i].checked)
                TypeList[i].checked = false;
        }
        let hdChecked = this.state.hdChecked
        let inventoryOriginalData = this.state.inventoryOriginalData;/*this.state.AllInventryClear;*/
        this.setState({ categoryList: categoryList, AllCategory: categoryList, inventoryType: TypeList, filterList: filterList, hdChecked: hdChecked, inventorydata: inventoryOriginalData, Category: "", Warehouse: "", selectAllCheck: false });
        this.setState({ searchText: "", fieldName: "" });
        this.props.fetchFilters(TypeList, categoryList, filterList);
    }

    componentDidUpdate(prevProps) {
        if (this.props.search.hdChecked != prevProps.search.hdChecked) {
            this.populateInventoryData();
        }
        if (this.props.CompleteOrder != prevProps.CompleteOrder) {
            this.setState({
                TotalsBySb: ""
            });
        }
        if (this.props.search.searchText != prevProps.search.searchText || this.props.search.fieldName != prevProps.search.fieldName) {
            var filter = [];

            // get Inventory data according to filter by selected type,location and category 
            let inventoryOriginalData = this.getFilterData();

            if (this.props.search.fieldName == "salvageCategory" && (this.props.search.index != undefined || this.props.search.searchText != "")) {
                this.getDataBySearch(this.props.search.searchText.toUpperCase(), this.props.search.fieldName);
            }
            else if (this.props.search.fieldName != "salvageCategory") {
                //let inventoryOriginalData = this.state.inventorydata;
                if (this.props.search.searchText != "" && this.props.search.fieldName === "Select") {
                    filter = this.search(inventoryOriginalData, this.props.search.searchText)
                } else if (this.props.search.searchText != "" && this.props.search.fieldName != "Select") {
                    if (this.props.search.fieldName === "containerQuantity") {
                        filter = inventoryOriginalData.filter(a => a[this.props.search.fieldName] == this.props.search.searchText);
                    }
                    else
                        filter = inventoryOriginalData.filter(a => a[this.props.search.fieldName].toLowerCase().includes(this.props.search.searchText.toLowerCase()));
                } else {
                    filter = inventoryOriginalData;
                }
                this.setState({
                    inventorydata: filter,
                    searchText: this.props.search.searchText,
                    fieldName: this.props.search.fieldName,
                    Category: ""
                });
            }
        }

    }

    componentDidMount() {
        const pageLoad = true;
        this.populateInventoryData(pageLoad);
    }
    componentWillUnmount() {

        clearTimeout(this.intervalID);
    }
    async handleChecked(id, warehouseId, pallets) {
        try {
                if (pallets > 26) {
                    this.props.openDialog(1,
                        "Alert",
                        "Manifest cannot be selected since maximum pallet per order is 26", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                }
                else {
                    this.setState({
                        selectSB: true,
                    });
                    const newSelected = Object.assign({}, this.state.selected);
                    let SelectedTotalsBySb = this.state.TotalsBySb;
                    var sameWarehouse = true;
                    var totalPalletQty = 0;
                    if (SelectedTotalsBySb != "") {
                        for (let i = 0; i < SelectedTotalsBySb.split(',').length; i++) {
                            if (SelectedTotalsBySb.split(',')[i].split('|')[9] != warehouseId) {
                                this.props.openDialog(1,
                                    "Alert",
                                    "Manifest selections must be located in the same warehouse", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                                sameWarehouse = false;
                                this.setState({
                                    selectSB: false,
                                });
                                break;
                            }
                            else {
                                if (SelectedTotalsBySb.split(',')[i].split('|')[0] == id) {
                                    newSelected[id] = false;
                                    break;
                                }
                                else
                                    newSelected[id] = true;
                            }
                            totalPalletQty += parseInt(SelectedTotalsBySb.split(',')[i].split('|')[4]);
                        }
                        totalPalletQty += parseInt(pallets);
                        if (totalPalletQty > 26) {
                            this.props.openDialog(1,
                                "Alert",
                                "Manifest cannot be selected since maximum pallet per order is 26", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                            sameWarehouse = false;
                            this.setState({
                                selectSB: false,
                            });
                        }
                    }
                    else
                        newSelected[id] = !this.state.selected[id];
                    if (sameWarehouse) {
                        if (newSelected[id]) {
                            this.callCheckedApi("add", id)

                        }
                        else {
                            this.callCheckedApi("remove", id)
                        }
                        this.setState({
                            selected: newSelected,
                        });
                    }
            }
        } catch (e) {

        }


    }
    handleclick = (rowData) => {
        this.setState({
            sbNumber: rowData.original.sbNumber, blNumber: rowData.original.blNumber, warehouse: rowData.original.warehouse, salvageCategory: rowData.original.salvageCategory,
            containerQuantity: rowData.original.containerQuantity, warehouseId: rowData.original.warehouseId, show: true, loading: true
        });
        this.setState({ show: true });


    }
    getSBNumber = (e) => {
        return e.original.sbNumber;
    }
    getFilterData() {
        let inventoryOriginalData = this.state.inventoryOriginalData;
        let select, selectCat, selectType;
        let filterList, categoryList, inventoryType;
        filterList = this.state.filterList;
        categoryList = this.state.categoryList;
        inventoryType = this.state.inventoryType;
        select = this.state.filterList.filter(item => item.checked);
        selectCat = this.state.categoryList.filter(item => item.checked);
        selectType = this.state.inventoryType.filter(item => item.checked);
        if ((selectType.length === 0 || selectType.length === inventoryType.length) && (select.length === 0 || select.length === filterList.length) && (selectCat.length === 0 || selectCat === categoryList.length)) {
            inventoryOriginalData = this.state.inventoryOriginalData;
        }
        else {
            if (selectType.length != 0) {
                inventoryOriginalData = inventoryOriginalData.filter(o1 => selectType.some(o2 => o1.hdDotCom.toUpperCase() === o2.value.toUpperCase()));
            }
            if (select.length != 0) {
                inventoryOriginalData = inventoryOriginalData.filter(o1 => select.some(o2 => o1.warehouseId === o2.id));
            }
            if (selectCat.length != 0) {
                inventoryOriginalData = inventoryOriginalData.filter(o1 => selectCat.some(o2 => o1.salvageCategory === o2.caption));
            }
        }
        return inventoryOriginalData;
    }
    getDataBySearch(categoryName, searchField) {
        let inventoryOriginalData = this.state.inventoryOriginalData;
        let AllInventoryData = inventoryOriginalData;
        let selectCat;
        let filterList, categoryList, inventoryType, newCategory, stateCtegory;
        filterList = this.state.filterList;
        stateCtegory = this.state.categoryList;
        categoryList = this.state.AllCategoryClear;
        inventoryType = this.state.inventoryType;

        // bind and check category according to select
        newCategory = categoryList;
        let cnt = 0;
        for (let i = 0; i < newCategory.length; i++) {
            let cnt = 0;
            for (let j = 0; j < stateCtegory.length; j++) {
                if (newCategory[i].caption.toUpperCase() == categoryName || (newCategory[i].caption.toUpperCase() == stateCtegory[j].caption.toUpperCase() && stateCtegory[j].checked)) {
                    newCategory[i].checked = true;
                    cnt++;
                }
            }
            if (cnt == 0)
                newCategory[i].checked = false;
        }
        this.setState({
            categoryList: newCategory,
        })
        selectCat = newCategory.filter(item => item.checked);
        if (selectCat.length != 0) {
            inventoryOriginalData = inventoryOriginalData.filter(o1 => selectCat.some(o2 => o1.salvageCategory === o2.caption));
        }
        if (selectCat.length != 0) {
            for (var i = 0; i < filterList.length; i++) {
                filterList[i].checked = false;
                for (var j = 0; j < inventoryOriginalData.length; j++) {
                    if (filterList[i].name.toUpperCase() == inventoryOriginalData[j].warehouse.toUpperCase())
                        filterList[i].checked = true;
                }
            }
            for (var i = 0; i < inventoryType.length; i++) {
                inventoryType[i].checked = false;
                for (var j = 0; j < inventoryOriginalData.length; j++) {
                    if (inventoryType[i].name.toUpperCase() == inventoryOriginalData[j].hdDotCom.toUpperCase())
                        inventoryType[i].checked = true;
                }
            }
            // function for select categoryList on inventoryType and Warehouse for selected category
            categoryList = getCategoryListByCategory(AllInventoryData, filterList, inventoryType, "", selectCat)
        }

        this.setState({
            inventorydata: inventoryOriginalData,
            categoryList: categoryList,
            filterList: filterList,
            inventoryType: inventoryType,
            searchText: categoryName,
            fieldName: searchField,
            Category: ""
        });
    }
    search = (data, search) => {
        var obj = [], index = 0;
        for (var i = 0; i < data.length; i++) {
            for (var key in data[i]) {
                if (data[i][key] && data[i][key].toString().toLowerCase().includes(search.toLowerCase())) {
                    obj[index] = data[i];
                    index++;
                    break;
                }
            }
        }
        return obj;
    }
    onToggle(index, e) {
        this.setState({ Warehouse: '', Category: '' });
        let inventoryOriginalData = this.state.inventoryOriginalData;
        let AllInventoryData = inventoryOriginalData;
        let select, selectCat, selectType;
        let filterList, categoryList, inventoryType, newItems, newCategory, newType;
        filterList = this.state.filterList;
        categoryList = this.state.categoryList;
        inventoryType = this.state.inventoryType;
        if (e == "warehouse") {
            newItems = this.state.filterList.slice();
            newItems[index].checked = !newItems[index].checked;
            this.setState({
                filterList: newItems,
            })
            select = this.state.filterList.filter(item => item.checked);
            selectCat = this.state.categoryList.filter(item => item.checked);
            selectType = this.state.inventoryType.filter(item => item.checked);
            if ((selectType.length === 0 || selectType.length === inventoryType.length) && (select.length === 0 || select.length === filterList.length) && (selectCat.length === 0 || selectCat === categoryList.length)) {
                inventoryOriginalData = this.state.inventoryOriginalData;
                categoryList = getCategoryData(inventoryOriginalData, selectCat);
            }
            else {
                if (selectType.length != 0) {
                    inventoryOriginalData = inventoryOriginalData.filter(o1 => selectType.some(o2 => o1.hdDotCom.toUpperCase() === o2.value.toUpperCase()));
                    categoryList = getCategoryData(inventoryOriginalData, selectCat);
                }
                if (select.length != 0) {
                    inventoryOriginalData = inventoryOriginalData.filter(o1 => select.some(o2 => o1.warehouseId === o2.id));
                    for (var i = 0; i < inventoryOriginalData.length; i++) {
                        for (var k = 0; k < inventoryType.length; k++) {
                            if (inventoryType[k].name.toUpperCase() == inventoryOriginalData[i].hdDotCom.toUpperCase())
                                inventoryType[k].checked = true;
                        }
                    }
                    categoryList = getCategoryData(inventoryOriginalData, selectCat);
                }
                if (select.length == 0) {
                    for (var i = 0; i < inventoryOriginalData.length; i++) {
                        for (var j = 0; j < categoryList.length; j++) {
                            if (categoryList[j].caption.toUpperCase() == inventoryOriginalData[i].salvageCategory.toUpperCase())
                                categoryList[j].checked = false;
                        }
                    }
                    selectCat = categoryList.filter(item => item.checked);
                }
                if (selectCat.length != 0 && categoryList.filter(x1 => selectCat.some(x2 => x1.caption === x2.caption)).length > 0) {
                    inventoryOriginalData = inventoryOriginalData.filter(o1 => selectCat.some(o2 => o1.salvageCategory === o2.caption));
                }
                //for (var i = 0; i < inventoryOriginalData.length; i++) {
                //    for (var j = 0; j < categoryList.length; j++) {
                //        if (categoryList[j].caption.toUpperCase() == inventoryOriginalData[i].salvageCategory.toUpperCase())
                //            categoryList[j].checked = true;
                //    }
                //}
            }

        }
        else if (e == "category") {
            newCategory = this.state.categoryList.slice();
            newCategory[index].checked = !newCategory[index].checked;
            this.setState({
                categoryList: newCategory,
            })
            select = this.state.filterList.filter(item => item.checked);
            selectCat = this.state.categoryList.filter(item => item.checked);
            selectType = this.state.inventoryType.filter(item => item.checked);
            if ((selectType.length === 0 || selectType.length === inventoryType.length) && (select.length === 0 || select.length === filterList.length) && (selectCat.length === 0 || selectCat === categoryList.length)) {
                inventoryOriginalData = this.state.inventoryOriginalData;
                categoryList = getCategoryData(inventoryOriginalData, selectCat);
            }
            else {
                if (selectType.length != 0) {
                    inventoryOriginalData = inventoryOriginalData.filter(o1 => selectType.some(o2 => o1.hdDotCom.toUpperCase() === o2.value.toUpperCase()));
                    categoryList = getCategoryData(inventoryOriginalData, selectCat);
                }
                if (select.length != 0) {
                    inventoryOriginalData = inventoryOriginalData.filter(o1 => select.some(o2 => o1.warehouseId === o2.id));
                    categoryList = getCategoryData(inventoryOriginalData, selectCat);
                }
                if (selectCat.length != 0) {
                    inventoryOriginalData = inventoryOriginalData.filter(o1 => selectCat.some(o2 => o1.salvageCategory === o2.caption));
                }
            }
            if (selectCat.length != 0) {
                for (var i = 0; i < inventoryOriginalData.length; i++) {
                    for (var j = 0; j < filterList.length; j++) {
                        if (filterList[j].name.toUpperCase() == inventoryOriginalData[i].warehouse.toUpperCase())
                            filterList[j].checked = true;
                    }
                    for (var k = 0; k < inventoryType.length; k++) {
                        if (inventoryType[k].name.toUpperCase() == inventoryOriginalData[i].hdDotCom.toUpperCase())
                            inventoryType[k].checked = true;
                    }
                }
                // function for select categoryList on inventoryType and Warehouse for selected category
                categoryList = getCategoryListByCategory(AllInventoryData, filterList, inventoryType, "", selectCat)
            }
        }
        else if (e == "type") {
            newType = this.state.inventoryType.slice();
            newType[index].checked = !inventoryType[index].checked;
            this.setState({
                inventoryType: newType,
            })
            select = this.state.filterList.filter(item => item.checked);
            selectCat = this.state.categoryList.filter(item => item.checked);
            selectType = this.state.inventoryType.filter(item => item.checked);
            if ((selectType.length === 0 || selectType.length === inventoryType.length) && (select.length === 0 || select.length === filterList.length) && (selectCat.length === 0 || selectCat === categoryList.length)) {
                inventoryOriginalData = this.state.inventoryOriginalData;
                categoryList = getCategoryData(inventoryOriginalData, selectCat);
            }
            else {
                if (selectType.length != 0) {
                    inventoryOriginalData = inventoryOriginalData.filter(o1 => selectType.some(o2 => o1.hdDotCom.toUpperCase() === o2.value.toUpperCase()));
                    for (var i = 0; i < inventoryOriginalData.length; i++) {
                        for (var j = 0; j < filterList.length; j++) {
                            if (filterList[j].name.toUpperCase() == inventoryOriginalData[i].warehouse.toUpperCase())
                                filterList[j].checked = true;
                        }
                    }
                    categoryList = getCategoryData(inventoryOriginalData, selectCat);
                }
                if (selectType.length == 0) {
                    for (var i = 0; i < inventoryOriginalData.length; i++) {
                        for (var j = 0; j < categoryList.length; j++) {
                            if (categoryList[j].caption.toUpperCase() == inventoryOriginalData[i].salvageCategory.toUpperCase())
                                categoryList[j].checked = false;
                        }
                    }
                    selectCat = categoryList.filter(item => item.checked);
                }
                if (select.length != 0) {
                    inventoryOriginalData = inventoryOriginalData.filter(o1 => select.some(o2 => o1.warehouseId === o2.id));
                    categoryList = getCategoryData(inventoryOriginalData, selectCat);
                }
                if (selectCat.length != 0 && categoryList.filter(x1 => selectCat.some(x2 => x1.caption === x2.caption)).length > 0) {
                    inventoryOriginalData = inventoryOriginalData.filter(o1 => selectCat.some(o2 => o1.salvageCategory === o2.caption));
                }
            }
        }
        else if (e == "All") {
            if (this.state.selectAllCheck) {
                this.setState({
                    selectAllCheck: false
                })
                for (var i = 0; i < categoryList.length; i++) {
                    categoryList[i].checked = false;
                }
                for (var j = 0; j < filterList.length; j++) {
                    filterList[j].checked = false;
                }
                for (var k = 0; k < inventoryType.length; k++) {
                    inventoryType[k].checked = false;
                }
            }
            else {
                this.setState({
                    selectAllCheck: true
                })
                for (var i = 0; i < categoryList.length; i++) {
                    categoryList[i].checked = true;
                }
                for (var j = 0; j < filterList.length; j++) {
                    filterList[j].checked = true;
                }
                for (var k = 0; k < inventoryType.length; k++) {
                    inventoryType[k].checked = true;
                }
            }
            select = this.state.filterList.filter(item => item.checked);
            selectCat = this.state.categoryList.filter(item => item.checked);
            selectType = this.state.inventoryType.filter(item => item.checked);
            if ((selectType.length === 0 || selectType.length === inventoryType.length) && (select.length === 0 || select.length === filterList.length) && (selectCat.length === 0 || selectCat === categoryList.length)) {
                inventoryOriginalData = this.state.inventoryOriginalData;
                categoryList = getCategoryData(inventoryOriginalData, selectCat);
            }
            else {
                if (selectType.length != 0) {
                    inventoryOriginalData = inventoryOriginalData.filter(o1 => selectType.some(o2 => o1.hdDotCom.toUpperCase() === o2.value.toUpperCase()));
                    for (var i = 0; i < inventoryOriginalData.length; i++) {
                        for (var j = 0; j < filterList.length; j++) {
                            if (filterList[j].name.toUpperCase() == inventoryOriginalData[i].warehouse.toUpperCase())
                                filterList[j].checked = true;
                        }
                    }
                    categoryList = getCategoryData(inventoryOriginalData, selectCat);
                }
                if (select.length != 0) {
                    inventoryOriginalData = inventoryOriginalData.filter(o1 => select.some(o2 => o1.warehouseId === o2.id));
                    categoryList = getCategoryData(inventoryOriginalData, selectCat);
                }
                for (var i = 0; i < inventoryOriginalData.length; i++) {
                    for (var j = 0; j < categoryList.length; j++) {
                        if (categoryList[j].caption.toUpperCase() == inventoryOriginalData[i].salvageCategory.toUpperCase())
                            categoryList[j].checked = true;
                    }
                }
            }
        }
        if (AllInventoryData.length == 0) {
            categoryList = this.state.DemoCategories;
        }
        this.setState({
            inventorydata: inventoryOriginalData,
            categoryList: categoryList,
            filterList: filterList,
            inventoryType: inventoryType,
            Category: ""
        });
        this.props.fetchFilters(inventoryType, categoryList, filterList);
    }
    render() {
        const { inventorydata, loading, selectSB } = this.state;
        const { classes } = this.props;
        const columns = [
            //{
            //    id: "checkbox",
            //    accessor: "",
            //    Cell: ({ row }) => {
            //        return (selectSB

            //            ? <div>
            //                <div style={style}>
            //                    <SyncLoader
            //                        css={override}
            //                        size={15}
            //                        color={"#fd7e14"}
            //                        loading={this.state.selectSB}
            //                    />
            //                </div>
            //                <input
            //                    type="checkbox"
            //                    className="checkbox"
            //                    checked={this.state.selected[row.original.id] == true || row.original.isSelected == true}
            //                    onClick={() => this.handleChecked(row.original.id, row.original.warehouseId, row.original.containerQuantity)} />
            //            </div>
            //            :
            //            <input
            //                type="checkbox"
            //                className="checkbox"
            //                checked={this.state.selected[row.original.id] == true || row.original.isSelected == true}
            //                onClick={() => this.handleChecked(row.original.id, row.original.warehouseId, row.original.containerQuantity)}
            //            />
            //        );
            //    },
            //    sortable: false,
            //    filterable: false,
            //    width: 45
            //},
            {
                Header: 'Make Offer',
                accessor: 'isBuynow',
                //Cell: ({ row }) => {
                //    if (row.original.isMakeofferManage == 1) {
                //        return (<div>
                //            <button className="btn" style={{ background: '#fa7a22', color: '#fff', padding: '5px 16px' }}
                //                onClick={() => this.handleMakeofferclick(row)}
                //            //we are setting dynamic color from array on the basis of index
                //            > Make Offer
                //           </button>

                //        </div>)
                //    }
                //    else if (row.original.isBuyNow == 1) {
                //        if (row.original.isMakeofferManage == null) {
                //            return (<div>
                //                <button className="btn" style={{ background: '#fa7a22', color: '#fff', padding: '5px 16px' }}
                //                    onClick={() => this.handleMakeofferclick(row)}
                //                //we are setting dynamic color from array on the basis of index
                //                > Make Offer
                //           </button>

                //            </div>)
                //        }
                //        else if (row.original.isMakeofferManage == 0) {
                //            return (
                //                <div>
                //                    <button className="btn" disabled="true" style={{ background: '#666666', color: '#fff', padding: '5px 16px' }}
                //                        onClick={() => this.handleMakeofferclick(row)}
                //                    //we are setting dynamic color from array on the basis of index
                //                    > Make Offer
                //           </button>

                //                </div>
                //                //row.original.orderStatus
                //            )
                //        }
                //        else {
                //            return (
                //                <div>
                //                    <button className="btn" disabled="true" style={{ background: '#666666', color: '#fff', padding: '5px 16px' }}
                //                        onClick={() => this.handleMakeofferclick(row)}
                //                    //we are setting dynamic color from array on the basis of index
                //                    > Make Offer
                //           </button>

                //                </div>
                //                //row.original.orderStatus
                //            )
                //        }
                //    }


                //    else {
                //        return (
                //            <div>
                //                <button className="btn" disabled="true" style={{ background: '#666666', color: '#fff', padding: '5px 16px' }}
                //                    onClick={() => this.handleMakeofferclick(row)}
                //                //we are setting dynamic color from array on the basis of index
                //                > Make Offer
                //           </button>

                //            </div>
                //            //row.original.orderStatus
                //        )
                //    }

                //}

                Cell: ({ row }) => {

                    if (row.original.isBuynow == 1 && row.original.isApplyPallet == 1) {
                        return (<div>
                            <button className="btn" style={{ background: '#fa7a22', color: '#fff', padding: '5px 16px' }}
                                onClick={() => this.handleMakeofferclick(row)}
                            //we are setting dynamic color from array on the basis of index
                            > Make Offer
                           </button>

                        </div>)
                    }
                    else {
                        return (
                            <div>
                                <button className="btn" disabled="true" style={{ background: '#666666', color: '#fff', padding: '5px 16px' }}
                                    onClick={() => this.handleMakeofferclick(row)}
                                //we are setting dynamic color from array on the basis of index
                                > Make Offer
                           </button>

                            </div>
                            //row.original.orderStatus
                        )
                    }

                }


            },
            {
                Header: 'Apply Proxy Bid',
                accessor: 'addProxyBidAccessor',
                Cell: ({ row }) => {
                    if (row.original.isBuynow == 1 && row.original.isApplyPallet == 1) {
                        return (<div style={{ width: '130px' }}>
                            <button className="btn" style={{ background: '#22c93b', color: '#fff', padding: '5px 12px', fontSize: '12px' }}
                                onClick={() => this.handleAddProxyBidclick(row)}
                            //we are setting dynamic color from array on the basis of index
                            > Apply Proxy Bid
                            </button>

                        </div>)
                    }
                    else {
                        return (
                            <div style={{ width: '130px' }}>
                                <button className="btn" disabled="true" style={{ background: '#666666', color: '#fff', padding: '5px 12px', fontSize: '12px' }}
                                    onClick={() => this.handleAddProxyBidclick(row)}
                                //we are setting dynamic color from array on the basis of index
                                > Apply Proxy Bid
                                </button>

                            </div>
                            //row.original.orderStatus
                        )
                    }
                }

            },
            {
                Header: 'SB #',
                accessor: 'sbNumber',


                Cell: ({ row }) => (
                    <div>
                        <a style={{ cursor: 'pointer', color: 'blue' }}
                            onClick={() => this.handleclick(row)}>
                            {this.getSBNumber(row)}
                        </a>
                    </div>
                )
            },
            {
                Header: 'BL #',
                accessor: 'blNumber',
            },
            {
                Header: 'Warehouse',
                accessor: 'warehouse',

            },
            {
                Header: 'Category',
                accessor: 'salvageCategory',
                filter: "fuzzyText"
            },
            {
                Header: 'Type',
                accessor: 'hdDotCom',
            },
            {
                Header: 'Item',
                accessor: 'itemQuantity',


            },
            {
                Header: 'Pallet Qty',
                accessor: 'containerQuantity',

            },

            {
                Header: 'Current Bid Rate %',
                accessor: 'currentbid',
                Cell: ({ row }) => {
                    var getcurrentbidrate = this.state.gettimedataa.some(item => row.original.id === item.id);


                    if (getcurrentbidrate) {
                        var getbidval = this.state.gettimedataa.filter(item => row.original.id === item.id);
                        return (
                            <div>
                                <label>{Math.floor(getbidval[0].newbuyitnow)}%
                                </label>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div>
                                <label>   {Math.floor(parseFloat(row.original.makeofferrate))}%
                                </label>
                            </div>
                        )
                    }

                }
            },
            {
                Header: 'Wholesale',
                accessor: 'cost',
                Cell: ({ row }) => (
                    <React.Fragment>
                        {row.original.cost ? numberformat(row.original.cost) : row.original.cost}
                    </React.Fragment>
                )

            },
            //{
            //    Header: 'Rate',
            //    accessor: 'rate',

            //},


            //{
            //    Header: 'Cost',
            //    accessor: 'rate_Cost',
            //    Cell: ({ row }) => (
            //        <React.Fragment>
            //            {row.original.rate_Cost ? numberformat(row.original.rate_Cost) : row.original.rate_Cost}
            //        </React.Fragment>
            //    )

            //},
            {
                Header: 'Bid Status',
                accessor: 'bidstatus',
                Cell: ({ row }) => {

                    var s = this.state.getdisplaybidstatus.some(item => row.original.id === item.id);
                    var outbid = this.state.getdisplayoutbidstatus.some(item => row.original.id === item.id);

                    if (s) {
                        var getmaxval = this.state.getdisplaybidstatus.filter(item => row.original.id === item.id);

                        return (<div>
                            { }

                            <h5 style={{ fontSize: '15px', alignSelf: 'flex-start', padding: '5px 16px' }} ><span style={{ fontSize: '13px' }}>Your bid amount: ${(getmaxval[0].newbuyitnow * row.original.cost / 100).toFixed(2)}</span><span style={{ backgroundColor: 'green', color: '#fff', fontSize: '15px' }}> HighBid</span>
                            </h5>

                        </div>)
                    }
                    else if (outbid) {
                        var getminval = this.state.getdisplayoutbidstatus.filter(item => row.original.id === item.id);
                        return (<div>
                            <h5 style={{ fontSize: '15px', alignSelf: 'flex-start', padding: '5px 16px' }} ><span style={{ fontSize: '13px' }}>Your bid amount: $ {(getminval[0].newbuyitnow * row.original.cost / 100).toFixed(2)}</span><span style={{ backgroundColor: 'red', color: '#fff', fontSize: '15px' }}> OutBid</span>
                            </h5>

                        </div>)
                    }


                    else {
                        return (

                            <div>
                            </div>
                        )
                    }


                }
            },


            {
                Header: 'Buy Now Rate %',
                accessor: 'buynowrate',
            },


            {
                Header: 'Buy Now Cost',
                accessor: 'ratecost',
                Cell: ({ row }) => (

                    <React.Fragment>

                        {row.original.ratecost ? numberformat(row.original.ratecost) : row.original.ratecost}
                    </React.Fragment>
                )
            },

            {
                Header: 'Bid Timer',
                accessor: 'Timer',
                Cell: ({ row }) => {

                    //       var n = this.state.duration;

                    var s = this.state.gettimedataa.filter(item => row.original.id === item.id);

                    //return (
                    //      <label>00:00:00:0000</label>
                    //   )

                    if (s.length > 0) {

                        return (<div>
                            <Timmer time={row.original.id}  goto={() => this.populateInventoryData()} />

                        </div>)

                    }


                    else {
                        return (
                            <label>00:00:00</label>
                        )
                    }


                }
            },

            {
                Header: 'Bid History',
                accessor: 'buynowcostt',
                Cell: ({ row }) => {

                    var s = this.state.gettimedataa.some(item => row.original.id === item.id);

                    if (s) {
                        return (<div>
                            <Button className="text-right" style={{ padding: '5px 16px', background: '#337ab7', color: '#fff' }} onClick={() => this.Timercheck(row.original.id, row.original.sbNumber)} variant="outline-secondary">Detail</Button>


                        </div>)
                    }

                    else {
                        return (

                            <div>

                                <button className="btn" disabled="true" style={{ background: '#337ab7', color: '#fff', padding: '5px 16px' }} > Detail
                               </button>
                            </div>
                        )
                    }


                }
            },


            {
                Header: 'Buy Now',
                accessor: 'isBuyNown',

                Cell: ({ row }) => {

                //    var enablebutton = this.state.getenablebuttonlist.some(item => row.original.id === item.Id);

                    if (row.original.isBuynow == 1 && row.original.showbutton == 0 ) {
                        return (<div>
                            <button className="btn" style={{ background: 'rgb(39 53 70)', color: '#fff', padding: '5px 16px' }} //we are setting dynamic color from array on the basis of index
                                onClick={() => this.handleforbuyitnow(row)}
                            > Buy Now
                           </button>

                        </div>)
                    }
                    else {
                        return (
                            <div>
                                <button className="btn" disabled="true" style={{ background: 'rgb(39 53 70)', color: '#fff', padding: '5px 16px' }}  //we are setting dynamic color from array on the basis of index
                                    onClick={() => this.handleforbuyitnow(row)}
                                > Buy Now
                           </button>

                            </div>//row.original.orderStatus
                        )
                    }

                }
            },


        ]

        if (loading && inventorydata.length === 0) {
            return (
                <div><em>Please wait while Loading Inventory Data......</em>
                    <div style={style}>
                        <SyncLoader
                            css={override}
                            size={15}
                            color={"#fd7e14"}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            );
        }
        if (inventorydata == null || inventorydata == "")
            return (<div>
                {//<p className="bg-dark p-3 mb-4 text-white shadow-sm"><strong>NOTE:</strong> SB # is the identifying Slot By Number used to identify manifests</p>
                }{
                    <div>
                        <form>
                            <div >
                                <Accordion defaultActiveKey="0">
                                    <Card>
                                        <Card.Header className="p-1 icon-down" style={{ backgroundColor: "#fa7a22" }} >
                                            <Accordion.Toggle as={Button} className="positive-relative text-decoration-none" variant="link" eventKey="0">
                                                <div className="d-flex text-light ">
                                                    <h2 className="text-light">Filter By:</h2>

                                                    <span className="float-right down_btn position-absolute" style={{ left: "95%" }} >
                                                        <i class="fa-2x fa fa-angle-down" aria-hidden="true"></i></span>
                                                </div>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <div className="searchContainer">
                                                    <form>
                                                        <div className="mycheckbox">
                                                            <input type="checkbox" className="mr-1" checked={this.state.selectAllCheck} onChange={this.onToggle.bind(this, 0, "All")} />
                                                            <label style={{ fontSize: "20" }} className="mr-3">Select All</label>
                                                            <span className="mycheck"></span>
                                                        </div>
                                                    </form>
                                                    <div className="clearfix"></div>
                                                </div>
                                                <div className="searchContainer">
                                                    <h3 className="mt-2 mb-3"><strong>Inventory Type</strong></h3>
                                                    <form>
                                                        {this.state.inventoryType.map((filter, i) => (
                                                            <React.Fragment key={i}>
                                                                <div className="mycheckbox">
                                                                    <input type="checkbox" className="mr-1" checked={filter.checked} onChange={this.onToggle.bind(this, i, "type")} />
                                                                    <label style={{ fontSize: "20" }} className="mr-3" htmlFor={filter.id}>{filter.name}</label>
                                                                    <span className="mycheck"></span>
                                                                </div>
                                                            </React.Fragment>
                                                        ))}
                                                    </form>
                                                    <div className="clearfix"></div>
                                                </div>

                                                <div className="clearfix"></div>
                                                <div className="searchContainer">
                                                    <h3 className="mt-2 mb-3"><strong>Warehouses</strong></h3>
                                                    <form>
                                                        {this.state.filterList.map((filter, i) => (
                                                            <React.Fragment key={i}>
                                                                <div className="mycheckbox">
                                                                    <input type="checkbox" className="mr-1" checked={filter.checked} onChange={this.onToggle.bind(this, i, "warehouse")} />
                                                                    <label style={{ fontSize: "20" }} className="mr-3" htmlFor={filter.id}>{filter.name}</label>
                                                                    <span className="mycheck"></span>
                                                                </div>
                                                            </React.Fragment>
                                                        ))}
                                                    </form>
                                                    <div className="clearfix"></div>
                                                </div>
                                                <div className="searchContainer mb-3">

                                                    {this.state.categoryList.length > 0 ?
                                                        <h3 className="mt-2 mb-3"><strong>Category</strong></h3>
                                                        : <h3 className="mt-2 mb-3"><strong></strong></h3>
                                                    }
                                                    <form>
                                                        {this.state.categoryList.map((filter, i) => (
                                                            <React.Fragment key={i}>
                                                                <div className="mycheckbox">
                                                                    <input type="checkbox" className="mr-1" checked={filter.checked} onChange={this.onToggle.bind(this, i, "category")} />
                                                                    <label style={{ fontSize: "20" }} className="mr-3" htmlFor={filter.id}>{filter.caption}</label>
                                                                    <span className="mycheck"></span>
                                                                </div>
                                                            </React.Fragment>
                                                        ))}
                                                    </form>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                        </form>
                    </div>
                }
                <div>
                    <div class="clearfix"></div>
                    <CssBaseline />
                    <div class="clearfix mt-3 mb-3">
                        <div style={{ float: 'right' }}>
                            <Button className="text-right" onClick={() => this.clearFilter()} variant="outline-secondary">Clear Filter</Button>
                        </div>
                    </div>
                    <EnhancedTable
                        columns={columns}
                        data={this.state.inventorydata} minRows={0} />
                </div>
                <div>
                    <h2>No SB Available.Please Refresh Page</h2>
                </div>
            </div>)


        return (
            <div>
                <div>
                    <StyledLoader
                        active={this.state.selectSB}
                        classNamePrefix='MyLoader_'>
                        <div>
                            <form>
                                <div >
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="p-1 icon-down" style={{ backgroundColor: "#fa7a22" }} >
                                                <Accordion.Toggle as={Button} className="positive-relative text-decoration-none" variant="link" eventKey="0">
                                                    <div className="d-flex text-light ">
                                                        <h2 className="text-light">Filter By:</h2>

                                                        <span className="float-right down_btn position-absolute" style={{ left: "95%" }} >
                                                            <i class="fa-2x fa fa-angle-down" aria-hidden="true"></i></span>
                                                    </div>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                    <div className="searchContainer">
                                                        <form>
                                                            <div className="mycheckbox">
                                                                <input type="checkbox" className="mr-1" checked={this.state.selectAllCheck} onChange={this.onToggle.bind(this, 0, "All")} />
                                                                <label style={{ fontSize: "20" }} className="mr-3">Select All</label>
                                                                <span className="mycheck"></span>
                                                            </div>
                                                        </form>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                    <div className="searchContainer">
                                                        <h3 className="mt-2 mb-3"><strong>Inventory Type</strong></h3>
                                                        <form>
                                                            {this.state.inventoryType.map((filter, i) => (
                                                                <React.Fragment key={i}>
                                                                    <div className="mycheckbox">
                                                                        <input type="checkbox" className="mr-1" checked={filter.checked} onChange={this.onToggle.bind(this, i, "type")} />
                                                                        <label style={{ fontSize: "20" }} className="mr-3" htmlFor={filter.id}>{filter.name}</label>
                                                                        <span className="mycheck"></span>
                                                                    </div>
                                                                </React.Fragment>
                                                            ))}
                                                        </form>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                    <div className="searchContainer">
                                                        <h3 className="mt-2 mb-3"><strong>Warehouses</strong></h3>
                                                        <form>
                                                            {this.state.filterList.map((filter, i) => (
                                                                <React.Fragment key={i}>
                                                                    <div className="mycheckbox">
                                                                        <input type="checkbox" className="mr-1" checked={filter.checked} onChange={this.onToggle.bind(this, i, "warehouse")} />
                                                                        <label style={{ fontSize: "20" }} className="mr-3" htmlFor={filter.id}>{filter.name}</label>
                                                                        <span className="mycheck"></span>
                                                                    </div>
                                                                </React.Fragment>
                                                            ))}
                                                        </form>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                    <div className="searchContainer mb-3">
                                                        {this.state.categoryList.length > 0 ?
                                                            <h3 className="mt-2 mb-3"><strong>Category</strong></h3>
                                                            : <h3 className="mt-2 mb-3"><strong></strong></h3>
                                                        }
                                                        <form>
                                                            {this.state.categoryList.map((filter, i) => (
                                                                <React.Fragment key={i}>
                                                                    <div className="mycheckbox">
                                                                        <input type="checkbox" className="mr-1" checked={filter.checked} onChange={this.onToggle.bind(this, i, "category")} />
                                                                        <label style={{ fontSize: "20" }} className="mr-3" htmlFor={filter.id}>{filter.caption}</label>
                                                                        <span className="mycheck"></span>
                                                                    </div>
                                                                </React.Fragment>
                                                            ))}
                                                        </form>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </div>
                            </form>
                            <Snackbar
                                anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                                open={this.state.snackbarOpen}
                                autoHideDuration={2000}
                                onClose={this.snackBarClose}
                                ContentProps={{
                                    "aria-describedby": "message-id",
                                    className: classes.snackbarStyleViaNestedContent
                                }}
                                message={
                                    <span id="message-id">
                                        <div>SB added to cart successfully!!!</div>
                                    </span>
                                }

                            />
                            <Snackbar
                                anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                                open={this.state.snackbaruncheck}
                                autoHideDuration={2000}
                                onClose={this.snackBarClose}
                                ContentProps={{
                                    "aria-describedby": "message-id",
                                    className: classes.snackbarStyleViaNestedContent
                                }}
                                message={
                                    <span id="message-id">
                                        <div>Sb removed from Cart Successfully!!!</div>
                                    </span>
                                }
                            />
                            <Snackbar
                                anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                                open={this.state.snackbarUnavailableSB}
                                autoHideDuration={2000}
                                onClose={this.snackBarClose}
                                ContentProps={{
                                    "aria-describedby": "message-id",
                                    className: classes.snackbarStyleViaContentProps
                                }}
                                message={
                                    <span id="message-id">
                                        <div>This sb has been hold by another user</div>
                                    </span>
                                }
                            />
                        </div>
                        <div>
                            <div class="clearfix"></div>
                            <CssBaseline />
                            <div class="clearfix mt-3 mb-3">

                                <div style={{ float: 'right' }}>
                                    <Button className="text-right" onClick={() => this.clearFilter()} variant="outline-secondary">Clear Filter</Button>
                                </div>
                            </div>
                            <EnhancedTable
                                columns={columns}
                                data={this.state.inventorydata} minRows={0}
                                pageSize={(this.state.inventorydata.length > 10) ? this.state.inventorydata.length : this.state.inventorydata.length} />
                        </div>
                    </StyledLoader>
                </div>
                <Modal centered size="xl" show={this.state.show} onHide={this.handleClose} className="sb-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>SB Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p><strong> SB #:</strong> {this.state.sbNumber}, <strong>Bl #:</strong> {this.state.blNumber}, <strong>Warehouse:</strong> {this.state.warehouse}, <strong>Category:</strong>{this.state.salvageCategory}, <strong>Pallet Qty:</strong> {this.state.containerQuantity}</p>
                        <InventoryDetail sbNumber={this.state.sbNumber} warehouse={this.state.warehouseId} />
                    </Modal.Body>
                </Modal>

                <Modal size="lg" centered className="sb-details-modal" show={this.state.showproxybidinformation} onHide={
                    this.handleCloseproxybidinformation
                }>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            What is a Proxy Bid and how does it work?
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5 style={{ paddingBottom: '0.5em' }}>
                            Proxy bidding is allowing the system to automatically bid on your behalf up to your chosen maximum amount. A proxy bid is your Maximum bid - not your current bid.
                        </h5>
                        <h5 style={{ paddingBottom: '0.5em' }}>When you place a bid, enter the maximum amount you are willing to pay for that item. This amount will always be kept secret. Whenever someone else places a bid, our software will automatically enter the lowest possible bid for you, up to your maximum bid, until you are the winning bidder.</h5>
                        <h5 style={{ paddingBottom: '0.5em' }}>At the end of an auction, if you are the highest bidder, you are the winner. As the winner of the auction, you only pay the lowest possible winning bid, not necessarily your maximum bid.</h5>
                        <h5 style={{ paddingBottom: '0.5em' }}>When Proxy Bidding is in effect, it can appear as if one bidder has beat another bidder with the same bid amount. This is normal and occurs when a bidder has a proxy bid in effect and a second bidder comes along and makes their bid at the exact same amount the current proxy bid is at. Since the proxy bid was placed earlier, that is the bid that will succeed. For the second bidder to succeed in placing a bid, they must bid higher than the proxy bid that is in effect.</h5>
                    </Modal.Body>
                </Modal>

                <Modal centered size="xl" show={this.state.showaddproxybid} onHide={this.handleCloseaddproxybid} className="sb-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Apply Proxy Bid
                            <HtmlTooltip title="Click here to know more about Proxy Bidding process" placement="right-start" arrow>
                                <span onClick={this.handleshowproxybidinformation} style={{ cursor: 'pointer', color: '#fa7a22', marginLeft: '1em', fontSize: "0.44em" }}>
                                    <a>(What is a proxy bid?)</a>
                                </span>
                            </HtmlTooltip>
                                </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div style={{ padding: "0 0 1em 0" }}> <h3 >  Apply the Maximum proxy bid Rate % (Wholesale) you are willing to pay for {this.state.Truckbarcode}

                        </h3></div>
                        {/*<div style={{ display: 'flex', marginTop: '15px' }}>*/}

                        {/*    <h3>Apply proxy bid %*/}
                        {/*    </h3>*/}

                        {/*</div>*/}

                        <div style={{ display: 'flex', alignItems: "center"}}>

                            <input style={{ fontSize: "1.5em" }} 
                                onKeyDown={(e) => {
                                    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
                                }}

                                name="txtval" value={this.state.proxybidrateinput} onChange={this.handleProxyBidRateInputChange} />
                            <h3 style={{ fontSize: "1.5em", marginLeft: '3em', color: '#fa7a22' }}>
                                {/* {this.state.proxybidrateinput != null ? this.state.proxybidrateinput : ""}% of Wholesale {this.state.proxybidrateinput ? calculatedynamic(this.state.proxybidrateinput, this.state.wholesalevalue) : ""}*/}
                                {/*{(((this.state.previousMaximumProxyBidRate != undefined && this.state.previousMaximumProxyBidRate != null) ? (`Your current Maximum proxy Bid Rate(%) is ${this.state.previousMaximumProxyBidRate}%`) : "You have not applied any Max. Proxy Bid rate (%)"))}*/}
                                {(this.state.proxybidrateinput ? this.state.proxybidrateinput : "")}% of Wholesale {this.state.proxybidrateinput ? calculatedynamic(this.state.proxybidrateinput, this.state.wholesalevalue) : ""}

                            </h3>

                        </div>

                        <div style={{ padding: "1em 0" }}>
                            <h5 style={{ fontSize: '18px', marginTop: '18px', fontWeight: 'bold', lineHeight: '1.5em', color: '#fa7a22' }}>{(((this.state.previousMaximumProxyBidRate != undefined && this.state.previousMaximumProxyBidRate != null) ? (`Your current Maximum proxy Bid Rate(%) is ${Math.floor(this.state.previousMaximumProxyBidRate)}%`) : "You have not applied any Max. Proxy Bid rate (%)"))}</h5>
                            <h5 style={{ fontSize: '18px', fontWeight: 'bold', fontWeight: 'bold', lineHeight: '1.5em' }}>Minimum proxy bid rate : {Math.floor(parseFloat((((this.state.proxybid_lastMakeOfferRate != undefined && this.state.proxybid_lastMakeOfferRate != null && this.state.proxybid_lastMakeOfferRate != 0) ? this.state.proxybid_lastMakeOfferRate : this.state.buyitnow)))) + 1}%</h5>
                            <h5 style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '1.5em'}}>Maximum  proxy bid rate : 100%</h5>
                            <h5 style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '1.5em' }}>To Reset/Disable Proxy Bid process : 0%</h5>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <button className="btn" style={{ background: '#fa7a22', color: '#fff', padding: '5px 16px', marginTop: '10px', marginRight: '30px' }}
                                onClick={this.handleAddProxyBidDialogClick} >
                                Apply Proxy Bid
                            </button>

                            <h3 style={{ marginLeft: '40px', marginTop: '18px', color: '#fa7a22' }}>                                        Current High Bid is {(((this.state.proxybid_lastMakeOfferRate != undefined && this.state.proxybid_lastMakeOfferRate != null && this.state.proxybid_lastMakeOfferRate != 0) ? Math.floor(this.state.proxybid_lastMakeOfferRate) + "%" : Math.floor(parseFloat(this.state.buyitnow)) + "%"))}</h3>
                        </div>

                    </Modal.Body>
                </Modal>

                <Modal centered size="lg" show={this.state.showmakeoffer} onHide={this.handleCloseMakeoffer} className="sb-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Make Offer</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div> <h3 >  Enter the Maximum % (Wholesale) you are willing to pay for SB: {this.state.Truckbarcode}

                        </h3></div>

                        <div style={{ display: 'flex', marginTop: '15px' }}>

                            <h3>Place Offer %
                            </h3>

                        </div>

                        {/*<div>*/}
                        {/*    <label title={this.state.name} />*/}
                        {/*    <input*/}
                        {/*        className="form-control col-4 mt-4"*/}
                        {/*        // Adding hint in Text Input using Place holder.*/}
                        {/*        placeholder={this.state.buyitnow}*/}
                        {/*        onChange={this.handleChange}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div style={{display: 'flex'}}>

                            <input 

                                onKeyDown={(e) => {
                                    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
                                }}

                                value={this.state.name} name="txtval" onChange={this.handleChange} placeholder={Math.floor(parseFloat(this.state.buyitnow)) + 1} defaultValue={(Math.floor(parseFloat(this.state.buyitnow)) + 1)} />
                            <h2 style={{ fontSize: '20px', marginTop: '12px', marginLeft: '30px' }}>{(this.state.name ? this.state.name : "") + "%" } of Wholesale is {this.state.name ? calculatedynamic(this.state.name, this.state.wholesalevalue) : ""}</h2>
                        </div>
                        <div><h5 style={{ fontSize: '13px', marginTop: '18px' }}>Minimum Offer :{Math.floor(parseFloat(this.state.buyitnow)) + 1}%</h5></div>
                        <div style={{ display: 'flex' }}>
                            <button className="btn" style={{ background: '#fa7a22', color: '#fff', padding: '5px 16px', marginTop: '10px', marginRight: '30px' }}
                                onClick={this.handleClick} >
                                Make Offer
                           </button>
                            <h3 style={{ marginLeft: '40px', marginTop: '18px', color: '#fa7a22' }}> Current High Bid is {Math.floor(this.state.buyitnow)}%</h3>
                        </div>

                    </Modal.Body>
                </Modal>
                <Modal centered size="lg" show={this.state.showlastmakeoffer} onHide={this.handleCloselatestoffer} className="sb-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Make Offer</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div> <h3 >  Enter the Maximum % (Wholesale) you are willing to pay for SB:{this.state.Truckbarcode}

                        </h3></div>
                        <div style={{ display: 'flex', marginTop: '15px' }}>

                            <h3>Place Offer %
                            </h3>

                        </div>

                        <div style={{ display: 'flex' }}>

                            <input 

                                onKeyDown={(e) => {
                                    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
                                }}
                                value={this.state.name} name="txtval" onChange={this.handleChange} placeholder={Math.floor(parseFloat((this.state.lastMakeofferprice))) + 1} defaultValue={Math.floor(parseFloat((this.state.lastMakeofferprice))) + 1} />

                            <h2 style={{ fontSize: '20px', marginTop: '12px', marginLeft: '30px' }}>{(this.state.name ? this.state.name : "") + "%" } of Wholesale is {this.state.name ? calculatedynamic(this.state.name, this.state.wholesalevalue) : ""}</h2>
                        </div>
                        <div style={{ display: 'flex' }}><h5 style={{ fontSize: '13px', marginTop: '18px' }}>Minimum Offer :{Math.floor(parseFloat(this.state.lastMakeofferprice)) + 1}%</h5> <h5 style={{ marginLeft: '30px',fontSize: '13px', marginTop: '18px' }}>{this.state.makeOfferCount} Offers</h5></div>


                        <div style={{ display: 'flex' }}>
                            <button className="btn" style={{ background: '#fa7a22', color: '#fff', padding: '5px 16px', marginTop: '10px', marginRight: '30px' }}
                                onClick={this.handleClicklatest} >
                                Make Offer
                            </button>
                            <h3 style={{ marginLeft: '40px', marginTop: '18px', color: '#fa7a22' }}>Current High Bid is {Math.floor(parseFloat(this.state.lastMakeofferprice)) + "%"}</h3>
                        </div>
                    </Modal.Body>
                </Modal>


                <Modal centered size="l" show={this.state.showBuynowConfirmation} onHide={this.handleBuynowConfirmationclose} className="sb-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Buy Now</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>The Buy it Now % For SB {this.state.barcode} is: {this.state.buyitnow}
                        </h5>

                        <div style={{ padding: '17px 0px', display: 'flex' }}>
                            <button className="btn ml-2 mr-2" style={{ background: 'rgb(39 53 70)', color: '#fff' }}
                                onClick={this.handleBuynowpopup} >
                                Buy Now
                                  </button>


                            <h3 style={{ marginTop: '6px' }}>{this.state.buyitnow} of Wholesale is {this.state.percentvaluee ? numberformat(this.state.percentvaluee) : this.state.percentvaluee}</h3>

                        </div>



                    </Modal.Body>
                </Modal>



                <Modal centered size="l" show={this.state.showBuynowforbidConfirmation} onHide={this.handleHighestBuynowConfirmationclose} className="sb-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Buy Now</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>The Buy it Now % For SB {this.state.barcode} is: {this.state.highbidamouttobuy}
                        </h5>
                        <div style={{ display: 'flex', padding: '17px 0px' }}>
                            <button className="btn ml-2 mr-2" style={{ background: 'rgb(39 53 70)', color: '#fff' }}
                                onClick={this.handleHightestBuynowpopup} >
                                Buy Now
                           </button>


                            <h3 style={{ marginTop: '6px' }}>{this.state.highbidamouttobuy} of Wholesale is {this.state.percentvaluee ? numberformat(this.state.percentvaluee) : this.state.percentvaluee}</h3>
                        </div>
                    </Modal.Body>
                </Modal>



                <Modal centered size="lg" show={this.state.showBuynow} onHide={this.handleBuynowclose} className="sb-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Buy Now</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>By clicking on Buy Now, you are agreeing to buy SB {this.state.barcode} at price of: {this.state.percentvaluee ? numberformat(this.state.percentvaluee) : this.state.percentvaluee}. Click to confirm!
                        </h5>

                        <div style={{ textAlign: 'center', padding: '17px 0px' }}>
                            <button className="btn ml-2 mr-2" style={{ background: 'rgb(39 53 70)', color: '#fff' }}
                                onClick={this.handleBuynow} >
                                Buy Now
                           </button>
                            <button className="btn ml-2 mr-2" style={{ background: 'rgb(197 197 197)' }}
                                onClick={this.handleBuynowclose} >
                                Cancel
                           </button>
                            {/*                            <h3 style={{ marginTop: '6px' }}>{this.state.buyitnow}  of Wholesale is {this.state.percentvaluee ? numberformat(this.state.percentvaluee) : this.state.percentvaluee} </h3>*/}
                        </div>



                    </Modal.Body>
                </Modal>
                <Modal centered size="lg" show={this.state.showBuynowforbid} onHide={this.handleHighestBuynowclose} className="sb-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Buy Now</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>By clicking on Buy Now, you are agreeing to buy SB {this.state.barcode} at price of: {this.state.percentvaluee ? numberformat(this.state.percentvaluee) : this.state.percentvaluee}. Click to confirm!
                        </h5>
                        <div style={{ textAlign: 'center', padding: '17px 0px' }}>
                            <button className="btn ml-2 mr-2" style={{ background: 'rgb(39 53 70)', color: '#fff' }}
                                onClick={this.handleBuynow} >
                                Buy Now
                           </button>
                            <button className="btn ml-2 mr-2" style={{ background: 'rgb(197 197 197)' }}
                                onClick={this.handleHighestBuynowclose} >
                                Cancel
                           </button>
                            {/*                            <h3 style={{ marginTop: '6px' }}>{this.state.highbidamouttobuy}  of Wholesale is {this.state.percentvaluee ? numberformat(this.state.percentvaluee) : this.state.percentvaluee}</h3>*/}
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal centered size="xl" show={this.state.handleTimervalue} onHide={this.handlecloseTimervalue} className="sb-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>History</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        <div>
                            <Table responsive size="xl" className="table-invoice table-bordered">
                                <thead>
                                    <tr>
                                        <th colSpan="10" className="text-center datacol">Bid History</th>
                                    </tr>
                                    <tr>
                                        <th className="text-center">SB</th>
                                        <th className="text-center">Company Name</th>
                                        <th className="text-center">Customer First Name</th>
                                        <th className="text-center">Customer Last Name</th>
                                        <th className="text-center">Highest Bid %</th>
                                    </tr>
                                </thead>
                                <tbody className="pheonixes">
                                    {this.state.gettimedata.map(list => {
                                        return (

                                            <tr key={list.id} >
                                                <td className="text-center" >{list.Barcode}</td>
                                                <td className="text-center" >{list.CompanyName}</td>
                                                <td className="text-center">{list.FisrstName}</td>
                                                <td className="text-center" >{list.LateName}</td>
                                                <td className="text-center" >{list.MaxBid}</td>
                                            </tr>

                                        )

                                    })}
                                </tbody>

                            </Table>
                            {this.state.gettimedata && this.state.gettimedata.length > 0 ?
                                <div style={{ float: 'right' }}>
                                    <a onClick={() => this.ViewBidHistory(this.state.TruckId,this.state.BidHistorySBnumber)} style={{ cursor: 'pointer', color: 'blue' }}>View Bid History</a>
                                </div> : null}
                        </div>


                        {/*     <p><strong> TruckLoad Barcode :</strong> {this.state.TruckBarcode}</p>*/}
                        {/*<InventoryDetail sbNumber={this.state.sbNumber} warehouse={this.state.warehouseId} />*/}


                    </Modal.Body>
                </Modal>
                <Modal centered size="xl" show={this.state.handleBidHistory} onHide={this.handlecloseBidHistory} className="sb-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>History</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        <div>
                            <Table responsive size="xl" className="table-invoice table-bordered">
                                <thead>
                                    <tr>
                                        <th colSpan="10" className="text-center datacol">All Bid History</th>
                                    </tr>
                                    <tr>
                                        <th className="text-center">SB</th>
                                        <th className="text-center">Company Name</th>
                                        <th className="text-center">Customer First Name</th>
                                        <th className="text-center">Customer Last Name</th>
                                        <th className="text-center">Highest Bid %</th>
                                        <th className="text-center">Bid DateTime %</th>
                                    </tr>
                                </thead>
                                <tbody className="pheonixes">
                                    {!this.state.bidData ? null : this.state.bidData.map(list => {
                                        return (

                                            <tr  >
                                                <td className="text-center" >{list.sb_number}</td>
                                                <td className="text-center" >{list.companyName}</td>
                                                <td className="text-center">{list.customerfirstname}</td>
                                                <td className="text-center" >{list.customerlastname}</td>
                                                <td className="text-center" >{list.newbuyitnow}</td>
                                                <td className="text-center" >{moment(list.insertDateTime).format("DD-MM-YYYY hh:mm:ss")}</td>
                                            </tr>

                                        )

                                    })}
                                </tbody>
                            </Table>
                        </div>


                        {/*     <p><strong> TruckLoad Barcode :</strong> {this.state.TruckBarcode}</p>*/}
                        {/*<InventoryDetail sbNumber={this.state.sbNumber} warehouse={this.state.warehouseId} />*/}


                    </Modal.Body>
                </Modal>

                {/*Add Proxy confirm box*/}
                <Modal centered size="l" show={this.state.ConfirmAddProxyBidBox} onHide={this.handleConfirmAddProxyBidBoxClose} className="sb-details-modal">
                    <Modal.Header className="bag-check">
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h2>You are setting your Maximum Proxy bid rate(%) of {this.state.proxybidrateinput ? this.state.proxybidrateinput : this.state.buyitnow}% of Wholesale on {this.state.Truckbarcode} </h2>

                        <h3 style={{ paddingTop: '25px', paddingBottom: '25px' }}>Please Confirm to Submit your Proxy bid Rate</h3>

                        <button className="btn ml-2 mr-2" style={{ background: '#fa7a22', color: '#fff' }}
                            onClick={this.confirmAddProxyBidDialogClick} disabled={this.state.isLoading}>
                            Confirm
                        </button>
                        <button className="btn ml-2 mr-2" style={{ background: 'rgb(39 53 70)', color: '#fff' }}
                            onClick={this.handleConfirmAddProxyBidBoxClose} >
                            Cancel
                        </button>
                    </Modal.Body>
                </Modal>

                <Modal centered size="l" show={this.state.ConfirmBox} onHide={this.handleConfirmBoxclose} className="sb-details-modal">
                    <Modal.Header className="bag-check">
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h2>You are making an Offer of {this.state.name ? this.state.name : this.state.buyitnow}% of Wholesale,on {this.state.Truckbarcode} </h2>

                        <h3 style={{ paddingTop: '25px', paddingBottom: '25px' }}>Please Confirm to Submit your Offer</h3>


                        <button className="btn ml-2 mr-2" style={{ background: '#fa7a22', color: '#fff' }}
                            onClick={this.confirmMakeoffer} >
                            Continue
                           </button>
                        <button className="btn ml-2 mr-2" style={{ background: 'rgb(39 53 70)', color: '#fff' }}
                            onClick={this.handleConfirmBoxclose} >
                            Cancel
                           </button>
                    </Modal.Body>
                </Modal>

            </div>
        );
    }


    handleBuynow = () => {

        this.BuynowTruck(this.state.getval.id);
    }

    handleProxyBidRateInputChange = (e) => {
        const proxyBidRateInput = e.target.value;
        if (!proxyBidRateInput || /^\d+$/i.test(proxyBidRateInput) ) {
        //if (!proxyBidRateInput || proxyBidRateInput.match(/^\d{1,}(\.\d{0,4})?$/)) {
            this.setState({ proxybidrateinput: proxyBidRateInput });
        }
    }

    handleChange = (e) => {
        const name
            = e.target.value;
        if (!name || name.match(/^\d+$/)) {
        //if (!name || name.match(/^\d{1,}(\.\d{0,4})?$/)) {
           // this.setState(() => ({ name }));
            this.setState({ name: name });
        }
    }


    handleClicklatest = () => {
        var lastMakeofferprice = Math.floor(parseFloat(this.state.lastMakeofferprice));
        var name = parseFloat(this.state.name);

        if (this.state.name == '' || this.state.name == null) {
            this.props.openDialog(1,
                "Alert",
            /*"Please Enter Value ", "Close", "", DIALOG_ACTION_CLOSE, null, null);*/
                "Please enter amount greater than OR equal to \"minimum bid amount\"", "Close", "", DIALOG_ACTION_CLOSE, null, null);
        }
        else if (Math.floor(lastMakeofferprice) > name) {
            this.props.openDialog(1,
                "Alert",
                "Please Enter value greater than Current Make Offer Rate(%)", "Close", "", DIALOG_ACTION_CLOSE, null, null);
        }

        else if (/^\d+$/i.test(this.state.name)) {
        //else if (/^(0|[1-9]\d*)(\.\d+)?$/i.test(this.state.name)) {
            let i = name - Math.floor(lastMakeofferprice)
            if (i >= 1) {
                this.setState({ ConfirmBox: true, name: this.state.name, id: this.state.getdata.id, buyitnow: this.state.buyitnow, wholesalevalue: this.state.wholesalevalue, showlastmakeoffer: false });
            }
            else {
                this.props.openDialog(1,
                    "Alert",
                    "Your Bid is minimum 1% greater than the previous bid ", "Close", "", DIALOG_ACTION_CLOSE, null, null);
            }
            /*           this.storeMakeoffervalue(this.state.txtval, this.state.getdata.id, this.state.buyitnow, this.state.wholesalevalue);*/
        }
        else {
            this.props.openDialog(1,
                "Alert",
                "Please Enter Correct Value", "Close", "", DIALOG_ACTION_CLOSE, null, null);
        }
    }


    handleAddProxyBidDialogClick = () => {

        var proxyBidRateInput_value = parseFloat(this.state.proxybidrateinput);

        if (this.state.proxybidrateinput == '' || this.state.proxybidrateinput == null) {
            this.props.openDialog(1,
                "Alert",
            /*"Please Enter Value ", "Close", "", DIALOG_ACTION_CLOSE, null, null);*/
                "Please enter Bid rate greater than OR equal to \"minimum proxy bid rate\"", "Close", "", DIALOG_ACTION_CLOSE, null, null);
        }

        else if (proxyBidRateInput_value > 100) {
            this.props.openDialog(1,
                "Alert",
                "Please Enter value less than 100(%)", "Close", "", DIALOG_ACTION_CLOSE, null, null);
        }
        else if (proxyBidRateInput_value > 0 && proxyBidRateInput_value < Math.floor(parseFloat(this.state.getdata.makeofferrate))) {
            this.props.openDialog(1,
                "Alert",
                "Please Enter value greater than Current Highest Rate(%)", "Close", "", DIALOG_ACTION_CLOSE, null, null);
        }
        else if (/^\d+$/.test(this.state.proxybidrateinput)) {
        //else if (/^(0|[1-9]\d*)(\.\d+)?$/i.test(this.state.proxybidrateinput)) {
            let i = (proxyBidRateInput_value) -
                Math.floor(((this.state.proxybid_lastMakeOfferRate != undefined && this.state.proxybid_lastMakeOfferRate != null && parseFloat(this.state.proxybid_lastMakeOfferRate) != 0) ? parseFloat(this.state.proxybid_lastMakeOfferRate) : parseFloat((this.state.getdata.makeofferrate))));
            if (i >= 1 || proxyBidRateInput_value == 0) {
                this.setState({ ConfirmAddProxyBidBox: true, proxybidrateinput: this.state.proxybidrateinput, id: this.state.getdata.id, buyitnow: this.state.buyitnow, wholesalevalue: this.state.wholesalevalue, showaddproxybid: false });
            }
            else {
                this.props.openDialog(1,
                    "Alert",
                    "Your Proxy Bid should be greater than or equal to Minimum proxy Bid Rate.", "Close", "", DIALOG_ACTION_CLOSE, null, null);
            }
        }

    }

    handleClick = () => {


        var buynowrate = Math.floor(parseFloat(this.state.getdata.makeofferrate));
        var name = parseFloat(this.state.name);

        if (this.state.name == '' || this.state.name == null) {
            this.props.openDialog(1,
                "Alert",
            /* "Please Enter Value ", "Close", "", DIALOG_ACTION_CLOSE, null, null);*/
                "Please enter amount greater than OR equal to \"minimum bid amount\"", "Close", "", DIALOG_ACTION_CLOSE, null, null); 
        }

        else if (buynowrate > name) {
            this.props.openDialog(1,
                "Alert",
                "Please Enter value greater than Current Make Offer Rate(%)", "Close", "", DIALOG_ACTION_CLOSE, null, null);
        }
        else if (/^\d+$/i.test(this.state.name)) {
            let i = name - (buynowrate)
            if (i >= 1) {
                this.setState({ ConfirmBox: true, name: this.state.name, id: this.state.getdata.id, buyitnow: this.state.buyitnow, wholesalevalue: this.state.wholesalevalue, showmakeoffer: false });
            }
            else {
                this.props.openDialog(1,
                    "Alert",
                    "Your Bid is minimum 1% greater than the previous bid ", "Close", "", DIALOG_ACTION_CLOSE, null, null);
            }   
        }

        else {
            this.props.openDialog(1,
                "Alert",
                "Please Enter Correct Value", "Close", "", DIALOG_ACTION_CLOSE, null, null);
        }
    }
    handlecloseBidHistory() {
        this.setState({ handleBidHistory: false });
    }
    handleCloseproxybidinformation() {
        this.setState({ showproxybidinformation: false });
    }
    handleCloseaddproxybid() {
        this.setState({ showaddproxybid: false, proxybidrateinput: null });
    }

    handleCloseMakeoffer() {
        this.setState({ showmakeoffer: false, name: null });


    }
    handleCloselatestoffer() {
        this.setState({ showlastmakeoffer: false, name: null });

    }
    handleBuynowclose() {
        this.setState({ showBuynow: false });
    }
    handleHighestBuynowclose() {
        this.setState({ showBuynowforbid: false });
    }


    handleBuynowpopup() {
        this.setState({ showBuynow: true, showBuynowConfirmation: false });
    }
    handleBuynowConfirmationclose() {
        this.setState({ showBuynowConfirmation: false });

    }

    handleHightestBuynowpopup() {
        this.setState({ showBuynowforbid: true, showBuynowforbidConfirmation: false });
    }
    handleshowproxybidinformation = () => {
        this.setState({ showproxybidinformation: true });
    }

    handleHighestBuynowConfirmationclose() {
        this.setState({ showBuynowforbidConfirmation: false });
    }

    handleAddProxyBidclick = async (rowData) => {
        const token = await authService.getAccessToken();
        this.setState({ proxybidrateinput: null, buyitnow: null });

        try {
            const response = await fetch('InventoryCode/CheckAddMaximumProxybidRate?truckid=' + rowData.original.id + '&IsStandardorTruck=1', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            /*const data = await response.json();*/
            const data = await commonErrorHandler(response);
            //Json Reponse : -
            //accessDenied = true | false,
            //isProxyBidder = true | false,
            //maxProxyBidRate = user's highest proxy bid rate,
            //lastMakeOfferPrice = current highest bid rate
            // isError = true | false

            if (data.hasOwnProperty('accessDenied') && data.accessDenied == true) {
                setTimeout(
                    function () {
                        this.props.openDialog(1,
                            "Restriction",
                            "Sorry, Your account has been restricted from purchasing. Please contact your account manager.", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                    }
                        .bind(this),
                    50
                );
            }
            else if (data.hasOwnProperty('isError') && data.isError == true) {
                setTimeout(
                    function () {
                        this.props.openDialog(1,
                            "Error",
                            "Sorry, an error occurred. Please try again.", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                    }
                        .bind(this),
                    50
                );
            }
            else {
                if (data.hasOwnProperty('maxProxyBidRate') && data.maxProxyBidRate != null) {
                    this.setState({ previousMaximumProxyBidRate: data.maxProxyBidRate });
                }
                else {
                    this.setState({ previousMaximumProxyBidRate: null });
                }
                if (data.hasOwnProperty('lastMakeOfferPrice')) {
                    this.setState({ proxybid_lastMakeOfferRate: (data.lastMakeOfferPrice == null ? 0 : data.lastMakeOfferPrice) });
                }
                this.setState({ proxybidrateinput: (Math.floor((this.state.proxybid_lastMakeOfferRate != undefined && this.state.proxybid_lastMakeOfferRate != null && this.state.proxybid_lastMakeOfferRate != 0) ? this.state.proxybid_lastMakeOfferRate : parseFloat(rowData.original.makeofferrate)) + 1) })
                this.setState({ getdata: rowData.original, buyitnow: rowData.original.makeofferrate, buynowcost: rowData.original.buynowcost, wholesalevalue: rowData.original.cost, Truckbarcode: rowData.original.sbNumber, showaddproxybid: true });
            }
        } catch (e) {
            console.log(e);
        }
    }

    handlecloseTimervalue() {
        this.setState({ handleTimervalue: false });
    }

    handleMakeofferclick = async (rowData) => {


        ///*  */ var wholesaleAmtTotal = rowData.original.wholesaleAmtTotal;
        ///*  */ var buynow = rowData.original.buynowrate;
        ///*  */ var percentcal = wholesaleAmtTotal * buynow / 100;
        ///*  */ var valuecheck = percentcal.toFixed(2);

        try {
                this.setState({ name: null, buyitnow: null });
                var makeofferrate = rowData.original.makeofferrate;
                makeofferrate = makeofferrate.replace("%", "");

                this.setState({ getdata: rowData.original, buyitnow: makeofferrate, ratecost: rowData.original.ratecost, wholesalevalue: rowData.original.cost, Truckbarcode: rowData.original.sbNumber });

                this.checknewMakeoffer(rowData.original.id, rowData.original.makeofferrate, rowData.original.cost);
        }

        catch(err) {
            try {
                var makeofferrate = rowData.original.makeofferrate;
                makeofferrate = makeofferrate.replace("%", "");


                this.setState({ getdata: rowData.original, buyitnow: makeofferrate, ratecost: rowData.original.ratecost, wholesalevalue: rowData.original.cost, Truckbarcode: rowData.original.sbNumber });

                this.checknewMakeoffer(rowData.original.id, rowData.original.makeofferrate, rowData.original.cost);

            } catch (e) {

            }
        }

        //debugger;
        //if (this.state.lastMakeofferprice != null) {
        //    this.setState({ getdata: rowData.original, showmakeoffer: true, buyitnow: rowData.original.buynowrate });

        //}
        //else {
        //    this.setState({ getdata: rowData.original, showmakeoffer: true, buyitnow: rowData.original.buynowrate });
        //}
    }

    handleforbuyitnow = async (rowData) => {

        //check first that current user is allowed to buy/bid
        const token = await authService.getAccessToken();
        const response = await fetch('InventoryCode/IsBuyAndBidAllowed', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        /*const data = await response.json();*/
        const data = await commonErrorHandler(response);

        //response structure : Success & accessDenied : both boolean
        if (data != null && data.success == true && data.accessDenied == true) {
            //access denied
            setTimeout(
                function () {
                    this.props.openDialog(1,
                        "Restriction",
                        "Sorry, Your account has been restricted from purchasing. Please contact your account manager.", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                }
                    .bind(this),
                50
            );

        }
        else {
            this.getvalueofhighestbid(rowData.original.id, rowData.original.buynowrate, rowData.original.ratecost);

            this.setState({ getval: rowData.original, buyitnow: rowData.original.buynowrate, ratecost: rowData.original.ratecost, wholesalevalue: rowData.original.cost, barcode: rowData.original.sbNumber });
        }
    }
    handleConfirmAddProxyBidBoxClose() {
        //this.setState({ proxybidrateinput: null, buyitnow: null })
        this.setState({ ConfirmAddProxyBidBox: false });
    }
    handleconfirmation = () => {
        this.setState({ ConfirmBox: true });
    }
    handleConfirmBoxclose() {

        this.setState({ ConfirmBox: false });
    }
    confirmAddProxyBidDialogClick = () => {
        this.setState({ isLoading: true });
        this.storeProxyBidvalue(this.state.proxybidrateinput, this.state.id, this.state.buyitnow, this.state.wholesalevalue);
        this.setState({ ConfirmAddProxyBidBox: false });
    }
    confirmMakeoffer = () => {
        this.storeMakeoffervalue(this.state.name, this.state.id, this.state.buyitnow, this.state.wholesalevalue);
    }
    Timercheck = (truckid,sbnumber) => {

        this.PopulateTimerValue(truckid, sbnumber);
        this.setState({ handleTimervalue: true, TruckId: truckid,BidHistorySBnumber:sbnumber});
    }

    async PopulateTimerValue(truckid, sbnumber) {
        const token = await authService.getAccessToken();
        const response = await fetch('InventoryCode/CheckATimmer?truckid=' + truckid + '&IsStandardorTruck=1' + '&sbnumber=' + sbnumber, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        /*const data = await response.json();*/
        const data = await commonErrorHandler(response);

        if (data != null) {


            let dynamicCategoryListt = [];
            this.setState({
                gettimedata: []
            })

            dynamicCategoryListt.push({ Time: data.timestamp, Barcode: data.sb_number, Id: data.id, FisrstName: data.customerfirstname, LateName: data.customerlastname, MaxBid: data.newbuyitnow, CompanyName: data.campanyName })


            this.setState({
                gettimedata: dynamicCategoryListt
            })
            //  this.start(dynamicCategoryListt)
        }
        else {

        }

    }

    ViewBidHistory = (truckid, sbnumber) => {
        this.populateBidHistory(truckid, sbnumber);
        this.setState({ handleBidHistory: true });
    }
    async populateBidHistory(truckid, sbnumber) {
        const token = await authService.getAccessToken();
        const response = await fetch('InventoryCode/BidHistory?truckid=' + truckid + '&IsStandardorTruck=1' + '&sbnumber=' + sbnumber, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        /*const data = await response.json();*/
        const data = await commonErrorHandler(response);

        if (data != null) {


            //let dynamicCategoryListt = [];
            //this.setState({
            //    gettimedata: []
            //})

            //dynamicCategoryListt.push({ Time: data.timestamp, Barcode: data.truckLoad_Barcode, Id: data.id, FisrstName: data.customerfirstname, LateName: data.customerlastname, MaxBid: data.newbuyitnow, CompanyName: data.campanyName })


            this.setState({
                bidData: data.bidHistoryList
            })
            //  this.start(dynamicCategoryListt)
        }
        else {

        }

    }


    async getvalueofhighestbid(truckid, buynow, wholesaleAmtTotal) {

        ///*  */ var wholesaleAmtTotal = rowData.original.wholesaleAmtTotal;
        ///*  */ var buynow = rowData.original.buynowrate;
        ///*  */ var percentcal = wholesaleAmtTotal * buynow / 100;
        ///*  */ var valuecheck = percentcal.toFixed(2);

        const token = await authService.getAccessToken();
        const response = await fetch('InventoryCode/getvalueinbuypopup?truckid=' + truckid + '&IsStandardorTruck=1', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        /*const data = await response.json();*/
        const data = await commonErrorHandler(response);

        if (data.manifestTruckList != null) {
            if (data.manifestTruckList.length > 0) {
                if (data.manifestTruckList[0].makeofferprice == 0) {
                    //var wholesaleAmtTotall = wholesaleAmtTotal;
                    //var buynoval = buynow.replace('%', '');
                    //var percentcal = wholesaleAmtTotall * buynoval / 100;
                    //var valuecheck = percentcal.toFixed(2);

                    this.setState({ showBuynowConfirmation: true, percentvaluee: wholesaleAmtTotal });
                }
                else {
                    var wholesaleAmtTotall = wholesaleAmtTotal;
                    var buynoval = data.manifestTruckList[0].makeofferprice;
                    var percentcal = wholesaleAmtTotall * buynoval / 100;
                    var valuecheck = percentcal.toFixed(2);
                    this.setState({ highbidamouttobuy: buynow, showBuynowforbidConfirmation: true, percentvaluee: wholesaleAmtTotal });
                }
            }
        }
        else {


            var wholesaleAmtTotall = wholesaleAmtTotal;
            var buynoval = buynow;
            var percentcal = wholesaleAmtTotall * buynoval / 100;
            var valuecheck = percentcal.toFixed(2);

            this.setState({ showBuynowConfirmation: true, percentvaluee: wholesaleAmtTotal });
            //error
        }
    }



    async checknewMakeoffer(truckid, buynow, wholesaleAmtTotal) {

        ///*  */ var wholesaleAmtTotal = rowData.original.wholesaleAmtTotal;
        ///*  */ var buynow = rowData.original.buynowrate;
        ///*  */ var percentcal = wholesaleAmtTotal * buynow / 100;
        ///*  */ var valuecheck = percentcal.toFixed(2);

        const token = await authService.getAccessToken();
        const response = await fetch('InventoryCode/CheckNewMakeoffer?truckid=' + truckid + '&IsStandardorTruck=1', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        /*const data = await response.json();*/
        const data = await commonErrorHandler(response);

        //BuyAndBidAccess - true then allow else deny
        if (!data.buyAndBidAccess) {
            //access denied
            setTimeout(
                function () {
                    this.props.openDialog(1,
                        "Restriction",
                        "Sorry, Your account has been restricted from purchasing. Please contact your account manager.", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                }
                    .bind(this),
                50
            );
        }
        else if (data.hasOwnProperty('isProxyBidder') && data.isProxyBidder == true) {
            //Do not allow make offer if the customer is already an active proxy bidder
            setTimeout(
                function () {
                    this.props.openDialog(1,
                        "Alert",
                        "Sorry, can not make an offer if proxy bidding is active.", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                }
                    .bind(this),
                50
            );
        }
        else if (data.manifestTruckList != null) {
            if (data.manifestTruckList.length > 0) {
                if (data.manifestTruckList[0].makeofferprice == 0) {
                    var wholesaleAmtTotall = wholesaleAmtTotal;
                   // var buynoval = buynow;
                    var buynoval = buynow.replace('%', '');
                    var percentcal = wholesaleAmtTotall * buynoval / 100;
                    var valuecheck = percentcal.toFixed(2);

                    this.setState({ name: (Math.floor(parseFloat(this.state.buyitnow)) + 1) });
                    this.setState({ showmakeoffer: true, percentvalue: valuecheck });
                }
                else {
                    var wholesaleAmtTotall = wholesaleAmtTotal;
                    var buynoval = data.manifestTruckList[0].makeofferprice;
                    var percentcal = wholesaleAmtTotall * buynoval / 100;
                    var valuecheck = percentcal.toFixed(2);
                    var addpercent = data.manifestTruckList[0].makeofferprice + "%";
                    this.setState({ name: (Math.floor(parseFloat(data.manifestTruckList[0].makeofferprice)) + 1) })
                    this.setState({ lastMakeofferprice: addpercent, showlastmakeoffer: true, percentvalue: valuecheck });
                }
            }
        }
        else {


            var wholesaleAmtTotall = wholesaleAmtTotal;
            var buynoval = buynow;
            var percentcal = wholesaleAmtTotall * buynoval / 100;
            var valuecheck = percentcal.toFixed(2);

            this.setState({ name: (Math.floor(parseFloat(this.state.buyitnow)) + 1) });
            this.setState({ showmakeoffer: true, percentvalue: valuecheck });
            //error
        }
        if (data.manifestTruckCount != null) {
            if (data.manifestTruckCount.length > 0) {
                var bidcount = data.manifestTruckCount[0].bidCount;
                this.setState({
                    makeOfferCount: bidcount
                });
            }
        }
    }

    async storeProxyBidvalue(NewProxyBidRate, truckid, buyitnow, wholesalevalue) {
        try {
            const token = await authService.getAccessToken();
            const response = await fetch('InventoryCode/AddMaximumProxybidRate?truckid=' + truckid + '&HighestProxyBidRate=' + NewProxyBidRate + '&IsStandardorTruck=1', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            /*const data = await response.json();*/
            const data = await commonErrorHandler(response);
            this.setState({ isLoading: false });


            if (data.hasOwnProperty('accessDenied') && data.accessDenied == true) {
                setTimeout(
                    function () {
                        this.props.openDialog(1,
                            "Restriction",
                            "Sorry, Your account has been restricted from purchasing. Please contact your account manager.", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                    }
                        .bind(this),
                    50
                );
            }
            else if (data.hasOwnProperty('countoforder') && data.countoforder > 0) {
                setTimeout(
                    function () {
                        this.props.openDialog(1,
                            "Alert",
                            "Order Already Placed.Kindly Refresh Page", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                    }
                        .bind(this),
                    50
                );
            }
            else if (data.hasOwnProperty('submitSuccess') && data.submitSuccess == true) {
                if (data.hasOwnProperty('updateSuccess') && data.updateSuccess == true) {
                    if (data.hasOwnProperty('message') && data.message) {
                        setTimeout(
                            function () {
                                this.props.openDialog(0,
                                    "Success",
                                    data.message, "Close", "", DIALOG_ACTION_CLOSE, null, null);
                            }
                                .bind(this),
                            50
                        );
                    }
                    else {
                        setTimeout(
                            function () {
                                this.props.openDialog(0,
                                    "Success",
                                    (NewProxyBidRate == 0 ? "Your Maximum proxy bid rate resetted to zero successfully." : "Your Maximum proxy bid rate updated successfully."), "Close", "", DIALOG_ACTION_CLOSE, null, null);
                            }
                                .bind(this),
                            50
                        );
                    }
                }
                else if (data.hasOwnProperty('insertSuccess') && data.insertSuccess == true) {
                    if (data.hasOwnProperty('message') && data.message) {
                        setTimeout(
                            function () {
                                this.props.openDialog(0,
                                    "Success",
                                    data.message, "Close", "", DIALOG_ACTION_CLOSE, null, null);
                            }
                                .bind(this),
                            50
                        );
                    }
                    else {
                        setTimeout(
                            function () {
                                this.props.openDialog(0,
                                    "Success",
                                    "Your Maximum proxy bid rate submitted successfully.", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                            }
                                .bind(this),
                            50
                        );
                    }
                }
                else {
                    setTimeout(
                        function () {
                            this.props.openDialog(1,
                                "Error",
                                "Sorry, an error occurred. Please try again.", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                        }
                            .bind(this),
                        50
                    );
                }



            }
            else if (NewProxyBidRate == 0 && data.hasOwnProperty('resetToZeroSuccess') && data.resetToZeroSuccess == false) {
                if (data.hasOwnProperty('errorMessage') && data.errorMessage != '') {
                    setTimeout(
                        function () {
                            this.props.openDialog(1,
                                "Alert",
                                data.errorMessage, "Close", "", DIALOG_ACTION_CLOSE, null, null);
                        }
                            .bind(this),
                        50
                    );
                } else {
                    setTimeout(
                        function () {
                            this.props.openDialog(1,
                                "Alert",
                                "Sorry, you can not reset your maximum proxy bid to zero as you are already the highest proxy bidder.", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                        }
                            .bind(this),
                        50
                    );
                }
            }
            else if (data.hasOwnProperty('errorMessage') && data.errorMessage != "") {
                setTimeout(
                    function () {
                        this.props.openDialog(1,
                            "Error",
                            data.errorMessage, "Close", "", DIALOG_ACTION_CLOSE, null, null);
                    }
                        .bind(this),
                    50
                );
            }
            else {
                setTimeout(
                    function () {
                        this.props.openDialog(1,
                            "Error",
                            "Sorry, Your Maximum Proxy bid rate was not submitted successfully.", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                    }
                        .bind(this),
                    50
                );
            }
            this.setState({ showaddproxybid: false });
            this.setState({ ConfirmAddProxyBidBox: false });
            this.setState({ proxybidrateinput: null })
            this.populateInventoryData();
        } catch (e) {
            setTimeout(
                function () {
                    this.props.openDialog(1,
                        "Error",
                        "Sorry, an error occurred. Please try again.", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                }
                    .bind(this),
                50
            );
        }
    }

    async storeMakeoffervalue(NewBuyitNowRate, truckid, buyitnow, wholesalevalue) {
        const token = await authService.getAccessToken();
        const response = await fetch('InventoryCode/Makeoffer?truckid=' + truckid + '&NewBuyitNowRate=' + NewBuyitNowRate + '&IsStandardorTruck=1', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        /*const data = await response.json();*/
        const data = await commonErrorHandler(response);
        //if (data.isapplypallet == 1)
        //{
        //    this.props.openDialog(1,
        //            "Alert",
        //            "Kindly Refresh Page", "Close", "", DIALOG_ACTION_CLOSE, null, null);
        //        //this.checknewMakeoffer(truckid, buyitnow, wholesalevalue);

        //        this.setState({ ConfirmBox: false });
        //    window.location.reload();
        //}

        if (data.data > 0) {
            setTimeout(
                function () {
                    this.props.openDialog(0,
                        "Success",
                        "Value Saved Successfully!", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                }
                    .bind(this),
                1000
            );
            this.setState({
                showmakeoffer: false,
                ConfirmBox: false,
                showlastmakeoffer: false
            });
            this.populateInventoryData();
        }
        else if (data.countfororder > 0) {
            this.props.openDialog(1,
                "Alert",
                "Order Already Placed.Kindly Refresh Page", "Close", "", DIALOG_ACTION_CLOSE, null, null);
            //this.checknewMakeoffer(truckid, buyitnow, wholesalevalue);

            this.setState({ ConfirmBox: false });
        }
        else {
            if (data.accessDenied != undefined && data.accessDenied == true) {
                //buy/bid not allowed
                setTimeout(
                    function () {
                        this.props.openDialog(1,
                            "Restriction",
                            "Sorry, Your account has been restricted from purchasing. Please contact your account manager.", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                    }
                        .bind(this),
                    50
                );
                this.setState({
                    showmakeoffer: false,
                    ConfirmBox: false,
                    showlastmakeoffer: false
                });
            }
            else if (data.hasOwnProperty('isProxyBidder') && data.isProxyBidder == true) {
                //Do not allow make offer if the customer is already an active proxy bidder
                setTimeout(
                    function () {
                        this.props.openDialog(1,
                            "Alert",
                            "Sorry, you can not make an offer as your proxy bidding is active.", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                    }
                        .bind(this),
                    50
                );
            }
        }
    }



    async BuynowTruck(truckid) {
        const token = await authService.getAccessToken();
        const response = await fetch('InventoryCode/BuyNowTruck?truckid=' + truckid + '&IsStandardorTruck=1', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        /* const data = await response.json();*/
        const data = await commonErrorHandler(response);

        if (data.accessDenied != undefined && data.accessDenied == true) {
            //buy/bid not allowed
            setTimeout(
                function () {
                    this.props.openDialog(1,
                        "Restriction",
                        "Sorry, Your account has been restricted from purchasing. Please contact your account manager.", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                }
                    .bind(this),
                50
            );
            this.setState({ showBuynow: false, showBuynowforbid: false });
        }
        else if (data.data > 0) {
            setTimeout(
                function () {
                    this.props.openDialog(0,
                        "Success",
                        "Order Saved Successfully!", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                }
                    .bind(this),
                2000
            );
            this.setState({ showBuynow: false, showBuynowforbid: false });
            this.populateInventoryData();

        }
        else if (data.countfororder > 0) {

            setTimeout(
                function () {
                    this.props.openDialog(1,
                        "Alert",
                        "Order Already Placed.Kindly Refresh Page", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                }
                    .bind(this),
                2000
            );

            this.setState({
                showBuynow: false
            });
            this.populateInventoryData();
        }
        else if (data.maxbidchange > 1) {
            setTimeout(
                function () {
                    this.props.openDialog(1,
                        "Alert",
                        "Highest Bid has Changed.Kindly Refresh Page", "Close", "", DIALOG_ACTION_CLOSE, null, null);
                }
                    .bind(this),
                2000
            );

            this.setState({
                showBuynow: false
            });
            this.populateInventoryData();
        }
        else {
            //error

        }
    }



    async callCheckedApi(act, manifestId) {
        console.time("Act");
        let inventorydata = this.state.inventorydata;
        const token = await authService.getAccessToken();
        const newSelected = Object.assign({}, this.state.selected);
        console.timeEnd("Act");
        console.time("check SB");
        const response = await fetch('InventoryCode/SetSelectedRowsSession?manifestId=' + manifestId + '&abc=' + act, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        /*const data = await response.json();*/
        const data = await commonErrorHandler(response);
        if (data.accessDenied == true) {
            //buy/bid is disabled
            //this.props.openDialog(1,
            //    "Restriction",
            //    "Sorry, Your account has been restricted from purchasing. Please contact your account manager.","Close", "", DIALOG_ACTION_CLOSE, null, null);
            this.props.openDialog(1,
                "Alert",
                "Manifest cannot be selected since maximum pallet per order is 26", "Close", "", DIALOG_ACTION_CLOSE, null, null);
            this.setState({
                selectSB: false,
            });
        }
        else if (data.addSBId == -2) {
            this.props.openDialog(1,
                "Alert",
                "Manifest cannot be selected since maximum pallet per order is 26", "Close", "", DIALOG_ACTION_CLOSE, null, null);
            this.setState({
                selectSB: false,
            });
        }
        else {

            this.setState({
                inventorydata: []
            })
            console.timeEnd("check SB");
            this.setState({ CheckedData: data, loading: false });
            console.time("PopulateInventory");
            this.populateInventoryData(false, true, token);
            this.setState({
                selectSB: false,
            });
            console.timeEnd("PopulateInventory");
            if (act == "add" && data.addSBId > 0)
                this.setState({ snackbarOpen: true, inventorydata: inventorydata });
            else if (act == "add" && data.addSBId == 0) {
                newSelected[manifestId] = false;
                this.setState({ snackbarUnavailableSB: true, inventorydata: inventorydata, selected: newSelected });
            }
            else
                this.setState({ snackbaruncheck: true, inventorydata: inventorydata });
        }
    }

    async populateInventoryData(pageLoad, sbCheck, Authtoken) {
        this.setState({ loading: true });
        let formData = new FormData();
        let token, action;
        action = this.props.CompleteOrder;
        if (Authtoken != undefined && Authtoken != "" && Authtoken != null) {
            token = Authtoken;
        }
        else {
            token = await authService.getAccessToken();
        }
        const response = await fetch('InventoryCode/SearchInventoryIsApply', {
            method: 'post',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,

            },
            body: formData
        });
        /*const data = await response.json();*/
        const data = await commonErrorHandler(response);

        //var chekpallet = data.value.checkpallet[0].isApplyPallet;
        //if (chekpallet < 1) {
        //    alert('ttt');
        //    //setTimeout(
        //    //    function () {
        //    //        this.props.openDialog(1,
        //    //            "Alert",
        //    //            "Apply To Pallet Rate Has Changed.Please Refresh Page", "Close", "", DIALOG_ACTION_CLOSE, null, null);
        //    //    }
        //    //        .bind(this),
        //    //    5000
        //    //);
        //    //window.location.reload();
        //  //  return false;
        //}

        if (data.value.manifestList == null) {
            data.value.manifestList = [];
        }
        //mahesh changes
        if (data.value.displaybiddetails == null) {
            data.value.displaybiddetails = [];
        }
        if (data.value.displaybidstatus == null) {
            data.value.displaybidstatus = [];
        }
        if (data.value.displayoutbidstatus == null) {
            data.value.displayoutbidstatus = [];
        }
        var displayoutbidstatus = data.value.displayoutbidstatus;
        var displaybidstatus = data.value.displaybidstatus;
        //end
        for (var i = 0; i < data.value.manifestList.length; i++) {
            var rate = 0.0;
            var rate = (data.value.manifestList[i].rate) * 100;
            data.value.manifestList[i].rate = rate.toFixed(2) + " %";
            var buynowrate = 0.0;
            var buynowrate = (data.value.manifestList[i].buynowrate) * 100;
            data.value.manifestList[i].buynowrate = buynowrate.toFixed(2) + " %";

            var makeofferrate = 0.0;
            var makeofferrate = (data.value.manifestList[i].makeofferrate) * 100;
            data.value.manifestList[i].makeofferrate = makeofferrate.toFixed(2) + " %";

            var cost = 0.0;
            /*var cost = (data.value.manifestList[i].cost) * 1.15;*/
            var cost = (data.value.manifestList[i].cost);
            data.value.manifestList[i].cost = cost.toFixed(2);
            var ratecost = 0.0;
            /* var ratecost = (data.value.manifestList[i].ratecost) * 1.15;*/
            var ratecost = (data.value.manifestList[i].ratecost);
            data.value.manifestList[i].ratecost = ratecost.toFixed(2);
            var rate_Cost = 0.0;
            data.value.manifestList[i].rate_Cost = (parseFloat(cost) * parseFloat(rate) / 100).toFixed(2);
            if (data.value.manifestList[i].warehouse == 0)
                data.value.manifestList[i].warehouse = "NotSpecified";
            else if (data.value.manifestList[i].warehouse == 8615)
                data.value.manifestList[i].warehouse = "Indianapolis";
            else if (data.value.manifestList[i].warehouse == 8616)
                data.value.manifestList[i].warehouse = "McDonough";
            else if (data.value.manifestList[i].warehouse == 8617)
                data.value.manifestList[i].warehouse = "Phoenix";
            else if (data.value.manifestList[i].warehouse == 8618)
                data.value.manifestList[i].warehouse = "Pittston";
            else if (data.value.manifestList[i].warehouse == 9999)
                data.value.manifestList[i].warehouse = "Other";
            else
                data.value.manifestList[i].warehouse = data.value.manifestList[i].warehouse;
            for (var j = 0; j < data.value.selectedTotalsBySb.split(',').length; j++) {
                if (data.value.selectedTotalsBySb.split(',')[j].split('|')[0] == data.value.manifestList[i].id) {
                    data.value.manifestList[i].isSelected = true;
                }
            }
        }
        var gridData = data.value.manifestList;
        //mahesh changes
        var displaybiddetails = data.value.displaybiddetails;
        //mahesh end
        //let allInventryClear = data.value.manifestList;
        var completeData = gridData;
        var dynamicCategoryList = [];
        for (var i = 0; i < gridData.length; i++) {
            let catCounnt = 0;
            for (var j = 0; j < dynamicCategoryList.length; j++) {
                if (dynamicCategoryList[j].name == gridData[i].salvageCategory) {
                    catCounnt++;
                }
            }
            if (catCounnt == 0) {
                dynamicCategoryList.push({ caption: gridData[i].salvageCategory, name: gridData[i].salvageCategory })
            }
        }
        var InventoryData = data.value.manifestList;
        let categoryName, warehouseName, allLocation;
        if (sbCheck) {
            categoryName = '';
            warehouseName = '';
            allLocation = '';
        }
        else {
            allLocation = this.state.AllLocation;
            categoryName = this.state.Category;
            warehouseName = this.state.Warehouse;
        }
        let filterList, categoryList, inventoryType, tempCategoryList = [], checkAll = false;
        inventoryType = this.state.inventoryType;
        filterList = this.state.filterList;
        categoryList = dynamicCategoryList;
        let allCategoryClear = categoryList.sort((a, b) => a.caption > b.caption ? 1 : -1);
        let selectType = this.state.inventoryType.filter(item => item.checked);
        let selectCat = this.state.categoryList.filter(item => item.checked);
        let select = this.state.filterList.filter(item => item.checked);
        if (categoryName != "" && categoryName != undefined && categoryName != null) {
            for (var i = 0; i < categoryList.length; i++) {
                if (categoryList[i].name.toUpperCase() == categoryName.toUpperCase() || categoryList[i].checked) {
                    categoryList[i].checked = true;
                }
                else {
                    categoryList[i].checked = false;
                }
                if (categoryName.toUpperCase() == "MIXED LOAD") {
                    if (categoryList[i].name.toUpperCase() == "001" || categoryList[i].checked)
                        categoryList[i].checked = true;
                }
            }
            if (selectCat.length > 0) {
                gridData = gridData.filter(o1 => selectCat.some(o2 => o1.salvageCategory.toUpperCase() === o2.name.toUpperCase()))
            }
            else {
                if (categoryName.toUpperCase() == "MIXED LOAD") {
                    gridData = gridData.filter(x => x.salvageCategory.toUpperCase() == categoryName.toUpperCase() || x.salvageCategory.toUpperCase() == "001")
                }
                else
                    gridData = gridData.filter(x => x.salvageCategory.toUpperCase() == categoryName.toUpperCase())
                if (gridData.length != 0) {
                    for (var i = 0; i < gridData.length; i++) {
                        for (var j = 0; j < filterList.length; j++) {
                            if (filterList[j].name.toUpperCase() == gridData[i].warehouse.toUpperCase())
                                filterList[j].checked = true;
                        }
                        for (var k = 0; k < inventoryType.length; k++) {
                            if (inventoryType[k].name.toUpperCase() == gridData[i].hdDotCom.toUpperCase())
                                inventoryType[k].checked = true;
                        }
                    }

                    // function for select categoryList on inventoryType and Warehouse for selected category
                    tempCategoryList = getCategoryListByCategory(InventoryData, filterList, inventoryType, categoryName, "")

                    dynamicCategoryList = tempCategoryList;
                }
                else {
                    for (var j = 0; j < categoryList.length; j++) {
                        if (categoryList[j].caption.toUpperCase() == categoryName.toUpperCase()) {
                            tempCategoryList.push({ caption: categoryName.toUpperCase(), name: categoryName.toUpperCase(), checked: true });
                        }
                        else {
                            tempCategoryList.push({ caption: categoryList[j].caption, name: categoryList[j].caption, checked: false });
                        }
                    }
                    dynamicCategoryList = tempCategoryList;
                }

            }
        }
        else if (warehouseName != "" && warehouseName != undefined && warehouseName != null) {
            for (var i = 0; i < filterList.length; i++) {
                if (filterList[i].name.toUpperCase() == warehouseName.toUpperCase() || filterList[i].checked)
                    filterList[i].checked = true;
            }
            if (select.length > 0) {
                gridData = gridData.filter(o1 => select.some(o2 => o1.warehouseId === o2.id))
            }
            else {
                gridData = gridData.filter(x => x.warehouse == warehouseName)
                dynamicCategoryList = getCategoryList(gridData);
                for (var i = 0; i < gridData.length; i++) {
                    for (var k = 0; k < inventoryType.length; k++) {
                        if (inventoryType[k].name.toUpperCase() == gridData[i].hdDotCom.toUpperCase())
                            inventoryType[k].checked = true;
                    }
                    for (var l = 0; l < dynamicCategoryList.length; l++) {
                        if (dynamicCategoryList[l].caption.toUpperCase() == gridData[i].salvageCategory.toUpperCase())
                            dynamicCategoryList[l].checked = true;
                    }
                }
                categoryList = dynamicCategoryList;
            }
        }
        else if ((allLocation != "" && allLocation != undefined && allLocation != null) || sbCheck == undefined) {
            if (allLocation == "AllLocationn" && this.props.Filters.inventoryType.length > 0) {
                inventoryType = this.props.Filters.inventoryType;
                filterList = this.props.Filters.filterList;
                dynamicCategoryList = this.props.Filters.categoryList;

                select = filterList.filter(item => item.checked);
                selectCat = dynamicCategoryList.filter(item => item.checked);
                selectType = inventoryType.filter(item => item.checked);
                if (selectType.length > 0) {
                    gridData = gridData.filter(o1 => selectType.some(o2 => o1.hdDotCom.toUpperCase() === o2.value.toUpperCase()));
                }
                if (select.length > 0) {
                    gridData = gridData.filter(o1 => select.some(o2 => o1.warehouseId === o2.id));
                }
                if (selectCat.length > 0) {
                    gridData = gridData.filter(o1 => selectCat.some(o2 => o1.salvageCategory === o2.caption));
                }
            }
            else if (gridData.length != 0) {
                for (var i = 0; i < gridData.length; i++) {
                    for (var j = 0; j < filterList.length; j++) {
                        if (filterList[j].name.toUpperCase() == gridData[i].warehouse.toUpperCase())
                            filterList[j].checked = true;
                    }
                    for (var k = 0; k < inventoryType.length; k++) {
                        if (inventoryType[k].name.toUpperCase() == gridData[i].hdDotCom.toUpperCase())
                            inventoryType[k].checked = true;
                    }
                    for (var l = 0; l < dynamicCategoryList.length; l++) {
                        if (dynamicCategoryList[l].caption.toUpperCase() == gridData[i].salvageCategory.toUpperCase())
                            dynamicCategoryList[l].checked = true;
                    }
                }
                categoryList = dynamicCategoryList;
                checkAll = true;
            }
        }
        if (sbCheck) {
            if ((selectType.length === 0 || selectType.length === filterList.length) && (select.length === 0 || select.length === filterList.length) && (selectCat.length === 0 || selectCat === categoryList.length)) {
                dynamicCategoryList = getCategoryData(completeData, selectCat);
            }
            else {
                if (selectType.length != 0) {
                    completeData = completeData.filter(o1 => selectType.some(o2 => o1.hdDotCom.toUpperCase() === o2.value.toUpperCase()));
                    dynamicCategoryList = getCategoryData(completeData, selectCat);
                }
                if (select.length != 0) {
                    completeData = completeData.filter(o1 => select.some(o2 => o1.warehouseId === o2.id));
                    dynamicCategoryList = getCategoryData(completeData, selectCat);
                }
                if (selectCat.length != 0) {
                    completeData = completeData.filter(o1 => selectCat.some(o2 => o1.salvageCategory === o2.caption));
                    if (select.length == 0 && selectType.length == 0) {
                        let AllCategory = this.state.AllCategory;
                        dynamicCategoryList = getAllCategory(selectCat, AllCategory);
                    }
                }
            }
            gridData = completeData;
            this.updateViewCart(data.value);
        }
        if (InventoryData.length > 0) {
            dynamicCategoryList = dynamicCategoryList.sort((a, b) => a.caption > b.caption ? 1 : -1)
            categoryList = categoryList.sort((a, b) => a.caption > b.caption ? 1 : -1)
        }
        else {
            dynamicCategoryList = this.state.DemoCategories.sort((a, b) => a.caption > b.caption ? 1 : -1)
            categoryList = this.state.DemoCategories.sort((a, b) => a.caption > b.caption ? 1 : -1)
            allCategoryClear = this.state.DemoCategories.sort((a, b) => a.caption > b.caption ? 1 : -1)
        }
        this.props.fetchFilters(inventoryType, dynamicCategoryList, filterList);
        this.setState({ TotalsBySb: data.value.selectedTotalsBySb, MaximumPallet: data.value.maximumPalletPerOrder, selectAllCheck: checkAll, AllCategoryClear: allCategoryClear })
        this.setState({ inventorydata: gridData, inventoryOriginalData: InventoryData, categoryList: dynamicCategoryList, gettimedataa: displaybiddetails, getdisplaybidstatus: displaybidstatus, getdisplayoutbidstatus: displayoutbidstatus, AllCategory: categoryList, filterList: filterList, inventoryType: inventoryType, loading: false })
        if (pageLoad) {
            this.intervalID = setTimeout(this.populateInventory.bind(this, token), 6000);
        }
    }

    async populateInventory(Authtoken) {
        let formData = new FormData();
        let token;
        if (Authtoken != undefined && Authtoken != "" && Authtoken != null) {
            token = Authtoken;
        }
        else {
            token = await authService.getAccessToken();
        }
        const response = await fetch('InventoryCode/SearchInventoryIsApply', {
            method: 'post',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,

            },
            body: formData
        });
        /*const data = await response.json();*/
        const data = await commonErrorHandler(response);

        //var chekpallet = data.value.checkpallet[0].isApplyPallet;
        //if (chekpallet < 1) {
        //    alert('tt');

        //    //setTimeout(
        //    //    function () {
        //    //        this.props.openDialog(1,
        //    //            "Alert",
        //    //            "Apply To Pallet Rate Has Changed.Please Refresh Page.Please Refresh Page", "Close", "", DIALOG_ACTION_CLOSE, null, null);
        //    //    }
        //    //        .bind(this),
        //    //    5000
        //    //);
        //    //window.location.reload();
        //    return false;
        //}


        if (data.value.manifestList == null) {
            data.value.manifestList = [];
        }

        if (data.value.displaybiddetails == null) {
            data.value.displaybiddetails = [];
        }
        if (data.value.displaybidstatus == null) {
            data.value.displaybidstatus = [];
        }
        if (data.value.displayoutbidstatus == null) {
            data.value.displayoutbidstatus = [];
        }
        var displayoutbidstatus = data.value.displayoutbidstatus;
        var displaybidstatus = data.value.displaybidstatus;
        var displaybiddetails = data.value.displaybiddetails;

        if (data.value.manifestList == null && data.value.warehouseId == -1) {
            this.setState({
                inventorydata: this.state.inventorydata
            });
        }
        else {
            var InventoryData = data.value.manifestList;
            for (var i = 0; i < data.value.manifestList.length; i++) {
                var rate = 0.0;
                var rate = (data.value.manifestList[i].rate) * 100;
                data.value.manifestList[i].rate = rate.toFixed(2) + " %";
                var buynowrate = 0.0;
                var buynowrate = (data.value.manifestList[i].buynowrate) * 100;
                data.value.manifestList[i].buynowrate = buynowrate.toFixed(2) + " %";
                var makeofferrate = 0.0;
                var makeofferrate = (data.value.manifestList[i].makeofferrate) * 100;
                data.value.manifestList[i].makeofferrate = makeofferrate.toFixed(2) + " %";


                var cost = 0.0;
                /*var cost = (data.value.manifestList[i].cost) * 1.15;*/
                var cost = (data.value.manifestList[i].cost);
                data.value.manifestList[i].cost = cost.toFixed(2);
                var ratecost = 0.0;
                /*var ratecost = (data.value.manifestList[i].ratecost) * 1.15;*/
                var ratecost = (data.value.manifestList[i].ratecost);
                data.value.manifestList[i].ratecost = ratecost.toFixed(2);
                var rate_Cost = 0.0;
                data.value.manifestList[i].rate_Cost = (parseFloat(cost) * parseFloat(rate) / 100).toFixed(2);
                if (data.value.manifestList[i].warehouse == 0)
                    data.value.manifestList[i].warehouse = "NotSpecified";
                else if (data.value.manifestList[i].warehouse == 8615)
                    data.value.manifestList[i].warehouse = "Indianapolis";
                else if (data.value.manifestList[i].warehouse == 8616)
                    data.value.manifestList[i].warehouse = "McDonough";
                else if (data.value.manifestList[i].warehouse == 8617)
                    data.value.manifestList[i].warehouse = "Phoenix";
                else if (data.value.manifestList[i].warehouse == 8618)
                    data.value.manifestList[i].warehouse = "Pittston";
                else if (data.value.manifestList[i].warehouse == 9999)
                    data.value.manifestList[i].warehouse = "Other";
                else
                    data.value.manifestList[i].warehouse = data.value.manifestList[i].warehouse;
                for (var j = 0; j < data.value.selectedTotalsBySb.split(',').length; j++) {
                    if (data.value.selectedTotalsBySb.split(',')[j].split('|')[0] == data.value.manifestList[i].id) {
                        data.value.manifestList[i].isSelected = true;
                    }
                }
            }
            var gridData = data.value.manifestList;
            let select, selectCat, selectType;
            let filterList, categoryList, inventoryType, categoryName;
            let prevData = this.state.inventoryOriginalData;
            filterList = this.state.filterList;
            categoryList = this.state.categoryList;
            inventoryType = this.state.inventoryType;
            categoryName = this.state.Category;
            if (gridData.length == prevData.length) {
                select = this.state.filterList.filter(item => item.checked);
                selectCat = this.state.categoryList.filter(item => item.checked);
                selectType = this.state.inventoryType.filter(item => item.checked);

                if ((selectType.length === 0 || selectType.length === inventoryType.length) && (select.length === 0 || select.length === filterList.length) && (selectCat.length === 0 || selectCat === categoryList.length) && categoryName == "") {
                    gridData = gridData;
                }
                else {
                    if (selectType.length != 0) {
                        gridData = gridData.filter(o1 => selectType.some(o2 => o1.hdDotCom.toUpperCase() === o2.value.toUpperCase()));
                    }
                    if (select.length != 0) {
                        gridData = gridData.filter(o1 => select.some(o2 => o1.warehouseId === o2.id));
                    }
                    if (selectCat.length != 0 && categoryList.filter(x1 => selectCat.some(x2 => x1.caption === x2.caption)).length > 0) {
                        gridData = gridData.filter(o1 => selectCat.some(o2 => o1.salvageCategory === o2.caption));
                    }
                    if (categoryName != "" && categoryName != undefined) {
                        if (categoryName.toUpperCase() == "MIXED LOAD") {
                            gridData = gridData.filter(x => x.salvageCategory.toUpperCase() == categoryName.toUpperCase() || x.salvageCategory.toUpperCase() == "001")
                        }
                        else
                            gridData = gridData.filter(x => x.salvageCategory.toUpperCase() == categoryName.toUpperCase())
                    }
                }
                if (this.state.fieldName == "salvageCategory") {
                    this.getDataBySearch(this.state.searchText.toUpperCase(), this.state.fieldName);
                }
                else if (this.state.searchText != "" && this.state.fieldName != "salvageCategory") {
                    if (this.state.fieldName === "Select") {
                        gridData = this.search(gridData, this.state.searchText)
                    } else if (this.state.fieldName != "Select") {
                        if (this.state.fieldName === "containerQuantity") {
                            gridData = gridData.filter(a => a[this.state.fieldName] == this.state.searchText);
                        }
                        else
                            gridData = gridData.filter(a => a[this.state.fieldName].toLowerCase().includes(this.state.searchText.toLowerCase()));
                    }
                }
                this.setState({
                    fieldName: this.state.fieldName, searchText: this.state.searchText,
                    inventoryType: inventoryType, filterList: filterList, categoryList: categoryList,
                    inventorydata: gridData, inventoryOriginalData: InventoryData, gettimedataa: displaybiddetails,
                    getdisplaybidstatus: displaybidstatus, getdisplayoutbidstatus: displayoutbidstatus
                });
            }
            else {
                InventoryData = gridData;
                this.inventoryUpload(InventoryData)
            }
        }
        this.props.getCartSummary();
        this.intervalID = setTimeout(this.populateInventory.bind(this, token), 6000);
    }

    async inventoryUpload(InventoryData) {
        var AllInventory = InventoryData;
        var dynamicCategoryList = [];
        for (let i = 0; i < InventoryData.length; i++) {
            let catCounnt = 0;
            for (let j = 0; j < dynamicCategoryList.length; j++) {
                if (dynamicCategoryList[j].name == InventoryData[i].salvageCategory) {
                    catCounnt++;
                }
            }
            if (catCounnt == 0) {
                dynamicCategoryList.push({ caption: InventoryData[i].salvageCategory, name: InventoryData[i].salvageCategory })
            }
        }
        var filterList, categoryList, inventoryType, allCategoryList, data;
        var select, selectCat, selectType;
        allCategoryList = dynamicCategoryList;

        inventoryType = this.props.Filters.inventoryType;
        filterList = this.props.Filters.filterList;
        categoryList = this.props.Filters.categoryList;

        select = filterList.filter(item => item.checked);
        selectCat = categoryList.filter(item => item.checked);
        selectType = inventoryType.filter(item => item.checked);
        for (var i = 0; i < dynamicCategoryList.length; i++) {
            for (var j = 0; j < selectCat.length; j++) {
                if (dynamicCategoryList[i].caption.toUpperCase() == selectCat[j].caption.toUpperCase()) {
                    dynamicCategoryList[i].checked = true;
                }
            }
        }
        categoryList = dynamicCategoryList;
        if (InventoryData.length > 0 && (selectType.length != 0 || select.length != 0 || selectCat.length != 0)) {
            if (selectType.length > 0) {
                InventoryData = InventoryData.filter(o1 => selectType.some(o2 => o1.hdDotCom.toUpperCase() === o2.value.toUpperCase()));
                categoryList = getCategoryData(InventoryData, selectCat);
            }
            if (select.length > 0) {
                InventoryData = InventoryData.filter(o1 => select.some(o2 => o1.warehouseId === o2.id));
                categoryList = getCategoryData(InventoryData, selectCat);
            }
            if (selectCat.length > 0) {
                InventoryData = InventoryData.filter(o1 => selectCat.some(o2 => o1.salvageCategory === o2.caption));
            }
        }
        else {
            for (var i = 0; i < inventoryType.length; i++) {
                inventoryType[i].checked = false;
            }
            for (var i = 0; i < filterList.length; i++) {
                filterList[i].checked = false;
            }
            categoryList = dynamicCategoryList;
        }
        data = InventoryData;
        if (this.state.fieldName == "salvageCategory") {
            this.getDataBySearch(this.state.searchText.toUpperCase(), this.state.fieldName);
        }
        else if (this.state.searchText != "" && this.state.fieldName != "salvageCategory") {
            if (this.state.fieldName === "Select") {
                data = this.search(data, this.state.searchText)
            } else if (this.state.fieldName != "Select") {
                if (this.state.fieldName === "containerQuantity") {
                    data = data.filter(a => a[this.state.fieldName] == this.state.searchText);
                }
                else
                    data = data.filter(a => a[this.state.fieldName].toLowerCase().includes(this.state.searchText.toLowerCase()));
            }
        }
        if (selectCat.length == 0) {
            for (var i = 0; i < dynamicCategoryList.length; i++) {
                dynamicCategoryList[i].checked = false;
            }
        }
        dynamicCategoryList = dynamicCategoryList.sort((a, b) => a.caption > b.caption ? 1 : -1);
        categoryList = categoryList.sort((a, b) => a.caption > b.caption ? 1 : -1);
        this.setState({
            fieldName: this.state.fieldName, searchText: this.state.searchText,
            inventoryType: inventoryType, filterList: filterList, categoryList: categoryList, AllCategory: dynamicCategoryList, AllCategoryClear: dynamicCategoryList,
            inventorydata: data, inventoryOriginalData: AllInventory
        })
    }
    async updateViewCart(data) {
        var checkedInventory = [];
        var Item = 0;
        var WholeSale = 0.0;
        var Amount = 0.0;
        var PalletQty = 0;
        if (data.selectedTotalsBySb != null && data.selectedTotalsBySb != "") {
            for (var i = 0; i < data.selectedTotalsBySb.split(',').length; i++) {

                var obj = {};
                obj["manifestId"] = data.selectedTotalsBySb.split(',')[i].split('|')[0];
                obj["sbNumber"] = data.selectedTotalsBySb.split(',')[i].split('|')[1];
                obj["category"] = data.selectedTotalsBySb.split(',')[i].split('|')[2];
                obj["type"] = data.selectedTotalsBySb.split(',')[i].split('|')[3];
                obj["palletQuantity"] = data.selectedTotalsBySb.split(',')[i].split('|')[4];
                obj["totalWeight"] = data.selectedTotalsBySb.split(',')[i].split('|')[5];
                obj["wholesale"] = parseFloat(data.selectedTotalsBySb.split(',')[i].split('|')[6]).toFixed(2);
                obj["rate%"] = data.selectedTotalsBySb.split(',')[i].split('|')[7];
                obj["itemQuantity"] = data.selectedTotalsBySb.split(',')[i].split('|')[8];
                obj["warehouseId"] = data.selectedTotalsBySb.split(',')[i].split('|')[9];
                checkedInventory.push(Object.values(obj));
            }
            for (var j = 0; j < data.selectedTotalsBySb.split(',').length; j++) {
                WholeSale += parseFloat(data.selectedTotalsBySb.split(',')[j].split('|')[6]);
                PalletQty += parseInt(data.selectedTotalsBySb.split(',')[j].split('|')[4])
                Amount += parseFloat(data.selectedTotalsBySb.split(',')[j].split('|')[6]) * parseFloat(data.selectedTotalsBySb.split(',')[j].split('|')[7]) / 100
            }
            WholeSale = WholeSale.toFixed(2);
            Amount = Amount.toFixed(2);
            Item = data.selectedTotalsBySb.split(',').length;

        }
        if (data.manifestList != "" && data.manifestList != null) {
            var gridData = data.manifestList;
            var dynamicCategoryList = [];
            for (var i = 0; i < gridData.length; i++) {
                let catCounnt = 0;
                for (var j = 0; j < dynamicCategoryList.length; j++) {
                    if (dynamicCategoryList[j] == gridData[i].salvageCategory) {
                        catCounnt++;
                    }
                }
                if (catCounnt == 0) {
                    dynamicCategoryList.push(gridData[i].salvageCategory)
                }
            }
        }
        this.props.viewCartUpdate(Item, Amount, checkedInventory, PalletQty, dynamicCategoryList);
    }
}

function getCategoryList(gridData) {
    let dynamicCategoryList = [];
    for (var i = 0; i < gridData.length; i++) {
        let count = 0;
        for (var j = 0; j < dynamicCategoryList.length; j++) {
            if (dynamicCategoryList[j].name == gridData[i].salvageCategory) {
                count++;
            }
        }
        if (count == 0) {
            dynamicCategoryList.push({ caption: gridData[i].salvageCategory, name: gridData[i].salvageCategory, checked: false })
        }
    }
    return dynamicCategoryList;
}
function getCategoryData(gridData, selectCat) {
    let dynamicCategoryList = [];
    let updatedCatList = [];
    for (var i = 0; i < gridData.length; i++) {
        let count = 0;
        for (var j = 0; j < dynamicCategoryList.length; j++) {
            if (dynamicCategoryList[j].name == gridData[i].salvageCategory) {
                count++;
            }
        }
        if (count == 0) {
            dynamicCategoryList.push({ caption: gridData[i].salvageCategory, name: gridData[i].salvageCategory })
        }
    }
    if (selectCat.length > 0) {
        for (var j = 0; j < dynamicCategoryList.length; j++) {
            let cnt = 0;
            for (var i = 0; i < selectCat.length; i++) {
                if (selectCat[i].caption == dynamicCategoryList[j].caption && selectCat[i].checked)
                    cnt++;
            }
            if (cnt > 0)
                updatedCatList.push({ caption: dynamicCategoryList[j].caption, name: dynamicCategoryList[j].caption, checked: true })
            else
                updatedCatList.push({ caption: dynamicCategoryList[j].caption, name: dynamicCategoryList[j].caption, checked: false })
        }
    }
    else {
        for (var i = 0; i < dynamicCategoryList.length; i++) {
            updatedCatList.push({ caption: dynamicCategoryList[i].caption, name: dynamicCategoryList[i].caption, checked: false })
        }
    }

    return updatedCatList.sort((a, b) => a.caption > b.caption ? 1 : -1);
}

function getAllCategory(selectCat, AllCategory) {
    let updatedCatList = [];
    for (var j = 0; j < AllCategory.length; j++) {
        let cnt = 0;
        for (var i = 0; i < selectCat.length; i++) {
            if (selectCat[i].caption == AllCategory[j].caption && selectCat[i].checked)
                cnt++;
        }
        if (cnt > 0) {
            updatedCatList.push({ caption: AllCategory[j].caption, name: AllCategory[j].caption, checked: true })
        }
        else
            updatedCatList.push({ caption: AllCategory[j].caption, name: AllCategory[j].caption, checked: false })
    }
    return updatedCatList.sort((a, b) => a.caption > b.caption ? 1 : -1);
}
function getCategoryListByCategory(InventoryData, filterList, inventoryType, categoryName, selectCat) {
    let selectType, select, tempCategoryList = [];
    selectType = inventoryType.filter(item => item.checked);
    select = filterList.filter(item => item.checked);
    if (selectType.length > 0) {
        InventoryData = InventoryData.filter(x => selectType.some(y => x.hdDotCom.toUpperCase() === y.name.toUpperCase()));
    }
    if (select.length > 0) {
        InventoryData = InventoryData.filter(o1 => select.some(o2 => o1.warehouseId === o2.id));
    }
    for (var i = 0; i < InventoryData.length; i++) {
        let count = 0;
        for (var j = 0; j < tempCategoryList.length; j++) {
            if (tempCategoryList[j].name == InventoryData[i].salvageCategory) {
                count++;
            }
        }
        if (count == 0) {
            if (categoryName != "") {
                if (InventoryData[i].salvageCategory == categoryName)
                    tempCategoryList.push({ caption: InventoryData[i].salvageCategory, name: InventoryData[i].salvageCategory, checked: true })
                else
                    tempCategoryList.push({ caption: InventoryData[i].salvageCategory, name: InventoryData[i].salvageCategory, checked: false })
                if (categoryName.toUpperCase() == "MIXED LOAD") {
                    if (InventoryData[i].salvageCategory == "001")
                        //tempCategoryList.push({ caption: InventoryData[i].salvageCategory, name: InventoryData[i].salvageCategory, checked: true })
                        for (var k = 0; k < tempCategoryList.length; k++) {
                            if (tempCategoryList[k].caption == "001")
                                tempCategoryList[k].checked = true;
                        }
                }
            }
            else if (selectCat.length > 0) {
                for (var k = 0; k < selectCat.length; k++) {
                    if (InventoryData[i].salvageCategory == selectCat[k].caption)
                        tempCategoryList.push({ caption: InventoryData[i].salvageCategory, name: InventoryData[i].salvageCategory, checked: true })
                }
                if (tempCategoryList.filter(x => x.caption == InventoryData[i].salvageCategory).length == 0) {
                    tempCategoryList.push({ caption: InventoryData[i].salvageCategory, name: InventoryData[i].salvageCategory, checked: false })
                }

            }
        }
    }
    return tempCategoryList.sort((a, b) => a.caption > b.caption ? 1 : -1);
}


const mapStateToProps = state => ({
    search: state.searchText,
    cart: state.cart,
    clearSearch: state.clearSearch,
    Filters: state.fetchFilters
});

const mapActionsToProps = {
    getCartSummary: updateCartData,
    openDialog: openDialog,
    fetchOrders: fetchOrders,
    setClearSearch: updateClearSearch,
    viewCartUpdate: updateViewCart,
    fetchFilters: FetchInventoryFilters
}
export default compose(connect(mapStateToProps, mapActionsToProps), withStyles(styles))(InventoryBidDT);
