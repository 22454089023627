import React, { Component } from 'react';
import { Col, Row, Form, Button, ButtonToolbar, Container } from 'react-bootstrap';
import ContentLayout from "../components/ContentLayout";
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import ArchiveOrderData from '../components/DataTable/ArchiveOrderData';
import OrderDetailModal from '../components/DataTable/Order-Detail-Modal';
import authService from '../components/api-authorization/AuthorizeService'
import { connect } from 'react-redux';
import { updateSearchList, returnUpdateSearch, updateOrderSearchList } from '../actions/search-actions';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Icon from '@material-ui/core/Icon';

class MyOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderdata: [], loading: true, data: '',
            orderDetail: [], orderDetailDate: []
        };
        this.props.updateOrderSearchList([
            {
                id: '1',
                caption: 'Order Number',
                name: 'orderNumber'
            }]);
        this.handleChange = this.handleChange.bind(this);
        this.props.updateSearchList([]);
        this.props.returnUpdateSearch();
    }

    handleChange(event) {
        this.setState({ data: event.target.value, loading: false });
        this.props.history.replace({ state: event.target.value });
        this.setState({
            orderDetail: [], loading: true
        });
    }
    checkData = (orderNumber, status) => {
        this.setState({
            orderNumber: orderNumber, status: status.original.orderStatus, warehouse: status.original.warehouse, orderDetail: [], loading: true
        });
        this.populateOrderDetailById(orderNumber);

    }

    async populateOrderDetailById(orderNumber) {
        const token = await authService.getAccessToken();
        const response = await fetch('Order/Detail?id=' + orderNumber, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ orderDetail: data.manifestList, loading: false });
        this.setState({ orderDetailDate: data.orderDetail, loading: false });
    }

    navigateToInventory = () => {
        this.props.history.push({
            pathname: "/Inventory"
        })
    }
    render() {
        return (
            <ContentLayout>
                <Breadcrumb
                    breadcrumb={[
                        { name: "Home", path: "/" },
                        { name: "My Orders", path: "/MyOrders" },
                        { name: "Archive Orders", path: "/ArchiveOrders" }
                    ]}
                />
                <div className="bg-grey pt-5 pb-2">  <Button className="text-white" onClick={() => this.navigateToInventory()} variant="outline-secondary" style={{ backgroundColor: "#273546" }}><ArrowBackIcon /> <span>Back to Inventory</span></Button>
                    <Container fluid className="pl-5 pr-5">
                        <Row>
                            <Col lg="8">
                                <form>
                                    <Form.Group className="mb-0 w-100 bg-white p-3 search-byshor">
                                        <Form.Label>
                                            Sort by Status:
                                        </Form.Label>
                                        <Form.Control as="select" value={this.props.location.state} onChange={this.handleChange}>
                                            <option value="All">All</option>
                                            <option value="Open">Open</option>
                                            <option value="PendingApproval">Pending Approval</option>
                                            <option value="Assigned">Assigned</option>
                                            <option value="Approved">Approved</option>
                                            <option value="Shipped">Shipped</option>
                                            <option value="PartiallyShipped">Partially Shipped</option>
                                            <option value="Cancelled">Cancelled</option>
                                            <option value="Scheduled">Scheduled</option>
                                            <option value="PartiallyScheduled">Partially Scheduled</option>
                                        </Form.Control>
                                    </Form.Group>
                                </form>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="content invoices-contant border-top bg-grey pt-0 pb-5">

                    <Container fluid className="pl-5 pr-5">
                        <Row className="justify-content-center">
                            <Col md="12">
                                <ArchiveOrderData goto={(orderNumber, status) => this.checkData(orderNumber, status)} data={this.props.location.state} orderNumber={this.props.location.orderNumber} />
                            </Col>
                        </Row>
                    </Container>

                </div>
            </ContentLayout>
        );
    }

}


const mapStateToProps = state => ({
    userOrders: state.orders
});

const mapActionsToProps = {
    updateSearchList: updateSearchList,
    returnUpdateSearch: returnUpdateSearch,
    updateOrderSearchList: updateOrderSearchList
}

export default connect(mapStateToProps, mapActionsToProps)(MyOrders);