import React, { Component } from 'react';
import ContentLayout from "../components/ContentLayout";
import { Link } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import { Col, Container, Row, Card, Button, Nav, Form } from 'react-bootstrap';
import InventoryDT from '../components/DataTable/InventoryDatatable';
import TruckInventoryDT from '../components/DataTable/TruckLoadInventoryDatatable';
import CanvasJSReact from '../assets/css/canvasjs.react';
import SyncLoader from "react-spinners/SyncLoader";
import { css } from "@emotion/core";
import authService from '../components/api-authorization/AuthorizeService';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import { updateSearchList, updateSearchListt, returnUpdateSearch } from '../actions/search-actions';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const override = css`
  display: block;
  margin: 2 auto;
  border-color: red;
`;
const style = { position: "fixed", top: "45%", left: "50%", transform: "translate(-50%, -50%)" };

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            DashboardOrderData: [], DashboardTruckOrderData: [], DashboardInvoiceData: {}, DashboardCreditData: {}, TotalOutstandingInvoices: "", TotalOutstandingInvoiceLimit: "", TotalOverdueInvoices: "", loading: true
        };
        this.props.updateSearchList([]);
        this.props.updateSearchListt([]);
        this.props.returnUpdateSearch();
    }
    //componentWillMount()
    componentDidMount() {
        this.populateDashboardData();
    }
    handleOrderClick(status) {
        this.props.history.push({
            pathname: '/MyOrders',
            state: status
        })

    }
    handleInvoiceClick(status) {
        this.props.history.push({
            pathname: '/Invoices',
            state: status,
            dashboard: true
        })
    }
    handleCreditClick(status) {
        this.props.history.push({
            pathname: '/Managecreditrequests',
            state: status
        })
    }
    navigateToInventory = () => {
        this.props.history.push({
            pathname: "/Inventory"
        })
    }
    render() {

        //// Chart 1
        //const options1 = {
        //  animationEnabled: true,
        //  exportEnabled: true,
        //  theme: "light2",
        //  title:{
        //    text: ""
        //  },
        //  axisY: {
        //     title: "Number of Orders",
        //     includeZero: false
        //  },
        //  axisX: {
        //    prefix: "",
        //    interval: 1
        //    },
        //    toolTip: {
        //        shared: true
        //    },
        //  data: [{
        //      type: "area",
        //      name:"Orders",
        //    dataPoints: [
        //        { x: 1, label: "Open", y: this.state.DashboardOrderData.openOrderCount },
        //        { x: 2, label: "PendingApproval", y: this.state.DashboardOrderData.pendingApprovalOrderCount },
        //        { x: 3, label: "Assigned", y: this.state.DashboardOrderData.assignedOrderCount },
        //        { x: 4, label: "Approved", y: this.state.DashboardOrderData.approvedOrderCount },
        //        { x: 5, label: "Shipped", y: this.state.DashboardOrderData.shippedOrderCount },
        //        { x: 6, label: "Cancelled", y: this.state.DashboardOrderData.cancelledOrderCount },

        //    ]
        //  },
        //      {
        //          type: "area",
        //          name: "Credits",
        //          dataPoints: [
        //              {
        //                  x: 7, label: "PendingApproval", y: this.state.DashboardCreditData.pendingApprovalCreditRequestCount
        //              },
        //              {
        //                  x: 8, label: "Approved & Not Applied", y: this.state.DashboardCreditData.approvedAndNotAppliedCreditRequestCount
        //              },
        //              {
        //                  x: 9, label: "Approved&Applied", y: this.state.DashboardCreditData.approvedAndAppliedCreditRequestCount
        //              },
        //              {
        //                  x: 10, label: "Denied", y: this.state.DashboardCreditData.deniedCreditRequestCount
        //              },
        //              {
        //                  x: 11, label: "AutoDenied", y: 0
        //              },


        //          ]
        //      }]
        //}

        //// Chart 2
        //const options2 = {
        //  animationEnabled: true,
        //    subtitles: [{
        //        text: ((100 * this.state.DashboardOrderData.shippedOrderCount / this.state.DashboardOrderData.allOrderCount) + (100 * this.state.DashboardOrderData.approvedOrderCount / this.state.DashboardOrderData.allOrderCount)).toFixed(0) + "% Positive",
        //    verticalAlign: "center",
        //    fontSize: 24,
        //    dockInsidePlotArea: true
        //  }],
        //  data: [{
        //    type: "doughnut",
        //    showInLegend: true,
        //    indexLabel: "{name}: {y}",
        //    yValueFormatString: "#,###'%'",
        //    dataPoints: [
        //        { name: "Unsatisfied", y: (100 * this.state.DashboardOrderData.pendingApprovalOrderCount / this.state.DashboardOrderData.allOrderCount) },
        //        { name: "Very Unsatisfied", y: (100 * this.state.DashboardOrderData.cancelledOrderCount / this.state.DashboardOrderData.allOrderCount) },
        //        { name: "Very Satisfied", y: (100 * this.state.DashboardOrderData.shippedOrderCount / this.state.DashboardOrderData.allOrderCount) },
        //        { name: "Satisfied", y: (100 * this.state.DashboardOrderData.approvedOrderCount / this.state.DashboardOrderData.allOrderCount) },
        //        { name: "Neutral", y: (100 * this.state.DashboardOrderData.createOrderCount / this.state.DashboardOrderData.allOrderCount) + (100 * this.state.DashboardOrderData.assignedOrderCount / this.state.DashboardOrderData.allOrderCount) }
        //    ]
        //  }]
        // }


        if (this.state.loading && this.state.DashboardOrderData.length === 0) {

            return (
                <div>
                    <div>
                        <ContentLayout>

                            <Breadcrumb
                                breadcrumb={[
                                    { name: "Home", path: "/" },
                                    { name: "Dashboard", path: "/Dashboard" }
                                ]}

                            />

                            <div className="content dashboard-contant pt-5 pb-5">

                                <Button className="text-white" onClick={() => this.navigateToInventory()} variant="outline-secondary" style={{ backgroundColor: "#273546" }}><ArrowBackIcon /> <span>Back to Inventory</span></Button>
                                <Container>
                                    <Row>
                                        <Col lg="12">
                                            <h2 className="title mb-4">Active Orders, Invoices & Credits trending.</h2>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col xs="12" sm="4" lg="4">
                                            <Card className="text-white border-0 rounded thdmp-card p-3 bg-white h-100">
                                                <Card.Body className="p-0">
                                                    <Row>
                                                        <Col lg="8" xs="8">
                                                            <h5>Orders</h5>
                                                            {this.state.DashboardOrderData && this.state.DashboardOrderData.allOrderCount ? <h3 className="T-order">Total Orders: <span>{this.state.DashboardOrderData.allOrderCount}</span></h3> : <h3 className="T-order">Total Orders: <span>0</span></h3>}
                                                        </Col>
                                                        <Col lg="4" xs="4">
                                                            <span className="img-icon bg-red"><img src={'img/Orders.png'} alt="Order" /></span>
                                                        </Col>
                                                    </Row>
                                                    <div class="container">
                                                        <Nav as="ul" className="orders-list">
                                                            <Nav.Item as="li">
                                                                {this.state.DashboardOrderData && this.state.DashboardOrderData.openOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("Open")}><span id="hide_cursor">Open</span> <span>{this.state.DashboardOrderData.openOrderCount}</span></Nav.Link> : <Nav.Link onClick={() => this.handleOrderClick("Open")}><span id="hide_cursor">Open </span> <span>0</span></Nav.Link>}
                                                            </Nav.Item>
                                                            <Nav.Item as="li">
                                                                {this.state.DashboardOrderData && this.state.DashboardOrderData.pendingApprovalOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("PendingApproval")}><span id="hide_cursor">Pending appr.</span> <span>{this.state.DashboardOrderData.pendingApprovalOrderCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Pending appr.</span> <span>0</span></Nav.Link>}
                                                            </Nav.Item>
                                                            <Nav.Item as="li">
                                                                {this.state.DashboardOrderData && this.state.DashboardOrderData.assignedOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("Assigned")}><span id="hide_cursor">Assigned</span> <span>{this.state.DashboardOrderData.assignedOrderCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Assigned</span> <span>0</span></Nav.Link>}
                                                            </Nav.Item>
                                                            <Nav.Item as="li">
                                                                {this.state.DashboardOrderData && this.state.DashboardOrderData.approvedOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("Approved")}><span id="hide_cursor">Approved </span><span>{this.state.DashboardOrderData.approvedOrderCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor"><span id="hide_cursor">Approved</span></span> <span>0</span></Nav.Link>}
                                                            </Nav.Item>
                                                            <Nav.Item as="li">
                                                                {this.state.DashboardOrderData && this.state.DashboardOrderData.shippedOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("Shipped")}><span id="hide_cursor">Shipped </span><span>{this.state.DashboardOrderData.shippedOrderCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Shipped</span> <span>0</span></Nav.Link>}
                                                            </Nav.Item>
                                                            <Nav.Item as="li">
                                                                {this.state.DashboardOrderData && this.state.DashboardOrderData.partiallyShippedOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("PartiallyShipped")}><span id="hide_cursor">Partially Shipped </span><span>{this.state.DashboardOrderData.partiallyShippedOrderCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Partially Shipped</span> <span>0</span></Nav.Link>}
                                                                </Nav.Item>
                                                            <Nav.Item as="li">
                                                                {this.state.DashboardOrderData && this.state.DashboardOrderData.allOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("Cancelled")}><span id="hide_cursor">Cancelled </span><span>{this.state.DashboardOrderData.cancelledOrderCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Cancelled</span> <span>0</span></Nav.Link>}
                                                            </Nav.Item>
                                                        </Nav>
                                                        <div style={{ marginTop: "20px" }}>
                                                            <span style={{ color: "black", fontStyle: "italic" }}>Note: All the counts mentioned above include "Archived" records.</span>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        {/*<Col xs="12" sm="4" lg="4">*/}
                                        {/*    <Card className="text-white border-0 rounded thdmp-card p-3 bg-white h-100">*/}
                                        {/*        <Card.Body className="p-0">*/}
                                        {/*            <Row>*/}
                                        {/*                <Col lg="8" xs="8">*/}
                                        {/*                    <h5>TruckLoad Orders</h5>*/}
                                        {/*                    {this.state.DashboardTruckOrderData && this.state.DashboardTruckOrderData.truckOrderCount ? <h3 className="T-order">Total Truck Orders: <span>{this.state.DashboardOrderData.truckOrderCount}</span></h3> : <h3 className="T-order">Total Truck Orders: <span>0</span></h3>}*/}
                                        {/*                </Col>*/}
                                        {/*                <Col lg="4" xs="4">*/}
                                        {/*                    <span className="img-icon bg-red"><img src={'img/Orders.png'} alt="Order" /></span>*/}
                                        {/*                </Col>*/}
                                        {/*            </Row>*/}
                                        {/*            <div class="container">*/}
                                        {/*                <Nav as="ul" className="orders-list">*/}
                                        {/*                    <Nav.Item as="li">*/}
                                        {/*                        {this.state.DashboardOrderData && this.state.DashboardOrderData.openOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("Open")}><span id="hide_cursor">Open</span> <span>{this.state.DashboardOrderData.openOrderCount}</span></Nav.Link> : <Nav.Link onClick={() => this.handleOrderClick("Open")}><span id="hide_cursor">Open </span> <span>0</span></Nav.Link>}*/}
                                        {/*                    </Nav.Item>*/}
                                        {/*                    <Nav.Item as="li">*/}
                                        {/*                        {this.state.DashboardOrderData && this.state.DashboardOrderData.pendingApprovalOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("PendingApproval")}><span id="hide_cursor">Pending appr.</span> <span>{this.state.DashboardOrderData.pendingApprovalOrderCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Pending appr.</span> <span>0</span></Nav.Link>}*/}
                                        {/*                    </Nav.Item>*/}
                                        {/*                    <Nav.Item as="li">*/}
                                        {/*                        {this.state.DashboardOrderData && this.state.DashboardOrderData.assignedOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("Assigned")}><span id="hide_cursor">Assigned</span> <span>{this.state.DashboardOrderData.assignedOrderCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Assigned</span> <span>0</span></Nav.Link>}*/}
                                        {/*                    </Nav.Item>*/}
                                        {/*                    <Nav.Item as="li">*/}
                                        {/*                        {this.state.DashboardOrderData && this.state.DashboardOrderData.approvedOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("Approved")}><span id="hide_cursor">Approved </span><span>{this.state.DashboardOrderData.approvedOrderCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor"><span id="hide_cursor">Approved</span></span> <span>0</span></Nav.Link>}*/}
                                        {/*                    </Nav.Item>*/}
                                        {/*                    <Nav.Item as="li">*/}
                                        {/*                        {this.state.DashboardOrderData && this.state.DashboardOrderData.shippedOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("Shipped")}><span id="hide_cursor">Shipped </span><span>{this.state.DashboardOrderData.shippedOrderCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Shipped</span> <span>0</span></Nav.Link>}*/}
                                        {/*                    </Nav.Item>*/}
                                        {/*                    <Nav.Item as="li">*/}
                                        {/*                        {this.state.DashboardOrderData && this.state.DashboardOrderData.allOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("Cancelled")}><span id="hide_cursor">Cancelled </span><span>{this.state.DashboardOrderData.cancelledOrderCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Open</span> <span>0</span></Nav.Link>}*/}
                                        {/*                    </Nav.Item>*/}
                                        {/*                </Nav>*/}

                                        {/*            </div>*/}
                                        {/*        </Card.Body>*/}
                                        {/*    </Card>*/}
                                        {/*</Col>*/}

                                        <Col xs="12" sm="4" lg="4">
                                            <Card className="text-white border-0 rounded thdmp-card p-3 bg-white h-100">
                                                <Row>
                                                    <Col lg="8" xs="8">
                                                        <h5>Invoices</h5>
                                                        {this.state.DashboardInvoiceData && this.state.DashboardInvoiceData.allInvoiceCount ? <h3 className="T-order">Total Invoices: <span>{this.state.DashboardInvoiceData.allInvoiceCount}</span></h3> : <h3 className="T-order">Total Invoices: <span>0</span></h3>}
                                                    </Col>
                                                    <Col lg="4" xs="4">
                                                        <span className="img-icon bg-green"><img src={'img/Invoices.png'} alt="Invoices" /></span>
                                                    </Col>
                                                </Row>
                                                <Nav as="ul" className="orders-list">
                                                    {/*<Nav.Item as="li">*/}
                                                    {/*    {this.state.DashboardInvoiceData && this.state.DashboardInvoiceData.outstandingInvoiceCount ? <Nav.Link onClick={() => this.handleInvoiceClick("Outstanding")}><span id="hide_cursor">Outstanding</span> <span>{this.state.DashboardInvoiceData.outstandingInvoiceCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Outstanding</span> <span>0</span></Nav.Link>}*/}
                                                    {/*</Nav.Item>*/}
                                                    <Nav.Item as="li">
                                                        {this.state.DashboardInvoiceData && this.state.DashboardInvoiceData.invoicedInvoiceCount ? <Nav.Link onClick={() => this.handleInvoiceClick("Invoiced")}><span id="hide_cursor">Invoiced</span> <span>{this.state.DashboardInvoiceData.invoicedInvoiceCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Invoiced</span> <span>0</span></Nav.Link>}
                                                    </Nav.Item>
                                                    <Nav.Item as="li">
                                                        {this.state.DashboardInvoiceData && this.state.DashboardInvoiceData.overdueInvoiceCount ? <Nav.Link onClick={() => this.handleInvoiceClick("Overdue")}><span id="hide_cursor">Overdue </span> <span>{this.state.DashboardInvoiceData.overdueInvoiceCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Overdue</span> <span>0</span></Nav.Link>}
                                                    </Nav.Item>
                                                    <Nav.Item as="li">
                                                        {this.state.DashboardInvoiceData && this.state.DashboardInvoiceData.partiallyPaidInvoiceCount ? <Nav.Link onClick={() => this.handleInvoiceClick("PartiallyPaid")}><span id="hide_cursor">Partially Paid</span> <span>{this.state.DashboardInvoiceData.partiallyPaidInvoiceCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Partially Paid</span> <span>0</span></Nav.Link>}
                                                    </Nav.Item>
                                                    <Nav.Item as="li">
                                                        {this.state.DashboardInvoiceData && this.state.DashboardInvoiceData.paidInvoiceCount ? <Nav.Link onClick={() => this.handleInvoiceClick("PaidInFull")}><span id="hide_cursor">Paid in Full</span> <span>{this.state.DashboardInvoiceData.paidInvoiceCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Paid in Full</span> <span>0</span></Nav.Link>}
                                                    </Nav.Item>
                                                    <Nav.Item as="li">
                                                        {this.state.DashboardInvoiceData && this.state.DashboardInvoiceData.cancelledInvoiceCount ? <Nav.Link onClick={() => this.handleInvoiceClick("Cancelled")}><span id="hide_cursor">Cancelled</span> <span>{this.state.DashboardInvoiceData.cancelledInvoiceCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Cancelled</span> <span>0</span></Nav.Link>}
                                                    </Nav.Item>
                                                    <Nav.Item as="li"><Nav.Link href="#"><span id="hide_cursor">Total Outstanding Invoices<NumberFormat value={this.state.TotalOutstandingInvoices} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span></Nav.Link></Nav.Item>
                                                    <Nav.Item as="li"><Nav.Link href="#"><span id="hide_cursor">Total overdue Invoices<NumberFormat value={this.state.TotalOverdueInvoices} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span></Nav.Link></Nav.Item>
                                                    <Nav.Item as="li"><Nav.Link href="#"><span id="hide_cursor">Total Outstanding Invoice Limit<NumberFormat value={this.state.TotalOutstandingInvoiceLimit} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span></Nav.Link></Nav.Item>

                                                </Nav>
                                                <div style={{ marginTop: "20px" }}>
                                                    <span style={{ color: "black", fontStyle: "italic" }}>Note: All the counts mentioned above include "Archived" records.</span>
                                                </div>
                                            </Card>
                                        </Col>
                                        <Col xs="12" sm="4" lg="4">
                                            <Card className="text-white border-0 rounded thdmp-card p-3 bg-white h-100">
                                                <Row>
                                                    <Col lg="8" xs="8">
                                                        <h5>Credits</h5>
                                                        {this.state.DashboardCreditData && this.state.DashboardCreditData.allCreditRequestCount ? <h3 className="T-order">Total Credits: <span>{this.state.DashboardCreditData.allCreditRequestCount
                                                        }</span></h3> : <h3 className="T-order">Total Credits: <span>0</span></h3>}
                                                    </Col>
                                                    <Col lg="4" xs="4">
                                                        <span className="img-icon bg-blue"><img src={'img/Credits.png'} alt="Credits" /></span>
                                                    </Col>
                                                </Row>
                                                <Nav as="ul" className="orders-list">
                                                    <Nav.Item as="li">
                                                        {this.state.DashboardCreditData && this.state.DashboardCreditData.pendingApprovalCreditRequestCount ? <Nav.Link onClick={() => this.handleCreditClick("Pending Approval")}><span id="hide_cursor">Pending Appr.</span> <span>{this.state.DashboardCreditData.pendingApprovalCreditRequestCount
                                                        }</span></Nav.Link> : <Nav.Link onClick={() => this.handleCreditClick("Pending Approval")}><span id="hide_cursor">Pending Appr. </span><span>0</span></Nav.Link>}
                                                    </Nav.Item>
                                                    <Nav.Item as="li">
                                                        {this.state.DashboardCreditData && this.state.DashboardCreditData.approvedAndNotAppliedCreditRequestCount ? <Nav.Link onClick={() => this.handleCreditClick("Approved and Not Applied")}><span id="hide_cursor">Appr. not Applied </span> <span>{this.state.DashboardCreditData.approvedAndNotAppliedCreditRequestCount
                                                        }</span></Nav.Link> : <Nav.Link onClick={() => this.handleCreditClick("Approved and Not Applied")}><span id="hide_cursor">Appr. not Applied </span> <span>0</span></Nav.Link>}
                                                    </Nav.Item>
                                                    <Nav.Item as="li">
                                                        {this.state.DashboardCreditData && this.state.DashboardCreditData.partiallyAppliedCreditRequestCount ? <Nav.Link onClick={() => this.handleCreditClick("Partially Applied")}><span id="hide_cursor">Partially Applied</span> <span>{this.state.DashboardCreditData.partiallyAppliedCreditRequestCount
                                                        }</span></Nav.Link> : <Nav.Link onClick={() => this.handleCreditClick("Partially Applied")}><span id="hide_cursor">Partially Applied </span><span>0</span></Nav.Link>}
                                                    </Nav.Item>
                                                    <Nav.Item as="li">
                                                        {this.state.DashboardCreditData && this.state.DashboardCreditData.approvedAndAppliedCreditRequestCount ? <Nav.Link onClick={() => this.handleCreditClick("Approved and Applied")}><span id="hide_cursor">Appr. Applied</span> <span>{this.state.DashboardCreditData.approvedAndAppliedCreditRequestCount
                                                        }</span></Nav.Link> : <Nav.Link onClick={() => this.handleCreditClick("Approved and Applied")}><span id="hide_cursor">Appr. Applied </span> <span>0</span></Nav.Link>}
                                                    </Nav.Item>
                                                    <Nav.Item as="li">
                                                        {this.state.DashboardCreditData && this.state.DashboardCreditData.deniedCreditRequestCount ? <Nav.Link onClick={() => this.handleCreditClick("Denied")}><span id="hide_cursor">Denied </span><span>{this.state.DashboardCreditData.deniedCreditRequestCount
                                                        }</span></Nav.Link> : <Nav.Link onClick={() => this.handleCreditClick("Denied")}><span id="hide_cursor">Denied </span> <span>0</span></Nav.Link>}
                                                    </Nav.Item>
                                                    <Nav.Item as="li">
                                                        {this.state.DashboardCreditData && this.state.DashboardCreditData.autoDeniedCreditRequestCount ? <Nav.Link onClick={() => this.handleCreditClick("Auto denied")}><span id="hide_cursor">Auto Denied </span><span>{this.state.DashboardCreditData.autoDeniedCreditRequestCount
                                                        }</span></Nav.Link> : <Nav.Link onClick={() => this.handleCreditClick("Auto denied")}><span id="hide_cursor">Auto Denied </span> <span>0</span></Nav.Link>}
                                                    </Nav.Item>
                                                </Nav>
                                                
                                            </Card>
                                            
                                        </Col>


                                    </Row>{
                                        //<Row className="mt-4">
                                        //  <Col lg="8">
                                        //    <div className="rounded thdmp-card bg-white mt-0 h-100">
                                        //      <div className="order-credtis-chart-title border-bottom pt-2 pb-2 pl-3 pr-3">
                                        //                      <h3 className="T-order d-inline">Orders &amp; Credits</h3>
                                        //                      {
                                        //                          ////<Form.Control as="select" className="selectBYday">
                                        //                          ////    <option>Today</option>
                                        //                          ////    <option>Last 7 Days</option>
                                        //                          ////    <option>Last 15 Days</option>
                                        //                          ////    <option>Last 20 Days</option>
                                        //                          ////    <option>Last 30 Days</option>
                                        //                          ////</Form.Control>
                                        //                      }
                                        //      </div>
                                        //      <div className="p-3">
                                        //        <CanvasJSChart options = {options1} />
                                        //      </div>
                                        //    </div>
                                        //  </Col>
                                        //  <Col lg="4">
                                        //    <div className="rounded thdmp-card bg-white h-100">
                                        //      <div className="order-credtis-chart-title border-bottom pt-2 pb-2 pl-3 pr-3">
                                        //        <h3 className="T-order">User Invoices</h3>
                                        //      </div>
                                        //      <div className="p-3">
                                        //        <CanvasJSChart options = {options2} />
                                        //      </div>
                                        //      {
                                        //      //                <Form.Control as="select" className="selectBYday ml-3 mb-4">
                                        //      //                    <option>Today</option>
                                        //      //                    <option>Last 7 Days</option>
                                        //      //                    <option>Last 15 Days</option>
                                        //      //                    <option>Last 20 Days</option>
                                        //      //                    <option>Last 30 Days</option>
                                        //      //                </Form.Control>                            
                                        //      }
                                        //    </div>
                                        //  </Col>
                                        //</Row>

                                        //<Row className="mt-4">
                                        //  <Col lg="12">
                                        //    <Card className="text-white border-0 rounded thdmp-card p-3 bg-white h-100">
                                        //      <Row>
                                        //        <Col lg="8" xs="8">
                                        //          <h5>Inventory</h5>
                                        //          <Link to="/Inventory" className="purchase_sbBatch_BTN">
                                        //            Purchase SB Inventory
                                        //          </Link>
                                        //        </Col>
                                        //        <Col lg="4" xs="4">
                                        //          <span className="img-icon bg-red"><img src={'img/Inventory.png'} alt="Inventory" /></span>
                                        //        </Col>
                                        //      </Row>
                                        //    </Card>
                                        //  </Col>
                                        //</Row>
                                    }
                                </Container>

                            </div>
                        </ContentLayout>
                    </div>
                    <div style={style}>
                        <SyncLoader
                            css={override}
                            size={15}
                            color={"#fd7e14"}
                            loading={this.state.loading}
                        />
                    </div>

                </div>
            );
        }
        return (



            <ContentLayout>

                <Breadcrumb
                    breadcrumb={[
                        { name: "Home", path: "/" },
                        { name: "Dashboard", path: "/Dashboard" }
                    ]}

                />

                <div className="content dashboard-contant pt-5 pb-5">

                    <Button className="text-white" onClick={() => this.navigateToInventory()} variant="outline-secondary" style={{ backgroundColor: "#273546" }}><ArrowBackIcon /> <span>Back to Inventory</span></Button>
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h2 className="title mb-4">Active Orders, Invoices & Credits trending.</h2>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col xs="12" sm="4" lg="4">
                                <Card className="text-white border-0 rounded thdmp-card p-3 bg-white h-100">
                                    <Card.Body className="p-0">
                                        <Row>
                                            <Col lg="8" xs="8">
                                                <h5>Orders</h5>
                                                {this.state.DashboardOrderData && this.state.DashboardOrderData.allOrderCount ? <h3 className="T-order">Total Orders: <span>{this.state.DashboardOrderData.allOrderCount}</span></h3> : <h3 className="T-order">Total Orders: <span>0</span></h3>}
                                            </Col>
                                            <Col lg="4" xs="4">
                                                <span className="img-icon bg-red"><img src={'img/Orders.png'} alt="Order" /></span>
                                            </Col>
                                        </Row>
                                        <div class="container">
                                            <Nav as="ul" className="orders-list">
                                                <Nav.Item as="li">
                                                    {this.state.DashboardOrderData && this.state.DashboardOrderData.openOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("Open")}><span id="hide_cursor">Open</span> <span>{this.state.DashboardOrderData.openOrderCount}</span></Nav.Link> : <Nav.Link onClick={() => this.handleOrderClick("Open")}><span id="hide_cursor">Open </span> <span>0</span></Nav.Link>}
                                                </Nav.Item>
                                                <Nav.Item as="li">
                                                    {this.state.DashboardOrderData && this.state.DashboardOrderData.pendingApprovalOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("PendingApproval")}><span id="hide_cursor">Pending appr.</span> <span>{this.state.DashboardOrderData.pendingApprovalOrderCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Pending appr.</span> <span>0</span></Nav.Link>}
                                                </Nav.Item>
                                                <Nav.Item as="li">
                                                    {this.state.DashboardOrderData && this.state.DashboardOrderData.assignedOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("Assigned")}><span id="hide_cursor">Assigned</span> <span>{this.state.DashboardOrderData.assignedOrderCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Assigned</span> <span>0</span></Nav.Link>}
                                                </Nav.Item>
                                                <Nav.Item as="li">
                                                    {this.state.DashboardOrderData && this.state.DashboardOrderData.approvedOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("Approved")}><span id="hide_cursor">Approved </span><span>{this.state.DashboardOrderData.approvedOrderCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor"><span id="hide_cursor">Approved</span></span> <span>0</span></Nav.Link>}
                                                </Nav.Item>
                                                <Nav.Item as="li">
                                                    {this.state.DashboardOrderData && this.state.DashboardOrderData.shippedOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("Shipped")}><span id="hide_cursor">Shipped </span><span>{this.state.DashboardOrderData.shippedOrderCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Shipped</span> <span>0</span></Nav.Link>}
                                                </Nav.Item>
                                                <Nav.Item as="li">
                                                    {this.state.DashboardOrderData && this.state.DashboardOrderData.partiallyShippedOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("PartiallyShipped")}><span id="hide_cursor">Partially Shipped </span><span>{this.state.DashboardOrderData.partiallyShippedOrderCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Partially Shipped</span> <span>0</span></Nav.Link>}
                                                </Nav.Item>
                                                <Nav.Item as="li">
                                                    {this.state.DashboardOrderData && this.state.DashboardOrderData.allOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("Cancelled")}><span id="hide_cursor">Cancelled </span><span>{this.state.DashboardOrderData.cancelledOrderCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Open</span> <span>0</span></Nav.Link>}
                                                </Nav.Item>
                                            </Nav>
                                            <div style={{ marginTop: "10px"}}>
                                                <span style={{ color: "black", fontStyle: "italic" }}>Note: All the counts mentioned above include "Archived" records.</span>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            {/*<Col xs="12" sm="4" lg="4">*/}
                            {/*    <Card className="text-white border-0 rounded thdmp-card p-3 bg-white h-100">*/}
                            {/*        <Card.Body className="p-0">*/}
                            {/*            <Row>*/}
                            {/*                <Col lg="8" xs="8">*/}
                            {/*                    <h5>TruckLoad Orders</h5>*/}
                            {/*                    {this.state.DashboardTruckOrderData && this.state.DashboardTruckOrderData.truckOrderCount ? <h3 className="T-order">Total Truck Orders: <span>{this.state.DashboardOrderData.truckOrderCount}</span></h3> : <h3 className="T-order">Total Truck Orders: <span>0</span></h3>}*/}
                            {/*                </Col>*/}
                            {/*                <Col lg="4" xs="4">*/}
                            {/*                    <span className="img-icon bg-red"><img src={'img/Orders.png'} alt="Order" /></span>*/}
                            {/*                </Col>*/}
                            {/*            </Row>*/}
                            {/*            <div class="container">*/}
                            {/*                <Nav as="ul" className="orders-list">*/}
                            {/*                    <Nav.Item as="li">*/}
                            {/*                        {this.state.DashboardOrderData && this.state.DashboardOrderData.openOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("Open")}><span id="hide_cursor">Open</span> <span>{this.state.DashboardOrderData.openOrderCount}</span></Nav.Link> : <Nav.Link onClick={() => this.handleOrderClick("Open")}><span id="hide_cursor">Open </span> <span>0</span></Nav.Link>}*/}
                            {/*                    </Nav.Item>*/}
                            {/*                    <Nav.Item as="li">*/}
                            {/*                        {this.state.DashboardOrderData && this.state.DashboardOrderData.pendingApprovalOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("PendingApproval")}><span id="hide_cursor">Pending appr.</span> <span>{this.state.DashboardOrderData.pendingApprovalOrderCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Pending appr.</span> <span>0</span></Nav.Link>}*/}
                            {/*                    </Nav.Item>*/}
                            {/*                    <Nav.Item as="li">*/}
                            {/*                        {this.state.DashboardOrderData && this.state.DashboardOrderData.assignedOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("Assigned")}><span id="hide_cursor">Assigned</span> <span>{this.state.DashboardOrderData.assignedOrderCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Assigned</span> <span>0</span></Nav.Link>}*/}
                            {/*                    </Nav.Item>*/}
                            {/*                    <Nav.Item as="li">*/}
                            {/*                        {this.state.DashboardOrderData && this.state.DashboardOrderData.approvedOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("Approved")}><span id="hide_cursor">Approved </span><span>{this.state.DashboardOrderData.approvedOrderCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor"><span id="hide_cursor">Approved</span></span> <span>0</span></Nav.Link>}*/}
                            {/*                    </Nav.Item>*/}
                            {/*                    <Nav.Item as="li">*/}
                            {/*                        {this.state.DashboardOrderData && this.state.DashboardOrderData.shippedOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("Shipped")}><span id="hide_cursor">Shipped </span><span>{this.state.DashboardOrderData.shippedOrderCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Shipped</span> <span>0</span></Nav.Link>}*/}
                            {/*                    </Nav.Item>*/}
                            {/*                    <Nav.Item as="li">*/}
                            {/*                        {this.state.DashboardOrderData && this.state.DashboardOrderData.allOrderCount ? <Nav.Link onClick={() => this.handleOrderClick("Cancelled")}><span id="hide_cursor">Cancelled </span><span>{this.state.DashboardOrderData.cancelledOrderCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Open</span> <span>0</span></Nav.Link>}*/}
                            {/*                    </Nav.Item>*/}
                            {/*                </Nav>*/}

                            {/*            </div>*/}
                            {/*        </Card.Body>*/}
                            {/*    </Card>*/}
                            {/*</Col>*/}

                            <Col xs="12" sm="4" lg="4">
                                <Card className="text-white border-0 rounded thdmp-card p-3 bg-white h-100">
                                    <Row>
                                        <Col lg="8" xs="8">
                                            <h5>Invoices</h5>
                                            {this.state.DashboardInvoiceData && this.state.DashboardInvoiceData.allInvoiceCount ? <h3 className="T-order">Total Invoices: <span>{this.state.DashboardInvoiceData.allInvoiceCount}</span></h3> : <h3 className="T-order">Total Invoices: <span>0</span></h3>}
                                        </Col>
                                        <Col lg="4" xs="4">
                                            <span className="img-icon bg-green"><img src={'img/Invoices.png'} alt="Invoices" /></span>
                                        </Col>
                                    </Row>
                                    <Nav as="ul" className="orders-list">
                                        {/*<Nav.Item as="li">*/}
                                        {/*    {this.state.DashboardInvoiceData && this.state.DashboardInvoiceData.outstandingInvoiceCount ? <Nav.Link onClick={() => this.handleInvoiceClick("Outstanding")}><span id="hide_cursor">Outstanding</span> <span>{this.state.DashboardInvoiceData.outstandingInvoiceCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Outstanding</span> <span>0</span></Nav.Link>}*/}
                                        {/*</Nav.Item>*/}
                                        <Nav.Item as="li">
                                            {this.state.DashboardInvoiceData && this.state.DashboardInvoiceData.invoicedInvoiceCount ? <Nav.Link onClick={() => this.handleInvoiceClick("Invoiced")}><span id="hide_cursor">Invoiced</span> <span>{this.state.DashboardInvoiceData.invoicedInvoiceCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Invoiced</span> <span>0</span></Nav.Link>}
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            {this.state.DashboardInvoiceData && this.state.DashboardInvoiceData.overdueInvoiceCount ? <Nav.Link onClick={() => this.handleInvoiceClick("Overdue")}><span id="hide_cursor">Overdue </span> <span>{this.state.DashboardInvoiceData.overdueInvoiceCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Overdue</span> <span>0</span></Nav.Link>}
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            {this.state.DashboardInvoiceData && this.state.DashboardInvoiceData.partiallyPaidInvoiceCount ? <Nav.Link onClick={() => this.handleInvoiceClick("PartiallyPaid")}><span id="hide_cursor">Partially Paid</span> <span>{this.state.DashboardInvoiceData.partiallyPaidInvoiceCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Partially Paid</span> <span>0</span></Nav.Link>}
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            {this.state.DashboardInvoiceData && this.state.DashboardInvoiceData.paidInvoiceCount ? <Nav.Link onClick={() => this.handleInvoiceClick("PaidInFull")}><span id="hide_cursor">Paid in Full</span> <span>{this.state.DashboardInvoiceData.paidInvoiceCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Paid in Full</span> <span>0</span></Nav.Link>}
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            {this.state.DashboardInvoiceData && this.state.DashboardInvoiceData.cancelledInvoiceCount ? <Nav.Link onClick={() => this.handleInvoiceClick("Cancelled")}><span id="hide_cursor">Cancelled</span> <span>{this.state.DashboardInvoiceData.cancelledInvoiceCount}</span></Nav.Link> : <Nav.Link href="#"><span id="hide_cursor">Cancelled</span> <span>0</span></Nav.Link>}
                                        </Nav.Item>
                                        <Nav.Item as="li"><Nav.Link href="#"><span id="hide_cursor">Total Outstanding Invoices<NumberFormat value={this.state.TotalOutstandingInvoices} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span></Nav.Link></Nav.Item>
                                        <Nav.Item as="li"><Nav.Link href="#"><span id="hide_cursor">Total overdue Invoices<NumberFormat value={this.state.TotalOverdueInvoices} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span></Nav.Link></Nav.Item>
                                        <Nav.Item as="li"><Nav.Link href="#"><span id="hide_cursor">Total Outstanding Invoice Limit<NumberFormat value={this.state.TotalOutstandingInvoiceLimit} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span></Nav.Link></Nav.Item>

                                    </Nav>
                                    <div style={{ marginTop: "20px" }}>
                                        <span style={{ color: "black", fontStyle: "italic" }}>Note: All the counts mentioned above include "Archived" records.</span>
                                    </div>
                                </Card>
                            </Col>
                            
                            <Col xs="12" sm="4" lg="4">
                                <Card className="text-white border-0 rounded thdmp-card p-3 bg-white h-100">
                                    <Row>
                                        <Col lg="8" xs="8">
                                            <h5>Credits</h5>
                                            {this.state.DashboardCreditData && this.state.DashboardCreditData.allCreditRequestCount ? <h3 className="T-order">Total Credits: <span>{this.state.DashboardCreditData.allCreditRequestCount
                                            }</span></h3> : <h3 className="T-order">Total Credits: <span>0</span></h3>}
                                        </Col>
                                        <Col lg="4" xs="4">
                                            <span className="img-icon bg-blue"><img src={'img/Credits.png'} alt="Credits" /></span>
                                        </Col>
                                    </Row>
                                    <Nav as="ul" className="orders-list">
                                        <Nav.Item as="li">
                                            {this.state.DashboardCreditData && this.state.DashboardCreditData.pendingApprovalCreditRequestCount ? <Nav.Link onClick={() => this.handleCreditClick("Pending Approval")}><span id="hide_cursor">Pending Appr.</span> <span>{this.state.DashboardCreditData.pendingApprovalCreditRequestCount
                                            }</span></Nav.Link> : <Nav.Link onClick={() => this.handleCreditClick("Pending Approval")}><span id="hide_cursor">Pending Appr. </span><span>0</span></Nav.Link>}
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            {this.state.DashboardCreditData && this.state.DashboardCreditData.approvedAndNotAppliedCreditRequestCount ? <Nav.Link onClick={() => this.handleCreditClick("Approved and Not Applied")}><span id="hide_cursor">Appr. not Applied </span> <span>{this.state.DashboardCreditData.approvedAndNotAppliedCreditRequestCount
                                            }</span></Nav.Link> : <Nav.Link onClick={() => this.handleCreditClick("Approved and Not Applied")}><span id="hide_cursor">Appr. not Applied </span> <span>0</span></Nav.Link>}
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            {this.state.DashboardCreditData && this.state.DashboardCreditData.partiallyAppliedCreditRequestCount ? <Nav.Link onClick={() => this.handleCreditClick("Partially Applied")}><span id="hide_cursor">Partially Applied</span> <span>{this.state.DashboardCreditData.partiallyAppliedCreditRequestCount
                                            }</span></Nav.Link> : <Nav.Link onClick={() => this.handleCreditClick("Partially Applied")}><span id="hide_cursor">Partially Applied </span><span>0</span></Nav.Link>}
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            {this.state.DashboardCreditData && this.state.DashboardCreditData.approvedAndAppliedCreditRequestCount ? <Nav.Link onClick={() => this.handleCreditClick("Approved and Applied")}><span id="hide_cursor">Appr. Applied</span> <span>{this.state.DashboardCreditData.approvedAndAppliedCreditRequestCount
                                            }</span></Nav.Link> : <Nav.Link onClick={() => this.handleCreditClick("Approved and Applied")}><span id="hide_cursor">Appr. Applied </span> <span>0</span></Nav.Link>}
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            {this.state.DashboardCreditData && this.state.DashboardCreditData.deniedCreditRequestCount ? <Nav.Link onClick={() => this.handleCreditClick("Denied")}><span id="hide_cursor">Denied </span><span>{this.state.DashboardCreditData.deniedCreditRequestCount
                                            }</span></Nav.Link> : <Nav.Link onClick={() => this.handleCreditClick("Denied")}><span id="hide_cursor">Denied </span> <span>0</span></Nav.Link>}
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            {this.state.DashboardCreditData && this.state.DashboardCreditData.autoDeniedCreditRequestCount ? <Nav.Link onClick={() => this.handleCreditClick("Auto denied")}><span id="hide_cursor">Auto Denied </span><span>{this.state.DashboardCreditData.autoDeniedCreditRequestCount
                                            }</span></Nav.Link> : <Nav.Link onClick={() => this.handleCreditClick("Auto denied")}><span id="hide_cursor">Auto Denied </span> <span>0</span></Nav.Link>}
                                        </Nav.Item>
                                    </Nav>
                                    
                                </Card>
                            </Col>


                        </Row>{
                            //<Row className="mt-4">
                            //  <Col lg="8">
                            //    <div className="rounded thdmp-card bg-white mt-0 h-100">
                            //      <div className="order-credtis-chart-title border-bottom pt-2 pb-2 pl-3 pr-3">
                            //                      <h3 className="T-order d-inline">Orders &amp; Credits</h3>
                            //                      {
                            //                          ////<Form.Control as="select" className="selectBYday">
                            //                          ////    <option>Today</option>
                            //                          ////    <option>Last 7 Days</option>
                            //                          ////    <option>Last 15 Days</option>
                            //                          ////    <option>Last 20 Days</option>
                            //                          ////    <option>Last 30 Days</option>
                            //                          ////</Form.Control>
                            //                      }
                            //      </div>
                            //      <div className="p-3">
                            //        <CanvasJSChart options = {options1} />
                            //      </div>
                            //    </div>
                            //  </Col>
                            //  <Col lg="4">
                            //    <div className="rounded thdmp-card bg-white h-100">
                            //      <div className="order-credtis-chart-title border-bottom pt-2 pb-2 pl-3 pr-3">
                            //        <h3 className="T-order">User Invoices</h3>
                            //      </div>
                            //      <div className="p-3">
                            //        <CanvasJSChart options = {options2} />
                            //      </div>
                            //      {
                            //      //                <Form.Control as="select" className="selectBYday ml-3 mb-4">
                            //      //                    <option>Today</option>
                            //      //                    <option>Last 7 Days</option>
                            //      //                    <option>Last 15 Days</option>
                            //      //                    <option>Last 20 Days</option>
                            //      //                    <option>Last 30 Days</option>
                            //      //                </Form.Control>                            
                            //      }
                            //    </div>
                            //  </Col>
                            //</Row>

                            //<Row className="mt-4">
                            //  <Col lg="12">
                            //    <Card className="text-white border-0 rounded thdmp-card p-3 bg-white h-100">
                            //      <Row>
                            //        <Col lg="8" xs="8">
                            //          <h5>Inventory</h5>
                            //          <Link to="/Inventory" className="purchase_sbBatch_BTN">
                            //            Purchase SB Inventory
                            //          </Link>
                            //        </Col>
                            //        <Col lg="4" xs="4">
                            //          <span className="img-icon bg-red"><img src={'img/Inventory.png'} alt="Inventory" /></span>
                            //        </Col>
                            //      </Row>
                            //    </Card>
                            //  </Col>
                            //</Row>
                        }
                    </Container>

                </div>
            </ContentLayout>
        );
    }
    async populateDashboardData() {
        try {
            const token = await authService.getAccessToken();
            const response = await fetch('InventoryCode/Dashboard', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                const data = await response.json();
                this.setState({ DashboardOrderData: data.orderViewModel, loading: false });
                this.setState({ DashboardTruckOrderData: data.truckOrderViewModel, loading: false });
                this.setState({ DashboardInvoiceData: data.updateCustomerInvoice, loading: false });
                this.setState({ DashboardCreditData: data.manageCreditViewModel, loading: false });
                this.setState({ TotalOutstandingInvoices: data.totalOutstandingInvoices, TotalOverdueInvoices: data.totalOverdueInvoices, TotalOutstandingInvoiceLimit: data.totalOutstandingInvoiceLimit, loading: false });
            }
        } catch (error) {
            console.log(error)
        }

    }
}

const mapActionsToProps = {
    updateSearchList: updateSearchList,
    updateSearchListt: updateSearchListt,
    returnUpdateSearch: returnUpdateSearch
}

export default connect(null, mapActionsToProps)(Dashboard);