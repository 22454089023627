import React, { Component } from 'react';
import ContentLayout from "../components/ContentLayout";
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import { Col, Container, Row, Card, Button, Accordion } from 'react-bootstrap';
import { connect } from 'react-redux';
import { updateSearchList, returnUpdateSearch } from '../actions/search-actions';

class faq extends Component {
    constructor(props) {
        super(props);
        this.props.updateSearchList([]);
        this.props.returnUpdateSearch();
    }
    render() {
        return (
            <ContentLayout>
                <Breadcrumb
                    breadcrumb={[
                        { name: "Home", path: "/" },
                        { name: "FAQ", path: "/faq" }
                    ]}
                />
                <div className="content pt-5 pb-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="12">
                                <div className="animated fadeIn">
                                    <Card className="rounded-0 bg-white shadow-sm">
                                        <Card.Body>
                                            <Card.Title className="mb-2">Get answers to your queries</Card.Title>
                                            <Card.Text>
                                                <Accordion defaultActiveKey="0">
                                                    <Card>
                                                        <Card.Header>
                                                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                                               1.	How to register to THDMarketplace site?
                              </Accordion.Toggle>
                                                        </Card.Header>
                                                        <Accordion.Collapse eventKey="0">
                                                            <Card.Body>
                                                                <p>If you are a new user, click the <b>Register</b> button. You will be presented with the following web page. </p>
                                                                <p>To create a new account, you will need to provide the following information as shown on the new user registration web page above. </p>
                                                                <p><b>NOTE:</b> all data fields marked with an asterisk (*) are required.</p>
                                                                <p><b>NOTE:</b> Please note your EMAIL address will subsequently become your user ID to log into the THD Marketplace web portal.</p>
                                                                <p>If no form errors occur, you will be presented with the THD Marketplace web portal - <b>New User Registration Confirmation</b> page shown in the following figure.</p>
                                                                <p><b>NOTE:</b> Once you notice this successful registration confirmation as above, you will need to wait to secure access or log into THD Marketplace web portal until an administrator reviews your registration details and approves your registration. </p>
                                                                <p>After an administrator approves your account, you will receive an email from salvage@homedepot.com with instructions to set your new password for your new account to log into the THD Marketplace portal as shown below.</p>
                                                                <p>In the account password setup email that you received, click <b>here</b> link and you will be presented with <b>Reset Password</b> page as shown below.</p>
                                                                <p>You will need to provide the following information in order to set your password on above page. All fields are required on this page. Click <b>Reset</b> button on this page after entering all the information.</p>
                                                                <p>If no form errors occur, you will be presented with <b>Reset Password Confirmation</b> page shown in the following view.</p>
                                                                <p>Click on the link click here to log in which will redirect you to the log in page for THD Marketplace web portal.</p>
                                                                <p>Please use your registered Email Address and your Password that you just reset to log into the web portal.</p>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                    <Card>
                                                        <Card.Header>
                                                            <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                                                2.	How to login?
                              </Accordion.Toggle>
                                                        </Card.Header>
                                                        <Accordion.Collapse eventKey="1">
                                                            <Card.Body>Please use your Email Address that is registered and approved and your Password to log into the web portal. NOTE: Your registered email address has to be approved by administrator before you will be able to log into the web portal.</Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ContentLayout>
        );
    }
}

const mapActionsToProps = {
    updateSearchList: updateSearchList,
    returnUpdateSearch: returnUpdateSearch
}

export default connect(null, mapActionsToProps)(faq);