import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Dropdown, InputGroup, DropdownButton, Nav } from "react-bootstrap";
import { withRouter,Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import user from '../../assets/img/avatars/user.png';


class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null
        };
    }
    handleInvoiceClick(status) {
        this.props.history.push({
            pathname: '/Invoices',
            state: status
        })
    }
    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    navigateToProfile = () => {
        this.props.history.push({
            pathname: '/MyProfile'
        })
    }
    navigateToTruckLoadorder = () => {
        this.props.history.push({
            pathname: '/TruckLoadOrders'
        })
    }

    navigateToDashboard = () => {
        this.props.history.push({
            pathname: '/Dashboard'
        })
    }

    navigateToCredits = () => {
        this.props.history.push({
            pathname: '/MyCredits'
        })
    }

    navigateToInventory = (location) => {
        this.props.history.push({
            pathname: '/Inventory',
            allLocation: location
        })
    }

    navigateToSupportHelp = () => {
        this.props.history.push({
            pathname: '/SupportHelp'
        })
    }

    render() {
        const { isAuthenticated, userName } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath);
        }
    }

    authenticatedView(userName, profilePath, logoutPath) {
        return (<Fragment>
            <Nav.Item as="li">
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <span><strong>Welcome,</strong>{userName} &nbsp;<span className="user-img">
                           
                        </span></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-list shadow-sm rounded">
                        <Dropdown.Item onClick={() => this.navigateToProfile()}>My Profile</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.navigateToDashboard()}>Dashboard</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.navigateToCredits()}> My Credits</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.handleInvoiceClick("All")}>Invoices</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.navigateToInventory("AllLocation")} >Inventory</Dropdown.Item>
{/*                        <Dropdown.Item onClick={() => this.navigateToTruckLoadorder()}>TruckLoad Order</Dropdown.Item>*/}
                        
                        <NavLink tag={Link} className="logout_btn" to={logoutPath}>Logout</NavLink>
                    </Dropdown.Menu>
                </Dropdown>
            </Nav.Item>
            
        </Fragment>);

    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            <NavItem>
                {
                  //  <NavLink tag={Link} className="text-dark" to={registerPath}>Register</NavLink>
                }
            </NavItem>
            <NavItem>
                {
                  //  <NavLink tag={Link} className="text-dark" to={loginPath}>Login</NavLink>
                }
            </NavItem>
        </Fragment>);
    }
}

export default withRouter(LoginMenu);