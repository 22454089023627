export const UPDATE_CART = 'cart:update';
export const FETCH_DATA_CART = 'cart:fetch_data';
export const FETCH_ORDERS_CART = 'cart:fetch_orders';

export const OPEN_DIALOG = 'dialog:open';
export const CLOSE_DIALOG = 'dialog:close';

export const DIALOG_ACTION_CLOSE = 'dialog:action:close';
export const DIALOG_ACTION_CLEARCART = 'dialog:action:clear_cart';
export const DIALOG_ACTION_REMOVE_CART_ITEM = 'dialog:action:remove_cart_item';

export const SEARCH_OPTIONS_UPDATE = 'search:options:update';
export const SEARCH_OPTIONS_UPDATET = 'search:options:updatet';
export const SEARCH_TEXT_UPDATE = 'search:text:update';
export const SEARCH_TEXT_UPDATET = 'search:text:updatet';
export const SEARCH_TEXT_CLEAR = 'search:text:clear';
export const RETURN_SEARCH_TEXT_UPDATE = 'return:search:text:update';
export const DIALOG_ACTION_REMOVE_SB_FROM_ORDER = 'dialog:action:remove_sb_from_order';

export const SEARCH_OPTIONS_UPDATE_ORDER = 'search:options:update:order';
export const SEARCH_TEXT_UPDATE_ORDER = 'search:text:update:order';
export const RETURN_SEARCH_TEXT_UPDATE_ORDER = 'return:search:text:update:order';

export const FETCH_FILTERS = 'filter:fetch_filter';