import { FETCH_FILTERS } from '../constants';

export const fetchFilterReducer = function (state = { inventoryType:[], categoryList:[], filterList:[] }, action) {
    let returnValue = state;

    switch (action.type) {
        case FETCH_FILTERS:
            return action.payload;
            break;
    }

    return returnValue;
}