import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {Layout} from "../components/Layout";

class Page404 extends Component {
  render() {
    return (
      
        <div className="app flex-row align-items-center h-100">
          <Container>
            <Row className="justify-content-center">
              <Col md="12">
                <div className="clearfix text-center pt-5">
                  <h1 className="display-3 mr-4">404</h1>
                  <h4 className="pt-3">Oops! You're lost.</h4>
                  <p className="text-muted">The page you are looking for was not found.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      
    );
  }
}

export default Page404;
