import React, { Component } from 'react';
import { Col, Container, Form, Row, Modal, Table } from 'react-bootstrap';
import authService from '../api-authorization/AuthorizeService';
import { dateMDY } from '../../DateHelper';
//import {Table } from 'react-bootstrap';
class ViewDocument extends Component {
    constructor(props) {
        super(props) //since we are extending class Table so we have to use super in order to override Component class constructor
        this.state = { //state is by default an object
            invoiceDetail: [], loading: true, showdocument: false
        }
    }

    componentDidMount() {
        this.populateAddInvoice(this.props.Id);
    }
    handleviewDocumentClose = () => {
        this.setState({ showdocument: false });
    }
    async populateAddInvoice(ID) {
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/ViewCreditRequestAttachments?creditRequestId=' + ID, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ invoiceDetail: data.attachmentList, loading: false });

    }

    handleClick = (path, type) => {
        //window.open(efp);

        //this.downLoadFile(path, type)
        while (path.indexOf("\\") !== -1) {
            path = path.replace("\\", "/");
        }
        var url = path.split("/");
        let getPath = url[url.length - 2] + '/' + url[url.length-1];
        window.open(getPath)
        // this.setState({ showdocument: true, fileurl: getPath });
        console.log('The filepath.' + getPath);
    };

    //async downLoadFile(path,type) {
    //    const token = await authService.getAccessToken();
    //    const response = await fetch('InvoiceCode/DownloadFile?filePath=' + path + '&fileContentType=' + type, {
    //        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    //    });
    //    const data = await response.json();
    //    this.setState({ FilePath: data.filePath, ContentType: data.fileContentType, loading: false });
    //}


    render() {
        return (
            <div>
                <Table responsive size="xl" className="table-invoice table-bordered">
                    <thead>
                        <tr>
                            <th colSpan="10" className="text-center datacol">Document Detail</th>
                        </tr>
                        <tr>
                            <th className="text-center">File Name</th>
                            <th className="text-center">Uploaded Date</th>
                            {/*<th className="text-center">File Path</th>*/}

                        </tr>
                    </thead>
                    <tbody className="pheonixes">
                        {this.state.invoiceDetail.map(list => {
                            return (

                                <tr key={list.id} >
                                    <td className="text-center" ><a style={{ cursor: 'pointer', color: 'blue' }} onClick={() => { this.handleClick(list.filePath, list.fileType) }}>{list.fileName}</a></td>
                                    {/*<td className="text-center" >{list.fileType}</td>*/}
                                    <td className="text-center">{list.insertDateTime ? dateMDY(list.insertDateTime.substring(0, 10)) : ""}</td>
                                </tr>
                            )
                        })}
                    </tbody>

                </Table>
                <Modal centered scrollable size="xl" show={this.state.showdocument} onHide={this.handleviewDocumentClose} className="sb-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>View Document</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/*    <img*/}
                        {/*    src="https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350"*/}
                        {/*    alt="new"*/}
                        {/*/>*/}
                        <img
                            src={this.state.fileurl}
                            alt="new"
                        />
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default ViewDocument