export default `
BLINDS
HOLIDAY
PLUMBING
001
GMP WH
MIXED LOAD
APPLIANCES
DOORS AND WINDOWS
FLOORING AND RUGS
HARDWARE AND TOOLS
OUTDOOR SEASONAL
KITCHEN AND BATH
LIGHTING
HOME DECOR
`.split('\n')