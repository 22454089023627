import React, { Component } from "react";
import { Table } from 'react-bootstrap';
import "../../assets/css/jquery.dataTables.min.css";
import authService from '../api-authorization/AuthorizeService';
import CssBaseline from '@material-ui/core/CssBaseline'
import ReactTable from './ReactTable'
import { CSVLink } from "react-csv";
import { numberformat, warehouse, dateMDY, orderStatus } from '../../DateHelper';
import { Modal } from 'react-bootstrap';
import Moment from 'react-moment';
import ModalDataTable from './Modal-DataTable';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { DIALOG_ACTION_CLOSE, DIALOG_ACTION_REMOVE_CART_ITEM, DIALOG_ACTION_CLEARCART, DIALOG_ACTION_REMOVE_SB_FROM_ORDER } from '../../constants';
import { openDialog } from '../../actions/dialog-actions';
import { removeSbFromOrder, fetchOrders } from '../../actions/cart-actions';
import { connect } from 'react-redux';
import SyncLoader from "react-spinners/SyncLoader";
import { css } from "@emotion/core";
//const $ = require('jquery');
//$.DataTable = require('datatables.net');
const override = css`
  display: block;
  margin: 2 auto;
  border-color: red;
`;
const style = { position: "fixed", top: "45%", left: "50%", transform: "translate(-50%, -50%)" };

class OrderDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OrderDetailData: [], orderDetail: [], orderDetailDate: '', orderDetailstatus: '', show: false,  loading: true
        }; 
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }
    componentDidMount() {
        this.populateOrderDetailById(this.props.orderNumber);
    }
    getOrderId = (e) => {
        return e.original.orderId;
    }
    handleclick = (rowData) => {
        this.setState({
            orderNumber: rowData.original.id, status: rowData.original.orderStatus, blNumber: rowData.original.blNumber, warehouse: rowData.original.warehouse, salvageCategory: rowData.original.salvageCategory,
            sbNumber: rowData.original.sbNumber, warehouseId: rowData.original.warehouseId, containerQuantity: rowData.original.containerQuantity
            //orderNumber: rowData.original.id, blNumber: rowData.original.blNumber, warehouse: rowData.original.warehouse, salvageCategory: rowData.original.salvageCategory,
            //containerQuantity: rowData.original.containerQuantity, warehouseId: rowData.original.warehouseId, show: true, loading: true
        });
            this.setState({ show: true });
   

        
    }
    removeChecked(id, manifestId, SBNumber) {
        this.removeSbfromOrder(id, manifestId, SBNumber)
        //this.props.openDialog(0, "Remove SB From Order", "Are you sure that you want remove SB # " + SBNumber + " from the Order " + id + "?", "Cancel", "Continue", DIALOG_ACTION_CLOSE, DIALOG_ACTION_REMOVE_SB_FROM_ORDER, { id: id, manifestId: manifestId, SBNumber: SBNumber });
        //this.populateOrderDetailById(id);//window.location.reload();

    }
    render() {
        const { orderDetail, orderDetailDate, orderDetailstatus, loading } = this.state;
        const columns = [
            {
                Header: 'Order #',
                accessor: 'orderId',
                //Cell: ({ row }) => (
                //    <div>
                //        <a style={{ cursor: 'pointer', color: 'blue' }}
                //            onClick={() => this.handleclick(row)}>
                //            {this.getOrderId(row)}
                //        </a>
                //    </div>
                //)
            },
            {
                Header: 'Date #',
                accessor: '',
                Cell: ({ row }) => (
                    <React.Fragment>
                        <Moment format="MM/DD/YYYY">{this.state.orderDetailDate ? dateMDY(this.state.orderDetailDate.substring(0, 10)) : ""}</Moment>
                    </React.Fragment>
                )
                //Cell: ({ row }) => (
                //    <React.Fragment>
                //        {row.original.insertDate != null || row.original.insertDate == null || row.original.insertDate == "" ? dateMDY(this.state.orderDetailDate.substring(0, 10)) : ""}
                //    </React.Fragment>
                //)
                
            },
            {
                Header: 'Status',
                accessor: 'orderStatus',
                Cell: ({ row }) => (
                    <React.Fragment>
                        {row.original.orderStatus != null || row.original.orderStatus == null || row.original.orderStatus == "" ? orderStatus(this.state.orderDetailstatus) : this.state.orderDetailstatus}
                    </React.Fragment>
                )
            },
            {
                Header: 'SB #',
                accessor: 'sbNumber',
                Cell: ({ row }) => (
                    <div>
                        <a style={{ cursor: 'pointer', color: 'blue' }}
                            onClick={() => this.handleclick(row)}>
                            {row.original.sbNumber}
                        </a>
                    </div>
                )
            },
            {
                Header: 'BL #',
                accessor: 'blNumber',
            },
            {
                Header: 'Warehouse',
                accessor: 'warehouse',
                Cell: ({ row }) => (
                    <React.Fragment>
                        {row.original.warehouse ? warehouse(row.original.warehouse) : row.original.warehouse}
                    </React.Fragment>
                )
            },
            {
                Header: 'Category',
                accessor: 'salvageCategory',
            },
            {
                Header: 'Type',
                accessor: 'hdDotCom',
            },
            {
                Header: 'Item',
                accessor: 'itemQuantity',
            },
            {
                Header: 'Container Qty',
                accessor: 'containerQuantity',
            },
            {
                Header: 'Wholesale',
                accessor: 'cost',
                Cell: ({ row }) => (
                    <React.Fragment>
                        {row.original.cost ? numberformat(row.original.cost) : row.original.cost}
                    </React.Fragment>
                )
            },
            {
                Header: 'Remove Sb',
                accessor: '',
                Cell: ({ row }) => {

                    if (this.props.orderStatus == "Open" && (row.original.sbNumber > 0) ) {
                        return (<div>
                            <Tooltip title="Remove Sb Number">
                                <IconButton onClick={() => { this.removeChecked(row.original.orderId, row.original.id, row.original.sbNumber) }}
                                    /*onClick={() => this.props.removeSbFromOrder(row.original.orderId, row.original.id, row.original.sbNumber)} */ style={{ border: "1px solid", padding: "5px", marginRight: "5px", color: "red" }} variant="outline-secondary">
                               <ClearIcon />
                                </IconButton>
                            </Tooltip>
                        </div>)
                    }
                    else {
                        return (
                            ""//this.props.orderStatus
                        )
                    }
                }
            },
        ]
        if (loading && orderDetail.length === 0) {
            return (
                <div><em>Please wait while Loading Order Details......</em>
                    <div style={style}>
                        <SyncLoader
                            css={override}
                            size={15}
                            color={"#fd7e14"}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            );
        }
        if (orderDetail == null || orderDetail == "" || orderDetailDate == null || orderDetailstatus == null )
            return (
                <div>
                    {
                        //<Table responsive hover id="example1" className="display" width="100%" ref={el => this.el = el}>
                        //</Table>
                    }
                    
                    <div>
                        <h2>No Record Found</h2>
                    </div>
                </div>)
        return (
            <div>
                {
                    //<Table responsive hover id="example1" className="display" width="100%" ref={el => this.el = el}>
                    //</Table>
                }
                <CssBaseline />
                <ReactTable
                    columns={columns}
                    data={this.state.orderDetail}
                />

                <Modal centered size="xl" show={this.state.show} onHide={this.handleClose} className="sb-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>SB Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p><strong> SB #:</strong> {this.state.sbNumber}, <strong>Bl #:</strong> {this.state.blNumber}, <strong>Warehouse:</strong> {this.state.warehouse ? warehouse(this.state.warehouse) : this.state.warehouse}, <strong>Category:</strong> {this.state.salvageCategory}, <strong>Pallet Qty:</strong> {this.state.containerQuantity}</p>
                        <ModalDataTable sbNumber={this.state.sbNumber} warehouseId={this.state.warehouse} />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }

    async populateOrderDetailById(orderNumber) {
        const token = await authService.getAccessToken();
        const response = await fetch('Order/Detail?id=' + orderNumber, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (data.manifestList === null  ) {
            this.setState({ orderDetail: null, loading: false });
        }
        else {
            for (var i = 0; i < data.manifestList.length; i++) {
                var cost = 0.0;
                /*var cost = (data.manifestList[i].cost) * 1.15;*/
                var cost = (data.manifestList[i].cost);
                data.manifestList[i].cost = cost.toFixed(2);
            }
            this.setState({ orderDetail: data.manifestList, loading: false });
            this.setState({ orderDetailDate: data.orderDetail.orderDateTime, loading: false });
            this.setState({ orderDetailstatus: data.orderDetail.orderStatus, loading: false });
        }
    }

    async removeSbfromOrder(id, manifestId, SBNmuber) {
        const token = await authService.getAccessToken();
        const response = await fetch('Order/UnlinkManifest?orderId=' + id + '&manifestId=' + manifestId + '&sbNum=' + SBNmuber, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.props.fetchOrders({ timeStamp: (new Date), data: [] });
        this.props.openDialog(0,
            "Remove SB From Order ",
            "SB #" + SBNmuber + "  is removed from order #" + id + " successfully", "Close", "", DIALOG_ACTION_CLOSE, null, null)
        
        this.populateOrderDetailById(id);
        this.setState({ CheckedData: data, loading: false });
    }

}
const mapStateToProps = state => ({
    userOrders: state.orders
});

const mapActionsToProps = {
    removeSbFromOrder: removeSbFromOrder,
    openDialog: openDialog,
    fetchOrders: fetchOrders
}

export default connect(mapStateToProps, mapActionsToProps)(OrderDetail);
//export default OrderDetail;
