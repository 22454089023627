import React, { Component } from "react";
import { Modal, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ModalDataTable from './Modal-DataTable';
import authService from '../api-authorization/AuthorizeService';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { removeSbFromOrder } from '../../actions/cart-actions';
import { dateMDY, numberformat } from '../../DateHelper';
import { DIALOG_ACTION_CLOSE, DIALOG_ACTION_REMOVE_CART_ITEM, DIALOG_ACTION_CLEARCART, DIALOG_ACTION_REMOVE_SB_FROM_ORDER } from '../../constants';
import { openDialog } from '../../actions/dialog-actions';




class OrderDetailModal extends Component {

  // Popup Js
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
        show: false, credit: ""
      };
  }
    removeChecked(id, manifestId, SBNumber) {
        //this.removeSbfromOrder(id, manifestId, SBNumber)
        this.props.openDialog(0, "Remove SB From Order", "Are you sure that you want remove SB # " + SBNumber + " from the Order " + id + "?", "Cancel", "Continue", DIALOG_ACTION_CLOSE, DIALOG_ACTION_REMOVE_SB_FROM_ORDER, { id: id, manifestId: manifestId, SBNumber: SBNumber });
    }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
    }

  render() {

    return (
      <div>
        <div className="order-details right-scroll bg-white border">
          <h3 className=""><i className="fas fa-cart-plus product-icon"></i> Order Detail</h3>
            <div className="table-grid_OSb p-3 mt-1">
              <Table className="table">
                <tbody> 
                            <tr>
                                <th>Order #</th>
                                <td onClick={this.handleShow} style={{ cursor: 'pointer', color: 'blue' }}><span>{this.props.orderNumber}</span></td>
                                <th>Date</th>
                                <td><span><Moment format="MM/DD/YYYY">{this.props.DateTime ? dateMDY(this.props.DateTime.substring(0, 10)) : ""}</Moment></span></td>
                                <th>Status</th>
                                <td><span>{this.props.status}</span></td>
                            </tr>
                </tbody>
              </Table>
              <Table className="table">
                <tbody>
                  <tr>
                                <th>SB # </th>
                                <td><span>{this.props.sbNumber}</span></td>
                    <th>BL # </th>
                                <td><span>{this.props.blNumber}</span></td>
                    <th>Warehouse</th>
                                <td><span>{this.props.warehouse}</span></td>
                  </tr>
                </tbody>
              </Table>
              <Table className="table">
              <tbody> 
                <tr>
                  <th>Cate.</th>
                                <td><span>{this.props.salvageCategory}</span></td>
                  <th>Type</th>
                                <td><span>{this.props.hdDotCom}</span></td>
                  <th>Item</th>
                                <td><span>{this.props.itemQuantity}</span></td>
                </tr>
              </tbody>
            </Table>
            <Table className="table">
              <tbody>
                <tr>
                  <th>Cont Qty</th>
                                <td><span>{this.props.ContQuantity}</span></td>
                                <th>Wholesale</th>
                                {/*<td><span>{(this.props.wholesale * 1.15).toFixed(2) ? numberformat((this.props.wholesale * 1.15).toFixed(2)) : (this.props.wholesale * 1.15).toFixed(2)}</span></td>*/}
                                <td><span>{(this.props.wholesale).toFixed(2) ? numberformat((this.props.wholesale).toFixed(2)) : (this.props.wholesale).toFixed(2)}</span></td>

                                <th>
                                    {this.props.status === "Open" && (this.props.sbNumber > 0) ?
                                    <Tooltip title="Remove Sb from order">
                                        <IconButton onClick={() => { this.removeChecked(this.props.orderNumber, this.props.manifestId, this.props.sbNumber) }} style={{ border: "1px solid", padding: "5px", marginRight: "5px",color:"red" }} variant="outline-secondary">
                                            <ClearIcon />
                                        </IconButton>
                                    </Tooltip> : null}</th>
                  <th><span style={{visibility: "hidden"}}>No display</span></th>
                  <td><span style={{visibility: "hidden"}}>No display</span></td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <Modal centered size="xl" show={this.state.show} onHide={this.handleClose} className="sb-details-modal">
          <Modal.Header closeButton>
            <Modal.Title>SB Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                    <p><strong> SB #:</strong> {this.props.sbNumber}, <strong>Bl #:</strong> {this.props.blNumber}, <strong>Warehouse:</strong> {this.props.warehouse}, <strong>Category:</strong> Mixed Load, <strong>Pallet Qty:</strong> {this.props.ContQuantity}</p>
                    <ModalDataTable sbNumber={this.props.sbNumber} warehouseId={this.props.warehouseId} />
          </Modal.Body>
        </Modal>
      </div>
    );
  }

}
const mapStateToProps = state => ({
    userOrders: state.orders
});

const mapActionsToProps = {
    removeSbFromOrder: removeSbFromOrder,
    openDialog: openDialog
}

export default connect(mapStateToProps, mapActionsToProps)(OrderDetailModal);


//export default OrderDetailModal;
