import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import {Layout} from "../components/Layout";

class LoginPage extends Component {
  render() {
    return (
      <Layout>
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
             <Col md="12">
                <div className="login-dialog">
                  <div className="login-content">
                    <div className="rbm_form_header">
                      <h3>SIGN IN</h3>
                      </div>
                      <div className="rbm_form_body">
                        <Form action="/Home">
                        <div className="rbm_input_txt">
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>email *</Form.Label>
                            <Form.Control
                              required 
                              type="email" 
                              className="form-control" 
                              placeholder="Enter email" 
                            />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput2">
                            <Form.Label>password *</Form.Label>
                            <Form.Control
                              required 
                              type="password" 
                              className="form-control" 
                              placeholder="Enter password" 
                            />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput3">
                            <div className="custom-control custom-checkbox custom__Checkbox d-flex align-items-center ">
                              <Form.Control type="checkbox" className="custom-control-input" id="customCheck1" />
                              <label className="custom-control-label pt-2" htmlFor="customCheck1">Remember me</label>
                               <p className="forgot-password pt-2 mb-0 text-right">
                                 <Link to="/Forgot">Forgot your password?</Link>
                              </p>
                            </div>
                          </Form.Group>
                          
                          <div className="rbm_form_submit">
                            <Button type="submit" className="rbm_btn_x_out_shtr">Submit</Button>
                          </div>
                        </div>
                      </Form>
                    </div>
                    <div className="text-center create-new-fau">
                      <Link to="/register">
                        Don't have an account? <span>Create an Account</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    );
  }
}

export default LoginPage;
