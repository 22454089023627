import React, { Component } from "react";
import IconButton from '@material-ui/core/IconButton';
import "../../assets/css/jquery.dataTables.min.css";
import { Link } from 'react-router-dom';
import authService from '../api-authorization/AuthorizeService'
import ReactDOM from 'react-dom';
import { css } from "@emotion/core";
import CssBaseline from '@material-ui/core/CssBaseline'
import ReactTable from './ReactTable'
import SyncLoader from "react-spinners/SyncLoader";



const override = css`
  display: block;
  margin: 2 auto;
  border-color: red;
`;
//const $ = require('jquery');
//$.DataTable = require('datatables.net');

class ManageRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderdata: [], loading: true
        };

    }

    componentDidMount() {
        if (this.props.data)
            this.manageCreditRequestData(this.props.data);
        else
            this.manageCreditRequestData("All");
    }

    //componentWillReceiveProps(props) {
    //    this.manageCreditRequestData(props.data);
    //}
    componentDidUpdate(prevProps) {
        console.log("Did Update");
        if ( prevProps.data != this.props.data ) {
            console.log("Updating");
            if (this.props.data) {
                this.manageCreditRequestData(this.props.data);
            }
            else {
                this.manageCreditRequestData(this.props.data);
            }
        }
    }

    getOrderId = (e) => {
        return e.original.orderNumber;
    }

    getOrderDate = (e) => {
        return e.original.insertDateTime
    }
    getOrderStatus = (e) => {
        return e.original.orderStatus
    }
    getWarehouse = (e) => {
        return e.original.warehouse
    }

    orderCredit(orderNumber){
        this.props.history.push({
            pathname: '/MyOrders',
            state: orderNumber
        })
    }
    render() {
       
        const columns = [
            {
                Header: 'Order #',
                accessor: 'orderNumber',
                Cell: ({ row }) => (
                    <div>
                        <a style={{ cursor: 'pointer', color: 'blue' }}
                            onClick={() => this.orderCredit(this.getOrderId(row))}>
                            {this.getOrderId(row)}
                        </a>
                    </div>
                )
            },
            {
                Header: 'Credit Amount Total',
                accessor: 'creditAmountTotal',
            },
            {
                Header: 'Credit Reason',
                accessor: 'creditReason',
            },
            {
                Header: 'Comments',
                accessor: 'comments',
            },
            {
                Header: 'Credit Request Status',
                accessor: 'status',
            },
            {
                Header: 'Submitted Date',
                accessor: 'insertDateTime',

                Cell: ({ row }) => {
                    var date = new Date(this.getOrderDate(row));
                    var month = date.getMonth() + 1;
                    return (month.toString().length > 1 ? month : "0" + month) + "/" + date.getDate() + "/" + date.getFullYear();
                }
            },

        ]
        let { orderdata = [] } = this.state;

        if (orderdata === null)
            return null;
        return (
            <div>
                <div>
                    <p className="bg-info p-3 mb-4 text-white shadow-sm"><strong>NOTE:</strong> SB # is the identifying Slot By Number used to identify manifests</p>
                    {
                        //<Table responsive hover id="inventory-datable" className="display" width="100%" ref={el => this.el = el} >

                        //</Table>
                    }
                        <CssBaseline />
                        <ReactTable
                        columns={columns}
                        data={this.state.orderdata}
                        minRows={0}
                        className="-striped -highlight"
                        
                        />
                    
                </div>
            </div>
        );
    }



    async manageCreditRequestData(status) {
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/ManageCreditRequests?status=' + status, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        //for (var i = 0; i < data.orderViewModel.orderList.length; i++) {
        //    var cost = 0.0;
        //    var cost = (data.orderViewModel.orderList[i].cost) * 1.15;
        //    data.orderViewModel.orderList[i].cost = "$" + cost.toFixed(2);

        //    if (data.orderViewModel.orderList[i].orderStatus == 0)
        //        data.orderViewModel.orderList[i].orderStatus = "NotSpecified";
        //    else if (data.orderViewModel.orderList[i].orderStatus == 1)
        //        data.orderViewModel.orderList[i].orderStatus = "Open";
        //    else if (data.orderViewModel.orderList[i].orderStatus == 2)
        //        data.orderViewModel.orderList[i].orderStatus = "Assigned";
        //    else if (data.orderViewModel.orderList[i].orderStatus == 3)
        //        data.orderViewModel.orderList[i].orderStatus = "Approved";
        //    else if (data.orderViewModel.orderList[i].orderStatus == 4)
        //        data.orderViewModel.orderList[i].orderStatus = "Shipped";
        //    else if (data.orderViewModel.orderList[i].orderStatus == 5)
        //        data.orderViewModel.orderList[i].orderStatus = "PartiallyShipped";
        //    else if (data.orderViewModel.orderList[i].orderStatus == 6)
        //        data.orderViewModel.orderList[i].orderStatus = "Cancelled";
        //    else if (data.orderViewModel.orderList[i].orderStatus == 7)
        //        data.orderViewModel.orderList[i].orderStatus = "PendingApproval";
        //    else
        //        data.orderViewModel.orderList[i].orderStatus = data.orderViewModel.orderList[i].orderStatus;
       // }
        this.setState({ orderdata: data.creditRequestList, loading: false });

    }

}


export default ManageRequest;