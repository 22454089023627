import React, { Component } from 'react';
import { Badge, Button, ButtonToolbar, Card, Col, Container, Form, Row, Table } from 'react-bootstrap';
import authService from '../components/api-authorization/AuthorizeService';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import ContentLayout from "../components/ContentLayout";
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import InvoiceDetailSalvageSales from '../components/Invoice/InvoiceDetailSalvageSales';
import InvoiceDetailSalvageSalesTruck from '../components/Invoice/InvoiceDetailSalvageSalesTruck';
import InvoiceDetailBerOrTurbo from '../components/Invoice/InvoiceDetailBerOrTurbo';
import InvoiceDetailOther from '../components/Invoice/InvoiceDetailOther';
import ArchivedInvoicesData from '../views/ArchivedInvoicesData';
import { connect } from 'react-redux';
import { updateSearchList, updateSearchListt, returnUpdateSearch } from '../actions/search-actions';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { CSVLink } from "react-csv";
import { dateMDY, numberformat } from '../DateHelper';

class Invoices extends Component {
    constructor(props) {
        super(props);
        this.getInvoiceDetails = this.getInvoiceDetails.bind(this);
        this.state = {
            ordertype: "All",
            invoicestatus: "All",
            invoicetype: "All",
            searchSB: "",
            InvoiceList: [],InvoiceListTruck: [], InvoiceDetailsById: [], customerDetail: {}, thd: {
                invoiceCustomer: {}
            }, loading: true, total: "", credit: "", payment: "", dynamicInvoiceTypeList: [], dynamicInvoiceStatusList: [],
            OtherInvoiceDetailsById: [], SalvageinvoiceItemList: [], Salvageinvoicecredit: [], BerTurboInvoiceDetailsById: [], FilterInvoice: []
        };
        this.ChangeInvoiceStatus = this.ChangeInvoiceStatus.bind(this);
        this.ChangeInvoiceType = this.ChangeInvoiceType.bind(this);
        this.ChangeOrderType = this.ChangeOrderType.bind(this);
        this.props.updateSearchList([]);
        this.props.updateSearchListt([]);
        this.props.returnUpdateSearch();
    }

    ChangeInvoiceType(event) {
        this.setState({ invoicetype: event.target.value, loading: false });
    }
    ChangeOrderType(event) {
        this.setState({ ...this.state, ordertype: event.target.value });
        console.log(this.state);
        if (this.state.invoicestatus) {
            this.props.history.replace({ statee: event.target.value, state: this.state.invoicestatus });

        }
        else {
            this.props.history.replace({ statee: event.target.value });
        }

    }


    ChangeInvoiceStatus(event) {
        this.setState({ ...this.state, invoicestatus: event.target.value });
        console.log(this.state);
        if (this.state.ordertype) {
            this.props.history.replace({ state: event.target.value, statee: this.state.ordertype });

        }
        else {
            this.props.history.replace({ state: event.target.value });
        }
    }



    getInvoiceDetails = (invoiceId, invoiceType, invoiceStatus, isTruck, invoiceList, filterInvoice) => {
        this.setState({
            invoiceId: invoiceId, invoiceType: invoiceType, invoiceStatus: invoiceStatus, orderDetail: [], InvoiceDetailsById: [], isTruck: isTruck, loading: true
        });
        if (invoiceType == "Salvage Sales" && this.props.location.statee != "Truck" && isTruck == false)
            this.SalvageInvoiceDetailsById(invoiceId);

        else if (this.props.location.statee == "Truck" || isTruck == true)
            this.SalvageInvoiceDetailsByIdTruck(invoiceId);

        else if (invoiceType == "BER" || invoiceType == "Turbo")
            this.BERInvoiceDetailsById(invoiceId);
        else
            this.OtherInvoiceDetailsById(invoiceId);
        let dynamicInvoiceTypeList = [], dynamicInvoiceStatusList = [];
        if (invoiceList != undefined && invoiceList != null) {
            if (invoiceList.length > 0) {
                var gridData = invoiceList;
                for (var i = 0; i < gridData.length; i++) {
                    let invoiceTypeCount = 0;
                    let invoiceStatusCount = 0;
                    for (var j = 0; j < dynamicInvoiceTypeList.length; j++) {
                        if (dynamicInvoiceTypeList[j].type == gridData[i].invoiceType) {
                            invoiceTypeCount++;
                        }

                    }
                    for (var k = 0; k < dynamicInvoiceStatusList.length; k++) {
                        if (dynamicInvoiceStatusList[k].status == gridData[i].invoiceStatus) {
                            invoiceStatusCount++;
                        }
                    }
                    if (i == 0) {
                        dynamicInvoiceTypeList.push({ type: "All" })
                        dynamicInvoiceStatusList.push({ status: "All" })
                    }
                    if (invoiceTypeCount == 0) {
                        dynamicInvoiceTypeList.push({ type: gridData[i].invoiceType })
                    }
                    if (invoiceStatusCount == 0) {
                        dynamicInvoiceStatusList.push({ status: gridData[i].invoiceStatus })
                    }
                }
                for (let i = 0; i < dynamicInvoiceStatusList.length; i++) {
                    if (dynamicInvoiceStatusList[i].status == "Invoiced")
                        dynamicInvoiceStatusList[i].status = "Outstanding";
                    else if (dynamicInvoiceStatusList[i].status == "Partial Payment Applied")
                        dynamicInvoiceStatusList[i].status = "PartiallyPaid";
                    else if (dynamicInvoiceStatusList[i].status == "Paid in Full")
                        dynamicInvoiceStatusList[i].status = "PaidInFull";
                }
                this.setState({ dynamicInvoiceTypeList: dynamicInvoiceTypeList, dynamicInvoiceStatusList: dynamicInvoiceStatusList, FilterInvoice: filterInvoice })
            }
        }

    }
    navigateToInventory = () => {
        this.props.history.push({
            pathname: "/Inventory"
        })
    }
    render() {
        const headers = [
            {
                label: 'Invoice Id',
                key: 'id',
            },
            {
                label: 'Invoice Status',
                key: 'invoiceStatus',
            },
            {
                label: 'Invoice Type',
                key: 'invoiceType',
            },
            {
                label: 'Order Number',
                key: 'orderNumbers[0].order_id',
            },
            {
                label: 'SB Number',
                key: 'sbNumbersAndWarehouseIdsList[0].sb_number',
            },
            {
                label: 'Invoice Amount',
                key: 'invoiceAmount',
            },
            {
                label: 'Invoice Balance',
                key: 'invoiceBalance',
            },
            {
                label: 'Credit Applied',
                key: 'creditsApplied',
            },
            {
                label: 'Invoice Date',
                key: 'invoiceDate',
            }
        ]
        let { dynamicInvoiceTypeList, dynamicInvoiceStatusList } = this.state;
        return (
            <ContentLayout>
                <Breadcrumb
                    breadcrumb={[
                        { name: "Home", path: "/" },
                        { name: "Invoices", path: "/Invoices" },
                        { name: "Archive Invoices", path: "/ArchiveInvoices" }
                    ]}
                />
                <div className="bg-grey pt-5 pb-5">
                    <Button className="text-white" onClick={() => this.navigateToInventory()} variant="outline-secondary" style={{ backgroundColor: "#273546" }}><ArrowBackIcon /> <span>Back to Inventory</span></Button>
                    <Container fluid className="pl-5 pr-5">
                        <Row className="bg-white p-2">
                            <Col lg="12">
                                <Form>
                                    <Form.Row className="d-flex justify-content-around">
                                        <Form.Group className="col-3" ><Form.Label>
                                            <h5>Order Type:</h5>
                                        </Form.Label>
                                            <Form.Control as="select" value={this.props.location.statee} onChange={this.ChangeOrderType}>
                                                <option value="All">All</option>
                                                <option value="Standard">Standard</option>
                                                <option value="Truck">TruckLoad</option>

                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="col-3" ><Form.Label>
                                            <h5>Invoice Type:</h5>
                                        </Form.Label>
                                            <Form.Control as="select" onChange={this.ChangeInvoiceType}>
                                                {!dynamicInvoiceTypeList ? null : dynamicInvoiceTypeList.map(invoice => {
                                                    return (
                                                        <option key={invoice.type} value={invoice.type}>{invoice.type}</option>
                                                    )
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="col-3">
                                            <Form.Label>
                                                <h5>Invoice Status:</h5>
                                            </Form.Label>
                                            <Form.Control as="select" value={this.props.location.state} onChange={this.ChangeInvoiceStatus}>
                                                {!dynamicInvoiceStatusList ? null : dynamicInvoiceStatusList.map(invoice => {
                                                    return (
                                                        <option key={invoice.status} value={invoice.status}>{invoice.status}</option>
                                                    )
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="col-3">
                                            <Form.Label >
                                                <h5>Search by SB/Order/Invoice:</h5>
                                            </Form.Label>
                                            <Form.Control type="text" placeHolder="SB Number/OrderNumber/InvoiceNumber" value={this.state.searchSB} onChange={(e) => {
                                                this.setState({ searchSB: e.target.value });
                                            }} />
                                        </Form.Group >
                                    </Form.Row>
                                    {this.props.location.state == 'Outstanding' ? <div className=" text-center"> <span className="font-italic ">*Outstanding status is displayed when the total amount due is<br></br> not the total amount marked as paid that has not been reconciled</span></div> : this.props.location.state == 'PartiallyPaid' ?
                                        <div className=" text-center"> <span className=" font-italic">*Partially Paid status is displayed when is the amount<br></br> that is showing what is paid and unpaid on the invoice</span></div> :
                                        this.props.location.state == 'PaidInFull' ? <div className=" text-center"> <span className=" font-italic ">*Paid in Full status is displayed when the invoice amount<br></br> is paid in full to include any wire payments and/or credits that were applied.</span></div> :
                                            this.props.location.state == 'Overdue' ? <div className=" text-center"> <span className=" font-italic">*Overdue status is displayed when the amount that is<br></br> outstanding according to our reconciled records is not paid</span></div> : null}
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div>
                    <CssBaseline />
                    {
                        <CSVLink
                            headers={headers}
                            data={this.state.FilterInvoice}
                            filename={"invoice.csv"}
                            className="btn text-white"
                            target="_blank"
                            style={{ float: 'left', backgroundColor: '#fd7e14' }}
                        >
                            Export Invoice Detail
                        </CSVLink>
                    }

                </div>
                <div className="content invoices-contant border-top bg-grey pt-0 pb-5">
                    <div className="container-fluid pl-5 pr-5">
                        <div className="thd-content thd-content-app mt-5">
                            <Row>
                                <Col lg={4}>
                                    <ArchivedInvoicesData searchSB={this.state.searchSB} goto={(invoiceId, invoiceType, invoiceStatus, invoiceList, filterInvoice, isTruck) => this.getInvoiceDetails(invoiceId, invoiceType, invoiceStatus, invoiceList, filterInvoice, isTruck)} data={this.props.location.state} isdashboard={this.props.location.dashboard} invoiceType={this.state.invoicetype} invoiceNumber={this.props.location.invoiceNumber} orderType={this.props.location.statee} />
                                </Col>
                                <Col lg={8}>
                                    {this.state.invoiceType == 'Salvage Sales' && this.props.location.statee != "Truck" && this.state.isTruck == false ?
                                        <InvoiceDetailSalvageSales invoiceId={this.state.invoiceId} invoiceStatus={this.state.invoiceStatus} InvoiceCust={this.state.thd.invoiceCustomer} InvoiceList={this.state.SalvageinvoiceItemList} Data={this.state.thd} stotal={this.state.total} scredit={this.state.credit} spayment={this.state.payment} />
                                        : this.state.invoiceType !== undefined && (this.props.location.statee == "Truck" || this.state.isTruck == true) ?
                                        <InvoiceDetailSalvageSalesTruck invoiceId={this.state.invoiceId} invoiceStatus={this.state.invoiceStatus} InvoiceCust={this.state.thd.invoiceCustomer} InvoiceList={this.state.SalvageinvoiceItemList} InvoiceListTruck={this.state.Salvageinvoicecredit} Data={this.state.thd} stotal={this.state.total} scredit={this.state.credit} spayment={this.state.payment} />
                                        : this.state.invoiceType == "BER" || this.state.invoiceType == "Turbo" ?
                                            <InvoiceDetailBerOrTurbo invoiceId={this.state.invoiceId} invoiceType={this.state.invoiceType} invoiceStatus={this.state.invoiceStatus} InvoiceCust={this.state.thd.invoiceCustomer} InvoiceList={this.state.BerTurboInvoiceDetailsById} Data={this.state.thd} stotal={this.state.total} scredit={this.state.credit} spayment={this.state.payment} />
                                            : this.state.invoiceType == 'Other' ?
                                                < InvoiceDetailOther invoiceId={this.state.invoiceId} invoiceStatus={this.state.invoiceStatus} InvoiceCust={this.state.thd.invoiceCustomer} InvoiceList={this.state.OtherInvoiceDetailsById} Data={this.state.thd} stotal={this.state.total} scredit={this.state.credit} spayment={this.state.payment} />
                                                : null}

                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>

            </ContentLayout>
        );
    }

    async SalvageInvoiceDetailsById(invoiceId) {
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/Invoice?id=' + invoiceId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        var subtotal = 0.0;
        var totalcredits = 0.0;
        var totalpayments = 0.0;
        if (data.invoiceItemList != null && data.invoiceItemList != "") {
            for (var j = 0; j < data.invoiceItemList.length; j++) {
                if (!data.invoiceItemList[j].isCredit && !data.invoiceItemList[j].isPayment) {
                    subtotal += parseFloat(data.invoiceItemList[j].unitCost) * parseFloat(data.invoiceItemList[j].quantity)
                }
                if (data.invoiceItemList[j].isCredit) {
                    totalcredits += parseFloat(data.invoiceItemList[j].unitCost) * parseFloat(data.invoiceItemList[j].quantity)
                }
                if (data.invoiceItemList[j].isPayment) {
                    totalpayments += parseFloat(data.invoiceItemList[j].paymentAmount)
                }
            }
            subtotal = subtotal.toFixed(2);
            totalcredits = Math.abs(totalcredits.toFixed(2));
            totalpayments = Math.abs(totalpayments.toFixed(2));
        }
        this.setState({ thd: data, loading: false });
        this.setState({ customerDetail: data.invoiceCustomer, loading: false });
        this.setState({ SalvageinvoiceItemList: data.invoiceItemList, total: subtotal, credit: totalcredits, payment: totalpayments, loading: false });
    }

    async SalvageInvoiceDetailsByIdTruck(invoiceId) {
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/InvoiceTruck?id=' + invoiceId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        var subtotal = 0.0;
        var totalcredits = 0.0;
        var totalpayments = 0.0;
        if (data.invoiceItemList != null && data.invoiceItemList != "") {
            for (var j = 0; j < data.invoiceItemList.length; j++) {
                if (!data.invoiceItemList[j].isCredit && !data.invoiceItemList[j].isPayment) {
                    if (data.invoiceItemList[j].truckLoadOrderamount == 0) {
                        var quant = (data.invoiceItemList[j].quantity / 100);
                        subtotal += parseFloat(data.invoiceItemList[j].unitCost) * parseFloat(quant)
                    }
                    else {
                        var unitcosttotal = data.invoiceItemList[j].unitCost;
                        var quant = (data.invoiceItemList[j].new_BuyitNow_Rate / 100);
                        subtotal += parseFloat(unitcosttotal) * parseFloat(quant)
                    }
                }
            }
            subtotal = subtotal.toFixed(2);
        }

        if (data.invoiceItemListTruck != null && data.invoiceItemListTruck != "") {
            for (var j = 0; j < data.invoiceItemListTruck.length; j++) {

                if (data.invoiceItemListTruck[j].isCredit) {
                    totalcredits += parseFloat(data.invoiceItemListTruck[j].unitCost) * parseFloat(data.invoiceItemListTruck[j].quantity)
                }
                if (data.invoiceItemListTruck[j].isPayment) {
                    totalpayments += parseFloat(data.invoiceItemListTruck[j].paymentAmount)
                }
            }

            totalcredits = Math.abs(totalcredits.toFixed(2));
            totalpayments = Math.abs(totalpayments.toFixed(2));
        }

        this.setState({ thd: data, loading: false });
        this.setState({ customerDetail: data.invoiceCustomer, loading: false });
        this.setState({ SalvageinvoiceItemList: data.invoiceItemList, Salvageinvoicecredit: data.invoiceItemListTruck, total: subtotal, credit: totalcredits, payment: totalpayments, loading: false });
    }

    async OtherInvoiceDetailsById(invoiceId) {
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/OtherManualInvoice?id=' + invoiceId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        var subtotal = 0.0;
        var totalcredits = 0.0;
        var totalpayments = 0.0;
        if (data.otherManualInvoiceItemList != null && data.otherManualInvoiceItemList != "") {
            for (var j = 0; j < data.otherManualInvoiceItemList.length; j++) {
                if (!data.otherManualInvoiceItemList[j].isCredit && !data.otherManualInvoiceItemList[j].isPayment) {
                    subtotal += parseFloat(data.otherManualInvoiceItemList[j].priceTotal)
                }
                if (data.otherManualInvoiceItemList[j].isCredit) {
                    totalcredits += parseFloat(data.otherManualInvoiceItemList[j].priceTotal)
                }
                if (data.otherManualInvoiceItemList[j].isPayment) {
                    totalpayments += parseFloat(data.otherManualInvoiceItemList[j].priceTotal)
                }
            }
            subtotal = subtotal.toFixed(2);
            totalcredits = Math.abs(totalcredits.toFixed(2));
            totalpayments = Math.abs(totalpayments.toFixed(2));
        }
        this.setState({ thd: data, loading: false });
        this.setState({ customerDetail: data.invoiceCustomer, loading: false });
        this.setState({ OtherInvoiceDetailsById: data.otherManualInvoiceItemList, total: subtotal, credit: totalcredits, payment: totalpayments, loading: false });
    }

    async BERInvoiceDetailsById(invoiceId) {
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/BEROrTurboManualInvoice?id=' + invoiceId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        var subtotal = 0.0;
        var totalcredits = 0.0;
        var totalpayments = 0.0;
        if (data.berOrTurboManualInvoiceItemList != null && data.berOrTurboManualInvoiceItemList != "") {
            for (var j = 0; j < data.berOrTurboManualInvoiceItemList.length; j++) {
                if (!data.berOrTurboManualInvoiceItemList[j].isCredit && !data.berOrTurboManualInvoiceItemList[j].isPayment) {
                    subtotal += parseFloat(data.berOrTurboManualInvoiceItemList[j].priceTotal)
                }
                if (data.berOrTurboManualInvoiceItemList[j].isCredit) {
                    totalcredits += parseFloat(data.berOrTurboManualInvoiceItemList[j].priceTotal)
                }
                if (data.berOrTurboManualInvoiceItemList[j].isPayment) {
                    totalpayments += parseFloat(data.berOrTurboManualInvoiceItemList[j].priceTotal)
                }
            }
            subtotal = subtotal.toFixed(2);
            totalcredits = Math.abs(totalcredits.toFixed(2));
            totalpayments = Math.abs(totalpayments.toFixed(2));
        }
        this.setState({ thd: data, loading: false });
        this.setState({ customerDetail: data.invoiceCustomer, loading: false });
        this.setState({ BerTurboInvoiceDetailsById: data.berOrTurboManualInvoiceItemList, total: subtotal, credit: totalcredits, payment: totalpayments, loading: false });
    }
}


const mapActionsToProps = {
    updateSearchList: updateSearchList,
    updateSearchListt: updateSearchListt,
    returnUpdateSearch: returnUpdateSearch
}

export default connect(null, mapActionsToProps)(Invoices);
