import React, { Component } from 'react';
import ContentLayout from "../components/ContentLayout";
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import { Col, Container, Row, Button, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { updateSearchList, returnUpdateSearch} from '../actions/search-actions';

class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.props.updateSearchList([]);
        this.props.returnUpdateSearch();
    }
  render() {
    return (
      <ContentLayout>
        <Breadcrumb
          breadcrumb={[
            { name: "Home", path: "/" },
            { name: "Privacy Policy", path: "/PrivacyPolicy" }
          ]}
        />
        <div className="content pt-5 pb-5">
          <Container>
            <Row className="justify-content-center">
              <Col md="12">
                <div className="animated fadeIn">
                  <Card className="rounded-0 bg-white shadow-sm">
                    <Card.Body>
                      <Card.Title>Privacy Policy</Card.Title>
                       <Card.Text>
                        <div><Button variant="contained"
                            color="default" id="privacy_text"
                        onClick={() => window.open('/ISSA.pdf')}><span>1.</span>International Salvage Sales Agreement
                        </Button></div>
                                           
                        <div> <Button  id="privacy_text" 
                            variant="contained"
                            color="default"
                            onClick={() => window.open('/DSS.pdf')}
                        ><span>2.</span>Domestic Salvage Sales Agreement</Button></div>
                        <div> <Button id="privacy_text" 
                            variant="contained"
                            color="default"
                            onClick={() => window.open('/NDA.pdf')}
                        ><span>3.</span>THD Mutual Non Disclosure Agreement</Button></div>
                       </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ContentLayout>
    );
  }
}
const mapActionsToProps = {
    updateSearchList: updateSearchList,
    returnUpdateSearch: returnUpdateSearch
}

export default connect(null, mapActionsToProps)(PrivacyPolicy);
