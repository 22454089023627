import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import rightArrow from "../../assets/img/icon/arrow grey.png";
import blueArrow from "../../assets/img/icon/arrow bl.png";
//import { Link } from "react-router-dom";
import { withRouter, Link } from 'react-router-dom';
import { updateCartData, fetchCartData } from '../../actions/cart-actions'
import { updateSearch, returnUpdateSearch } from '../../actions/search-actions';
import { connect } from 'react-redux';
import { openDialog } from '../../actions/dialog-actions';
import { DIALOG_ACTION_CLOSE, DIALOG_ACTION_REMOVE_CART_ITEM, DIALOG_ACTION_CLEARCART } from '../../constants';
import Checkbox from '@material-ui/core/Checkbox';
import { dateMDY, numberformat, warehouse } from '../../DateHelper';
//import '../header.css'
import Nav from 'react-bootstrap/Nav';
import styles from "../../components/Breadcrumb/Breadcrumb.module.css";


class Breadcrumb extends Component {
    constructor(props) {
        super(props);
    }
    handlelogoclick(category) {
        this.props.history.push({
            pathname: category,
            state: "Again"
        })

    }
    render() {

        const { breadcrumb } = this.props;

        return (
            <div className="page-header pt-3 pb-3 border-bottom">
                <Container fluid className="pl-5 pr-5">
                    <div className={styles.breadcrumb}>
                        {breadcrumb.map((crumb, index) => {
                            let image = index === breadcrumb.length - 2 ? blueArrow : rightArrow;
                            return (
                                    <Nav.Link className="m-0 p-0 mt-3" key={index} onClick={() => this.handlelogoclick(crumb.path)}>
                                    <span >
                                        {crumb.name}
                                        {index !== breadcrumb.length - 1 ? (
                                            //<img src={image} alt="right arrow" />
                                            <b> / </b>
                                        ) : null}
                                        </span>
                                    </Nav.Link>
                            );
                        })}
                        {
                        //    this.props.cart.checkedData && this.props.cart.checkedData.length > 0 ?
                        //    <div className="btn-group dropend position-relative side_bar " data-toggle="">
                        //        <span type="button" className="btn fa fa-ellipsis-v icon_display position-fixed" style={{ color: 'red', fontSize: "38px" }}
                        //            data-bs-toggle="dropdown" aria-expanded="false">
                        //        </span>
                        //        <ul class="dropdown-menu dropdownmenu_sidebar " >

                        //            <div className="data-text p-2">
                        //                <span ><h2 className="text-center pb-2"> CART SUMMARY</h2></span>
                        //                <div className="shopping-cart-header d-flex card_header border-bottom pb-2">
                        //                    <i className="fa-2x fa fa-shopping-cart cart-icon"></i><span className="badge badge-orange">{this.props.cart.itemsSelected}</span>
                        //                    <div className="shopping-cart-total order_total text-dark font-weight-bold" style={{ marginLeft: "30%", fontSize: "14px" }}>
                        //                        <span className="s-color-text">Order Total:</span>
                        //                        <span className="main-color-text">{this.props.cart.valuePurchased ? numberformat(this.props.cart.valuePurchased) : this.props.cart.valuePurchased}</span>

                        //                    </div>
                        //                </div>
                        //                <div className="shopping-cart-header border-bottom "></div>
                        //                {
                        //                    this.props.cart.checkedData != null ? this.props.cart.checkedData.map(item => {
                        //                        return (
                        //                            <span key={item[0]}>

                        //                                <div className="side_Content pt-1" style={{ fontSize: "13px" }}>
                        //                                    <span className="text-dark font-weight-bold">SBNumber : </span>
                        //                                    <span className="main-first font-weight-bold">{item[1]}</span>
                        //                                    <span className="text-dark font-weight-bold"> Category : </span>
                        //                                    <span className="main-secound font-weight-bold">{item[2]}</span>
                        //                                    <span className="text-dark font-weight-bold"> Type : </span>
                        //                                    <span className="main-third font-weight-bold">{item[3]}</span>
                        //                                    <span className="text-dark font-weight-bold"> Item Qty : </span>
                        //                                    <span className="main-four font-weight-bold">{item[8]}</span>
                        //                                    <span className="text-dark font-weight-bold"> Pallet QTY : </span>
                        //                                    <span className="main-five font-weight-bold">{item[4]}</span>
                        //                                    <span className="text-dark font-weight-bold"> Wholesale : </span>
                        //                                    <span className="main-six font-weight-bold">{item[6] ? numberformat(item[6]) : item[6]}</span>
                        //                                    <span className="text-dark font-weight-bold"> Rate% : </span>
                        //                                    <span className="main-seven font-weight-bold">{item[7]}</span>
                        //                                    <span className="text-dark font-weight-bold"> InvoiceAmount : </span>
                        //                                    <span className="main-eight font-weight-bold">{(parseFloat(item[6]) * parseFloat(item[7]) / 100).toFixed(2) ? numberformat((parseFloat(item[6]) * parseFloat(item[7]) / 100).toFixed(2)) : (parseFloat(item[6]) * parseFloat(item[7]) / 100).toFixed(2)}</span>
                        //                                    <span className="text-dark font-weight-bold"> Warehouse : </span>
                        //                                    <span className="main-ninet font-weight-bold">{item[9] ? warehouse(item[9]) : item[9]}</span>
                        //                                    <div className=" pb-2"></div>
                        //                                    <hr className=" text-secondary m-0 p-0 w-0" />
                        //                                </div>
                        //                            </span>

                        //                        )
                        //                    }) : null
                        //                }
                        //            </div>
                        //        </ul>
                        //    </div>
                        //: null
                        }
                    </div>
                </Container>
            </div>

        );
    }
}
const mapStateToProps = state => ({
    cart: state.cart,
    searchList: state.searchList,
    cartData: state.cartData
});

const mapActionsToProps = {
    getCartSummary: updateCartData,
    getCartData: fetchCartData,
    openDialog: openDialog,
    updateSearch: updateSearch,
    returnUpdateSearch: returnUpdateSearch
}

export default withRouter(connect(mapStateToProps, mapActionsToProps)(Breadcrumb));
//export default Breadcrumb;
