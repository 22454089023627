import React, { Component } from 'react';
import { Badge, Button, ButtonToolbar, Card, Col, Container, Form, Row, Table } from 'react-bootstrap';
import authService from '../components/api-authorization/AuthorizeService';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import ContentLayout from "../components/ContentLayout";
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import InvoiceDetailSalvageSales from '../components/Invoice/InvoiceDetailSalvageSales';
import SyncLoader from "react-spinners/SyncLoader";
import { css } from "@emotion/core";
import { dateMDY, numberformat } from '../DateHelper';
import TablePagination from '@material-ui/core/TablePagination';

const override = css`
  display: block;
  margin: 2 auto;
  border-color: red;
`;
const style = { position: "fixed", top: "45%", left: "50%", transform: "translate(-50%, -50%)" };

class InvoiceData extends Component {

    constructor(props) {
        super(props);
        // this.getInvoiceDetails = this.getInvoiceDetails.bind(this);
        this.state = {
            InvoiceList: [], InvoiceDetailsById: [], AllInvoice: [], customerDetail: {}, thd: {}, loading: true, page: 0, rowsPerPage: 10
        };
    }

    //componentDidMount() {
    //    this.populateInvoiceData();
    //}
    componentDidMount() {
        console.log("Did Mount");
        if (this.props.isdashboard && this.props.data) {
            this.populateInvoiceDataByStatus(this.props.data, this.props.isdashboard);
        }
        else if (this.props.data) {
            this.populateInvoiceDataByStatus(this.props.data);
        }
        else
            this.populateInvoiceDataByStatus("All");
    }

    componentDidUpdate(prevProps) {
        console.log("Did Update");

        if (prevProps.invoiceType != this.props.invoiceType || prevProps.data != this.props.data || prevProps.searchSB != this.props.searchSB || prevProps.orderType != this.props.orderType) {
            console.log("Updating");
            if (!this.props.invoiceType) {
                if (!this.props.orderType) {
                    this.populateInvoiceDataByStatus(this.props.data);
                }
                else {
                    this.populateInvoiceDataByType(this.props.data, this.props.invoiceType, this.props.orderType);
                }
            }
            else if (this.props.orderType == "Truck") {
                this.populateInvoiceDataByType(this.props.data, this.props.invoiceType, this.props.orderType);
            }
            else {
                this.populateInvoiceDataByType(this.props.data, this.props.invoiceType, this.props.orderType);
            }
        }
    }

    componentWillReceiveProps(props) {
    }
    ////async populateInvoiceData() {
    ////    const token = await authService.getAccessToken();
    ////    const response = await fetch('InvoiceCode/Manage', {
    ////        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    ////    });
    ////    const data = await response.json();
    ////    this.setState({ InvoiceList: data.updateCustomerInvoice.invoiceList, loading: false });
    ////}
    async populateInvoiceDataByStatus(Status, isDashboard) {
        const token = await authService.getAccessToken();
        if (isDashboard) {
            const responseAll = await fetch('InvoiceCode/Manage?sbNumber=' + this.props.searchSB + '&invoiceStatus=All', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            const data1 = await responseAll.json();
            this.setState({
                AllInvoice: data1.updateCustomerInvoice.invoiceList
            })
        }
        const response = await fetch('InvoiceCode/Manage?sbNumber=' + this.props.searchSB + '&invoiceStatus=' + Status + '&archivedInvoice=2', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (Status == "All") {
            this.setState({
                AllInvoice: data.updateCustomerInvoice.invoiceList
            })
        }
        this.updateInvoiceList(data.updateCustomerInvoice.invoiceList);
    }
    async populateInvoiceDataByType(InvoiceStatus, InvoiceType, OrderType) {
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/SearchInvoice?sbNumber=' + this.props.searchSB + '&InvoiceType=' + InvoiceType + '&OrderType=' + OrderType + '&InvoiceStatus=' + InvoiceStatus + '&archivedInvoice=2', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (InvoiceStatus !== "All" || InvoiceType !== "All" || OrderType !== "All")
            this.setState({ page: 0 })
        this.updateInvoiceList(data.updateCustomerInvoice.invoiceList);
    }
    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        })
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10)
        })
    };


    updateInvoiceList = (InvoiceList) => {

        console.log(InvoiceList);

        this.setState({ InvoiceList: InvoiceList, loading: false });
        let invoicesList = this.state.AllInvoice;
        if (InvoiceList && InvoiceList.length > 0) {
            var invoiceList = InvoiceList[0];
            this.props.goto(invoiceList.id, invoiceList.invoiceType, invoiceList.invoiceStatus, invoiceList.isTruck, invoicesList, InvoiceList)

        }
    }

    render() {
        const { page, rowsPerPage } = this.state
        if (this.state.InvoiceList === null)
            return <h2>No Record Found</h2>
        return (
            <div className="thd-content-left thd-content-left-invoice bg-white">
                <div className="thd-invoice-list">
                    <div style={style}>
                        <SyncLoader
                            css={override}
                            size={15}
                            color={"#fd7e14"}
                            loading={this.state.loading}
                        />
                    </div>
                    {!this.state.InvoiceList ? null : this.state.InvoiceList.slice((page) * rowsPerPage, (page + 1) * rowsPerPage).map(invoiceList => {
                        var a = invoiceList;
                        // debugger;
                        return (
                            <div key={invoiceList.id} className="media selected" onClick={() => this.props.goto(invoiceList.id, invoiceList.invoiceType, invoiceList.invoiceStatus, invoiceList.isTruck)}>
                                <div className="media-icon"><i className="far fa-file-alt"></i></div>

                                <div className="media-body">

                                    <h6>
                                        <span>Invoice{invoiceList.id}</span>
                                        <span><span>Balance:</span> {invoiceList.invoiceBalance ? numberformat(invoiceList.invoiceBalance) : invoiceList.invoiceBalance}</span>
                                    </h6>
                                    <div className="">
                                        <p><span>Date:</span> <Moment format="YYYY/MM/DD">{invoiceList.invoiceDate ? dateMDY(invoiceList.invoiceDate.substring(0, 10)) : ""}</Moment></p>
                                        <p><span>Type:</span> {invoiceList.invoiceType}</p>
                                        <p><span>Credits:</span> {invoiceList.creditsApplied}</p>
                                        <p><span>Payments:</span>{invoiceList.invoiceBalance ? numberformat(invoiceList.invoiceBalance) : invoiceList.invoiceBalance}</p>
                                    </div>
                                    <div className="">
                                        <p><span>Invoice Tot:</span> {invoiceList.invoiceAmount ? numberformat(invoiceList.invoiceAmount) : invoiceList.invoiceAmount}</p>
                                        <p><span>Due by:</span>{new Date(invoiceList.dueDate).getFullYear() != 1 ? <Moment format="YYYY/MM/DD">{dateMDY(invoiceList.dueDate.substring(0, 10))}</Moment> : "N/A"}</p>
                                        <p><span>Status:</span> <Badge className="badge badge-dark text-white p-1">{invoiceList.invoiceStatus}</Badge></p>
                                        <p>&nbsp;</p>
                                    </div>

                                </div>

                            </div>
                        )
                    })}
                    {this.state.InvoiceList.length < 1 ? null :
                        <TablePagination
                            rowsPerPageOptions={[
                                5,
                                10,
                                25,
                                { label: 'All', value: 10000 },
                            ]}
                            colSpan={0}
                            count={this.state.InvoiceList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}

                            SelectProps={{
                                inputProps: { 'aria-label': 'Data per page' },
                                native: true,

                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    }
                </div>
            </div>

        )
    }
}

export default InvoiceData;