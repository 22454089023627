import React, { Component } from "react";
import IconButton from '@material-ui/core/IconButton';
import authService from '../components/api-authorization/AuthorizeService'
import ReactDOM from 'react-dom';
import ContentLayout from "../components/ContentLayout";
import { css } from "@emotion/core";
import CssBaseline from '@material-ui/core/CssBaseline'
import ReactTable from '../components/DataTable/ReactTable'
import SyncLoader from "react-spinners/SyncLoader";
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import Tooltip from '@material-ui/core/Tooltip';
import { dateMDY, numberformat } from '../DateHelper';
import { Modal } from 'react-bootstrap';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';

const override = css`
  display: block;
  margin: 2 auto;
  border-color: red;
`;
const style = { position: "fixed", top: "75%", left: "50%", transform: "translate(-50%, -50%)" };
//const $ = require('jquery');
//$.DataTable = require('datatables.net');

class RegistrationUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderdata: [], registerationDetail: [], loading: false
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    handleClose() {
        this.setState({ show: false });
    }
    handleShow() {
        this.setState({ show: true });
    }
    handleChange(event) {
        // this.setState({ data: event.target.value, loading: false });
        this.props.history.replace({ state: event.target.value });
    }
    componentDidMount() {
        if (this.props.location.state)
            this.populateRegistrationData(this.props.location.state);
        else
            this.populateRegistrationData("NotApproved");
        //this.populateRegistrationData();
    }
    componentWillReceiveProps(props) {
        this.populateRegistrationData(props.location.state);
    }
    ApproveUser = (row) => {
        this.setState({ Fname: row.original.firstName, Lname: row.original.lastName, Company: row.original.company, Email: row.original.email });
        this.approveUserRegistration(row)
        // this.setState({ show: true });
    }
    DenyUser = (row) => {
        this.setState({ Id: row.original.id, Fname: row.original.firstName, Lname: row.original.lastName, Company: row.original.company, Email: row.original.email });
        this.denyUserRegistration(row)
        // this.setState({ show: true });
    }
    getName = (e) => {
        return e.original.firstName + " " + e.original.lastName;
    }
    //handleChange = (e) => this.setState({ Fname: e.target.value })
    render() {
        const { orderdata, loading } = this.state;
        const columns = [
            {
                Header: 'Name #',
                accessor: 'firstName',
                Cell: ({ row }) => {
                    /*if (row.original.isApproved === false && row.original.isDenied === false) {*/
                    return (<div>
                        <React.Fragment>
                            {this.getName(row)}
                        </React.Fragment>
                    </div>)
                    /*}*/
                }
            },
            {
                Header: 'Company',
                accessor: 'company',
            },
            {
                Header: 'Address',
                accessor: 'address1',

            },
            {
                Header: 'Phone1 #',
                accessor: 'phoneNumber1',
            },
            {
                Header: 'Phone2 #',
                accessor: 'phoneNumber2',
            },
            {
                Header: 'Email Address',
                accessor: 'email',
            },
            {
                Header: 'Registration Submit Date',
                accessor: 'registrationSubmitDate',

                //Cell: ({ row }) => {
                //    var date = new Date(this.getOrderDate(row));
                //    var month = date.getMonth() + 1;
                //    return (month.toString().length > 1 ? month : "0" + month) + "/" + date.getDate() + "/" + date.getFullYear();
                //}
                //Cell: ({ row }) => {
                //    if (row.original.orderDateTime != null) {
                //        return (<div>
                //            <React.Fragment>
                //                {row.original.orderDateTime ? dateMDY(row.original.orderDateTime.substring(0, 10)) : ""}
                //            </React.Fragment>
                //        </div>)
                //    }
                //    else {
                //        return (<div>
                //            <React.Fragment>
                //                {row.original.insertDate ? dateMDY(row.original.insertDate.substring(0, 10)) : ""}
                //            </React.Fragment>
                //        </div>)
                //    }
                //}
            },
            {
                Header: 'Status',
                accessor: '',
                Cell: ({ row }) => {
                    if (row.original.isApproved === false && row.original.isDenied === false) {
                        return (<div>
                            <React.Fragment>
                                Pending
                            </React.Fragment>
                        </div>)
                    }
                    else if (row.original.isApproved === true && row.original.isDenied === false) {
                        return (<div>
                            <React.Fragment>
                                Approved
                            </React.Fragment>
                        </div>)
                    }
                    else {
                        return (<div>
                            <React.Fragment>
                                Denied
                            </React.Fragment>
                        </div>)
                    }
                }
            },
            {
                Header: 'Action',
                accessor: '',
                Cell: ({ row }) => {

                    if (row.original.isApproved === false && row.original.isDenied === false) {
                        return (<div>
                            <button className="btn mr-4" style={{ background: '#fa7a22', color: '#fff', padding: '5px 16px' }}

                                onClick={() => this.ApproveUser(row)}
                            //we are setting dynamic color from array on the basis of index
                            > Approve
                           </button>
                            <button className="btn" style={{ background: '#fa7a22', color: '#fff', padding: '5px 16px' }}
                                onClick={() => this.DenyUser(row)}
                            //we are setting dynamic color from array on the basis of index
                            > Deny
                           </button>

                        </div>)
                    }
                    else {
                        return (
                            ""
                        )
                    }

                }
            }

        ]
        if (loading && orderdata.length === 0) {
            return (<ContentLayout>
                <Breadcrumb
                    breadcrumb={[
                        { name: "Home", path: "/" },
                        { name: "Registration Pending Requests", path: "/RegistrationUsers" }
                    ]}
                />
                <div><em>Please wait while Loading MY Order Data......</em>
                    <div style={style}>
                        <SyncLoader
                            css={override}
                            size={15}
                            color={"#fd7e14"}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            </ContentLayout>);
        }
        if (orderdata == null || orderdata == "")
            return (<ContentLayout>
                <Breadcrumb
                    breadcrumb={[
                        { name: "Home", path: "/" },
                        { name: "Registration Pending Requests", path: "/RegistrationUsers" }
                    ]}
                />
                <div className="bg-grey pt-5 pb-5">
                    <Container fluid className="pl-5 pr-5">
                        <Row>
                            <Col lg="8">
                                <Row>
                                    <div className="animated fadeIn">
                                        <h2 className="title">Registration Pending Requests</h2>
                                    </div>
                                </Row>
                                <form>
                                    <Form.Group className="mb-0 w-100 bg-white p-3 search-byshor">
                                        <Form.Label>
                                            Sort by Status:
                                        </Form.Label>
                                        <Form.Control as="select" value={this.props.location.state} onChange={this.handleChange}>
                                            <option value="NotApproved">NotApproved</option>
                                            <option value="Approved">Approved</option>
                                            <option value="Denied">Denied</option>
                                        </Form.Control>
                                    </Form.Group>
                                </form>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="content invoices-contant border-top bg-grey pt-0 pb-5">

                    <Container fluid className="pl-5 pr-5">
                        <Row className="justify-content-center">
                            <Col md="12">
                                <div>
                                    <div>
                                        <CssBaseline />
                                        <SyncLoader
                                            css={override}
                                            size={15}
                                            color={"#17a2b8"}
                                            loading={this.state.loading}
                                        />
                                    </div>
                                    <div>
                                        <h2>No Record Found</h2>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </div>
            </ContentLayout>)
        return (
            <ContentLayout>
                {/*<p><strong style={{ color: 'green' }}>{this.state.data.resultMessage === "success" ? this.state.data.resultMessage : ""}</strong></p>*/}
                {/*<p><strong style={{ color: 'red' }}>{this.state.data.resultType === "error" ? this.state.data.resultType : ""}</strong></p>*/}
                <div className="content invoices-contant border-top bg-grey pt-0 pb-5">
                    <Container fluid className="pl-5 pr-5">
                        <Row className="justify-content-center">
                            <Col md="12">
                                <div>
                                    <div>
                                        <Breadcrumb
                                            breadcrumb={[
                                                { name: "Home", path: "/" },
                                                { name: "Manage Credit Requests", path: "/RegistrationUsers" }
                                            ]}
                                        />
                                        <div className="bg-grey pt-5 pb-5">
                                            <Container fluid className="pl-5 pr-5">
                                                <Row>
                                                    <Col lg="8">
                                                        <Row>
                                                            <div className="animated fadeIn">
                                                                <h2 className="title">Registration Pending Requests</h2>
                                                            </div>
                                                        </Row>
                                                        <form>
                                                            <Form.Group className="mb-0 w-100 bg-white p-3 search-byshor">
                                                                <Form.Label>
                                                                    Sort by Status:
                                        </Form.Label>
                                                                <Form.Control as="select" value={this.props.location.state} onChange={this.handleChange}>
                                                                    <option value="NotApproved">NotApproved</option>
                                                                    <option value="Approved">Approved</option>
                                                                    <option value="Denied">Denied</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </form>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>
                                        <div className="content invoices-contant border-top bg-grey pt-0 pb-5">

                                            <Container fluid className="pl-5 pr-5">
                                                <Row className="justify-content-center">
                                                    <Col md="12">
                                                        <div>
                                                            <div>
                                                                <CssBaseline />
                                                                <div style={style}>
                                                                    <SyncLoader
                                                                        css={override}
                                                                        size={15}
                                                                        color={"#fd7e14"}
                                                                        loading={this.state.loading}
                                                                    />
                                                                </div>
                                                                <ReactTable
                                                                    columns={columns}
                                                                    data={this.state.orderdata}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>

                                        </div>
                                    </div>
                                </div></Col>
                        </Row>
                    </Container>


                </div>
                {/*      <Modal centered size="xl" show={this.state.show} onHide={this.handleClose} className="sb-details-modal">*/}
                {/*          <Modal.Header closeButton>*/}
                {/*              <Modal.Title>Order Details</Modal.Title>*/}
                {/*          </Modal.Header>*/}
                {/*          <Modal.Body>*/}
                {/*              <Form.Group >*/}
                {/*                  <Form.Label>FirstName: </Form.Label>*/}
                {/*                  <Form.Control type="text" onChange={this.handleChange} value={this.state.Fname} placeholder="name input" />*/}
                {/*                  <Form.Label>FirstName: </Form.Label>*/}
                {/*                  <Form.Control type="text" onChange={this.handleChange} value={this.state.Lname} placeholder="name input" />*/}
                {/*                  <Form.Label>FirstName: </Form.Label>*/}
                {/*                  <Form.Control type="text" onChange={this.handleChange} value={this.state.Company} placeholder="name input" />*/}
                {/*                  <Form.Label>FirstName: </Form.Label>*/}
                {/*                  <Form.Control type="text" onChange={this.handleChange} value={this.state.Email} placeholder="name input" />*/}
                {/*              </Form.Group>*/}
                {/*          </Modal.Body>*/}
                {/*          <Modal.Footer>*/}
                {/*              <button variant="primary" type="submit" >*/}
                {/*                  Submit*/}
                {/*</button>*/}
                {/*          </Modal.Footer>*/}
                {/*      </Modal>*/}
            </ContentLayout>);
    }



    async populateRegistrationData(status) {
        const formData = new FormData();
        formData.append("RegistrationStatus", status);
        const token = await authService.getAccessToken();
        const response = await fetch('InventoryCode/LoadRegistrationData', {
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            method: 'post',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });
        const dataa = await response.json();
        this.setState({ orderdata: dataa.registrationList, loading: false });

    }
    async approveUserRegistration(row) {
        const formData = new FormData();
        formData.append("Id", row.original.id);
        formData.append("FirstName", row.original.firstName);
        formData.append("LastName", row.original.lastName);
        formData.append("Company", row.original.company);
        formData.append("Address1", row.original.address1);
        formData.append("Address2", row.original.address2);
        formData.append("Address3", row.original.address3);
        formData.append("City", row.original.city);
        formData.append("StateProvinceRegion", row.original.stateProvinceRegion);
        formData.append("ZipPostalCode", row.original.zipPostalCode);
        formData.append("Country", row.original.country);
        formData.append("Email", row.original.email);
        formData.append("Email2", row.original.email2);
        formData.append("PhoneNumber1", row.original.phoneNumber1);
        formData.append("PhoneNumber2", row.original.phoneNumber2);
        formData.append("IsApproved", row.original.isApproved);
        formData.append("IsDenied", row.original.isDenied);
        const token = await authService.getAccessToken();
        const response = await fetch('Account/ApproveUserRegistration', {
            method: 'post',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });
        const data = await response.json();
        this.populateRegistrationData("NotApproved");
        this.setState({ data: data, loading: false });

    }

    async denyUserRegistration(row) {
        const formData = new FormData();
        formData.append("id", row.original.id);
        formData.append("firstName", row.original.firstName);
        formData.append("lastName", row.original.lastName);
        formData.append("email", row.original.email);
        const token = await authService.getAccessToken();
        const response = await fetch('Account/DenyRegistration', {
            method: 'post',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });
        const data = await response.json();
        this.populateRegistrationData("NotApproved");
        this.setState({ data: data, loading: false });

    }
}


export default RegistrationUsers;