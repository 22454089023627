import React, { Component } from 'react';
import ContentLayout from "../components/ContentLayout";
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import { Col, Container, Row, Card, Form, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { updateSearchList, returnUpdateSearch } from '../actions/search-actions';
import authService from '../components/api-authorization/AuthorizeService'

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Name: "", Email: "", Contact_Number: "", Message: "", credit: {}, fields: {},
            errors: {}
        };
        this.handleChange = this.handleChange.bind(this);
        this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
        this.Name = this.Name.bind(this);
        this.Email = this.Email.bind(this);
        this.Contact_Number = this.Contact_Number.bind(this);
        this.Message = this.Message.bind(this);
        this.getContactRequest = this.getContactRequest.bind(this);
        this.props.updateSearchList([]);
        this.props.returnUpdateSearch();
    }
    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });

    }
    submituserRegistrationForm(e) {
        e.preventDefault();
        if (this.validateForm()) {
            this.contactRequest()
            let fields = {};
            fields["Name"] = "";
            fields["Email"] = "";
            fields["Contact_Number"] = "";
            fields["Message"] = "";
            this.setState({ fields: fields });
        }

    }
    validateForm() {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["Name"]) {
            formIsValid = false;
            errors["Name"] = "*Please enter your Name.";
        }

        //if (typeof fields["Name"] !== "undefined") {
        //    if (!fields["Name"].match(/^[A-Za-z]*\s{1}[A-Za-z]*$/)) {
        //        formIsValid = false;
        //        errors["Name"] = "*Please enter correct value only.";
        //    }
        //}

        if (!fields["Email"]) {
            formIsValid = false;
            errors["Email"] = "*Please enter Email.";
        }

        if (typeof fields["Email"] !== "undefined") {
            if (!fields["Email"].match(/^[A-Za-z\._\-[0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/)) {
                formIsValid = false;
                errors["Email"] = "*Please enter correct Email.";
            }
        }

        if (!fields["Contact_Number"]) {
            formIsValid = false;
            errors["Contact_Number"] = "*Please enter your Contact Number.";
        }

        if (typeof fields["Contact_Number"] !== "undefined") {
            if (!fields["Contact_Number"].match(/^[0-9]{10}$/)) {
                formIsValid = false;
                errors["Contact_Number"] = "*Please enter numeric value only.";
            }
        }

        if (!fields["Message"]) {
            formIsValid = false;
            errors["Message"] = "*Please enter your Message.";
        }

        //if (typeof fields["Message"] !== "undefined") {
        //    if (!fields["Message"].match(/^[A-Za-z]*\s{1}[A-Za-z]*$/)) {
        //        formIsValid = false;
        //        errors["Message"] = "*Please enter alphabet characters only.";
        //    }
        //}

        this.setState({
            errors: errors
        });
        return formIsValid;


    }
    Name(event) {
        this.setState({ Name: event.target.value })
    }
    Email(event) {
        this.setState({ Email: event.target.value })
    }
    Contact_Number(event) {
        this.setState({ Contact_Number: event.target.value })
    }
    Message(event) {
        this.setState({ Message: event.target.value })
    }
    getContactRequest() {
        this.contactRequest()
    }
    async contactRequest() {
        let fields = this.state.fields;
        const formData = new FormData();

        formData.append("Name", fields["Name"]);
        formData.append("Email", fields["Email"]);
        formData.append("Contact_Number", fields["Contact_Number"]);
        formData.append("Message", fields["Message"]);
        const token = await authService.getAccessToken();
        const response = await fetch('Order/RequestContactus', {
            method: 'post',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });
        const data = await response.json();
        this.setState({ credit: data });
    }
    render() {
        return (
            <ContentLayout>
                <Breadcrumb
                    breadcrumb={[
                        { name: "Home", path: "/" },
                        { name: "Contact Us", path: "/ContactUs" }
                    ]}
                />
                <div className="content pt-5 pb-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="12">
                                <div className="animated fadeIn">
                                    <Card className="rounded-0 bg-white shadow-sm">
                                        <p><strong style={{ color: 'green' }}>{this.state.credit.status === "Success" ? this.state.credit.message : ""}</strong></p>
                                        <p><strong style={{ color: 'red' }}>{this.state.credit.status === "Failure" ? this.state.credit.message : ""}</strong></p>
                                        <Card.Body>
                                            <Card.Title>Contact Us</Card.Title>
                                            <Card.Text>
                                                
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="formGridName">
                                                            <Form.Label>Name</Form.Label>
                                                        <Form.Control type="text" name="Name" value={this.state.fields.Name} onChange={this.handleChange} placeholder="Enter Name" />
                                                        <div className="errorMsg">{this.state.errors.Name}</div>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridEmail">
                                                            <Form.Label>Email</Form.Label>
                                                        <Form.Control type="text" name="Email" value={this.state.fields.Email} onChange={this.handleChange} placeholder="Enter email" />
                                                        <div className="errorMsg">{this.state.errors.Email}</div>
                                                        </Form.Group>

                                                    </Form.Row>

                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="formGridMobile">
                                                            <Form.Label>Mobile Number</Form.Label>
                                                        <Form.Control type="text" name="Contact_Number" value={this.state.fields.Contact_Number} onChange={this.handleChange} placeholder="Enter Mobile Number" />
                                                        <div className="errorMsg">{this.state.errors.Contact_Number}</div>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridSubject">
                                                            <Form.Label>Subject</Form.Label>
                                                        <Form.Control type="email" placeholder="Subject" />
                                                        </Form.Group>

                                                    </Form.Row>
                                                    <Form.Group controlId="formGridquery">
                                                        <Form.Label>Inquiry</Form.Label>
                                                    <Form.Control as="textarea" rows="4" name="Message" value={this.state.fields.Message} onChange={this.handleChange} placeholder="Message" />
                                                    <div className="errorMsg">{this.state.errors.Message}</div>
                                                    </Form.Group>
                                                    <div className="text-center">
                                                    <Button variant="primary" type="submit" onClick={this.submituserRegistrationForm} className="Submit-Button">
                                                        Submit
                          </Button>
                                                </div>
                                                
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ContentLayout>
        );
    }
}
const mapActionsToProps = {
    updateSearchList: updateSearchList,
    returnUpdateSearch: returnUpdateSearch
}

export default connect(null, mapActionsToProps)(ContactUs);
