import { SEARCH_OPTIONS_UPDATE, SEARCH_OPTIONS_UPDATET, SEARCH_TEXT_UPDATE, RETURN_SEARCH_TEXT_UPDATE, SEARCH_TEXT_CLEAR, SEARCH_OPTIONS_UPDATE_ORDER, SEARCH_TEXT_UPDATE_ORDER ,RETURN_SEARCH_TEXT_UPDATE_ORDER } from '../constants';

export const searchListReducer = function (state = { list : "" }, action) {
    let returnValue = state;

    switch (action.type) {
        case SEARCH_OPTIONS_UPDATE:
            return action.payload;
            break;
    }

    return returnValue;
}
export const searchListReducert= function (state = { listt: "" }, action) {
    let returnValue = state;

    switch (action.type) {
        case SEARCH_OPTIONS_UPDATET:
            return action.payload;
            break;
    }

    return returnValue;
}

export const serachTextReducer = function (state = { fieldName: "", searchText: "", hdChecked:false}, action) {
    let returnValue = state;

    switch (action.type) {
        case SEARCH_TEXT_UPDATE:
            return action.payload;
            break;
    }

    return returnValue;

}


export const returnSerachTextReducer = function (state = { fieldName: "", searchText: "", hdChecked: false }, action) {
    let returnValue = state;

    switch (action.type) {
        case RETURN_SEARCH_TEXT_UPDATE:
            return action.payload;
            break;
    }

    return returnValue;

}

export const clearTextReducer = function (state = { searchText: ""}, action) {
    let returnValue = state;

    switch (action.type) {
        case SEARCH_TEXT_CLEAR:
            return action.payload;
            break;
    }

    return returnValue;

}

export const searchOrderListReducer = function (state = { list: "" }, action) {
    let returnValue = state;

    switch (action.type) {
        case SEARCH_OPTIONS_UPDATE_ORDER:
            return action.payload;
            break;
    }

    return returnValue;
}
export const serachOrderTextReducer = function (state = {searchText: "" }, action) {
    let returnValue = state;

    switch (action.type) {
        case SEARCH_TEXT_UPDATE_ORDER:
            return action.payload;
            break;
    }

    return returnValue;

}


export const returnSerachOrderTextReducer = function (state = { earchText: ""}, action) {
    let returnValue = state;

    switch (action.type) {
        case RETURN_SEARCH_TEXT_UPDATE_ORDER:
            return action.payload;
            break;
    }

    return returnValue;

}