import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService';
import { Badge, Button, ButtonToolbar, Card, Col, Container, Form, Row, Table, Modal } from 'react-bootstrap';
import InvoiceDetailSalvageSales from '../Invoice/InvoiceDetailSalvageSales';
import InvoiceDetailSalvageSalesTruck from '../Invoice/InvoiceDetailSalvageSalesTruck';
import InvoiceDetailBerOrTurbo from '../Invoice/InvoiceDetailBerOrTurbo';
import InvoiceDetailOther from '../Invoice/InvoiceDetailOther';

class ViewCreditRequest extends Component {
    constructor(props) {
        super(props) //since we are extending class Table so we have to use super in order to override Component class constructor
        this.state = { //state is by default an object
            students: [
                { id: 1, name: 'Wasif', age: 21, email: 'wasif@email.com', type: 'ber' },
                { id: 2, name: 'Ali', age: 19, email: 'ali@email.com' },
                { id: 3, name: 'Saad', age: 16, email: 'saad@email.com' },
                { id: 4, name: 'Asad', age: 25, email: 'asad@email.com' }
            ], invoiceDetail: [], loading: true, show: false, visible: false, customerDetail: {},thd: {
                invoiceCustomer: {}
            }, loading: true, total: "", credit: "", payment: "", selectedradioOption: "",
            OtherInvoiceDetailsById: [], SalvageinvoiceItemList: [], Salvageinvoicecredit: [], BerTurboInvoiceDetailsById: [], InvoiceList: [], InvoiceDetailsById: []
        }
    }
    handleClosee = () => {
        this.setState({ visible: false });
    }
    componentDidMount() {
        this.populateAddInvoice(this.props.Id, this.props.cId);
    }
    async populateAddInvoice(Id, customerID) {
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/GetInvoicesByCreditReqId?requestId=' + Id + '&CustomerId=' + customerID, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ invoiceDetail: data, loading: false });

    }
    invoiceCredit(Invoice, invoiceType,isTruck) {
        this.setState({
            Invoice: Invoice, invoiceType: invoiceType, isTruck: isTruck
        });
        if (invoiceType == "ARMInvoice" && isTruck!=true) {
            this.SalvageInvoiceDetailsById(Invoice);
        }
        if (invoiceType == "ARMInvoice" && isTruck == true) {
            this.SalvageInvoiceDetailsByIdTruck(Invoice);
        }
        else if (invoiceType == "BER" || invoiceType == "Turbo") {
            this.BERInvoiceDetailsById(Invoice);
        }
        else
            this.OtherInvoiceDetailsById(Invoice);

        this.setState({ visible: true });
    }

    render() {
        return (
            <div>
                <Table responsive size="xl" className="table-invoice table-bordered">
                    <thead>
                        <tr>
                            <th colSpan="12" className="text-center datacol">Invoiced Items</th>
                        </tr>
                        <tr>
                            <th className="text-center">Invoice Number</th>
                            <th className="text-center">Invoice Type</th>
                            <th className="text-center">Total Invoiced</th>
                            <th className="text-center">Credits Applied</th>
                            <th className="text-center">Payments Applied</th>
                            <th className="text-center">Invoice Balance</th>
                            <th className="text-center">Invoice Status</th>
                            <th className="text-center">Invoice Request Status</th>
                            <th className="text-center">Invoice Request Created By</th>
                            <th className="text-center">Created Date</th>
                            <th className="text-center">Created By</th>
                            <th className="text-center">Status </th>


                        </tr>
                    </thead>
                    <tbody className="pheonixes">
                        {this.state.invoiceDetail.map(list => {
                            if (list.invoiceType == 'ARMInvoice') {
                                return (

                                    <tr key={list.id} >

                                        <td className="text-center" ><a style={{ cursor: 'pointer', color: 'blue' }}
                                            onClick={() => this.invoiceCredit(list.invoiceNumber, list.invoiceType,list.isTruck)}>
                                            {list.invoiceNumber}
                                        </a></td>
                                        <td className="text-center" >{list.invoiceType}</td>
                                        <td className="text-center">{list.invoiceAmount}</td>
                                        <td className="text-center" >{list.creditsApplied}</td>
                                        <td className="text-center" >{list.paymentsApplied}</td>
                                        <td className="text-center" >{list.invoiceBalance}</td>
                                        <td className="text-center">{list.invoiceStatus}</td>
                                        <td className="text-center">{list.creditAppliedStatus}</td>
                                        <td className="text-center" >{list.invoiceRequestCreatedBy}</td>
                                        <td className="text-center" >{list.creditAppliedCreatedDate}</td>
                                        <td className="text-center">{list.creditAppliedCreatedBy}</td>
                                        <td className="text-center">{list.creditAppliedStatus}</td>
                                    </tr>




                                )
                            }
                        })}
                    </tbody>
                    <tbody className="pheonixes">
                        {this.state.invoiceDetail.map(list => {
                            if (list.invoiceType == 'BER' || list.invoiceType == 'Turbo') {
                                return (

                                    <tr key={list.id} >
                                        <td className="text-center" ><a style={{ cursor: 'pointer', color: 'blue' }}
                                            onClick={() => this.invoiceCredit(list.invoiceNumber, list.invoiceType)}>
                                            {list.invoiceNumber}
                                        </a></td>
                                        <td className="text-center" >{list.invoiceType}</td>
                                        <td className="text-center">{list.invoiceAmount}</td>
                                        <td className="text-center" >{list.creditsApplied}</td>
                                        <td className="text-center" >{list.paymentsApplied}</td>
                                        <td className="text-center" >{list.invoiceBalance}</td>
                                        <td className="text-center">{list.invoiceStatus}</td>
                                        <td className="text-center">{list.creditAppliedStatus}</td>
                                        <td className="text-center" >{list.invoiceRequestCreatedBy}</td>
                                        <td className="text-center" >{list.creditAppliedCreatedDate}</td>
                                        <td className="text-center">{list.creditAppliedCreatedBy}</td>
                                        <td className="text-center">{list.creditAppliedStatus}</td>
                                    </tr>




                                )
                            }
                        })}
                    </tbody>
                    <tbody className="pheonixes">
                        {this.state.invoiceDetail.map(list => {
                            if (list.invoiceType == 'Other') {
                                return (

                                    <tr key={list.id} >
                                        <td className="text-center" ><a style={{ cursor: 'pointer', color: 'blue' }}
                                            onClick={() => this.invoiceCredit(list.invoiceNumber, list.invoiceType)}>
                                            {list.invoiceNumber}
                                        </a></td>
                                        <td className="text-center" >{list.invoiceType}</td>
                                        <td className="text-center">{list.invoiceAmount}</td>
                                        <td className="text-center" >{list.creditsApplied}</td>
                                        <td className="text-center" >{list.paymentsApplied}</td>
                                        <td className="text-center" >{list.invoiceBalance}</td>
                                        <td className="text-center">{list.invoiceStatus}</td>
                                        <td className="text-center">{list.creditAppliedStatus}</td>
                                        <td className="text-center" >{list.invoiceRequestCreatedBy}</td>
                                        <td className="text-center" >{list.creditAppliedCreatedDate}</td>
                                        <td className="text-center">{list.creditAppliedCreatedBy}</td>
                                        <td className="text-center">{list.creditAppliedStatus}</td>
                                    </tr>




                                )
                            }
                        })}
                    </tbody>
                </Table>
                <Modal centered size="xl" show={this.state.visible} onHide={this.handleClosee} className="sb-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                        {this.state.invoiceType == 'ARMInvoice' && this.state.isTruck != true?
                            <InvoiceDetailSalvageSales invoiceId={this.state.Invoice} invoiceStatus={this.state.invoiceStatus} InvoiceCust={this.state.thd.invoiceCustomer} InvoiceList={this.state.SalvageinvoiceItemList} Data={this.state.thd} stotal={this.state.total} scredit={this.state.credit} spayment={this.state.payment} />
                            : this.state.invoiceType == 'ARMInvoice' && this.state.isTruck == true ?
                                <InvoiceDetailSalvageSalesTruck invoiceId={this.state.Invoice} invoiceStatus={this.state.invoiceStatus} InvoiceCust={this.state.thd.invoiceCustomer} InvoiceList={this.state.SalvageinvoiceItemList} InvoiceListTruck={this.state.Salvageinvoicecredit} Data={this.state.thd} stotal={this.state.total} scredit={this.state.credit} spayment={this.state.payment} />
                            : this.state.invoiceType == "BER" || this.state.invoiceType == "Turbo" ?
                                <InvoiceDetailBerOrTurbo invoiceId={this.state.Invoice} invoiceType={this.state.invoiceType} invoiceStatus={this.state.invoiceStatus} InvoiceCust={this.state.thd.invoiceCustomer} InvoiceList={this.state.BerTurboInvoiceDetailsById} Data={this.state.thd} stotal={this.state.total} scredit={this.state.credit} spayment={this.state.payment} />
                                : this.state.invoiceType == 'Other' ?
                                    < InvoiceDetailOther invoiceId={this.state.Invoice} invoiceStatus={this.state.invoiceStatus} InvoiceCust={this.state.thd.invoiceCustomer} InvoiceList={this.state.OtherInvoiceDetailsById} Data={this.state.thd} stotal={this.state.total} scredit={this.state.credit} spayment={this.state.payment} />
                                    : null}
                    </Modal.Header>
                    <Modal.Body>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }


    async BERInvoiceDetailsById(invoiceId) {
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/BEROrTurboManualInvoice?id=' + invoiceId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        var subtotal = 0.0;
        var totalcredits = 0.0;
        var totalpayments = 0.0;
        if (data.berOrTurboManualInvoiceItemList != null && data.berOrTurboManualInvoiceItemList != "") {
            for (var j = 0; j < data.berOrTurboManualInvoiceItemList.length; j++) {
                if (!data.berOrTurboManualInvoiceItemList[j].isCredit && !data.berOrTurboManualInvoiceItemList[j].isPayment) {
                    subtotal += parseInt(data.berOrTurboManualInvoiceItemList[j].priceTotal)
                }
                if (data.berOrTurboManualInvoiceItemList[j].isCredit) {
                    totalcredits += parseInt(data.berOrTurboManualInvoiceItemList[j].priceTotal)
                }
                if (data.berOrTurboManualInvoiceItemList[j].isPayment) {
                    totalpayments += parseInt(data.berOrTurboManualInvoiceItemList[j].priceTotal)
                }
            }
            subtotal = subtotal.toFixed(2);
            totalcredits = Math.abs(totalcredits.toFixed(2));
            totalpayments = Math.abs(totalpayments.toFixed(2));
        }
        this.setState({ thd: data, loading: false });
        this.setState({ customerDetail: data.invoiceCustomer, loading: false });
        this.setState({ BerTurboInvoiceDetailsById: data.berOrTurboManualInvoiceItemList, total: subtotal, credit: totalcredits, payment: totalpayments, loading: false });
    }
    async SalvageInvoiceDetailsById(invoiceId) {
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/Invoice?id=' + invoiceId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        var subtotal = 0.0;
        var totalcredits = 0.0;
        var totalpayments = 0.0;
        if (data.invoiceItemList != null && data.invoiceItemList != "") {
            for (var j = 0; j < data.invoiceItemList.length; j++) {
                if (!data.invoiceItemList[j].isCredit && !data.invoiceItemList[j].isPayment) {
                    subtotal += parseFloat(data.invoiceItemList[j].unitCost) * parseFloat(data.invoiceItemList[j].quantity)
                }
                if (data.invoiceItemList[j].isCredit) {
                    totalcredits += parseFloat(data.invoiceItemList[j].unitCost) * parseFloat(data.invoiceItemList[j].quantity)
                }
                if (data.invoiceItemList[j].isPayment) {
                    totalpayments += parseFloat(data.invoiceItemList[j].paymentAmount)
                }
            }
            subtotal = subtotal.toFixed(2);
            totalcredits = Math.abs(totalcredits.toFixed(2));
            totalpayments = Math.abs(totalpayments.toFixed(2));
        }
        this.setState({ thd: data, loading: false });
        this.setState({ customerDetail: data.invoiceCustomer, loading: false });
        this.setState({ SalvageinvoiceItemList: data.invoiceItemList, total: subtotal, credit: totalcredits, payment: totalpayments, loading: false });
    }

    async SalvageInvoiceDetailsByIdTruck(invoiceId) {
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/InvoiceTruck?id=' + invoiceId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        var subtotal = 0.0;
        var totalcredits = 0.0;
        var totalpayments = 0.0;
        if (data.invoiceItemList != null && data.invoiceItemList != "") {
            for (var j = 0; j < data.invoiceItemList.length; j++) {
                if (!data.invoiceItemList[j].isCredit && !data.invoiceItemList[j].isPayment) {
                    if (data.invoiceItemList[j].truckLoadOrderamount == 0) {
                        var quant = (data.invoiceItemList[j].quantity / 100);
                        subtotal += parseFloat(data.invoiceItemList[j].unitCost) * parseFloat(quant)
                    }
                    else {
                        var unitcosttotal = data.invoiceItemList[j].unitCost;
                        var quant = (data.invoiceItemList[j].new_BuyitNow_Rate / 100);
                        subtotal += parseFloat(unitcosttotal) * parseFloat(quant)
                    }
                }
                //if (data.invoiceItemList[j].isCredit) {
                //    totalcredits += parseFloat(data.invoiceItemList[j].unitCost) * parseFloat(data.invoiceItemList[j].quantity)
                //}
                //if (data.invoiceItemList[j].isPayment) {
                //    totalpayments += parseFloat(data.invoiceItemList[j].paymentAmount)
                //}
            }
            subtotal = subtotal.toFixed(2);
            //totalcredits = Math.abs(totalcredits.toFixed(2));
            //totalpayments = Math.abs(totalpayments.toFixed(2));
        }

        if (data.invoiceItemListTruck != null && data.invoiceItemListTruck != "") {
            for (var j = 0; j < data.invoiceItemListTruck.length; j++) {

                if (data.invoiceItemListTruck[j].isCredit) {
                    totalcredits += parseFloat(data.invoiceItemListTruck[j].unitCost) * parseFloat(data.invoiceItemListTruck[j].quantity)
                }
                if (data.invoiceItemListTruck[j].isPayment) {
                    totalpayments += parseFloat(data.invoiceItemListTruck[j].paymentAmount)
                }
            }

            totalcredits = Math.abs(totalcredits.toFixed(2));
            totalpayments = Math.abs(totalpayments.toFixed(2));
        }

        this.setState({ thd: data, loading: false });
        this.setState({ customerDetail: data.invoiceCustomer, loading: false });
        this.setState({ SalvageinvoiceItemList: data.invoiceItemList, Salvageinvoicecredit: data.invoiceItemListTruck, total: subtotal, credit: totalcredits, payment: totalpayments, loading: false });
    }

    async OtherInvoiceDetailsById(invoiceId) {
        const token = await authService.getAccessToken();
        const response = await fetch('InvoiceCode/OtherManualInvoice?id=' + invoiceId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        var subtotal = 0.0;
        var totalcredits = 0.0;
        var totalpayments = 0.0;
        if (data.otherManualInvoiceItemList != null && data.otherManualInvoiceItemList != "") {
            for (var j = 0; j < data.otherManualInvoiceItemList.length; j++) {
                if (!data.otherManualInvoiceItemList[j].isCredit && !data.otherManualInvoiceItemList[j].isPayment) {
                    subtotal += parseInt(data.otherManualInvoiceItemList[j].priceTotal)
                }
                if (data.otherManualInvoiceItemList[j].isCredit) {
                    totalcredits += parseInt(data.otherManualInvoiceItemList[j].priceTotal)
                }
                if (data.otherManualInvoiceItemList[j].isPayment) {
                    totalpayments += parseInt(data.otherManualInvoiceItemList[j].priceTotal)
                }
            }
            subtotal = subtotal.toFixed(2);
            totalcredits = Math.abs(totalcredits.toFixed(2));
            totalpayments = Math.abs(totalpayments.toFixed(2));
        }
        this.setState({ thd: data, loading: false });
        this.setState({ customerDetail: data.invoiceCustomer, loading: false });
        this.setState({ OtherInvoiceDetailsById: data.otherManualInvoiceItemList, total: subtotal, credit: totalcredits, payment: totalpayments, loading: false });
    }
}

export default ViewCreditRequest