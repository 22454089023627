import React, { Component } from 'react';
import ContentLayout from "../components/ContentLayout";
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { updateSearchList, returnUpdateSearch } from '../actions/search-actions';

class CartCheckout extends Component {
    constructor(props) {
        super(props);
        this.props.updateSearchList([]);
        this.props.returnUpdateSearch();
    }
  render() {
    return (
      <ContentLayout>
      <Breadcrumb
          breadcrumb={[
            { name: "Home", path: "/" },
            { name: "Cart Checkout", path: "/Cart-Checkout" }
          ]}
        />
        <div className="content pt-5 pb-5">
          <Container>
            <Row className="justify-content-center">
              <Col md="12">
                <h2>Cart Checkout</h2>
              </Col>
            </Row>
          </Container>
        </div>
      </ContentLayout>
    );
  }
}

const mapActionsToProps = {
    updateSearchList: updateSearchList,
    returnUpdateSearch: returnUpdateSearch
}

export default connect(null, mapActionsToProps)(CartCheckout);
