import React, { Component } from "react";
import { Table } from 'react-bootstrap';
import "../../assets/css/jquery.dataTables.min.css";
import authService from '../api-authorization/AuthorizeService';
import CssBaseline from '@material-ui/core/CssBaseline'
import ReactTable from './ReactTable'
import { CSVLink } from "react-csv";
import { numberformat } from '../../DateHelper';
//const $ = require('jquery');
//$.DataTable = require('datatables.net');


class OrderDetailModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OrderDetailData: [], loading: true
        };

    }
   //populateGrid = (columnData) => {
    
   // this.$el=$(this.el)
   // this.$el.DataTable(
   //   {
   //     columns: [
   //       //{ title: "<label class='control control--checkbox'><input name='select_all' value='1' id='example-select-allMP' type='checkbox' /><div class='control__indicator'></div></label>" },
   //       { title: "SKU" },
   //       { title: "Scan LP #" },
   //       { title: "Item Description" },
   //       { title: "Model #" },
   //       { title: "Qty" },
   //       { title: "Wholesale" }
   //     ],
   //     data: columnData,
   //     //columnDefs: [{
   //     //   'targets': 0,
   //     //   'className':'dt-body-centermp',
   //     //   'render': function (data, type, full, meta){
   //     //       return '<label class="control control--checkbox"><input name="select_all" value="1" type="checkbox" /><div class="control__indicator"></div></label>';
   //     //   }
   //     //},
   //     //{"orderable":false, "aTargets": [0]}
   //     //],
   //     order: [],
   //     aLengthMenu: [[5, 10, 25, -1], [5, 10, 25, "All"]],
   //     iDisplayLength: 5
   //   }
   // );

    
   // }  

    componentDidMount() {
        this.populateOrderDetailData(this.props.sbNumber, this.props.warehouseId);
    }


    render() {
        const columns = [
            {
                Header: 'SKU',
                accessor: 'itemId',
            },
            {
                Header: 'Scan LP #',
                accessor: 'lpNumber',
            },
            {
                Header: 'Item Description',
                accessor: 'itemDescription',
            },
            {
                Header: 'Model #',
                accessor: 'modelNumber',
            },
            {
                Header: 'Pallet ID #',
                accessor: 'palletId',
            },
            {
                Header: 'Qty',
                accessor: 'quantity',
            },
            {
                Header: 'Wholesale',
                accessor: 'Wholesale',
                Cell: ({ row }) => (
                    <React.Fragment>
                        {row.original.Wholesale ? numberformat(row.original.Wholesale) : row.original.Wholesale}
                    </React.Fragment>
                )
            },
        ]
        return (
            <div>
                {
                    //<Table responsive hover id="example1" className="display" width="100%" ref={el => this.el = el}>
                    //</Table>
                }
                <CssBaseline />{
                    //<CSVLink
                    //    columns={columns}
                    //    data={this.state.OrderDetailData}
                    //    filename={"SbItem.csv"}
                    //    className="btn btn-primary"
                    //    target="_blank"
                    //>
                    //    Export To Excel
                    //        </CSVLink>
                }
                <ReactTable
                    columns={columns}
                    data={this.state.OrderDetailData}
                />
            </div>
        );
    }

    async populateOrderDetailData(sbNumber, warehouse) {
        const token = await authService.getAccessToken();
         const response = await fetch('InventoryCode/Detail?wid=' + warehouse + '&sbn=' + sbNumber, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        //var myArray = [];
        for (var i = 0; i < data.manifestItemList.length; i++) {
            //var obj = {};
            //obj["ItemId"] = data.manifestItemList[i].itemId;
            //obj["LpNumber"] = data.manifestItemList[i].lpNumber;
            //obj["ItemDescription"] = data.manifestItemList[i].itemDescription;
            //obj["ModelNumber"] = data.manifestItemList[i].modelNumber;
            //obj["Quantity"] = data.manifestItemList[i].quantity;
            //data.manifestItemList[i].Wholesale = ((data.manifestItemList[i].quantity * data.manifestItemList[i].cost) * 1.15).toFixed(2);
            data.manifestItemList[i].Wholesale = ((data.manifestItemList[i].quantity * data.manifestItemList[i].cost)).toFixed(2);
            //obj["wc"] = 2000;

            //myArray.push(Object.values(obj));
        }
        this.setState({ OrderDetailData: data.manifestItemList, loading: false });
        //this.setState({ inventorydata: myArray, loading: false });
        //this.populateGrid(myArray);

    }

  

}

export default OrderDetailModal;
