import authService from '../components/api-authorization/AuthorizeService';
import { UPDATE_CART, FETCH_DATA_CART, DIALOG_ACTION_CLOSE, FETCH_ORDERS_CART } from '../constants';
import { openDialog } from '../actions/dialog-actions';
import SyncLoader from "react-spinners/SyncLoader";
import { AuthenticationResultStatus } from '../components/api-authorization/AuthorizeService';
import { commonErrorHandler } from '../Utils'


export const updateCart = function (itemsSelected, valuePurchased, checkedData, palletQty, categoryList,truckvalue) {
    return {
        type: UPDATE_CART,
        payload: {
            itemsSelected,
            valuePurchased,
            checkedData,
            categoryList,
            palletQty,
            truckvalue
        }
    }
}

export const fetchCart = function (itemQty, palletQty, wholeSale, amount, checkedData, categoryList,data) {
    return {
        type: FETCH_DATA_CART,
        payload: {
            itemQty,
            palletQty,
            wholeSale,
            amount,
            checkedData,
            categoryList,
            data
        }
    }
}

export const fetchOrders = function (timeStamp) {
    return {
        type: FETCH_ORDERS_CART,
        payload: {
            timeStamp
        }
    }
}
export  const reload = () => window.location.reload();

export function updateCartData() {
    console.time("ucart ");
    return dispatch => {
        console.time("ucartTokkenInventory");
        authService.getAccessToken().then(function (accessToken) {
            if (accessToken != null) {
                console.timeEnd("ucartTokkenInventory");
                fetch('InventoryCode/Manage', {
                    headers: !accessToken ? {} : { 'Authorization': `Bearer ${accessToken}` }
                })
                    .then(async res => {

                        return await commonErrorHandler(res);
                    })
                    .then(
                        (result) => {
                            console.log(result)
                            console.timeEnd("ucart ");
                            var data = result;
                            var checkedInventory = [];
                            var Item = 0;
                            var WholeSale = 0.0;
                            var Amount = 0.0;
                            var PalletQty = 0;
                            var truckvaluetest = 'namantest';
                            if (data.selectedTotalsBySb != null && data.selectedTotalsBySb != "") {
                                for (var i = 0; i < data.selectedTotalsBySb.split(',').length; i++) {

                                    var obj = {};
                                    obj["manifestId"] = data.selectedTotalsBySb.split(',')[i].split('|')[0];
                                    obj["sbNumber"] = data.selectedTotalsBySb.split(',')[i].split('|')[1];
                                    obj["category"] = data.selectedTotalsBySb.split(',')[i].split('|')[2];
                                    obj["type"] = data.selectedTotalsBySb.split(',')[i].split('|')[3];
                                    obj["palletQuantity"] = data.selectedTotalsBySb.split(',')[i].split('|')[4];
                                    obj["totalWeight"] = data.selectedTotalsBySb.split(',')[i].split('|')[5];
                                    obj["wholesale"] = parseFloat(data.selectedTotalsBySb.split(',')[i].split('|')[6]).toFixed(2);
                                    obj["rate%"] = data.selectedTotalsBySb.split(',')[i].split('|')[7];
                                    obj["itemQuantity"] = data.selectedTotalsBySb.split(',')[i].split('|')[8];
                                    obj["warehouseId"] = data.selectedTotalsBySb.split(',')[i].split('|')[9];
                                    checkedInventory.push(Object.values(obj));
                                }

                                for (var j = 0; j < data.selectedTotalsBySb.split(',').length; j++) {
                                    WholeSale += parseFloat(data.selectedTotalsBySb.split(',')[j].split('|')[6]);
                                    PalletQty += parseInt(data.selectedTotalsBySb.split(',')[j].split('|')[4])
                                    Amount += parseFloat(data.selectedTotalsBySb.split(',')[j].split('|')[6]) * parseFloat(data.selectedTotalsBySb.split(',')[j].split('|')[7]) / 100
                                }
                                WholeSale = WholeSale.toFixed(2);
                                Amount = Amount.toFixed(2);
                                Item = data.selectedTotalsBySb.split(',').length;

                            }
                            else {
                                if (data.value != undefined) {
                                    if (data.value.selectedTotalsBySb != "") {
                                        for (var i = 0; i < data.value.selectedTotalsBySb.split(',').length; i++) {

                                            var obj = {};
                                            obj["manifestId"] = data.value.selectedTotalsBySb.split(',')[i].split('|')[0];
                                            obj["sbNumber"] = data.value.selectedTotalsBySb.split(',')[i].split('|')[1];
                                            obj["category"] = data.value.selectedTotalsBySb.split(',')[i].split('|')[2];
                                            obj["type"] = data.value.selectedTotalsBySb.split(',')[i].split('|')[3];
                                            obj["palletQuantity"] = data.value.selectedTotalsBySb.split(',')[i].split('|')[4];
                                            obj["totalWeight"] = data.value.selectedTotalsBySb.split(',')[i].split('|')[5];
                                            obj["wholesale"] = parseFloat(data.value.selectedTotalsBySb.split(',')[i].split('|')[6]).toFixed(2);
                                            obj["rate%"] = data.value.selectedTotalsBySb.split(',')[i].split('|')[7];
                                            obj["itemQuantity"] = data.value.selectedTotalsBySb.split(',')[i].split('|')[8];
                                            obj["warehouseId"] = data.value.selectedTotalsBySb.split(',')[i].split('|')[9];
                                            checkedInventory.push(Object.values(obj));
                                        }

                                        for (var j = 0; j < data.value.selectedTotalsBySb.split(',').length; j++) {
                                            WholeSale += parseFloat(data.value.selectedTotalsBySb.split(',')[j].split('|')[6]);
                                            PalletQty += parseInt(data.value.selectedTotalsBySb.split(',')[j].split('|')[4])
                                            Amount += parseFloat(data.value.selectedTotalsBySb.split(',')[j].split('|')[6]) * parseFloat(data.value.selectedTotalsBySb.split(',')[j].split('|')[7]) / 100
                                        }
                                        WholeSale = WholeSale.toFixed(2);
                                        Amount = Amount.toFixed(2);
                                        Item = data.value.selectedTotalsBySb.split(',').length;

                                    }
                                }
                            }
                            if (data.manifestList != "" && data.manifestList != null) {
                                var gridData = data.manifestList;
                                var dynamicCategoryList = [];
                                for (var i = 0; i < gridData.length; i++) {
                                    let catCounnt = 0;
                                    for (var j = 0; j < dynamicCategoryList.length; j++) {
                                        if (dynamicCategoryList[j] == gridData[i].salvageCategory) {
                                            catCounnt++;
                                        }
                                    }
                                    if (catCounnt == 0) {
                                        dynamicCategoryList.push(gridData[i].salvageCategory)
                                    }
                                }
                            }
                            //else {
                            //    var gridData = data.manifestList;
                            //    var dynamicCategoryList = [];
                            //    for (var i = 0; i < gridData.length; i++) {
                            //        let catCounnt = 0;
                            //        for (var j = 0; j < dynamicCategoryList.length; j++) {
                            //            if (dynamicCategoryList[j] == gridData[i].salvageCategory) {
                            //                catCounnt++;
                            //            }
                            //        }
                            //        if (catCounnt == 0) {
                            //            dynamicCategoryList.push(gridData[i].salvageCategory)
                            //        }
                            //    }
                            //}
                            dispatch(updateCart(Item, Amount, checkedInventory, PalletQty, dynamicCategoryList, truckvaluetest));
                        },
                        (error) => {
                            console.log(error)
                        }
                    )

            }

        }
        );
    }
}

export function fetchCartData(data1) {
    console.time("fcart ");
    return dispatch => {
        authService.getAccessToken().then(function (token) {
            fetch('InventoryCode/Manage', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(async res => {
                return await commonErrorHandler(res);
            }).then(result => {
                console.timeEnd("fcart ");
                const data = result;
                var checkedInventory = [];
                var ItemQty = 0;
                var PalletQty = 0;
                var WholeSale = 0.0;
                var Amount = 0.0;
                var Item = 0;
                var truckvaluetest = 'namantest';
                if (data.selectedTotalsBySb != null && data.selectedTotalsBySb != "") {
                    for (var i = 0; i < data.selectedTotalsBySb.split(',').length; i++) {

                        var obj = {};
                        obj["manifestId"] = data.selectedTotalsBySb.split(',')[i].split('|')[0];
                        obj["sbNumber"] = data.selectedTotalsBySb.split(',')[i].split('|')[1];
                        obj["category"] = data.selectedTotalsBySb.split(',')[i].split('|')[2];
                        obj["type"] = data.selectedTotalsBySb.split(',')[i].split('|')[3];
                        obj["palletQuantity"] = data.selectedTotalsBySb.split(',')[i].split('|')[4];
                        obj["totalWeight"] = data.selectedTotalsBySb.split(',')[i].split('|')[5];
                        obj["wholesale"] = parseFloat(data.selectedTotalsBySb.split(',')[i].split('|')[6]).toFixed(2);
                        obj["rate%"] = data.selectedTotalsBySb.split(',')[i].split('|')[7];
                        obj["itemQuantity"] = data.selectedTotalsBySb.split(',')[i].split('|')[8];
                        obj["warehouseId"] = data.selectedTotalsBySb.split(',')[i].split('|')[9];
                        obj["blNumber"] = data.selectedTotalsBySb.split(',')[i].split('|')[10];
                        checkedInventory.push(Object.values(obj));
                    }
                    for (var j = 0; j < data.selectedTotalsBySb.split(',').length; j++) {
                        ItemQty += parseInt(data.selectedTotalsBySb.split(',')[j].split('|')[8])
                        PalletQty += parseInt(data.selectedTotalsBySb.split(',')[j].split('|')[4])
                        WholeSale += parseFloat(data.selectedTotalsBySb.split(',')[j].split('|')[6])
                        Amount += parseFloat(data.selectedTotalsBySb.split(',')[j].split('|')[6]) * parseFloat(data.selectedTotalsBySb.split(',')[j].split('|')[7]) / 100
                    }
                    WholeSale = WholeSale.toFixed(2);
                    Amount = Amount.toFixed(2);
                    Item = data.selectedTotalsBySb.split(',').length;
                }
                else {
                    if (data.value != undefined) {
                        if (data.value.selectedTotalsBySb != "") {
                            for (var i = 0; i < data.value.selectedTotalsBySb.split(',').length; i++) {

                                var obj = {};
                                obj["manifestId"] = data.value.selectedTotalsBySb.split(',')[i].split('|')[0];
                                obj["sbNumber"] = data.value.selectedTotalsBySb.split(',')[i].split('|')[1];
                                obj["category"] = data.value.selectedTotalsBySb.split(',')[i].split('|')[2];
                                obj["type"] = data.value.selectedTotalsBySb.split(',')[i].split('|')[3];
                                obj["palletQuantity"] = data.value.selectedTotalsBySb.split(',')[i].split('|')[4];
                                obj["totalWeight"] = data.value.selectedTotalsBySb.split(',')[i].split('|')[5];
                                obj["wholesale"] = parseFloat(data.value.selectedTotalsBySb.split(',')[i].split('|')[6]).toFixed(2);
                                obj["rate%"] = data.value.selectedTotalsBySb.split(',')[i].split('|')[7];
                                obj["itemQuantity"] = data.value.selectedTotalsBySb.split(',')[i].split('|')[8];
                                obj["warehouseId"] = data.value.selectedTotalsBySb.split(',')[i].split('|')[9];
                                checkedInventory.push(Object.values(obj));
                            }

                            for (var j = 0; j < data.value.selectedTotalsBySb.split(',').length; j++) {
                                WholeSale += parseFloat(data.value.selectedTotalsBySb.split(',')[j].split('|')[6]);
                                PalletQty += parseInt(data.value.selectedTotalsBySb.split(',')[j].split('|')[4])
                                Amount += parseFloat(data.value.selectedTotalsBySb.split(',')[j].split('|')[6]) * parseFloat(data.value.selectedTotalsBySb.split(',')[j].split('|')[7]) / 100
                            }
                            WholeSale = WholeSale.toFixed(2);
                            Amount = Amount.toFixed(2);
                            Item = data.value.selectedTotalsBySb.split(',').length;

                        }
                    }
                }
                if (data.manifestList != "" && data.manifestList != null) {
                    var gridData = data.manifestList;
                    var dynamicCategoryList = [];
                    for (var i = 0; i < gridData.length; i++) {
                        let catCounnt = 0;
                        for (var j = 0; j < dynamicCategoryList.length; j++) {
                            if (dynamicCategoryList[j] == gridData[i].salvageCategory) {
                                catCounnt++;
                            }
                        }
                        if (catCounnt == 0) {
                            dynamicCategoryList.push(gridData[i].salvageCategory)
                        }
                    }
                }
                //if (data.value != undefined) {
                //    dispatch(fetchCart(ItemQty, PalletQty, WholeSale, Amount, checkedInventory, dynamicCategoryList, data1.value));
                //    dispatch(updateCart(Item, Amount, checkedInventory, PalletQty, dynamicCategoryList, truckvaluetest));
                //}
                //else {
                dispatch(fetchCart(ItemQty, PalletQty, WholeSale, Amount, checkedInventory, dynamicCategoryList, data1));
                dispatch(updateCart(Item, Amount, checkedInventory, PalletQty, dynamicCategoryList, truckvaluetest));
                /*  }*/
            })
        })
    }
}

export function removeCartItem(act, manifestId) {
    return dispatch => {
        authService.getAccessToken().then(function (token) {
            fetch('InventoryCode/SetSelectedRowsSession?manifestId=' + manifestId + '&abc=' + act, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(res => res.json())
                .then(result => {
                    dispatch(openDialog(0,
                        "Remove Item from Cart",
                        act == "remove" ? "Item removed from the cart." : "All items removed from the cart.", "Close", "", DIALOG_ACTION_CLOSE, null, null));
                    dispatch(fetchCartData());
                    window.location.reload();
                })
        });
    }
}

export function completeOrder() {
    return dispatch => {
        authService.getAccessToken().then(function (token) {
            fetch('InventoryCode/Manage?rc=commitselected', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(async res => {
                /*res.json()*/
                return await commonErrorHandler(res);

            })
                .then(result => {
                    const data = result;
                    if (data.responseType == "restriction" || (data.value !== undefined && data.value.responseType == "restriction")) {
                        //access denied to buy/bid
                        setTimeout(
                            function () {
                                dispatch(openDialog(1,
                                    "Restriction",
                                    "Sorry, Your account has been restricted from purchasing. Please contact your account manager.", "Close", "", DIALOG_ACTION_CLOSE, null, null));
                            }, 100)
                        if (data.value != undefined) {
                            dispatch(fetchCartData(data.value));
                        }
                        else {
                            dispatch(fetchCartData(data));
                        }
                    }
                    else if (data.responseType == "success" || data.value.responseType == "success") {
                        if (data.value != undefined) {
                            dispatch(fetchCartData(data.value));
                        }
                        else {
                            //dispatch(openDialog(0,
                            //    "Add to Order",
                            //    data.responseMessage, "Close", "", DIALOG_ACTION_CLOSE, null, null));
                            dispatch(fetchCartData(data));
                        }
                        //setTimeout(function () {
                        //    window.location.replace("/Inventory"); // you can pass true to reload function to ignore the client cache and reload from the server
                        //}, 3000);
                    }
                    else if (data.responseType == "error" || data.value.responseType == "error") {
                        if (data.value != undefined) {
                            dispatch(fetchCartData(data.value));
                        }
                        else {
                            //dispatch(openDialog(1,
                            //    data.responseType,
                            //    data.responseMessage, "Close", "", DIALOG_ACTION_CLOSE, null, null));
                            dispatch(fetchCartData(data));
                        }
                    }

                    //dispatch(openDialog(0,
                    //    "Order",
                    //    "Order has been placed successfully", "Close", "", DIALOG_ACTION_CLOSE, null, null));
                    //dispatch(fetchCartData());
                })
        });
    }
}


export function createNewOrder() {
    return dispatch => {
        authService.getAccessToken().then(function (token) {
            fetch('InventoryCode/createNewOrder', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(res => res.json())
                .then(result => {
                    dispatch(openDialog(0,
                        "New Order",
                        "New Order created successfully", "Close", "", DIALOG_ACTION_CLOSE, null, null));
                    dispatch(fetchOrders({ timeStamp : (new Date), data : [] }));
                })
        });
    }
}

export function completeOpenOrder(id) {
    return dispatch => {
        authService.getAccessToken().then(function (token) {
            fetch('InventoryCode/CompleteOrder/' + id, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(res => res.json())
                .then(result => {
                    dispatch(openDialog(0,
                        "Complete Order",
                        "Order completed successfully", "Close", "", DIALOG_ACTION_CLOSE, null, null));
                    dispatch(fetchOrders({ timeStamp: (new Date), data: [] }));
                })
        });
    }
}

export function addToOrder(id) {
    return dispatch => {
        authService.getAccessToken().then(function (token) {
            fetch('InventoryCode/AssignToOrder/' + id, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(res => res.json())
                .then(result => {
                    const data = result;
                    var checkedInventory = [];
                    var PalletQty = 0;
                    var Amount = 0.0;
                    var Item = 0;
                    if (data.status == "success") {
                        dispatch(openDialog(0,
                            "Add to Order",
                            "Add to order successfully", "Close", "", DIALOG_ACTION_CLOSE, null, null));
                        dispatch(fetchOrders({ timeStamp: (new Date), data: [] }));
                        dispatch(updateCart(Item, Amount, checkedInventory, PalletQty));
                        // window.location.reload();
                    }
                    else if (data.status == "failure") {
                        dispatch(openDialog(1,
                            data.status,
                            data.message, "Close", "", DIALOG_ACTION_CLOSE, null, null));
                        dispatch(fetchOrders({ timeStamp: (new Date), data: [] }));
                    }
                })
        });
    }
}

export function removeSbFromOrder(id, manifestId, SBNmuber) {
    const formData = new FormData();

    formData.append("orderId", id);
    formData.append("manifestId", manifestId);
    formData.append("sbNum", SBNmuber);
    return dispatch => {
        authService.getAccessToken().then(function (token) {
            fetch('Order/UnlinkManifest', {
                method: 'post',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                body: formData
            }).then(res => res.json())
                .then(result => {
                    dispatch(openDialog(0,
                        "Remove SB From Order ",
                        "SB #" + SBNmuber + "  is removed from order #" + id + " successfully", "Close", "", DIALOG_ACTION_CLOSE, null, null));
                    dispatch(fetchOrders({ timeStamp: (new Date), data: [] }));
                    // reload();
                })
        });
    }
}

export function updateViewCart(Item, Amount, checkedInventory, PalletQty, dynamicCategoryList) {
    return (dispatch => {
        dispatch(updateCart(Item, Amount, checkedInventory, PalletQty, dynamicCategoryList));
    });
}
