import authService from './components/api-authorization/AuthorizeService';
import { AuthenticationResultStatus } from './components/api-authorization/AuthorizeService';
import { QueryParameterNames, ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import { get } from 'jquery';

function navigateToReturnUrl(returnUrl) {
    window.location.replace(returnUrl);
}

function getReturnUrl(state) {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
        // This is an extra check to prevent open redirects.
        alert("Error getReturnUrl");
        throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
    }
    return (state && state.returnUrl) ||
        fromQuery ||
        `${window.location.origin}${ApplicationPaths.Login}`;
}
export const commonErrorHandler = async (res) => {
    
    if (!res.ok) {
        var headerType = res.headers.get('www-authenticate');
        if ((headerType && headerType?.includes('invalid_token')) || (res.status==503)) {
            await authService.signOut("direct_signout");
        }
    }
    return res.json();

}