import React, { Component } from 'react';
import ContentLayout from "../components/ContentLayout";
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import { Col, Container, Row, Table, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import authService from '../components/api-authorization/AuthorizeService';
import { connect } from 'react-redux';
import { updateCartData, fetchCartData, removeCartItem, completeOrder } from '../actions/cart-actions';
import { DIALOG_ACTION_CLOSE, DIALOG_ACTION_REMOVE_CART_ITEM, DIALOG_ACTION_CLEARCART } from '../constants';
import { openDialog } from '../actions/dialog-actions';
import { updateSearchList, returnUpdateSearch} from '../actions/search-actions';
import { Modal } from 'react-bootstrap';
import { numberformat, warehouse } from "../DateHelper";
import InventoryDetail from '../components/DataTable/InventoryDetail';
import RotateLoader from 'react-spinners/RotateLoader'
import { css } from "@emotion/core";
import { commonErrorHandler } from '../Utils'
const override = css`
  display: block;
  margin: 2 auto;
  border-color: red;
`;
const style = { position: "fixed", top: "45%", left: "50%", transform: "translate(-50%, -50%)" };

class ViewCart extends Component {
    constructor(props) {
        super(props);
        this.removeChecked = this.removeChecked.bind(this);
        this.props.updateSearchList([]);
        this.props.returnUpdateSearch();
        this.state = {
            orderdata: [], checkedData: [],
            itemQty: "", palletQty: "", wholeSale: "", amount: "", show: false, completeOrdertrue:false,buttonDisabled:false,
            loading: true,

        };
    }
    async componentDidMount() {

        const token = await authService.getAccessToken();
        const response = await fetch('InventoryCode/IsBuyAndBidAllowed', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        /*const data = await response.json();*/
        const data = await commonErrorHandler(response);

        if (data.accessDenied != undefined && data.accessDenied != null && data.accessDenied == true) { //accessDenied [TRUE then don't allow, allow otherwise]
            //access denied to buy/bid
            this.props.openDialog(1,
                "Restriction",
                "Sorry, Your account has been restricted from purchasing. Please contact your account manager.", "Close", "", DIALOG_ACTION_CLOSE, null, null);
            setTimeout(() => {
                this.props.history.push('/Inventory');
            }, 2000);
        }
        else {
            this.props.getCartData();
        }
        //if (this.props.cartData != null && this.props.cartData.data != undefined) {
        //    this.setState({ completeOrdertrue: false });
        //    this.props.openDialog(0,
        //        "Add to Order",
        //        this.props.cartData.data.responseMessage, "Close", "", DIALOG_ACTION_CLOSE, null, null);
        //}
    }
    componentDidUpdate(prevProps) {
        if (this.props.cartData.data != prevProps.cartData.data) {
            if (this.props.cartData != null && this.props.cartData.data != undefined) {
                this.setState({ completeOrdertrue: false });
                if (this.props.cartData.data.responseType === "success") {
                    this.props.openDialog(0,
                        "Complete Order",
                        this.props.cartData.data.responseMessage, "Close", "", DIALOG_ACTION_CLOSE, null, null);
                    setTimeout(() => {
                        this.props.history.push('/Inventory');
                    }, 3000);
                }
                else if (this.props.cartData.data.responseType === "error"){
                    this.setState({ completeOrdertrue: false });
                    this.props.openDialog(1,
                        this.props.cartData.data.responseType,
                        this.props.cartData.data.responseMessage, "Close", "", DIALOG_ACTION_CLOSE, null, null);
                }
            }
        }
    }

    removeChecked(id, SBNmuber, itemName) {
        this.props.openDialog(0, "Remove Item from Cart", "Are you sure that you want remove SB # " + SBNmuber + " : " + itemName + " from the cart?", "Cancel", "Continue", DIALOG_ACTION_CLOSE, DIALOG_ACTION_REMOVE_CART_ITEM, { action: "remove", id: id });
    }

    ClearSelect() {
        this.props.openDialog(0, "Clear Cart", "Are you sure you want to clear the cart?", "Cancel", "Continue", DIALOG_ACTION_CLOSE, DIALOG_ACTION_CLEARCART, { action: "reset", id: 0 });
    }
    CompleteOrder() {
        this.setState({ completeOrdertrue: true, buttonDisabled:true });
        this.props.completeOrder()

    }
    AssignSBtoOrder() {
        this.props.history.push({
            pathname: '/MyOrders',
            state: 'Open',
            act: 'assignSelected'

        })
    }

    navigateToInventory = (location) => {
        this.props.history.push({
            pathname: "/Inventory",
            allLocation: location
        })
    }
    getSbDetails(sbnumber, wid, category, pallet,blNumber) {
        this.setState({ SBnumber: sbnumber, warehouseId: wid, salvageCategory: category, palletQty: pallet, BLNumber: blNumber });
        this.setState({ show: true });

    }
    handleClose = () => {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }
    render() {
        const { completeOrdertrue, buttonDisabled } = this.state;
        const enabled =
            this.props.cartData.checkedData.length > 0;
        return (
            <ContentLayout>
                <Breadcrumb
                    breadcrumb={[
                        { name: "Home", path: "/" },
                        { name: "View Cart", path: "/View-Cart" }
                    ]}
                />
                <div className="content pt-5 pb-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="12">
                                <h5 className="Cart__Summary__title">Cart Summary</h5>
                                <div className="main__cart">
                                    <Table responsive className="border-0 cart__table">
                                        <thead>
                                            <tr>
                                                <td>SB #</td>
                                                <td className="text-center">Category</td>
                                                <td className="text-center">Warehouse</td>
                                                <td className="text-center">Type</td>
                                                <td className="text-right">Item Qty</td>
                                                <td className="text-right">Pallet Qty</td>
                                                <td className="text-center">Wholesale</td>
                                                <td className="text-center">%</td>
                                                <td className="text-center">Amount</td>
                                                <td className="text-center">Remove</td>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {this.props.cartData.checkedData != null ? this.props.cartData.checkedData.map(item => {
                                                return (
                                                    <tr key={item[0]}>
                                                        <td className="b__dotted" onClick={() => { this.getSbDetails(item[1], item[9], item[2], item[4], item[10]) }} style={{ cursor: 'pointer' }}><span>{item[1]}</span></td>
                                                        <td className="text-center">{item[2]}</td>
                                                        <td className="text-center">{item[9] ? warehouse(item[9]): item[9]}</td>
                                                        <td className="stock"><i className="fas fa-dot-circle"></i> {item[3]}</td>
                                                        <td className="text-center"><span>{item[8]}</span></td>
                                                        <td className="text-center">{item[4]}</td>
                                                        <td className="text-center">{item[6] ? numberformat(item[6]) : item[6]}</td>
                                                        <td className="text-center">{item[7]}</td>
                                                        <td className="text-center">{(parseFloat(item[6]) * parseFloat(item[7]) / 100).toFixed(2) ? numberformat((parseFloat(item[6]) * parseFloat(item[7]) / 100).toFixed(2)) : (parseFloat(item[6]) * parseFloat(item[7]) / 100).toFixed(2)}</td>
                                                        <td className="text-center" style={{ width: "140px" }}>
                                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Remove item!</Tooltip>}>
                                                                <span className="d-inline-block">
                                                                    <Button onClick={() => { this.removeChecked(item[0], item[1], item[2]) }}>
                                                                        <i className="fas fa-times"></i>
                                                                    </Button>
                                                                </span>
                                                            </OverlayTrigger>
                                                        </td>
                                                    </tr>
                                                )
                                            }) : null}
                                            <tr>
                                                <td></td>
                                                <td className="text-center"><span><strong>Total</strong></span></td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                                <td className="text-center"><strong>{this.props.cartData.itemQty}</strong></td>
                                                <td className="text-center"><strong>{this.props.cartData.palletQty}</strong></td>
                                                <td className="text-center"><span><strong>{this.props.cartData.wholeSale ? numberformat(this.props.cartData.wholeSale) : this.props.cartData.wholeSale}</strong></span></td>
                                                <td className="text-right"></td>
                                                <td className="text-center"><span><strong>{this.props.cartData.amount ? numberformat(this.props.cartData.amount) : this.props.cartData.amount}</strong></span></td>
                                                <td colSpan="2" className="text-right"><Button disabled={!enabled || buttonDisabled} onClick={() => this.CompleteOrder() } variant="outline-primary">Complete Order</Button></td>
                                            </tr>
                                            <tr>
                                                <td><Button onClick={() => this.navigateToInventory("AllLocation")} variant="outline-secondary">Search Inventory</Button></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td colSpan="3" className="text-right"><Button disabled={!enabled} variant="outline-secondary" onClick={() => { this.ClearSelect() }}>Clear Cart</Button></td>
                                                {/* <td colSpan="3" className="text-right"><Button disabled={!enabled} variant="outline-secondary" onClick={() => this.AssignSBtoOrder()}>ASSIGN SB'S TO AN OPEN ORDER</Button></td>*/}
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {
                    completeOrdertrue ?
                        <div style={style}>
                            <RotateLoader
                                css={override}
                                size={15}
                                color={"#fd7e14"}
                                loading={completeOrdertrue}
                            />
                        </div> : null
                }
                <Modal centered size="xl" show={this.state.show} onHide={this.handleClose} className="sb-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>SB Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p><strong> SB #:</strong> {this.state.SBnumber}, <strong>Bl #:</strong> {this.state.BLNumber}, <strong>Warehouse:</strong> {this.state.warehouseId ? warehouse(this.state.warehouseId) : this.state.warehouseId}, <strong>Category:</strong>{this.state.salvageCategory}, <strong>Pallet Qty:</strong> {this.state.palletQty}</p>
                        <InventoryDetail sbNumber={this.state.SBnumber} warehouse={this.state.warehouseId} />
                    </Modal.Body>
                </Modal>
            </ContentLayout>
        );
    }
}


const mapStateToProps = state => ({
    cart: state.cart,
    cartData: state.cartData
});

const mapActionsToProps = {
    getCartSummary: updateCartData,
    getCartData: fetchCartData,
    removeCartItem: removeCartItem,
    openDialog: openDialog,
    completeOrder: completeOrder,
    updateSearchList: updateSearchList,
    returnUpdateSearch: returnUpdateSearch
}

export default connect(mapStateToProps, mapActionsToProps)(ViewCart);