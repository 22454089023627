import { OPEN_DIALOG,CLOSE_DIALOG } from '../constants';

export const dialogReducer = function (state = {errorCode:0, open:false,title:null,message:null,onCloseAction:null,onContinueAction:null}, action) {
    let returnValue = state;

    switch (action.type) {
        case OPEN_DIALOG:
            returnValue = action.payload;
            break;
        case CLOSE_DIALOG:
            returnValue = action.payload;
    }

    return returnValue;
}