import React, { Component } from "react";
import { Table } from 'react-bootstrap';
import "../../assets/css/jquery.dataTables.min.css";
import authService from '../api-authorization/AuthorizeService';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline'
import ReactTable from './ReactTable'
import { CSVLink } from "react-csv";
import { numberformat } from '../../DateHelper';




class Timmer extends Component {
    isComponentMounted = false;
    constructor(props) {
        super(props);
        // console.log(props);

        this.state = {
            time: this.props.time,
        

            duration: 6500





        };
        this.timer = null;
    }

    componentDidMount() {
        this.isComponentMounted = true;
        this.PopulateTimerValue(this.props.time);

    }
    componentDidUpdate() {
        if (this.state.hours == "00" && this.state.minutes == "00" && this.state.seconds == "00") {
            window.clearTimeout(this.timer)
            this.timer = null
        }
    }
    componentWillUnmount() {
        this.isComponentMounted = false;
        clearInterval(this.timer);
    }
    async PopulateTimerValue(truckid) {
        
            const token = await authService.getAccessToken();
            const response = await fetch('InventoryCode/CheckATimmerwhenmovetomain?truckid=' + truckid + '&IsStandardorTruck=' + 2 + '&sbnumber=' + '', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });

            const data = await response.json();
            if (data != null && this.isComponentMounted === true) {


                let dynamicCategoryListt = [];
                this.setState({
                    gettimedata: []
                })

                dynamicCategoryListt.push({ Time: data[0].timestamp })


                this.setState({
                    gettimedata: dynamicCategoryListt
                })

                this.start(dynamicCategoryListt)
            }

            else {

            }
    }

    async timeOver(truckid) {
        const token = await authService.getAccessToken();
        const response = await fetch('InventoryCode/Timeoverforbid?truckid=' + truckid + '&IsStandardorTruck=' + 2, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        const data = await response.json();
        if (data.data != null) {

        }
        else {

        }

    }




    start(timval) {

        if (!this.timer) {
            var date = new Date(timval[0].Time);
            var milliseconds = date.getTime();
            this.state.startTime = milliseconds;

            this.timer = window.setInterval(() => this.run(), 10);

        }
    }

    run() {

        var now = new Date();
        var utc_now = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
        //alert(utc_now);
        //alert(utc_now.getTime());
      //  var utc = new Date(utc_now.getTime() + now.getTimezoneOffset() * 60000);
        var nd = utc_now.getTime();
        //alert(nd);
        //var dateinutc = new Date(utc);
      //  var utcval = nd.getTime();
        //alert(utcval);
        const diff = nd - this.state.startTime ;
       

        let remaining = this.state.duration - diff;
      //  alert(remaining);
        if (remaining < 0) {
            remaining = 0;
            window.clearTimeout(this.timer)
            this.timer = null
        }





        this.setState({
            time: this.msToTime(remaining)
        });
        if (remaining === 0) {
            window.clearTimeout(this.timer);
            this.timer = null;
        }
    }

    msToTime(duration) {

        let milliseconds = parseInt((duration % 1000));
   let seconds = Math.floor((duration / 1000) % 60);
   let minutes = Math.floor((duration / (1000 * 60)) % 60);
   let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

       hours = hours.toString().padStart(2, '0');
       minutes = minutes.toString().padStart(2, '0');
       seconds = seconds.toString().padStart(2, '0');
       milliseconds = milliseconds.toString().padStart(3, '0');

        this.setState({ hours: hours, minutes: minutes, seconds: seconds, milliseconds: milliseconds });
    }


    render() {

        const timeOver = (val) => {
            let component = '';

            this.timeOver(val);

            return component;
        }
        //]
        //console.log(this.state);

        if (this.state.hours == "00" && this.state.minutes == "00" && this.state.seconds == "00") {
            window.clearTimeout(this.timer)
            this.timer = null

            return (

                <div>
                    {timeOver(this.props.time)}

                </div>
            );
        }
        else {
            return (
                <div>

                    {

                        this.state.hours
                    }: {
                        this.state.minutes
                    }: {
                        this.state.seconds
                    }




                </div>
            );
        }
    }

}

export default Timmer;
