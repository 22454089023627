import React, { Component } from 'react';
import { Col, Row, Form, Button, ButtonToolbar, Container } from 'react-bootstrap';
import ContentLayout from "../components/ContentLayout";
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';

import TruckLoadOrderData from '../components/DataTable/TruckLoadOrderData';
import { updateSearchListt, updateSearchList } from '../actions/search-actions'
import OrderDetailModal from '../components/DataTable/Order-Detail-Modal';
import authService from '../components/api-authorization/AuthorizeService'
import { connect } from 'react-redux';
import { updateCartData } from '../actions/cart-actions';
import { createNewOrder } from '../actions/cart-actions';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Icon from '@material-ui/core/Icon';

class TruckLoadOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderdata: [], loading: true, data: '',
            orderDetail: [], orderDetailDate: []
        };
        this.props.updateSearchList([]);
        this.props.updateSearchListt([
            {
                id: '1',
                caption: 'SB Number',
                name: 'sbNumber'
            },
            {
                id: '2',
                caption: 'BL Number',
                name: 'blNumber'
            },
            //{
            //    id: '3',
            //    caption: 'Warehouse',
            //    name: 'warehouse'
            //},
            {
                id: '4',
                caption: 'Category',
                name: 'salvageCategory'
            },
            //{
            //    id: '5',
            //    caption: 'Type',
            //    name: 'hdDotCom'
            //},
            //{
            //    id: '6',
            //    caption: 'Item',
            //    name: 'itemQuantity'
            //},
            {
                id: '7',
                caption: 'Pallet Quantity',
                name: 'containerQuantity'
            }
            //},
            //{
            //    id: '8',
            //    caption: 'Wholesale',
            //    name: 'cost'
            //},
            //{
            //    id: '9',
            //    caption: 'Rate',
            //    name: 'rate'
            //},
            //{
            //    id: '10',
            //    caption: 'Cost',
            //    name: 'rate_Cost'
            //}

        ]);
        //this.handleSubmit = this.handleSubmit.bind(this);
    }


   
    render() {
        return (
            <ContentLayout>
                <Breadcrumb
                    breadcrumb={[
                        { name: "Home", path: "/" },
                        { name: "My TruckLoadOrders", path: "/TruckLoadOrders" }
                    ]}
                />
            
                <div className="bg-grey pt-5 pb-2"> 
                  
                    <Container fluid className="pl-5 pr-5">
                        <h1>TruckLoad Orders</h1>
                        <div className="bg-grey pt-2 pb-0">
                            <Row>
                                <Col lg="8">{
                                    //   <Button className="text-white" onClick={() => this.navigateToInventory()} variant="outline-secondary" style={{ backgroundColor: "#273546" }}><ArrowBackIcon /> <span>Back to Inventory</span></Button>
                                } </Col>
                            </Row>
                        </div>
                    </Container>
                </div>

                <div className="content invoices-contant border-top bg-grey pt-0 pb-5">
               
                    <Container fluid className="pl-5 pr-5">
                        <Row className="justify-content-center">
                            <Col md="12">
                                <TruckLoadOrderData />
                            </Col>
                            {
                                //    <Col lg="4">
                                //        {this.state.orderDetail != null ? this.state.orderDetail.map(manifestList => {
                                //            return (
                                //                <OrderDetailModal orderNumber={this.state.orderNumber} sbNumber={manifestList.sbNumber} blNumber={manifestList.blNumber} warehouse={this.state.warehouse}
                                //                    hdDotCom={manifestList.hdDotCom} itemQuantity={manifestList.itemQuantity} wholesale={manifestList.cost} ContQuantity={manifestList.containerQuantity}
                                //                    status={this.state.status} DateTime={this.state.orderDetailDate.orderDateTime} salvageCategory={manifestList.salvageCategory} warehouseId={manifestList.warehouse} manifestId={manifestList.id} />
                                //            )
                                //        })
                                //            :
                                //            null}

                                //    </Col>
                            }
                        </Row>
                    </Container>

                </div>
            </ContentLayout>
        );
    }

}
const mapStateToProps = state => ({
    cart: state.cart
});


const mapActionsToProps = {
    getCartSummary: updateCartData,
    updateSearchListt: updateSearchListt,
    updateSearchList: updateSearchList,
}


export default connect(mapStateToProps, mapActionsToProps)(TruckLoadOrders);